import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../components/Sidebar";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import MuiDatatables from "mui-datatables";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import MetaTags from "react-meta-tags";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { Visibility as VisibilityIcon } from "@material-ui/icons";
import {
  collection,
  doc,
  Firestore,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { createTheme, makeStyles, ThemeProvider } from "@mui/material/styles";
import { toast } from "react-toastify";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  CssBaseline,
  Paper,
} from "@mui/material";
import { db, storage } from "../../components/utils/firebase";
import { Add, Delete } from "@mui/icons-material";
import FileUploaderComponent from "../components/FileUploaderComponent";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "@firebase/storage";
import Navbar from "../../components/Navbar/Navbar";
import { CustomerProvider } from "../../components/Navbar/CustomerContext";
import CustomSearchRender from "../../components/CustomSearchRender";
import { styled } from "@mui/material/styles";

const CustomMuiPaper = styled(Paper)({
  display: "flex",
  flexDirection: "column",
  height: "auto",
  maxHeight: "80vh",
  overflow: "auto",
});

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          height: "100%",
          // overflow: 'auto',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          flex: "1 1 auto", // Allow the table container to grow and shrink
        },
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          marginTop: "auto", // This ensures the footer stays at the bottom
        },
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          height: "20px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: '"Montserrat", sans-serif',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
          fontWeight: 600,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
          padding: "0px",
          textAlign: "start",
          justifyContent: "start",
          fontWeight: 600,
        },
      },
    },
  },
  MuiTableCell: {
    root: {
      borderColor: "#d3d3d3",
      fontWeight: 600,
    },
    head: {
      background: "lightgrey",
      "&:not(:last-child)": {
        borderRight: [[1, "solid", "#c0c0c0"]],
      },
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const Allcustomers = () => {
  const [customers, setCustomers] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [clientId, setClientId] = useState(null);
  const navigate = useNavigate();

  const [editCustomer, setEditCustomer] = useState(null);
  const [viewId, setViewId] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    const storedClientId = sessionStorage.getItem("clientId");
    setClientId(storedClientId);
    if (storedClientId) {
      fetchCustomerData(storedClientId);
    } else {
      setLoading(false);
    }
  }, []);

  const fetchCustomerData = (id) => {
    // Retrieve the last fetch time from session storage
    const lastFetchTimeRaw = sessionStorage.getItem(
      "localCustomerDataFetchTime"
    );
    const lastFetchTime = lastFetchTimeRaw
      ? new Date(lastFetchTimeRaw)
      : new Date(0);

    // Fetch cached customers from session storage
    const cachedCustomers =
      JSON.parse(sessionStorage.getItem("localCustomerData")) || [];
      console.log('cachedCustomers :>> ', cachedCustomers);
    setCustomers(cachedCustomers); // Update the state with cached data

    // Reference to the client's document in Firestore
    const docRef = doc(db, "clients", id);
    getDoc(docRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          const companyId = docSnap.data().companyId; // Get the company ID from the document

          // Create a query for the "customers" collection with filters
          const q = query(
            collection(db, "customers"),
            where("Personal.companyId", "==", companyId),
            where("updated_at", ">", lastFetchTime), // Filter for updates after last fetch time
            orderBy("Personal.name") // Order the results by name
          );

          // Set up a real-time listener with onSnapshot
          const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const newCustomers = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            // Merge new data with cached customers
            const updatedCustomers = [...cachedCustomers];

            newCustomers.forEach((newCustomer) => {
              const index = updatedCustomers.findIndex(
                (cached) => cached.id === newCustomer.id
              );
              if (index > -1) {
                updatedCustomers[index] = newCustomer;
              } else {
                updatedCustomers.push(newCustomer);
              }
            });

            // Update session storage with the latest data
            sessionStorage.setItem(
              "localCustomerData",
              JSON.stringify(updatedCustomers)
            );
            sessionStorage.setItem(
              "localCustomerDataFetchTime",
              new Date().toISOString()
            );

            // Update the state with the latest data
            setCustomers(updatedCustomers);
            setLoading(false); // Set loading to false after data is loaded
          });

          // Clean up the listener on component unmount
          return () => unsubscribe();
        } else {
          console.log("No such document!");
          setLoading(false); // Set loading to false if no document is found
        }
      })
      .catch((error) => {
        console.error("Error fetching document:", error);
        setError(error.message); // Set the error message in state
        setLoading(false); // Set loading to false if an error occurs
      });
  };
  // const fetchCustomerData = async (id) => {
  //   try {
  //     const lastFetchTimeRaw = sessionStorage.getItem(
  //       "localCustomerDataFetchTime"
  //     );
  //     const lastFetchTime = lastFetchTimeRaw
  //       ? new Date(lastFetchTimeRaw)
  //       : new Date(0);

  //     // Format lastFetchTime in the desired format
  //     const formattedLastFetchTime = lastFetchTime.toLocaleString("en-US", {
  //       month: "long",
  //       day: "numeric",
  //       year: "numeric",
  //       hour: "numeric",
  //       minute: "numeric",
  //       second: "numeric",
  //       hour12: true,
  //       timeZoneName: "short",
  //     });

  //     // Fetch cached customers
  //     const cachedCustomers = JSON.parse(sessionStorage.getItem("localCustomerData")) || [];
  //     setCustomers(cachedCustomers);

  //     const docRef = doc(db, "clients", id);
  //     const docSnap = await getDoc(docRef);

  //     if (docSnap.exists()) {
  //       const querySnapshot = await getDocs(
  //         query(
  //           collection(db, "customers"),
  //           where("Personal.companyId", "==", docSnap.data().companyId),
  //           where("updated_at", ">", lastFetchTime),
  //           orderBy("Personal.name")
  //         )
  //       );

  //       const newCustomers = querySnapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));

  //       // Merge new and cached customers
  //       const updatedCustomers = [...cachedCustomers];

  //       newCustomers.forEach((newCustomer) => {
  //         const index = updatedCustomers.findIndex(
  //           (cached) => cached.id === newCustomer.id
  //         );
  //         if (index > -1) {
  //           updatedCustomers[index] = newCustomer;
  //         } else {
  //           updatedCustomers.push(newCustomer);
  //         }
  //       });

  //       // console.log("updatedCustomers :>> ", updatedCustomers);

  //       // Update local storage
  //       sessionStorage.setItem(
  //         "localCustomerData",
  //         JSON.stringify(updatedCustomers)
  //       );
  //       sessionStorage.setItem(
  //         "localCustomerDataFetchTime",
  //         new Date().toISOString()
  //       );

  //       setCustomers(updatedCustomers);
  //     } else {
  //       console.log("No such document!");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching customers:", error);
  //     setError(error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const handleIconClick = (customer, index) => {
  //   setSelectedCustomer(customer);
  //   console.log('familymember.id :>> ', customer.Personal?.family_member);
  //   setDrawerOpen(true);
  // };

  const [familyMembers, setFamilyMembers] = useState([]);

  const [familyMemberId, setFamilyMemberId] = useState([]);
  const [familyMemberNames, setFamilyMemberNames] = useState([]);

  const fetchFamilyMemberNames = async (memberIds) => {
    const promises = memberIds.map(async (id) => {
      const docRef = doc(db, "customers", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return docSnap.data().Personal?.name; // Assuming the name field is stored under 'name'
      } else {
        console.log("No such document!");
        return null;
      }
    });

    const names = await Promise.all(promises);
    return names.filter((name) => name !== null); // Filter out any null values
  };

  const [refName, setRefName] = useState("");

  const fetchNameByRefID = async (refID) => {
    if (!refID) return null;

    try {
      const docRef = doc(db, "customers", refID);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return docSnap.data().Personal.name; // Adjust based on your Firestore structure
      } else {
        console.log("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error fetching name by refID: ", error);
      return null;
    }
  };

  const handleEditClick = (customer) => {
    setSelectedCustomer(customer);
    setEditCustomer(customer);
    // console.log('customer :>> ', customer.id);
    setDialogOpen(true);
  };

  const handleIconClick = async (customer) => {
    console.log("customer.id :>> ", customer.id);
    setSelectedCustomer(customer);
    setDrawerOpen(true);
    const custId = customer.id;
    setViewId(custId);
    // console.log("viewId :>> ", viewId);
    const memberId = customer?.Personal?.family_member.map(
      (member) => member.id
    );
    setFamilyMemberId(memberId);

    const refID = customer?.Personal?.ref_by;

    const memberNames = await fetchFamilyMemberNames(memberId);
    setFamilyMemberNames(memberNames); // Update state with names

    const refName = await fetchNameByRefID(refID);
    setRefName(refName); // Update state with refName
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedCustomer(null);
    setEditCustomer(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditCustomer((prevCustomer) => ({
      ...prevCustomer,
      Personal: {
        ...prevCustomer.Personal,
        [name]: value,
      },
    }));
  };

  const handleEditSubmit = async () => {
    try {
      setLoading(true);
      const docRef = doc(db, "customers", editCustomer.id);
      await updateDoc(docRef, {
        ...editCustomer,
        updated_at: serverTimestamp(),
      });
      setCustomers((prevCustomer) =>
        prevCustomer.map((customer) =>
          customer.id === editCustomer.id ? editCustomer : customer
        )
      );
      toast.success("User Data Update successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setDialogOpen(false);
    } catch (error) {
      console.error("Error updating document: ", error);
    } finally {
      setLoading(false);
    }
  };

  const [documents, setDocuments] = useState([]);
  const [documentName, setDocumentName] = useState("");

  useEffect(() => {
    if (selectedCustomer?.Personal?.documents) {
      setDocuments(selectedCustomer.Personal.documents);
    }
  }, [selectedCustomer]);

  const fileInputRef = useRef(null);

  const fetchDocuments = async (customerId) => {
    try {
      const docRef = doc(db, "customers", customerId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const customerData = docSnap.data();
        // console.log("customerData :>> ", customerData);
        // setOldDocuments(customerData.Personal?.documents || []);
        setDocuments(customerData.Personal?.documents || []); // Set existing documents array
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const [newDocuments, setNewDocuments] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);

  const handleClickOpen = async (customer) => {
    document.getElementById("fileInput").click();
    const custId = customer.id;
    setViewId(custId);
    await fetchDocuments(custId);
  };

  const handleFileChange = (e) => {
    const { name, value, files } = e.target;
    setOpen(true);
    if (name === "Newdocuments") {
      const filesArray = Array.from(e.target.files).map((file) => ({
        // file: file,
        name: file.name,
        url: URL.createObjectURL(file),
        editName: file.name,
      }));
      // console.log("filesArray :>> ", filesArray);
      setNewDocuments((prevDocuments) => [...prevDocuments, ...filesArray]);
      setDocuments((prevDocuments) => [...prevDocuments]);
      // console.log("documents :>> ", documents);
    }
  };

  const handleDocumentsUpload = async () => {
    try {
      setLoading(true);

      const uploadedDocuments = [];
      for (let i = 0; i < newDocuments.length; i++) {
        const documentRef = ref(
          storage,
          `CustomerDocument/${clientId}/${viewId}/${newDocuments[i].name}`
        );
        // console.log("documentRef :>> ", documentRef);
        await uploadBytes(documentRef, newDocuments[i].file);
        const documentURL = await getDownloadURL(documentRef);
        // console.log("documentURL :>> ", documentURL);
        uploadedDocuments.push({
          name: newDocuments[i].name,
          url: documentURL,
        });
      }

      const mergedDocuments = [...documents, ...uploadedDocuments];
      // console.log("mergedDocuments :>> ", mergedDocuments);

      const docRef = doc(db, "customers", viewId);
      await updateDoc(docRef, {
        // editCustomer,
        "Personal.documents": mergedDocuments,
        updated_at: serverTimestamp(),
      });

      toast.success("Documents uploaded successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setOpen(false);
    } catch (error) {
      console.error("Error uploading documents: ", error);
      toast.error("Failed to upload documents. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleEditNameChange = (index, newName) => {
    setNewDocuments((prevDocuments) => {
      const updatedDocuments = [...prevDocuments];
      updatedDocuments[index].editName = newName;
      return updatedDocuments;
    });
  };

  const handleSaveNameChange = (index) => {
    setNewDocuments((prevDocuments) => {
      const updatedDocuments = [...prevDocuments];
      updatedDocuments[index].name = updatedDocuments[index].editName;
      return updatedDocuments;
    });
  };

  const handleDeleteDocument = (index) => {
    setNewDocuments((prevDocuments) => {
      const updatedDocuments = [...prevDocuments];
      updatedDocuments.splice(index, 1);
      return updatedDocuments;
    });
  };

  const handleAddDocument = () => {
    if (documentName.trim()) {
      setDocuments([...documents, documentName]);
      setDocumentName("");
    }
  };

  // const handleDelete = async (docUrl) => {
  //   try {
  //     setLoading(true);
  //     const docRef = doc(db, "customers", viewId); // Adjust the path to your Firestore collection
  //     const customerDoc = await getDoc(docRef);

  //     if (customerDoc.exists()) {
  //       const updatedDocuments = customerDoc
  //         .data()
  //         .Personal.documents.filter((doc) => doc.url !== docUrl);

  //       await updateDoc(docRef, {
  //         "Personal.documents": updatedDocuments,
  //       });

  //       setDocuments((prevDocuments) =>
  //         prevDocuments.filter((doc) => doc.url !== docUrl)
  //       );

  //       console.log("Document successfully deleted!");
  //     } else {
  //       console.log("No such document!");
  //     }
  //   } catch (error) {
  //     console.error("Error removing document: ", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleDelete = async (docUrl) => {
    try {
      setLoading(true);

      // Reference to the Firestore document
      const docRef = doc(db, "customers", selectedCustomer.id);
      const customersDoc = await getDoc(docRef);

      if (customersDoc.exists()) {
        // Filter out the document that needs to be deleted
        const updatedDocuments = customersDoc
          .data()
          .Personal.documents.filter((doc) => doc.url !== docUrl);

        // Update Firestore with the remaining documents
        await updateDoc(docRef, {
          "Personal.documents": updatedDocuments,
        });

        // Delete the document from Firebase Storage
        const fileName = docUrl; // Extract file name from URL
        console.log("fileName :>> ", fileName);

        const documentRef = ref(storage, fileName);
        console.log("documentRef :>> ", documentRef);

        await deleteObject(documentRef).catch((error) => {
          console.error("Failed to delete document from storage:", error);
        });

        // Update state to reflect the deletion
        setDocuments((prevDocuments) =>
          prevDocuments.filter((doc) => doc.url !== docUrl)
        );

        setCustomers((prevCustomers) => {
          return prevCustomers.map((customer) => {
            if (customer.id === selectedCustomer.id) {
              return {
                ...customer,
                Personal: {
                  ...customer.Personal,
                  documents: updatedDocuments,
                },
              };
            }
            return customer;
          });
        });

        // Update the session storage to reflect the deletion
        const updatedCustomers = JSON.parse(sessionStorage.getItem("localCustomerData")) || [];
        const customerIndex = updatedCustomers.findIndex((customer) => customer.id === selectedCustomer.id);
        if (customerIndex > -1) {
          updatedCustomers[customerIndex].Personal.documents = updatedDocuments;
          sessionStorage.setItem("localCustomerData", JSON.stringify(updatedCustomers));
        }
        
        console.log("Document successfully deleted!");
        toast.success("Document successfully deleted!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        console.log("No such document!");
        toast.error("No such document!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error("Error removing document: ", error);
      toast.error("Error removing document. Please try again.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedCustomer(null);
    // sessionStorage.removeItem("familyMemberIDs");
  };

  const flattenData = () => {
    return customers.map((item) => ({
      id: item.id,
      customer_code: item.Personal?.customer_code || "-",
      name: item.Personal?.name || "-",
      phone_number: item.Personal?.phone_number || "-",
      email: item.Personal?.email || "-",
      gender: item.Personal?.gender || "-",
      address: item.Personal?.address || "-",
      customer_type: item.Personal?.customer_type || "-",
    }));
  };

  const flattenedCustomers = flattenData(customers);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [open, setOpen] = useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
    setNewDocuments([]);
  };

  const handleFilesChange = (files) => {
    setUploadedFiles(files);
    console.log("Uploaded files:", files);
    handleClose();
  };

  const columns = [
    {
      name: "no",
      label: "No",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                width: "60px",
                maxWidth: "30px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: "center",
              }}
            >
              {tableMeta.rowIndex + 1}
            </div>
          );
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value) => {
          const capitalizeFirstLetter = (str) => {
            if (!str) return str;
            return str.charAt(0).toUpperCase() + str.slice(1);
          };
          return (
            <div
              style={{
                // maxWidth: "150px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {capitalizeFirstLetter(value)}
            </div>
          );
        },
      },
    },
    {
      name: "phone_number",
      label: "Mobile Number",
      options: {
        customBodyRender: (value) => (
          <div
            style={{
              maxWidth: "250px",
              width: "150px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {value}
          </div>
        ),
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        customBodyRender: (value) => (
          <div
            style={{
              // maxWidth: "150px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {value}
          </div>
        ),
      },
    },
    {
      name: "gender",
      label: "Gender",
      options: {
        customBodyRender: (value) => {
          const capitalizeFirstLetter = (str) => {
            if (!str) return str;
            return str.charAt(0).toUpperCase() + str.slice(1);
          };

          return (
            <div
              style={{
                maxWidth: "200px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {capitalizeFirstLetter(value)}
            </div>
          );
        },
      },
    },
    {
      name: "customer_type",
      label: "Type",
      options: {
        customBodyRender: (value) => {
          const capitalizeFirstLetter = (str) => {
            if (!str) return str;
            return str.charAt(0).toUpperCase() + str.slice(1);
          };

          return (
            <div
              style={{
                maxWidth: "200px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {capitalizeFirstLetter(value)}
            </div>
          );
        },
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        customBodyRender: (value) => {
          const capitalizeFirstLetter = (str) => {
            if (!str) return str;
            return str.charAt(0).toUpperCase() + str.slice(1);
          };
          return (
            <div
              style={{
                // maxWidth: "200px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {capitalizeFirstLetter(value)}
            </div>
          );
        },
      },
    },
    // {
    //   name: "edit",
    //   label: "Edit",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (
    //       <IconButton
    //         onClick={() => handleEditClick(customers[tableMeta.rowIndex])}
    //       >
    //         <ModeEditOutlineIcon />
    //       </IconButton>
    //     ),
    //   },
    // },
    {
      name: "action",
      label: "Action",
      options: {
        // filter: false,
        // sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              // alignItems: "center",
              // justifyContent:"center"
            }}
          >
            {/* Eye */}
            <div>
              <IconButton
                sx={{ pt: 0 }}
                onClick={() => handleIconClick(customers[tableMeta.rowIndex])}
              >
                <VisibilityIcon />
              </IconButton>
            </div>

            {/* Edit */}
            <div>
              <IconButton
                sx={{ pt: 0 }}
                onClick={() => handleEditClick(customers[tableMeta.rowIndex])}
              >
                <ModeEditOutlineIcon />
              </IconButton>
            </div>

            {/* add Documents */}
            <div>
              <IconButton
                sx={{ pt: 0 }}
                onClick={() => handleClickOpen(customers[tableMeta.rowIndex])}
              >
                <Add />
                <input
                  name="Newdocuments"
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  multiple
                />
              </IconButton>
            </div>
          </div>
        ),
      },
    },
  ];

  const rows = 10; // Number of skeleton rows
  const columnsCount = 7; // Number of columns

  const options = {
    filterType: "multiselect",
    responsive: "standard",
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: loading ? (
          <Skeleton rows={rows} columns={columnsCount} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <>
      <MetaTags>
        <title>All Customers</title>
      </MetaTags>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <Sidebar />
          <Box sx={{ flex: 1, backgroundColor: "#F4F7F9", overflow: "hidden" }}>
            <CustomerProvider />
            <Navbar />
            <Box
              // component="main"
              sx={{
                p: 2,
                // width: "98%",
              }}
            >
              <Box sx={{ padding: "1%" }}>
                <Box
                  borderRadius="17px"
                  sx={{ boxShadow: "0px 9px 20px rgba(46, 35, 94, 0.07)" }}
                  overflow="hidden"
                >
                  <CustomMuiPaper>
                    <MuiDatatables
                      // title="All Policy"
                      data={
                        loading
                          ? []
                          : flattenedCustomers && flattenedCustomers.length > 0
                          ? flattenedCustomers
                          : []
                      }
                      columns={columns}
                      options={{
                        ...options,
                        searchOpen: true,
                        searchAlwaysOpen: true,
                        searchPlaceholder: "Search keyword",
                        customSearchRender: (searchText, handleSearch) => {
                          return (
                            <CustomSearchRender
                              searchText={searchText}
                              onSearch={handleSearch}
                            />
                          );
                        },
                      }}
                    />
                  </CustomMuiPaper>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle style={{ fontFamily: '"Montserrat", sans-serif' }}>
            Update Customer Details
          </DialogTitle>
          <DialogContent>
            {selectedCustomer && (
              <Box sx={{ padding: 1 }}>
                <Divider />
                <Box sx={{ marginY: 2 }}>
                  <TextField
                    label="Full Name"
                    name="name"
                    value={editCustomer?.Personal?.name || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    type="date"
                    label="Date of Birth"
                    name="dob"
                    value={editCustomer?.Personal?.dob || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    label="Phone Number"
                    name="phone_number"
                    value={editCustomer?.Personal?.phone_number || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Alternate no."
                    name="other_phonenumber"
                    value={editCustomer?.Personal?.other_phonenumber || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Email"
                    name="email"
                    value={editCustomer?.Personal?.email || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Other Email"
                    name="other_email"
                    value={editCustomer?.Personal?.other_email || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Address"
                    name="address"
                    value={editCustomer?.Personal?.address || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Remarks"
                    name="remarks"
                    value={editCustomer?.Personal?.remarks || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />
                </Box>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleDialogClose}
              variant="outlined"
              sx={{ border: "1px solid #57dd36", color: "#57dd36", p: 1 }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleEditSubmit}
              variant="contained"
              disabled={loading} // Assuming `loading` state indicates loading state
              // startIcon={loading ? <CircularProgress size={24} /> : null}
              sx={{ backgroundColor: "#57dd36", p: 1 }}
            >
              {loading ? <CircularProgress size={24} /> : "Save Changes"}
            </Button>
          </DialogActions>
        </Dialog>

        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={handleDrawerClose}
          sx={{ "& .MuiDrawer-paper": { width: 460, padding: 2 } }}
        >
          {selectedCustomer && (
            <Box sx={{ padding: 2 }}>
              {" "}
              {/* remove width 100% */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  onClick={handleDrawerClose}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="48"
                      d="M328 112L184 256l144 144"
                    />
                  </svg>
                </Typography>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ fontFamily: '"Montserrat", sans-serif' }}
                >
                  Customer Details
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ marginY: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 2,
                  }}
                >
                  <img
                    src={
                      selectedCustomer.Personal?.profilePhotoURL ||
                      "https://t4.ftcdn.net/jpg/05/42/36/11/360_F_542361185_VFRJWpR2FH5OiAEVveWO7oZnfSccZfD3.jpg"
                    }
                    alt={`${selectedCustomer.Personal?.name}'s profile`}
                    style={{
                      width: 100,
                      height: 100,
                      borderRadius: "50%",
                      marginBottom: 16,
                    }}
                  />
                  <Divider />
                </Box>

                {selectedCustomer.Personal?.name && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Name:</h5>{" "}
                    {capitalizeFirstLetter(selectedCustomer.Personal?.name)}
                  </Typography>
                )}

                {selectedCustomer.Personal?.customer_type && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Customer Type:</h5>{" "}
                    {capitalizeFirstLetter(
                      selectedCustomer.Personal?.customer_type
                    )}
                  </Typography>
                )}

                {selectedCustomer.Personal?.dob && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>DOB:</h5> {selectedCustomer.Personal?.dob}
                  </Typography>
                )}

                {selectedCustomer.Personal?.phone_number && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Mobile:</h5> {selectedCustomer.Personal?.phone_number}
                  </Typography>
                )}

                {selectedCustomer.Personal?.other_phonenumber && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Alternate no:</h5>{" "}
                    {selectedCustomer.Personal?.other_phonenumber}
                  </Typography>
                )}

                {selectedCustomer.Personal?.email && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Email:</h5> {selectedCustomer.Personal?.email}
                  </Typography>
                )}

                {selectedCustomer.Personal?.other_email && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Other Email:</h5>{" "}
                    {selectedCustomer.Personal?.other_email}
                  </Typography>
                )}

                {selectedCustomer.Personal?.gender && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Gender:</h5>{" "}
                    {capitalizeFirstLetter(selectedCustomer.Personal?.gender)}
                  </Typography>
                )}

                {selectedCustomer.Personal?.ref_by && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Ref By:</h5>{" "}
                    {capitalizeFirstLetter(
                      refName || selectedCustomer.Personal?.ref_by
                    )}
                  </Typography>
                )}

                {selectedCustomer.Personal?.address && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Address:</h5>{" "}
                    {capitalizeFirstLetter(selectedCustomer.Personal?.address)}
                  </Typography>
                )}

                {selectedCustomer.Personal?.remarks && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Remarks:</h5>{" "}
                    {capitalizeFirstLetter(selectedCustomer.Personal?.remarks)}
                  </Typography>
                )}

                {selectedCustomer.Personal?.documents &&
                  selectedCustomer.Personal?.documents.length > 0 && (
                    <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                      <h5>Docments:</h5>
                      {documents.map((docName) => (
                        <div
                          key={docName.url}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <a
                            href={docName.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            {docName.name}, <br />
                          </a>
                          <p
                            onClick={() => handleDelete(docName.url)}
                            sx={{ marginLeft: "10px" }}
                          >
                            <Delete sx={{ color: "#d60000" }} />
                          </p>
                        </div>
                      ))}
                    </Typography>
                  )}

                {selectedCustomer.Personal.family_member &&
                  selectedCustomer.Personal.family_member.length > 0 && (
                    <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                      <h5>Family Member:</h5>
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Box>
                          {familyMemberNames.map((name, index) => (
                            <p key={index}>{name}</p>
                          ))}
                        </Box>
                        <Box>
                          {selectedCustomer.Personal?.family_member.map(
                            (member, index) => (
                              <div key={index}>
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                                {member.relation}
                              </div>
                            )
                          )}
                        </Box>
                      </Box>
                    </Typography>
                  )}
              </Box>
            </Box>
          )}
        </Drawer>

        <Dialog open={open}>
          <DialogTitle>Documents</DialogTitle>
          <DialogContent>
            <List>
              {newDocuments.map((doc, index) => (
                <ListItem
                  key={index}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <ListItemText
                    primary={
                      <TextField
                        value={doc.editName}
                        onChange={(e) =>
                          handleEditNameChange(index, e.target.value)
                        }
                        variant="outlined"
                        size="small"
                        onBlur={() => handleSaveNameChange(index)}
                      />
                    }
                  />
                  <IconButton
                    edge="end"
                    onClick={() => handleDeleteDocument(index)}
                  >
                    <Delete />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
            <Button
              onClick={handleDocumentsUpload}
              color="primary"
              disabled={loading}
            >
              {loading ? "Uploading..." : "Upload"}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default Allcustomers;
