import React, { useEffect, useState } from "react";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import OutlinedInput from "@mui/material/OutlinedInput";
import logo from "./images/logo-nav.png";
import FirebaseAuthService from "./utils/FirebaseAuthService";
import { Loader } from "rsuite";
import { Box, CircularProgress } from "@mui/material";
import MetaTags from "react-meta-tags";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { sendPasswordResetEmail } from "@firebase/auth";
import { auth } from "./utils/firebase";
import styled from "styled-components";

const LoginContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 70%;
  height: 100%;
  max-width: 900px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 2rem 3rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FormContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;

  h2 {
    color: #3bd615;
    margin-bottom: 1rem;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
  }

  input {
    margin-bottom: 1rem;
    padding: 0.5rem;
    font-size: 1rem;
    // width: 100%
  }

  button {
    padding: 0.5rem;
    background-color: #3bd615;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 5px;
    padding: 10px;
    margin-top: 1rem;
    height: 50px;

    // width: 100%
  }

  button:hover {
    background-color: #3ddb16;
  }

  .forgot-password {
    margin-top: -1rem;
    color: #007bff;
    cursor: pointer;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
`;

export default function Home() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  // console.log('email :>> ', email);

  // const isEmailRegistered = async (email) => {
  //   // console.log('email :>> ', email);
  //   try {
  //     const signInMethods = await auth.fetchSignInMethodsForEmail(email);
  //     console.log('signInMethods :>> ', signInMethods);
  //     return signInMethods.length > 0;
  //   } catch (error) {
  //     console.error("Error checking email:", error);
  //     return false;
  //   }
  // };

  //   const handleForgotPassword = async () => {
  //     if (email) {
  //       console.log('email :>> ', email);
  //       try {
  //         const isRegistered = await isEmailRegistered(email);
  //         console.log('isRegistered :>> ', isRegistered);
  //         if (isRegistered !== false) {
  //           const userCredential = await auth.sendPasswordResetEmail(email);
  //           console.log('userCredential :>> ', userCredential);
  //           toast.success("Check your email for password reset instructions");
  //           return userCredential;
  //         } else {
  //           toast.error("Your email not registered!");
  //       }
  //       } catch (error) {
  //         alert(error.message);
  //         console.log('error :>> ', error.message);
  //       }
  //     } else {
  //       toast.error("Please Enter Email!")
  //     }
  //   };

  const handleForgotPassword = async () => {
    if (email) {
      try {
        await sendPasswordResetEmail(auth, email);
        toast.success("Check your email for password reset instructions");
      } catch (error) {
        alert(error.message);
        console.log("error :>> ", error.message);
      }
    } else {
      toast.error("Please Enter Email!");
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await FirebaseAuthService.loginService(email, password, navigate)();
    } catch (error) {
      console.error("Login error:", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  };

  const LogoContainer = styled.div`
    flex: 1;
    // background-color: #e9f7ef;
    display: flex;
    width: 30%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // padding: 2rem;
    text-align: center;

    img {
      width: 120px;
      height: auto;
    }

    h1 {
      // color: #28a745;
    }

    p {
      margin-top: 10px;
      color: #000000cc;
      font-size: 16px;
    }
  `;

  return (
    <Container>
      <MetaTags>
        <title>AutoPulse - Login</title>
      </MetaTags>
      <Header />
      {/* <Header /> */}
      {/* <div className="artboard">
        <img src={logo} />
        <div className="autopulse-tag">
          <p>
            Auto<strong>PULSE</strong>
          </p>
        </div>
      </div> */}
      <>
        <LoginContainer className="form">
          <FormContainer>
            <h2 style={{ marginBottom: "0px", fontFamily:'"Montserrat", sans-serif' }}>Sign In</h2>
            <span style={{ marginBottom: "20px", fontFamily:'"Montserrat", sans-serif'  }}>
              Sign in to access your account
            </span>
            <form onSubmit={handleSignIn}>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={handleEmailChange}
                style={{fontFamily:'"Montserrat", sans-serif', fontSize:"14px" }}
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={handlePasswordChange}
                style={{fontFamily:'"Montserrat", sans-serif' }}
              />
              <div className="forgot-password" onClick={handleForgotPassword} style={{fontFamily:'"Montserrat", sans-serif' }}>Forgot Password?</div>
              <button type="submit" disabled={loading}>
                {loading ? <CircularProgress size={24} sx={{color:"white"}} /> : <p>Sign In</p>}
              </button>
            </form>
          </FormContainer>

          {/* <Box height={300} sx={{backgroundColor:"#D9D9D9", width:"1px", display:"flex", justifyContent:"center", alignItems:"center"}}/> */}

          {/* <div className="logo-text">
          <p className="auto-pulse">
            Auto<strong>PULSE</strong>
          </p>
          <p className="text">
            Automated Policy Utilization and Lifecycle Services
          </p>
        </div> */}
          <LogoContainer>
            <img src={logo} alt="AutoPULSE Logo" />
            <h1 className="auto-pulse">
              Auto<strong>PULSE</strong>
            </h1>
            <p className="text">
              Automated Policy Utilization and Lifecycle Services
            </p>
          </LogoContainer>
        </LoginContainer>
      </>
      {/* <div className="form">
        <div className="form-box">
          <hr className="hr-line" />
          <div className="sign-in">
            <div className="sign-in-header">
              <p>Sign In</p>
              <span>Sign in to access your account</span>
            </div>
            <form onSubmit={handleSignIn}>
              <div className="sign-in-input">
                <OutlinedInput
                  className="input-user"
                  placeholder="Username or Email or Mobile Number"
                  value={email}
                  onChange={handleEmailChange}
                />
                <br />
                <br />
                <OutlinedInput
                  className="input-user"
                  placeholder="Password"
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <p className="forgot-password" onClick={handleForgotPassword}>Forgot Password?</p>
              </div>
              <button className="sign-in-btn" disabled={loading}>
                {loading ? <CircularProgress size={24} /> : <p>Sign In</p>}
              </button>
            </form>
          </div>
          <div className="logo-text">
            <img src={logo} />
            <p className="auto-pulse">
              Auto<strong>PULSE</strong>
            </p>
            <p className="text">
              Automated Policy Utilization and Lifecycle Services
            </p>
          </div>
        </div>
      </div> */}
      <Footer />
    </Container>
  );
}
