import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";

const FileUploaderComponent = ({ onFilesChange }) => {
  const [files, setFiles] = useState([]);

  const handleChange = (fileList) => {
    const updatedFiles = Array.from(fileList); 
    setFiles(updatedFiles);
    onFilesChange(updatedFiles);
  };

  return (
    <div>
        <FileUploader
          handleChange={handleChange}
          name="file"
          types={["JPG", "PNG", "GIF", "PDF"]}
          multiple
        />
    </div>
  );
};

export default FileUploaderComponent;
