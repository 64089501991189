// src/context/CustomerContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { doc, getDoc } from "@firebase/firestore";
import { db } from '../utils/firebase';

const CustomerContext = createContext();

export const useCustomer = () => {
  return useContext(CustomerContext);
};

export const CustomerProvider = ({ children }) => {
  const [customer, setCustomer] = useState({
    Personal: {
      profilePhotoURL: sessionStorage.getItem("profilePhotoURL") || "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTnui_ke5T_W3w_LQqpK5KZBdFMb-yE4OdaQ&s",
      name: sessionStorage.getItem("name") || "User",
    },
  });

  useEffect(() => {
    const fetchClient = async () => {
      const storedClientId = sessionStorage.getItem("clientId");
      if (storedClientId) {
        const docRef = doc(db, "clients", storedClientId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const customerData = docSnap.data();
          setCustomer(customerData);
          if (customerData.Personal?.profilePhotoURL) {
            sessionStorage.setItem("profilePhotoURL", customerData.Personal.profilePhotoURL);
            sessionStorage.setItem("name", customerData.Personal.name);
          }
        }
      }
    };

    fetchClient();
  }, []);

  return (
    <CustomerContext.Provider value={{ customer, setCustomer }}>
      {children}
    </CustomerContext.Provider>
  );
};
