import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
// import "./Dashboard.css";
import { BsArrowUpRight } from "react-icons/bs";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  InputBase,
} from "@mui/material";
import documentFile from "../components/images/doc.png";
import mediaFile from "../../components/images/mediaFile.png";
import otherFile from "../../components/images/otherFile.png";
import unknwonFile from "../../components/images/unknown.png";
import AClient from "../../components/images/client.png";
import ACustomer from "../../components/images/customer.png";
import ACompany from "../../components/images/companys.png";
import AMessage from "../../components/images/message.png";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../components/utils/firebase";
import { PieChart, useDrawingArea } from "@mui/x-charts";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import MetaTags from "react-meta-tags";

const CustomMuiPaper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  width: "auto",
  overflow: 'auto',
  maxWidth:"65vw",
  maxHeight: "80vh"
});

const theme = createTheme({
  components: {
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          height: "20px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: '"Montserrat", sans-serif',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
          fontWeight: 600,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: "0px",
          //   backgroundColor: "#0b0b0b",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
          padding: "0px",
          textAlign: "start",
          justifyContent: "start",
          fontWeight: 600,
        },
      },
    },
  },
  MuiTableCell: {
    root: {
      borderColor: "#d3d3d3",
      fontWeight: 600,
    },
    head: {
      background: "lightgrey",
      "&:not(:last-child)": {
        borderRight: [[1, "solid", "#c0c0c0"]],
      },
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-root": {
      border: "1px solid #d9d9d9",
      backgroundColor: "#ffffff",
    },
  },
});

const columns = [
  { id: "name", label: "Name", minWidth: 350 },
  { id: "first", label: "1st", minWidth: 30 },
  {
    id: "second",
    label: "2nd",
    minWidth: 30,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "third",
    label: "3rd",
    minWidth: 30,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "check",
    label: "check",
    minWidth: 30,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
];

function createData(name, first, second, third, check) {
  return { name, first, second, third, check };
}

const rows = [
  createData("Pareshbhai Babubhai Vaghasiya", "IN", 13, 32),
  createData("Pareshbhai Babubhai Vaghasiya", "CN", 14, 95),
  createData("Pareshbhai Babubhai Vaghasiya", "IT", 60, 30),
  createData("Pareshbhai Babubhai Vaghasiya", "US", 32, 98),
  createData("Pareshbhai Babubhai Vaghasiya", "CA", 37, 99),
  createData("Pareshbhai Babubhai Vaghasiya", "AU", 25, 78),
  createData("Pareshbhai Babubhai Vaghasiya", "DE", 87, 35),
  createData("Pareshbhai Babubhai Vaghasiya", "IE", 48, 70),
  createData("Pareshbhai Babubhai Vaghasiya", "MX", 12, 19),
  createData("Pareshbhai Babubhai Vaghasiya", "JP", 16, 37),
  createData("Pareshbhai Babubhai Vaghasiya", "FR", 67, 64),
  createData("Pareshbhai Babubhai Vaghasiya", "GB", 67, 24),
  createData("Pareshbhai Babubhai Vaghasiya", "RU", 14, 17),
  createData("Pareshbhai Babubhai Vaghasiya", "NG", 20, 92),
  createData("Pareshbhai Babubhai Vaghasiya", "BR", 21, 85),
];

const data = [
  { value: 5, label: "A" },
  { value: 10, label: "B" },
  { value: 15, label: "C" },
  { value: 20, label: "D" },
];

const size = {
  width: 350,
  height: 200,
};

const StyledText = styled("text")(({ theme, fontSize }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: fontSize || 20,
}));

function PieCenterLabel({ top, bottom, topFontSize, bottomFontSize }) {
  const { width, height, left, top: topOffset } = useDrawingArea();
  return (
    <>
      <StyledText
        x={left + width / 2}
        y={topOffset + height / 2 - 20}
        fontSize={topFontSize}
      >
        {top}
      </StyledText>
      <StyledText
        x={left + width / 2}
        y={topOffset + height / 2 + 20}
        fontSize={bottomFontSize}
      >
        {bottom}
      </StyledText>
    </>
  );
}

const ChartContainer = styled("div")({
  pointerEvents: "none", // Disable pointer events to remove hover effect
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  borderRadius: "17px",
  boxShadow: "0px 9px 20px rgba(46, 35, 94, 0.07)",
  fontFamily: '"Montserrat", sans-serif',
  backgroundColor: "#fff",
  transition: "all 0.3s ease",
  letterSpacing: "0.5px",
  padding: "10px 5px",
  height: "170px",
}));

const BottomStyledPaper = styled(Paper)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  borderRadius: "17px",
  boxShadow: "0px 9px 20px rgba(46, 35, 94, 0.07)",
  fontFamily: '"Montserrat", sans-serif',
  backgroundColor: "#fff",
  transition: "all 0.3s ease",
  letterSpacing: "0.5px",
  padding: "10px 5px",
}));

const AdminDashboard = () => {
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentDate] = useState(new Date());

  const navigate = useNavigate();
  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const formattedDate = currentDate.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const [company, setCompany] = useState([]);
  const [client, setClient] = useState([]);
  const [customer, setCustomer] = useState([]);

  useEffect(() => {
    const fetchCompany = async () => {
      const companyCollection = collection(db, "company");
      const clientCollection = collection(db, "clients");
      const customerCollection = collection(db, "customers");

      const querySnapshot = await getDocs(companyCollection);
      const clientQuerySnapshot = await getDocs(clientCollection);
      const customerQuerySnapshot = await getDocs(customerCollection);

      const companyData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const clientData = clientQuerySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const customerData = customerQuerySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setCompany(companyData);
      setClient(clientData);
      setCustomer(customerData);
    };

    fetchCompany();
  }, [db]);

  // console.log("customer :>> ", client);

  return (
    <>
      <MetaTags>
        <title>Admin Dashboard</title>
      </MetaTags>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <Sidebar />
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#F4F7F9",
            }}
          >
            <Navbar />
            <Box sx={{ p: 3, mb: 5 }}>
              <Box className="container">
                <Grid
                  container
                  spacing={3}
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                    marginBottom: "75%",
                  }}
                >
                  <Grid item xs={12} md={6} lg={3}>
                    <StyledPaper elevation={3}>
                      <div className="content">
                        <Box>
                          <img src={AClient} alt="" height={50} />
                        </Box>
                        <Typography
                          variant="4"
                          className="message-number"
                          sx={{ textAlign: "center" }}
                        >
                          {client.length}
                        </Typography>
                        <Typography variant="body2" className="message-text">
                          Total Clients
                        </Typography>
                        <Typography variant="body2">
                          <BsArrowUpRight /> 1.01% this week
                        </Typography>
                      </div>
                    </StyledPaper>
                  </Grid>

                  <Grid item xs={12} md={6} lg={3}>
                    <StyledPaper elevation={3} >
                      <div className="content">
                        <Box>
                          <img src={ACustomer} alt="" height={50} />
                        </Box>
                        <Typography variant="4" className="message-number">
                          {customer.length}
                        </Typography>
                        <Typography variant="body2" className="message-text">
                          Total Customers
                        </Typography>
                        <Typography variant="body2">
                          <BsArrowUpRight /> 1.01% this week
                        </Typography>
                      </div>
                    </StyledPaper>
                  </Grid>

                  <Grid item xs={12} md={6} lg={3}>
                    <StyledPaper elevation={3} >
                      <div className="content">
                        <Box>
                          <img src={ACompany} alt="" height={50} />
                        </Box>
                        <Typography variant="4" className="message-number">
                          {company.length}
                        </Typography>
                        <Typography variant="body2" className="message-text">
                          Total Companys
                        </Typography>
                        <Typography variant="body2">
                          <BsArrowUpRight /> 1.01% this week
                        </Typography>
                      </div>
                    </StyledPaper>
                  </Grid>

                  <Grid item xs={12} md={6} lg={3}>
                    <StyledPaper elevation={3} >
                      <div className="content">
                        <Box>
                          <img src={AMessage} alt="" height={50} />
                        </Box>
                        <Typography variant="4" className="message-number">
                          28384
                        </Typography>
                        <Typography variant="body2" className="message-text">
                          Total messages sent
                        </Typography>
                        <Typography variant="body2">
                          <BsArrowUpRight /> 1.01% this week
                        </Typography>
                      </div>
                    </StyledPaper>
                  </Grid>

                  <Grid item xs={8}>
                    <BottomStyledPaper elevation={3}>
                      <Box p={2}>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="h6">
                            Date: {formattedDate}
                          </Typography>
                        </Stack>
                        <TableContainer sx={{ maxHeight: 440 }}>
                          <Table stickyHeader>
                            <TableHead>
                              <TableRow>
                                {columns.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                  >
                                    {column.label}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody className={classes.table}>
                              {rows
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row) => (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.name}
                                  >
                                    {columns.map((column) => {
                                      const value = row[column.id];
                                      return (
                                        <TableCell
                                          key={column.id}
                                          align={column.align}
                                        >
                                          {column.format &&
                                          typeof value === "number"
                                            ? column.format(value)
                                            : value}
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 100]}
                          component="div"
                          count={rows.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Box>
                    </BottomStyledPaper>
                    {/* <Box
                  height={50}
                  sx={{
                    // height: "31rem",
                    width: "100%",
                    border: "1px solid #d9d9d9",
                    borderRadius: "5px",
                  }}
                >
                  <Stack direction="row">
                    <Grid item xs={2}>
                      <h6 style={{ padding: "12%" }}>Date:{formattedDate}</h6>
                    </Grid>
                    <Grid>
                      <Box height={10} />
                      <input
                        placeholder="Search"
                        style={{ height: "30px", marginInline: "100%" }}
                      />
                    </Grid>
                  </Stack>
                  <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer sx={{ maxHeight: 392 }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {columns.map((column) => (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  minWidth: column.minWidth,
                                  backgroundColor: "#d9d9d9",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody className={classes.table}>
                          {rows
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row) => {
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={row.code}
                                >
                                  {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                      <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{
                                          fontStyle: "normal",
                                          fontWeight: "400",
                                          fontSize: "15px",
                                        }}
                                      >
                                        {column.format &&
                                        typeof value === "number"
                                          ? column.format(value)
                                          : value}
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Paper>
                </Box> */}
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <BottomStyledPaper elevation={3}>
                      <Box p={2}>
                        <Typography variant="h6">Storage Details</Typography>
                        <ChartContainer>
                          <PieChart
                            series={[{ data, innerRadius: 70 }]}
                            {...size}
                          >
                            <PieCenterLabel
                              top="29.1"
                              bottom="Out of 128"
                              topFontSize={40}
                              bottomFontSize={20}
                            />
                          </PieChart>
                        </ChartContainer>
                        <Grid
                          container
                          direction="column"
                          spacing={2}
                          sx={{ mt: 2 }}
                        >
                          <Grid item>
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Box
                                sx={{ display: "flex", flexDirection: "row" }}
                              >
                                <img src={documentFile} alt="" height={40} />
                                <Box sx={{ marginLeft: "5px" }}>
                                  <Typography
                                    variant="title1"
                                    sx={{ fontWeight: "700" }}
                                  >
                                    Documents Files
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ fontSize: "12px" }}
                                  >
                                    1205 Files
                                  </Typography>
                                </Box>
                              </Box>
                              <Box>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontSize: "16px", fontWeight: "700" }}
                                >
                                  1.3GB
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Box
                                sx={{ display: "flex", flexDirection: "row" }}
                              >
                                <img src={mediaFile} alt="" height={40} />
                                <Box sx={{ marginLeft: "5px" }}>
                                  <Typography
                                    variant="title1"
                                    sx={{ fontWeight: "700" }}
                                  >
                                    Media Files
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ fontSize: "12px" }}
                                  >
                                    1400 Files
                                  </Typography>
                                </Box>
                              </Box>
                              <Box>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontSize: "16px", fontWeight: "700" }}
                                >
                                  2.8GB
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Box
                                sx={{ display: "flex", flexDirection: "row" }}
                              >
                                <img src={otherFile} alt="" height={40} />
                                <Box sx={{ marginLeft: "5px" }}>
                                  <Typography
                                    variant="title1"
                                    sx={{ fontWeight: "700" }}
                                  >
                                    Other Files
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ fontSize: "12px" }}
                                  >
                                    1350 Files
                                  </Typography>
                                </Box>
                              </Box>
                              <Box>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontSize: "16px", fontWeight: "700" }}
                                >
                                  1.5GB
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Box
                                sx={{ display: "flex", flexDirection: "row" }}
                              >
                                <img src={unknwonFile} alt="" height={40} />
                                <Box sx={{ marginLeft: "5px" }}>
                                  <Typography
                                    variant="title1"
                                    sx={{ fontWeight: "700" }}
                                  >
                                    Unknown Files
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ fontSize: "12px" }}
                                  >
                                    630 Files
                                  </Typography>
                                </Box>
                              </Box>
                              <Box>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontSize: "16px", fontWeight: "700" }}
                                >
                                  0.7GB
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </BottomStyledPaper>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default AdminDashboard;
