import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import "../../Client/css/Navbar.css";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { auth, db } from "../../components/utils/firebase";
import { useAppStore } from "./Appstore";
import { collection, doc, getDoc, getDocs, where } from "@firebase/firestore";
import Sidebars from "../../Client/components/Sidebar";
import { useState } from "react";
import { useEffect } from "react";

// const AppBar = styled(
//   MuiAppBar,
//   {}
// )(({ theme }) => ({
//   zIndex: theme.zIndex.drawer + 1,
// }));

export default function Navbar() {
  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        sessionStorage.removeItem("clientId");
        sessionStorage.removeItem("profilePhotoURL");
        sessionStorage.removeItem("name");
        
        sessionStorage.removeItem("localCustomerData");
        sessionStorage.removeItem("localCustomerDataFetchTime");

        sessionStorage.removeItem("localPolicyData");
        sessionStorage.removeItem("localPolicyDataFetchTime");

        sessionStorage.removeItem("localGICPolicyData");
        sessionStorage.removeItem("localGICDataFetchTime");

        sessionStorage.removeItem("localTPPolicyData");
        sessionStorage.removeItem("localTPPolicyDataFetchTime");
        // sessionStorage.removeItem(`totalSizeMB_${storedClientId}`);
        window.location.href = "/";
      })
      .catch((error) => {
        console.error("Logout error:", error);
      });
  };

  const handleShowProfile = () => {
    navigate("/profile");
  };

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // const storedClientId = sessionStorage.getItem("clientId");

  const [customer, setCustomer] = useState({
    Personal: {
      profilePhotoURL:
        sessionStorage.getItem("profilePhotoURL") ||
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTnui_ke5T_W3w_LQqpK5KZBdFMb-yE4OdaQ&s",
      name: sessionStorage.getItem("name") || "User",
    },
  });

  useEffect(() => {
    const fetchClient = async () => {
      const storedClientId = sessionStorage.getItem("clientId");
      if (storedClientId) {
        const docRef = doc(db, "clients", storedClientId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const customerData = docSnap.data();
          setCustomer(customerData);
          if (customerData.Personal?.profilePhotoURL) {
            sessionStorage.setItem(
              "profilePhotoURL",
              customerData.Personal.profilePhotoURL
            );
            sessionStorage.setItem("name", customerData.Personal.name);
          }
        }
      }
    };

    fetchClient();
  }, []);

  // useEffect(() => {
  //   const fetchClient = async () => {
  //     const docRef = doc(db, "clients", storedClientId);
  //     const docSnap = await getDoc(docRef);

  //     if (docSnap.exists()) {
  //       const customerData = docSnap.data();
  //       setCustomer(customerData);
  //       console.log("customerData :>> ", customerData);
  //       if (customerData.Personal?.profilePhotoURL) {
  //         sessionStorage.setItem(
  //           "profilePhotoURL",
  //           customerData.Personal.profilePhotoURL
  //         );
  //         sessionStorage.setItem("name", customerData.Personal.name);
  //       }
  //     }
  //   };

  //   fetchClient();
  // }, [storedClientId]);

  // React.useEffect(() => {
  //   const fetchClient = async () => {
  //     const docRef = doc(db, "clients", storedClientId);
  //     const docSnap = await getDoc(docRef);
  //     setCustomer(docSnap.data());

  //   };

  //   fetchClient();
  // }, [storedClientId]);
  const location = useLocation();

  const getBreadcrumbs = () => {
    switch (location.pathname) {
      case "/profile":
        return ["Home", "Profile"];
      case "/cDashboard":
        return ["Home", " Dashboard"];
      case "/premiumreminders":
        return ["Home", " Premium Reminders"];
      case "/newcustomer":
        return ["Customer", " New Customer"];
      case "/allcustomers":
        return ["Customer", " All Customer"];
      case "/newlicpolicy":
        return ["Life Insurance", " New Policy"];
      case "/alllicpolicy":
        return ["Life Insurance", " All Policy"];
      case "/newgicpolicy":
        return ["General Insurance", " New Policy"];
      case "/thirdparty":
        return ["General Insurance", " New Third Party Policy"];
      case "/allthirdpartypolicy":
        return ["General Insurance", " All Third Party Policy"];
      case "/allgicpolicy":
        return ["General Insurance", " All Policy"];
      case "/managegstrates":
        return ["Setting", " Manage GST Rates"];
      default:
        return ["Dashboard", " Profile"];
    }
  };

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const breadcrumbs = getBreadcrumbs();
  const currentBreadcrumb = breadcrumbs[breadcrumbs.length - 1];

  return (
    <Box className="client-navbar" sx={{ zIndex: 1, }}>
      <div className="app-bar">
        <div>
          <div className="top-breadcrumb">
            <span className="current">{currentBreadcrumb}</span>
          </div>
          <div className="bottom-breadcrumb">
            <span className="icon-home">🏠 /</span>
            {breadcrumbs.map((crumb, index) => (
              <React.Fragment key={index}>
                <span
                  className={index === breadcrumbs.length - 1 ? "current" : ""}
                >
                  {crumb}
                </span>
                {index < breadcrumbs.length - 1 && (
                  <span className="separator"> / </span>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="actions">
          <div className="icons">
            <span className="icon notification" style={{paddingRight:"10px", margin:"0"}}>
              🔔
            </span>
            <span className="icon calendar" style={{paddingRight:"10px", margin:"0"}}>📅</span>
            <div className="profile" onClick={toggleDropdown}>
              <img
                src={
                  customer.Personal?.profilePhotoURL ||
                  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTnui_ke5T_W3w_LQqpK5KZBdFMb-yE4OdaQ&s"
                }
                alt="User"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  textAlign: "start",
                }}
              >
                <span>{customer.Personal?.name || "User"}</span>
                <span className="role">Agent</span>
              </div>
              {/* {dropdownVisible && ( */}
              <div className="dropdown-menu">
                <div onClick={handleShowProfile}>Profile Details</div>
                <div>My Account</div>
                <div onClick={handleLogout}>Logout</div>
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}
