import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  runTransaction,
  serverTimestamp,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { Visibility as VisibilityIcon } from "@material-ui/icons";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { query } from "firebase/database";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import MetaTags from "react-meta-tags";
import { CustomerProvider } from "../../components/Navbar/CustomerContext";
import Navbar from "../components/Navbar";
import CachedIcon from "@mui/icons-material/Cached";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import MuiDatatables from "mui-datatables";
import CustomSearchRender from "../../components/CustomSearchRender";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "@firebase/storage";
import { storage } from "../../components/utils/firebase";

const CustomMuiPaper = styled(Paper)({
  display: "flex",
  flexDirection: "column",
  height: "auto",
  maxHeight: "80vh",
  overflow: "auto",
});

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          height: "auto",
          // height: "100%",
          // overflow: 'auto',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          flex: "1 1 auto", // Allow the table container to grow and shrink
        },
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          marginTop: "auto", // This ensures the footer stays at the bottom
        },
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          height: "20px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: '"Montserrat", sans-serif',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
          fontWeight: 600,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
          padding: "0px",
          textAlign: "start",
          justifyContent: "start",
          fontWeight: 600,
        },
      },
    },
  },
  MuiTableCell: {
    root: {
      borderColor: "#d3d3d3",
      fontWeight: 600,
    },
    head: {
      background: "lightgrey",
      "&:not(:last-child)": {
        borderRight: [[1, "solid", "#c0c0c0"]],
      },
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const AllClients = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [clients, setClients] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [refName, setRefName] = useState("");

  const db = getFirestore();

  const [companyId, setCompanyId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Set up a real-time listener for the clients collection
    try {
      const lastFetchTimeRaw = sessionStorage.getItem(
        "localClientDataFetchTime"
      );
      const lastFetchTime = lastFetchTimeRaw
        ? new Date(lastFetchTimeRaw)
        : new Date(0);

      const cachedClient =
        JSON.parse(sessionStorage.getItem("localClientData")) || [];
      // console.log("cachedClient :>> ", cachedClient);
      setClients(cachedClient);

      const unsubscribe = onSnapshot(
        collection(db, "clients"),
        where("updated_at", ">", lastFetchTime),
        (querySnapshot) => {
          const clientData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          // console.log('clientData :>> ', clientData);

          // Merge new and cached customers
          const updatedClient = [...cachedClient];

          clientData.forEach((newClient) => {
            const index = updatedClient.findIndex(
              (cached) => cached.id === newClient.id
            );
            if (index > -1) {
              updatedClient[index] = newClient;
            } else {
              updatedClient.push(newClient);
            }
          });

          // Update local storage
          sessionStorage.setItem(
            "localClientData",
            JSON.stringify(updatedClient)
          );
          sessionStorage.setItem(
            "localClientDataFetchTime",
            new Date().toISOString()
          );

          setClients(clientData);
          // console.log("clientData :>> ", clientData);
        }
      );

      // Clean up the listener when the component unmounts
      return () => unsubscribe();
    } catch (error) {}
  }, [db]);

  const fetchNameByRefID = async (refID) => {
    if (!refID) return null;

    try {
      const docRef = doc(db, "clients", refID);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return docSnap.data().Personal.name; // Adjust based on your Firestore structure
      } else {
        console.log("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error fetching name by refID: ", error);
      return null;
    }
  };

  const [documents, setDocuments] = useState([]);
  const [documentName, setDocumentName] = useState("");

  useEffect(() => {
    if (selectedClient?.Personal?.documents) {
      setDocuments(selectedClient.Personal.documents);
    }
  }, [selectedClient]);

  const fileInputRef = useRef(null);

  // const fetchDocuments = async (customerId) => {
  //   try {
  //     const docRef = doc(db, "customers", customerId);
  //     const docSnap = await getDoc(docRef);
  //     if (docSnap.exists()) {
  //       const customerData = docSnap.data();
  //       // console.log("customerData :>> ", customerData);
  //       // setOldDocuments(customerData.Personal?.documents || []);
  //       setDocuments(customerData.Personal?.documents || []); // Set existing documents array
  //     } else {
  //       console.log("No such document!");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching documents:", error);
  //   }
  // };

  const handleIconClick = async (client) => {
    setSelectedClient(client);
    setDrawerOpen(true);

    const refID = client?.Personal?.ref_by;

    const refName = await fetchNameByRefID(refID);
    setRefName(refName); // Update state with refName
  };

  const handleEditClick = (client) => {
    console.log("client :>> ", client);
    setSelectedClient(client);
    setEditClient(client);
    // console.log('customer :>> ', customer.id);
    setDialogOpen(true);
  };

  const [viewId, setViewId] = useState(null);
  const [open, setOpen] = useState(false);
  const [newDocuments, setNewDocuments] = useState([]);

  const fetchDocuments = async (customerId) => {
    try {
      const docRef = doc(db, "clients", customerId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const customerData = docSnap.data();
        console.log("customerData :>> ", customerData);
        // setOldDocuments(customerData.Personal?.documents || []);
        setDocuments(customerData.Personal?.documents || []); // Set existing documents array
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const handleClickOpen = async (client) => {
    document.getElementById("fileInput").click();
    const clnttId = client.id;
    setViewId(clnttId);
    await fetchDocuments(clnttId);
  };

  const handleEditNameChange = (index, newName) => {
    setNewDocuments((prevDocuments) => {
      const updatedDocuments = [...prevDocuments];
      updatedDocuments[index].editName = newName;
      return updatedDocuments;
    });
  };

  const handleSaveNameChange = (index) => {
    setNewDocuments((prevDocuments) => {
      const updatedDocuments = [...prevDocuments];
      updatedDocuments[index].name = updatedDocuments[index].editName;
      return updatedDocuments;
    });
  };

  const handleDeleteDocument = (index) => {
    setNewDocuments((prevDocuments) => {
      const updatedDocuments = [...prevDocuments];
      updatedDocuments.splice(index, 1);
      return updatedDocuments;
    });
  };

  const handleClose = () => {
    setOpen(false);
    setNewDocuments([]);
    setDrawerOpen(false);
    setSelectedClient(null);
  };

  const handleFileChange = (e) => {
    const { name, value, files } = e.target;
    setOpen(true);
    if (name === "Newdocuments") {
      const filesArray = Array.from(e.target.files).map((file) => ({
        // file: file,
        name: file.name,
        url: URL.createObjectURL(file),
        editName: file.name,
      }));
      console.log("filesArray :>> ", filesArray);
      setNewDocuments((prevDocuments) => [...prevDocuments, ...filesArray]);
      setDocuments((prevDocuments) => [...prevDocuments]);
      console.log("documents :>> ", documents);
    }
  };

  const handleDocumentsUpload = async () => {
    try {
      setLoading(true);

      const uploadedDocuments = [];
      for (let i = 0; i < newDocuments.length; i++) {
        const documentRef = ref(
          storage,
          `ClientDocument/${viewId}/${newDocuments[i].name}`
        );
        // console.log("documentRef :>> ", documentRef);
        await uploadBytes(documentRef, newDocuments[i].file);
        const documentURL = await getDownloadURL(documentRef);
        // console.log("documentURL :>> ", documentURL);
        uploadedDocuments.push({
          name: newDocuments[i].name,
          url: documentURL,
        });
      }

      console.log("uploadedDocuments :>> ", uploadedDocuments);

      const mergedDocuments = [...documents, ...uploadedDocuments];
      console.log("mergedDocuments :>> ", mergedDocuments);

      const docRef = doc(db, "clients", viewId);
      await updateDoc(docRef, {
        // editCustomer,
        "Personal.documents": mergedDocuments,
        updated_at: serverTimestamp(),
      });

      toast.success("Documents uploaded successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setOpen(false);
    } catch (error) {
      console.error("Error uploading documents: ", error);
      console.log("Error: ", error);
      toast.error("Failed to upload documents. Please try again later.");
    } finally {
      setLoading(false);
      setNewDocuments([]);
      setDrawerOpen(false);
    }
  };

  const handleDelete = async (docUrl) => {
    try {
      setLoading(true);

      // Reference to the Firestore document
      const docRef = doc(db, "clients", selectedClient.id);
      const clientsDoc = await getDoc(docRef);

      if (clientsDoc.exists()) {
        // Filter out the document that needs to be deleted
        const updatedDocuments = clientsDoc
          .data()
          .Personal.documents.filter((doc) => doc.url !== docUrl);

        // Update Firestore with the remaining documents
        await updateDoc(docRef, {
          "Personal.documents": updatedDocuments,
        });

        // Delete the document from Firebase Storage
        const fileName = docUrl; // Extract file name from URL
        console.log("fileName :>> ", fileName);

        const documentRef = ref(storage, fileName);
        console.log("documentRef :>> ", documentRef);

        await deleteObject(documentRef).catch((error) => {
          console.error("Failed to delete document from storage:", error);
        });

        // Update state to reflect the deletion
        setDocuments((prevDocuments) =>
          prevDocuments.filter((doc) => doc.url !== docUrl)
        );

        console.log("Document successfully deleted!");
        toast.success("Document successfully deleted!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        console.log("No such document!");
        toast.error("No such document!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error("Error removing document: ", error);
      toast.error("Error removing document. Please try again.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setLoading(false);
    }
  };

  // const handleDelete = async (docUrl) => {
  //   try {
  //     setLoading(true);
  //     const docRef = doc(db, "clients", selectedClient.id);
  //     const clientsDoc = await getDoc(docRef);

  //     if (clientsDoc.exists()) {
  //       const updatedDocuments = clientsDoc
  //         .data()
  //         .Personal.documents.filter((doc) => doc.url !== docUrl);

  //       await updateDoc(docRef, {
  //         "Personal.documents": updatedDocuments,
  //       });

  //       setDocuments((prevDocuments) =>
  //         prevDocuments.filter((doc) => doc.url !== docUrl)
  //       );

  //       console.log("Document successfully deleted!");
  //     } else {
  //       console.log("No such document!");
  //     }
  //   } catch (error) {
  //     console.error("Error removing document: ", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedClient(null);
    setEditClient(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditClient((prevClient) => ({
      ...prevClient,
      Personal: {
        ...prevClient.Personal,
        [name]: value,
      },
    }));
  };

  const handleEditSubmit = async () => {
    try {
      setLoading(true);
      const docRef = doc(db, "clients", editClient.id);
      await updateDoc(docRef, {
        ...editClient,
        updated_at: serverTimestamp(),
      });
      setClients((prevCustomer) =>
        prevCustomer.map((client) =>
          client.id === editClient.id ? editClient : client
        )
      );
      toast.success("Client Data Update successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setDialogOpen(false);
    } catch (error) {
      console.error("Error updating document: ", error);
    } finally {
      setLoading(false);
    }
  };

  const [editClient, setEditClient] = useState(null);
  const [curruntCompanyId, setCurruntCompanyId] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const flattenData = () => {
    return clients.map((item) => ({
      id: item.id,
      customer_code: item.Personal?.client_code || "-",
      name: item.Personal?.name || "-",
      phone_number: item.Personal?.phone_number || "-",
      email: item.Personal?.email || "-",
      gender: item.Personal?.gender || "-",
      address: item.Personal?.address || "-",
      client_type: item.Personal?.client_type || "-",
    }));
  };

  const flattenedClients = flattenData(clients);

  const columns = [
    {
      name: "no",
      label: "No",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                width: "60px",
                maxWidth: "30px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: "center",
              }}
            >
              {tableMeta.rowIndex + 1}
            </div>
          );
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value) => {
          const capitalizeFirstLetter = (str) => {
            if (!str) return str;
            return str.charAt(0).toUpperCase() + str.slice(1);
          };
          return (
            <div
              style={{
                // maxWidth: "150px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {capitalizeFirstLetter(value)}
            </div>
          );
        },
      },
    },
    {
      name: "phone_number",
      label: "Mobile Number",
      options: {
        customBodyRender: (value) => (
          <div
            style={{
              maxWidth: "250px",
              width: "150px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {value}
          </div>
        ),
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        customBodyRender: (value) => (
          <div
            style={{
              // maxWidth: "150px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {value}
          </div>
        ),
      },
    },
    {
      name: "gender",
      label: "Gender",
      options: {
        customBodyRender: (value) => {
          const capitalizeFirstLetter = (str) => {
            if (!str) return str;
            return str.charAt(0).toUpperCase() + str.slice(1);
          };

          return (
            <div
              style={{
                maxWidth: "200px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {capitalizeFirstLetter(value)}
            </div>
          );
        },
      },
    },
    {
      name: "client_type",
      label: "Type",
      options: {
        customBodyRender: (value) => {
          const capitalizeFirstLetter = (str) => {
            if (!str) return str;
            return str.charAt(0).toUpperCase() + str.slice(1);
          };

          return (
            <div
              style={{
                maxWidth: "200px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {capitalizeFirstLetter(value)}
            </div>
          );
        },
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        customBodyRender: (value) => {
          const capitalizeFirstLetter = (str) => {
            if (!str) return str;
            return str.charAt(0).toUpperCase() + str.slice(1);
          };
          return (
            <div
              style={{
                // maxWidth: "200px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {capitalizeFirstLetter(value)}
            </div>
          );
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        // filter: false,
        // sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              // alignItems: "center",
              // justifyContent:"center"
            }}
          >
            {/* Eye */}
            <div>
              <IconButton
                sx={{ pt: 0 }}
                onClick={() => handleIconClick(clients[tableMeta.rowIndex])}
              >
                <VisibilityIcon />
              </IconButton>
            </div>

            {/* Edit client */}
            <div>
              <IconButton
                sx={{ pt: 0 }}
                onClick={() => handleEditClick(clients[tableMeta.rowIndex])}
              >
                <ModeEditOutlineIcon />
              </IconButton>
            </div>

            {/* Edit */}
            {/* <div>
              <IconButton
                sx={{ pt: 0 }}
                onClick={() => handleUpdateClick(clients[tableMeta.rowIndex])}
              >
                <CachedIcon />
              </IconButton>
            </div> */}
          </div>
        ),
      },
    },
  ];

  const rows = 10; // Number of skeleton rows
  const columnsCount = 7; // Number of columns

  const options = {
    filterType: "multiselect",
    responsive: "standard",
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: loading ? (
          <Skeleton rows={rows} columns={columnsCount} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedClient(null);
  };

  return (
    <>
      {/* <Navbar /> */}
      <MetaTags>
        <title>All Clients</title>
      </MetaTags>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <Sidebar />
          <Box sx={{ flex: 1, backgroundColor: "#F4F7F9", overflow: "hidden" }}>
            <Navbar />
            <Box
              sx={{
                p: 2,
                // width: "98%",
              }}
            >
              <Box sx={{ padding: "1%" }}>
                <Box
                  borderRadius="17px"
                  sx={{ boxShadow: "0px 9px 20px rgba(46, 35, 94, 0.07)" }}
                  overflow="hidden"
                >
                  <CustomMuiPaper>
                    <MuiDatatables
                      // title="All Policy"
                      data={
                        loading
                          ? []
                          : flattenedClients && flattenedClients.length > 0
                          ? flattenedClients
                          : []
                      }
                      columns={columns}
                      options={{
                        ...options,
                        searchOpen: true,
                        searchAlwaysOpen: true,
                        searchPlaceholder: "Search keyword",
                        customSearchRender: (searchText, handleSearch) => {
                          return (
                            <CustomSearchRender
                              searchText={searchText}
                              onSearch={handleSearch}
                            />
                          );
                        },
                      }}
                    />
                  </CustomMuiPaper>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle style={{ fontFamily: '"Montserrat", sans-serif' }}>
            Update Client Details
          </DialogTitle>
          <DialogContent>
            {selectedClient && (
              <Box sx={{ padding: 1 }}>
                <Divider />
                <Box sx={{ marginY: 2 }}>
                  <TextField
                    label="Full Name"
                    name="name"
                    value={editClient?.Personal?.name || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    type="date"
                    label="Date of Birth"
                    name="dob"
                    value={editClient?.Personal?.dob || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <RadioGroup
                    row
                    label="Gender"
                    name="gender" // This name should be aligned with your input structure
                    value={editClient?.Personal?.gender || ""}
                    onChange={handleInputChange} // Use the existing handleInputChange function
                  >
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Female"
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio />}
                      label="Other"
                    />
                  </RadioGroup>

                  <TextField
                    label="Phone Number"
                    name="phone_number"
                    value={editClient?.Personal?.phone_number || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Email"
                    name="email"
                    value={editClient?.Personal?.email || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Address"
                    name="address"
                    value={editClient?.Personal?.address || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Additional info"
                    name="additional_info"
                    value={editClient?.Personal?.additional_info || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />
                </Box>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleDialogClose}
              variant="outlined"
              sx={{ border: "1px solid #57dd36", color: "#57dd36", p: 1 }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleEditSubmit}
              variant="contained"
              disabled={loading} // Assuming `loading` state indicates loading state
              // startIcon={loading ? <CircularProgress size={24} /> : null}
              sx={{ backgroundColor: "#57dd36", p: 1 }}
            >
              {loading ? <CircularProgress size={24} /> : "Save Changes"}
            </Button>
          </DialogActions>
        </Dialog>

        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={handleDrawerClose}
          sx={{ "& .MuiDrawer-paper": { width: 460, padding: 2 } }}
        >
          {selectedClient && (
            <Box sx={{ padding: 2, fontFamily: '"Montserrat", sans-serif' }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  onClick={handleDrawerClose}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="48"
                      d="M328 112L184 256l144 144"
                    />
                  </svg>
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Client Details
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ marginY: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 2,
                  }}
                >
                  <img
                    src={
                      selectedClient.Personal?.profilePhotoURL
                        ? selectedClient.Personal?.profilePhotoURL
                        : "https://t4.ftcdn.net/jpg/05/42/36/11/360_F_542361185_VFRJWpR2FH5OiAEVveWO7oZnfSccZfD3.jpg"
                    }
                    alt={`${selectedClient.Personal?.name}'s profile`}
                    style={{
                      width: 100,
                      height: 100,
                      borderRadius: "50%",
                      marginBottom: 16,
                    }}
                  />
                  <Divider />
                </Box>

                {selectedClient.Personal?.name && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Name:</h5>{" "}
                    {capitalizeFirstLetter(selectedClient.Personal?.name)}
                  </Typography>
                )}

                {selectedClient.Personal?.phone_number && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Mobile:</h5> {selectedClient.Personal?.phone_number}
                  </Typography>
                )}

                {selectedClient.Personal?.email && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Email:</h5> {selectedClient.Personal?.email}
                  </Typography>
                )}

                {selectedClient.Personal?.dob && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>DOB:</h5> {selectedClient.Personal?.dob}
                  </Typography>
                )}

                {selectedClient.Personal?.gender && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Gender:</h5>{" "}
                    {capitalizeFirstLetter(selectedClient.Personal?.gender)}
                  </Typography>
                )}

                {selectedClient.Personal?.ref_by && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Ref By:</h5>{" "}
                    {capitalizeFirstLetter(
                      refName || selectedClient.Personal?.ref_by
                    )}
                  </Typography>
                )}

                {selectedClient.Personal?.address && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Address:</h5>{" "}
                    {capitalizeFirstLetter(selectedClient.Personal?.address)}
                  </Typography>
                )}

                {selectedClient.Personal?.additional_info && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Additional info:</h5>{" "}
                    {capitalizeFirstLetter(
                      selectedClient.Personal?.additional_info
                    )}
                  </Typography>
                )}

                <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <h5>Document:</h5>
                    <Button
                      sx={{ display: "flex", justifyContent: "center", width:"6%", minWidth:"6%" }}
                      onClick={() => handleClickOpen(selectedClient)}
                    >
                      <Add size={28} sx={{ color: "#3bd615" }} />
                      <input
                        name="Newdocuments"
                        type="file"
                        id="fileInput"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        multiple
                      />
                    </Button>
                  </Box>
                  {selectedClient.Personal?.documents &&
                    selectedClient.Personal?.documents.length > 0 && (
                      <>
                        {documents.map((docName) => (
                          <div
                            key={docName.url}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <a
                              href={docName.url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                              {docName.name}, <br />
                            </a>
                            <p
                              onClick={() => handleDelete(docName.url)}
                              sx={{ marginLeft: "10px", curser: "pointer" }}
                              // disabled={loading}
                            >
                              <Delete sx={{ color: "#d60000" }} />
                              {/* {loading ? <CircularProgress /> : <Delete sx={{ color: "#d60000" }} />}   */}
                            </p>
                          </div>
                        ))}
                      </>
                    )}
                </Typography>

              </Box>
            </Box>
          )}
        </Drawer>

        <Dialog open={open}>
          <DialogTitle>Documents</DialogTitle>
          <DialogContent>
            <List>
              {newDocuments.map((doc, index) => (
                <ListItem
                  key={index}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <ListItemText
                    primary={
                      <TextField
                        value={doc.editName}
                        onChange={(e) =>
                          handleEditNameChange(index, e.target.value)
                        }
                        variant="outlined"
                        size="small"
                        onBlur={() => handleSaveNameChange(index)}
                      />
                    }
                  />
                  <IconButton
                    edge="end"
                    onClick={() => handleDeleteDocument(index)}
                  >
                    <Delete />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
            <Button
              onClick={handleDocumentsUpload}
              color="primary"
              disabled={loading}
            >
              {loading ? "Uploading..." : "Upload"}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default AllClients;
