// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from 'firebase/database';
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// { Production Config }
// const firebaseConfig = {
//   apiKey: "AIzaSyADVIBF8FmIo6F7rZfWIHg5ZKVU1hwXmGA",
//   authDomain: "autopulse-pro.firebaseapp.com",
//   projectId: "autopulse-pro",
//   storageBucket: "autopulse-pro.appspot.com",
//   messagingSenderId: "916577404600",
//   appId: "1:916577404600:web:e58af98329afbd1733d64d",
//   measurementId: "G-XCXFLLX257"
// };

// { Dev 2 Config}
// const firebaseConfig = {
//   apiKey: "AIzaSyDLkxNsO4E4I872la5jbRIXWSgfQXkHkKs",
//   authDomain: "autopulse-dev2-14835.firebaseapp.com",
//   projectId: "autopulse-dev2-14835",
//   storageBucket: "autopulse-dev2-14835.appspot.com",
//   messagingSenderId: "889511616699",
//   appId: "1:889511616699:web:6785fc1abccb4d00d6c99e",
//   measurementId: "G-8WL4BD4W88"
// };

//  { Dev Config}
const firebaseConfig = {
  apiKey: "AIzaSyAMzOWtZCy4TUFY10m5XVP-sW2dZnm5ww4",
  authDomain: "autopulse-dev.firebaseapp.com",
  projectId: "autopulse-dev",
  storageBucket: "autopulse-dev.appspot.com",
  messagingSenderId: "978930247283",
  appId: "1:978930247283:web:2264e35b44e0f8acde6c06",
  measurementId: "G-4W7C7C44K5"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app)
const storage = getStorage(app);

export { db, app, auth, storage  };
