import React from "react";
import Grow from "@mui/material/Grow";
import TextField from "@mui/material/TextField";
import { withStyles } from "tss-react/mui";
import { Padding, SearchOutlined } from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";

const defaultSearchStyles = (theme) => ({
  searchText: {
    flex: "0.8 0",
    marginTop: 10,
    Padding: 0
  },
  searchIcon: {
    "&:hover": {
      color: theme.palette.error.main,
    },
  },
});

const CustomSearchRender = (props) => {
  const { classes, onSearch, searchText } = props;
  const handleTextChange = (event) => {
    onSearch(event.target.value);
  };

  return (
    <Grow appear in={true} timeout={300}>
      <TextField
        placeholder="Search.."
        size="medium"
        className={classes.searchText}
        value={searchText || ""}
        onChange={handleTextChange}
        fullWidth
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlined />
            </InputAdornment>
          ),
        }}
        sx={{
            width:"60%",
            "& .MuiOutlinedInput-root": {
                border: "1px solid #F4F7F9",
                borderRadius:"5px",
                "& input": {
                    padding: "7px 3px",
                    border: "none",
                }
            }
        }}
      />
    </Grow>
  );
};

export default withStyles(CustomSearchRender, defaultSearchStyles, {
  name: "CustomSearchRender",
});