import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./components/Login";
import ClientDashboard from "./Client/pages/clientDashboard";
import NewCustomer from "./Client/pages/newCustomer";
import AllCustomers from "./Client/pages/AllCustomers";
import NewLicPolicy from "./Client/pages/NewLicPolicy";
import { ToastContainer } from "react-toastify";
import NewGicPolicy from "./Client/pages/NewGicPolicy";
import AdminDashboard from "./Admin/pages/AdminDashboard";
import NewClient from "./Admin/pages/NewClient";
import AllClients from "./Admin/pages/AllClients";
import NewCompany from "./Admin/pages/NewCompany";
import AllCompany from "./Admin/pages/AllCompany";
import AllLicPolicy from "./Client/pages/AllLicPolicy";
import AllGicPolicy from "./Client/pages/AllGicPolicy";
import NewLICJointPolicy from "./Client/pages/NewLICJointPolicy";
import GstRates from "./Client/pages/GstRates";
import TermsCondition from "./components/TermsCondition";
import ClientProfile from "./Client/pages/ClientProfile";
import ThirdParty from "./Client/pages/ThirdParty";
import AllThirdPartyPolicy from "./Client/pages/AllThirdPartyPolicy";
import PremiumReminders from "./Client/pages/PremiumReminders";

const checkAuth = () => {
  let isAuthenticated = sessionStorage.getItem("clientId");
  if (!isAuthenticated) {
    isAuthenticated = sessionStorage.getItem("masterAdminId");
  }
  // console.log("isAuthenticated :>> ", isAuthenticated);
  return isAuthenticated;
};

const ProtectedRoute = ({ element, path }) => {
  const isAuthenticated = checkAuth();
  return isAuthenticated ? element : <Navigate to="/" />;
};

// in package.json file
// "homepage": "https://dev.autopulseweb.com/",

function App() {
  return (
    <Router>
      {/* // <Router basename="/dev"> */}
      <ToastContainer />
      <Routes>
        <Route exact path="/" element={<Login />}></Route>

        {/* Admin PAnel */}
        <Route
          exact
          path="/dashboard"
          element={<ProtectedRoute element={<AdminDashboard />} />}
        ></Route>
        <Route
          exact
          path="/newclient"
          element={<ProtectedRoute element={<NewClient />} />}
        ></Route>
        <Route
          exact
          path="/allclients"
          element={<ProtectedRoute element={<AllClients />} />}
        ></Route>
        <Route
          exact
          path="/newcompany"
          element={<ProtectedRoute element={<NewCompany />} />}
        ></Route>
        <Route
          exact
          path="/allcompany"
          element={<ProtectedRoute element={<AllCompany />} />}
        ></Route>

        {/* Client Panel */}
        <Route
          exact
          path="/cDashboard"
          element={<ProtectedRoute element={<ClientDashboard />} />}
        ></Route>
        <Route
          exact
          path="/profile"
          element={<ProtectedRoute element={<ClientProfile />} />}
        ></Route>
        <Route
          exact
          path="/premiumreminders"
          element={<ProtectedRoute element={<PremiumReminders />} />}
        ></Route>
        <Route
          exact
          path="/newcustomer"
          element={<ProtectedRoute element={<NewCustomer />} />}
        ></Route>
        <Route
          exact
          path="/allcustomers"
          element={<ProtectedRoute element={<AllCustomers />} />}
        ></Route>
        <Route
          exact
          path="/newlicpolicy"
          element={<ProtectedRoute element={<NewLicPolicy />} />}
        ></Route>
        <Route
          exact
          path="/alllicpolicy"
          element={<ProtectedRoute element={<AllLicPolicy />} />}
        ></Route>
        <Route
          exact
          path="/newjointpolicy"
          element={<ProtectedRoute element={<NewLICJointPolicy />} />}
        ></Route>
        <Route
          exact
          path="/allgicpolicy"
          element={<ProtectedRoute element={<AllGicPolicy />} />}
        ></Route>
        <Route
          exact
          path="/newgicpolicy"
          element={<ProtectedRoute element={<NewGicPolicy />} />}
        ></Route>
        <Route
          exact
          path="/managegstrates"
          element={<ProtectedRoute element={<GstRates />} />}
        ></Route>
        <Route
          exact
          path="/thirdparty"
          element={<ProtectedRoute element={<ThirdParty/>} />}
        ></Route>
        <Route
          exact
          path="/allthirdpartypolicy"
          element={<ProtectedRoute element={<AllThirdPartyPolicy/>} />}
        ></Route>

        <Route
          exact
          path="/terms-condition"
          element={<TermsCondition />}
        ></Route>
      </Routes>
    </Router>
  );
}

export default App;
