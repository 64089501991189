import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Skeleton,
  IconButton,
  Radio,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { BsArrowUpRight } from "react-icons/bs";
import Sidebar from "../../Client/components/Sidebar";
import "../css/Dashboard.css";
import "../../Admin/css/NewCompany.css";
import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";
import documentFile from "../../components/images/doc.png";
import mediaFile from "../../components/images/mediaFile.png";
import otherFile from "../../components/images/otherFile.png";
import unknwonFile from "../../components/images/unknown.png";
import CCustomer from "../../components/images/customer.png";
import CPolicy from "../../components/images/policy.png";
import CRevenue from "../../components/images/banknote.png";
import CMessage from "../../components/images/message.png";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db, storage } from "../../components/utils/firebase";
import Navbar from "../../components/Navbar/Navbar";
import MetaTags from "react-meta-tags";
import { ref, listAll, getMetadata } from "firebase/storage";
import MuiDatatables from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CustomSearchRender from "../../components/CustomSearchRender";
import PRIcon from "../../components/images/PR-icon.png";

const CustomMuiPaper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  width: "auto",
  overflow: 'auto',
  maxWidth:"65vw",
  maxHeight: "80vh"
});

const theme = createTheme({
  components: {
    // MuiPaper: {
    //   styleOverrides: {
    //     root: {
    //       display: 'flex',
    //       flexDirection: 'column',
    //       height: '100%',
    //       width:"53.1vw",
    //       // overflow: 'auto',
    //     },
    //   },
    // },
    // MuiTableContainer: {
    //   styleOverrides: {
    //     root: {
    //       flex: '1 1 auto', // Allow the table container to grow and shrink
    //     },
    //   },
    // },
    // MuiTableFooter: {
    //   styleOverrides: {
    //     root: {
    //       marginTop: 'auto', // This ensures the footer stays at the bottom
    //     },
    //   },
    // },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          height: "20px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: '"Montserrat", sans-serif',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
          fontWeight: 600,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: "0px",
          //   backgroundColor: "#0b0b0b",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
          padding: "0px",
          textAlign: "start",
          justifyContent: "start",
          fontWeight: 600,
        },
      },
    },
  },
  MuiTableCell: {
    root: {
      borderColor: "#d3d3d3",
      fontWeight: 600,
    },
    head: {
      background: "lightgrey",
      "&:not(:last-child)": {
        borderRight: [[1, "solid", "#c0c0c0"]],
      },
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const data = [
  { value: 5, label: "A" },
  { value: 10, label: "B" },
  { value: 15, label: "C" },
  { value: 20, label: "D" },
];

const size = {
  width: 350,
  height: 200,
};

const StyledText = styled("text")(({ theme, fontSize }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: fontSize || 20,
}));

function PieCenterLabel({ top, bottom, topFontSize, bottomFontSize }) {
  const { width, height, left, top: topOffset } = useDrawingArea();
  return (
    <>
      <StyledText
        x={left + width / 2}
        y={topOffset + height / 2 - 20}
        fontSize={topFontSize}
      >
        {top}
      </StyledText>
      <StyledText
        x={left + width / 2}
        y={topOffset + height / 2 + 20}
        fontSize={bottomFontSize}
      >
        {bottom}
      </StyledText>
    </>
  );
}

const ChartContainer = styled("div")({
  pointerEvents: "none", // Disable pointer events to remove hover effect
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  borderRadius: "17px",
  boxShadow: "0px 9px 20px rgba(46, 35, 94, 0.07)",
  fontFamily: '"Montserrat", sans-serif',
  backgroundColor: "#fff",
  transition: "all 0.3s ease",
  letterSpacing: "0.5px",
  padding: "10px 5px",
  height: "170px",
}));

const BottomStyledPaper = styled(Paper)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  borderRadius: "17px",
  boxShadow: "0px 9px 20px rgba(46, 35, 94, 0.07)",
  fontFamily: '"Montserrat", sans-serif',
  backgroundColor: "#fff",
  transition: "all 0.3s ease",
  letterSpacing: "0.5px",
  padding: "10px 5px",
}));

const Dashboard = () => {
  const storedClientId = sessionStorage.getItem("clientId");
  const [totalSizeMB, setTotalSizeMB] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [customer, setCustomer] = useState([]);
  const [licData, setLicData] = useState([]);
  const [gicData, setGicData] = useState([]);
  const [totalPolicy, setTotalPolicy] = useState([]);

  useEffect(() => {
    const getFolderSize = async (folderPath) => {
      let totalSize = 0;

      try {
        const folderRef = ref(storage, folderPath);
        const result = await listAll(folderRef);

        for (const fileRef of result.items) {
          const metadata = await getMetadata(fileRef);
          totalSize += metadata.size;
        }

        for (const folderRef of result.prefixes) {
          totalSize += await getFolderSize(folderRef.fullPath);
        }
      } catch (e) {
        console.error("Error:", e);
      }

      return totalSize;
    };

    const calculateTotalSize = async () => {
      // const cachedSize = sessionStorage.getItem(`totalSizeMB_${storedClientId}`);
      // if (cachedSize) {
      //   setTotalSizeMB(Number(cachedSize));
      //   setIsLoading(false);
      //   return;
      // }

      const customerDocSizeBytes = await getFolderSize(
        `CustomerDocument/${storedClientId}`
      );
      // console.log("customerDocSizeBytes :>> ", customerDocSizeBytes);
      const clientDocSizeBytes = await getFolderSize(
        `ClientDocument/${storedClientId}`
      );
      const totalSizeBytes = customerDocSizeBytes + clientDocSizeBytes;
      // let finalSize = (totalSizeBytes / (1024 * 1024)).toFixed(2);
      setTotalSizeMB((totalSizeBytes / (1024 * 1024)).toFixed(2));
      // sessionStorage.setItem(`totalSizeMB_${storedClientId}`, totalSizeMB);

      // console.log("totalSizeMB :>> ", totalSizeMB);
      setIsLoading(false);
    };

    const fetchCompany = async () => {
      const docRef = doc(db, "clients", storedClientId);
      const docSnap = await getDoc(docRef);

      const companyCollection = collection(db, "company");
      const clientCollection = collection(db, "clients");
      const customerCollection = collection(db, "customers");

      const querySnapshot = await getDocs(companyCollection);
      const clientQuerySnapshot = await getDocs(clientCollection);
      const customerQuerySnapshot = await getDocs(
        query(
          collection(db, "customers"),
          where("Personal.companyId", "==", docSnap.data().companyId)
        )
      );
      const licQuerySnapshot = await getDocs(
        query(
          collection(db, "lic"),
          where("companyId", "==", docSnap.data().companyId)
        )
      );
      const gicQuerySnapshot = await getDocs(
        query(
          collection(db, "gic"),
          where("companyId", "==", docSnap.data().companyId)
        )
      );

      // const companyData = querySnapshot.docs.map((doc) => ({
      //   id: doc.id,
      //   ...doc.data(),
      // }));

      const licData = licQuerySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLicData(licData);
      // console.log('licData :>> ', licData.length);

      const gicData = gicQuerySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setGicData(gicData);
      // console.log('gicData :>> ', gicData.length);

      setTotalPolicy(licData.length + gicData.length);
      // console.log("totalPolicy :>> ", totalPolicy.length);

      const customerData = customerQuerySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // setCompany(companyData);
      // setClient(clientData);
      setCustomer(customerData);
      // console.log("customerData :>> ", customerData.length);
    };

    fetchCompany();
    calculateTotalSize();
  }, [storedClientId, totalSizeMB]);

  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [clientId, setClientId] = useState(null);

  const fetchClientData = async (id) => {
    try {
      const docRef = doc(db, "clients", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const companyId = docSnap.data().companyId;

        const licSnapshot = await getDocs(
          query(
            collection(db, "lic"),
            where("companyId", "==", companyId),
            orderBy("name")
          )
        );

        const gicSnapshot = await getDocs(
          query(
            collection(db, "gic"),
            where("companyId", "==", companyId),
            orderBy("name")
          )
        );

        const thirdPartySnapshot = await getDocs(
          query(
            collection(db, "thirdPartyPolicy"),
            where("companyId", "==", companyId),
            orderBy("name")
          )
        );

        const combineData = (snapshot, source) =>
          snapshot.docs.map((doc) => ({
            id: doc.id,
            source,
            ...doc.data(),
          }));

        const customerData = [
          // ...combineData(licSnapshot, "LIC"),
          ...combineData(gicSnapshot, "GIC"),
          ...combineData(thirdPartySnapshot, "TPP"),
        ];

        // console.log("customerData :>> ", customerData);
        setCustomers(customerData);
        // setEditCustomer(customerData.id)
      } else {
        console.log("No such document!");
      }
    } catch (err) {
      console.error("Error fetching document:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const storedClientId = sessionStorage.getItem("clientId");
    setClientId(storedClientId);
    if (storedClientId) {
      fetchClientData(storedClientId);
    } else {
      setLoading(false);
    }
  }, []);

  const flattenData = (data) => {
    return data.map((item) => ({
      id: item.id,
      name: item.name || "-",
      policyName: item.policyName || "-",
      premiumAmount: item.premiumAmount || "-",
      policyNumber: item.policyNumber || "-",
      sumAssured: item.sumAssured || "-",
      source: item.source || "-",
      firstField:
        item.reminderDate && item.reminderDate[0]
          ? item.reminderDate[0].status === true
          : true,
      secondField:
        item.reminderDate && item.reminderDate[1]
          ? item.reminderDate[1].status === true
          : true,
      thirdField:
        item.reminderDate && item.reminderDate[2]
          ? item.reminderDate[2].status === true
          : true,
    }));
  };

  const flattenedCustomers = flattenData(customers);

  // Define custom styles for the Radio button
  const CustomRadio = withStyles({
    root: {
      color: "#000", // Default color when unchecked
      "&$checked": {
        color: "#000", // Color when checked
        backgroundColor: "black", // Background color when checked
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  const columns = [
    {
      name: "no",
      label: "No",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                width: "60px",
                maxWidth: "30px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: "center",
              }}
            >
              {tableMeta.rowIndex + 1}
            </div>
          );
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value) => {
          const capitalizeFirstLetter = (str) => {
            if (!str) return str;
            return str.charAt(0).toUpperCase() + str.slice(1);
          };
          return (
            <div
              style={{
                // maxWidth: "150px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {capitalizeFirstLetter(value)}
            </div>
          );
        },
      },
    },
    {
      name: "firstField",
      label: "1st",
      options: {
        customBodyRender: (value) => (
          <div
            style={{
              maxWidth: "250px",
              width: "30px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <CustomRadio checked={value} />
          </div>
        ),
      },
    },
    {
      name: "secondField",
      label: "2nd",
      options: {
        customBodyRender: (value) => (
          <div
            style={{
              maxWidth: "150px",
              width: "30px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <CustomRadio checked={value} />
          </div>
        ),
      },
    },
    {
      name: "thirdField",
      label: "3rd",
      options: {
        customBodyRender: (value) => {
          return (
            <div
              style={{
                maxWidth: "200px",
                width: "30px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <CustomRadio checked={value} />
            </div>
          );
        },
      },
    },
    {
      name: "policyName",
      label: "Policy Name",
      options: {
        customBodyRender: (value) => {
          const capitalizeFirstLetter = (str) => {
            if (!str) return str;
            return str.charAt(0).toUpperCase() + str.slice(1);
          };

          return (
            <div
              style={{
                maxWidth: "200px",
                // width: "130px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {capitalizeFirstLetter(value)}
            </div>
          );
        },
      },
    },
    {
      name: "premiumAmount",
      label: "Premium amount",
      options: {
        customBodyRender: (value) => {
          const capitalizeFirstLetter = (str) => {
            if (!str) return str;
            return str.charAt(0).toUpperCase() + str.slice(1);
          };
          return (
            <div
              style={{
                maxWidth: "200px",
                width: "100px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {capitalizeFirstLetter(value)}
            </div>
          );
        },
      },
    },
    {
      name: "policyNumber",
      label: "Policy Number ",
      options: {
        customBodyRender: (value) => {
          const capitalizeFirstLetter = (str) => {
            if (!str) return str;
            return str.charAt(0).toUpperCase() + str.slice(1);
          };
          return (
            <div
              style={{
                maxWidth: "200px",
                width: "145px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {capitalizeFirstLetter(value)}
            </div>
          );
        },
      },
    },
    {
      name: "sumAssured",
      label: "Sum Assured",
      options: {
        customBodyRender: (value) => {
          const capitalizeFirstLetter = (str) => {
            if (!str) return str;
            return str.charAt(0).toUpperCase() + str.slice(1);
          };
          return (
            <div
              style={{
                maxWidth: "200px",
                width: "120px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {capitalizeFirstLetter(value)}
            </div>
          );
        },
      },
    },
    {
      name: "source",
      label: "Policy",
      options: {
        customBodyRender: (value) => {
          return (
            <div
              style={{
                maxWidth: "200px",
                width: "40px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {value}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        // filter: false,
        // sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              // alignItems: "center",
              // justifyContent:"center"
            }}
          >
            {/* Eye */}
            <div>
              <IconButton
                sx={{ pt: 0 }}
                // onClick={() => handleIconClick(customers[tableMeta.rowIndex])}
              >
                <img src={PRIcon} alt="view" height={20} />
              </IconButton>
            </div>
          </div>
        ),
      },
    },
  ];

  const rows = 10; // Number of skeleton rows
  const columnsCount = 7; // Number of columns

  const options = {
    filterType: "multiselect",
    responsive: "standard",
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: loading ? (
          <Skeleton rows={rows} columns={columnsCount} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
  };

  return (
    <>
      <MetaTags>
        <title>Dashboard</title>
      </MetaTags>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <Sidebar />
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#F4F7F9",
            }}
          >
            <Navbar />
            <Box
              // component="main"
              sx={{ p: 3, mb: 5 }}
            >
              <Box className="container">
                <Grid
                  container
                  spacing={3}
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                    marginBottom: "75%",
                  }}
                >
                  <Grid item xs={12} md={6} lg={3}>
                    <StyledPaper elevation={3}>
                      <div className="content">
                        <Box>
                          <img src={CCustomer} alt="" height={50} />
                        </Box>
                        <Typography variant="4" className="message-number">
                          {customer.length}
                        </Typography>
                        <Typography variant="body2" className="message-text">
                          Total Customers
                        </Typography>
                        <Typography variant="p" className="message-text2">
                          <BsArrowUpRight /> 1.01% this week
                        </Typography>
                      </div>
                    </StyledPaper>
                  </Grid>

                  <Grid item xs={12} md={6} lg={3}>
                    <StyledPaper elevation={3}>
                      <div className="content">
                        <Box>
                          <img src={CMessage} alt="" height={50} />
                        </Box>
                        <Typography variant="4" className="message-number">
                          75
                        </Typography>
                        <Typography variant="body2" className="message-text">
                          Total Messages Sent
                        </Typography>
                        <p variant="p" className="message-text2">
                          <BsArrowUpRight /> 1.01% this week
                        </p>
                      </div>
                    </StyledPaper>
                  </Grid>

                  <Grid item xs={12} md={6} lg={3}>
                    <StyledPaper elevation={3}>
                      <div className="content">
                        <Box>
                          <img src={CPolicy} alt="" height={50} />
                        </Box>
                        {/* {!totalPolicy ? (
                      <Typography
                        variant="4"
                        className="message-number"
                        sx={{ textAlign: "center" }}
                      >
                        0
                      </Typography>
                    ) : ( */}
                        <Typography
                          variant="4"
                          className="message-number"
                          sx={{ textAlign: "center" }}
                        >
                          {totalPolicy || "0"}
                        </Typography>
                        {/* )} */}
                        <Typography variant="body2" className="message-text">
                          Total Policy
                        </Typography>
                        <Typography variant="p" className="message-text2">
                          <BsArrowUpRight /> 1.01% this week
                        </Typography>
                      </div>
                    </StyledPaper>
                  </Grid>

                  <Grid item xs={12} md={6} lg={3}>
                    <StyledPaper elevation={3}>
                      <div className="content">
                        <Box>
                          <img src={CRevenue} alt="" height={50} />
                        </Box>
                        <Typography variant="4" className="message-number">
                          430700
                        </Typography>
                        <Typography variant="body2" className="message-text">
                          Revenue This Year
                        </Typography>
                        <Typography variant="p" className="message-text2">
                          <BsArrowUpRight /> 1.01% this week
                        </Typography>
                      </div>
                    </StyledPaper>
                  </Grid>

                  <Grid item xs={12} md={8} lg={8}> 
                    <Box
                      borderRadius="17px"
                      sx={{
                        boxShadow: "0px 9px 20px rgba(46, 35, 94, 0.07);",
                      }}
                      overflow="hidden"
                    >
                      <CustomMuiPaper>
                        <MuiDatatables
                          data={loading ? [] : flattenedCustomers}
                          columns={columns}
                          options={{
                            ...options,
                            searchOpen: true,
                            searchAlwaysOpen: true,
                            searchPlaceholder: "Search keyword",
                            customSearchRender: (searchText, handleSearch) => {
                              return (
                                <CustomSearchRender
                                  searchText={searchText}
                                  onSearch={handleSearch}
                                />
                              );
                            },
                          }}
                        />
                      </CustomMuiPaper>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={4} lg={4}>
                    <BottomStyledPaper elevation={3}>
                      <Box p={2} pt={1}>
                        <Typography
                          variant="h5"
                          sx={{
                            textAlign: "start",
                            fontFamily: '"Montserrat", sans-serif',
                            fontSize: "22px",
                            fontWeight: "400",
                          }}
                        >
                          Storage Details
                        </Typography>
                        <ChartContainer>
                          <PieChart
                            series={[{ data, innerRadius: 70 }]}
                            {...size}
                          >
                            <PieCenterLabel
                              top={`${totalSizeMB}MB`}
                              bottom="Out of 128"
                              topFontSize={30}
                              bottomFontSize={20}
                            />
                          </PieChart>
                        </ChartContainer>
                        <Grid
                          container
                          direction="column"
                          spacing={2}
                          sx={{ mt: 2 }}
                        >
                          <Grid item>
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Box
                                sx={{ display: "flex", flexDirection: "row" }}
                              >
                                <img src={documentFile} alt="" height={40} />
                                <Box sx={{ marginLeft: "5px" }}>
                                  <Typography
                                    variant="title1"
                                    sx={{ fontWeight: "700" }}
                                  >
                                    Documents Files
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      textAlign: "start",
                                      fontSize: "12px",
                                    }}
                                  >
                                    1205 Files
                                  </Typography>
                                </Box>
                              </Box>
                              <Box>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontSize: "16px", fontWeight: "700" }}
                                >
                                  1.3GB
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Box
                                sx={{ display: "flex", flexDirection: "row" }}
                              >
                                <img src={mediaFile} alt="" height={40} />
                                <Box sx={{ marginLeft: "5px" }}>
                                  <Typography
                                    variant="title1"
                                    sx={{ fontWeight: "700" }}
                                  >
                                    Media Files
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      textAlign: "start",
                                      fontSize: "12px",
                                    }}
                                  >
                                    1400 Files
                                  </Typography>
                                </Box>
                              </Box>
                              <Box>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontSize: "16px", fontWeight: "700" }}
                                >
                                  2.8GB
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Box
                                sx={{ display: "flex", flexDirection: "row" }}
                              >
                                <img src={otherFile} alt="" height={40} />
                                <Box sx={{ marginLeft: "5px" }}>
                                  <Typography
                                    variant="title1"
                                    sx={{ fontWeight: "700" }}
                                  >
                                    Other Files
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      textAlign: "start",
                                      fontSize: "12px",
                                    }}
                                  >
                                    1350 Files
                                  </Typography>
                                </Box>
                              </Box>
                              <Box>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontSize: "16px", fontWeight: "700" }}
                                >
                                  1.5GB
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Box
                                sx={{ display: "flex", flexDirection: "row" }}
                              >
                                <img src={unknwonFile} alt="" height={40} />
                                <Box sx={{ marginLeft: "5px" }}>
                                  <Typography
                                    variant="title1"
                                    sx={{ fontWeight: "700" }}
                                  >
                                    Unknown Files
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      textAlign: "start",
                                      fontSize: "12px",
                                    }}
                                  >
                                    630 Files
                                  </Typography>
                                </Box>
                              </Box>
                              <Box>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontSize: "16px", fontWeight: "700" }}
                                >
                                  0.7GB
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </BottomStyledPaper>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default Dashboard;
