import React from 'react';
import { Skeleton, TableCell, TableRow } from '@mui/material';

const SkeletonLoader = ({ rows, columns }) => {
  return (
    <>
      {Array.from(new Array(rows)).map((_, rowIndex) => (
        <TableRow key={rowIndex}>
          {Array.from(new Array(columns)).map((_, colIndex) => (
            <TableCell key={colIndex}>
              <Skeleton variant="rectangular" width="100%" height={40} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default SkeletonLoader;
