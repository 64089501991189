import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Button from "@mui/material/Button";
// import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Box from "@mui/material/Box";
import vector from "../../components/images/Vector.png";
import email from "../../components/images/LicImage/emailIcon.png";
import remarks from "../../components/images/star.png";
import remarkIcon from "../../components/images/LicImage/remarkIcon.png";
import SDate from "../../components/images/LicImage/start-date.png";
import EDate from "../../components/images/LicImage/EndDate.png";
import PMaturityDate from "../../components/images/MaturityDate.png";
import SumAssured from "../../components/images/SumAssured.png";
import MoneyBack from "../../components/images/moneyBack.png";
import uploadImage from "../../components/images/uploadImage.png";
import LastPremiumDate from "../../components/images/LicImage/remarkIcon.png";
import PolicyMaturityDate from "../../components/images/LicImage/PolicyMaturityDate.png";
import SumAssuredIcon from "../../components/images/LicImage/SumAssuredIcon.png";
import MoneyBackAmount from "../../components/images/LicImage/MoneyBackAmount.png";
import bday from "../../components/images/calendar.png";
import policyNumber from "../../components/images/LicImage/PolicyNumber.png";
import {
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import policyNum from "../../components/images/policy-num.png";
import Avatar from "react-avatar-edit";
import { toast } from "react-toastify";
import { db, storage } from "../../components/utils/firebase";
import { centerCrop } from "react-image-crop";
import policyName from "../../components/images/policyName.png";
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  serverTimestamp,
  doc,
  updateDoc,
} from "firebase/firestore";
import { MenuItem, FormControl, InputLabel } from "@mui/material";
import { getDownloadURL, ref, uploadBytes } from "@firebase/storage";
import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import "../css/NewLicPolicy.css";
import { Image } from "@mui/icons-material";
import MetaTags from "react-meta-tags";

const nameOptions = [
  { value: "John", label: "John" },
  { value: "Jane", label: "Jane" },
  { value: "James", label: "James" },
  // Add more options as needed
];

const NewLICJointPolicy = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Debugging to check windowHeight
  useEffect(() => {
    // console.log("Current windowHeight:", windowHeight);
    // console.log("Current windowWidth:", windowWidth);
  }, [windowHeight, windowWidth]);

  const [licData, setLicData] = useState({
    name: null,
    policyName: "",
    policyNumber: "",
    planNumber: "",
    policyTerm: "",
    premiumPayingTerm: "",
    startDate: "",
    endDate: "",
    lastPremiumDate: "",
    policyMaturityDate: "",
    sumAssured: "",
    // moneyBackAmmount: "",
    // moneyBackYear: null,
    remarks: "",
    policyDocument: null,
    lic_code: "",
    dynamicFields: [
      {
        moneyBackAmount: "",
        moneyBackYear: null,
      },
    ],
    policyHolderName: [
      {
        name: null,
        value: null,
      },
    ],
  });

  // const handleDynamicFieldChange = (index, event) => {
  //   const newFields = licData.dynamicFields.map((field, i) => {
  //     if (i === index) {
  //       return { ...field, [event.target.name]: event.target.value };
  //     }
  //     return field;
  //   });
  //   setLicData((prevData) => ({
  //     ...prevData,
  //     dynamicFields: newFields,
  //   }));
  // };

  const [image, setImage] = useState(null);
  const [imageURL, setImageURL] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [imageCrop, setImageCrop] = useState(false);
  const [profile, setProfile] = useState([]);
  const [pview, setPview] = useState(false);

  const [customers, setCustomers] = useState([]);
  const [clientId, setClientId] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Get clientId from sessionStorage
    const storedClientId = sessionStorage.getItem("clientId");
    setClientId(storedClientId);

    if (storedClientId) {
      setClientId(storedClientId);
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const q = query(
      collection(db, "customers"),
      where("Personal.clientId", "==", clientId)
    );

    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const customers = [];
        querySnapshot.forEach((doc) => {
          customers.push({ id: doc.id, ...doc.data() });
        });
        setCustomers(customers);
      },
      (error) => {
        console.error("Error fetching customers:", error);
      }
    );

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [clientId]);

  const customerOptions = customers.map((customers) => ({
    value: customers.id,
    label: customers.Personal.name,
  }));

  const handlePolicyHolderChange = (selectedOption, index) => {
    const updatedNames = [...licData.policyHolderName];
    updatedNames[index] = {
      name: selectedOption ? selectedOption.label : null,
      value: selectedOption ? selectedOption.value : null,
    };
    // console.log("updatedNames :>> ", updatedNames);
    setLicData({ ...licData, policyHolderName: updatedNames });
  };

  const handleAddPolicyHolder = () => {
    // Check if any policy holder name is still null
    const hasNullName = licData.policyHolderName.some(
      (policyHolder) => policyHolder.name === null
    );
    // console.log("hasNullName :>> ", hasNullName);

    // Check if the current number of policy holders is less than 4
    if (licData.policyHolderName.length < 4) {
      setLicData({
        ...licData,
        policyHolderName: [
          ...licData.policyHolderName,
          { name: null, value: null },
        ],
      });
    }
  };

  // Remove policy holder field
  const handleRemovePolicyHolder = (index) => {
    const updatedNames = licData.policyHolderName.filter(
      (_, idx) => idx !== index
    );
    setLicData({ ...licData, policyHolderName: updatedNames });
  };

  const onCrop = (view) => {
    setPview(view);
  };

  const saveCropImage = () => {
    setProfile([{ pview }]);
    // console.log("pview :>> ", pview);
    setImageCrop(false);
    toast.success("profile photo added successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const profileFinal = profile.map((item) => item.pview);

  const handleClickImage = () => {
    setImageCrop(true);
  };

  //Year dropdown
  const startYear = 1940;
  const endYear = 2050;
  // const endYear = new Date().getFullYear();

  const [selectedYear, setSelectedYear] = useState(null);

  const handleYearChange = (index, selectedOptions) => {
    const selectedYearValue = selectedOptions ? selectedOptions.value : null;

    setLicData((prevData) => {
      const updatedDynamicFields = [...prevData.dynamicFields];
      // console.log("updatedDynamicFields :>> ", updatedDynamicFields);
      const [year, month, day] = prevData.startDate.split("-");
      const updatedMoneyBackYear = selectedYearValue
        ? `${day}-${month}-${selectedYearValue}`
        : null;
      // console.log("updatedMoneyBackYear :>> ", updatedMoneyBackYear);

      updatedDynamicFields[index].moneyBackYear = selectedYearValue;

      const updatedData = {
        ...prevData,
        dynamicFields: updatedDynamicFields,
      };

      // console.log("updated licData :>> ", updatedData);
      return updatedData;
    });

    // console.log(
    //   "selectedOptions :>> ",
    //   selectedOptions ? selectedOptions.value : null
    // );
  };

  const addDynamicField = () => {
    setLicData({
      ...licData,
      dynamicFields: [
        ...licData.dynamicFields,
        { moneyBackAmount: "", moneyBackYear: null },
      ],
    });
  };

  const generateYearOptions = () => {
    const years = [];
    for (let year = endYear; year >= startYear; year--) {
      years.push({ value: year, label: year.toString() });
    }
    return years;
  };

  const handleDynamicFieldChange = (index, event) => {
    const { name, value } = event.target;
    const updatedDynamicFields = [...licData.dynamicFields];
    updatedDynamicFields[index][name] = value;
    setLicData({ ...licData, dynamicFields: updatedDynamicFields });
  };

  const handleChange = async (e) => {
    const { name, value, files } = e.target;
    if (name === "policyDocument") {
      setLicData((prev) => ({ ...prev, policyDocument: files[0] }));
      const file = e.target.files[0];
      if (file) {
        setImage(file);
        setImageURL(URL.createObjectURL(file));
        setImageCrop(true);
      }
    } else {
      setLicData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const [showForm, setShowForm] = useState(false);
  const handleClose = () => {
    setShowForm(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      const docRef = await addDoc(collection(db, "licjointpolicy"), {
        Personal: {
          //   name: licData.name,
          policyName: licData.policyName,
          policyNumber: licData.policyNumber,
          planNumber: licData.planNumber,
          policyTerm: licData.policyTerm,
          premiumPayingTerm: licData.premiumPayingTerm,
          startDate: licData.startDate,
          endDate: licData.endDate,
          lastPremiumDate: licData.lastPremiumDate,
          policyMaturityDate: licData.policyMaturityDate,
          sumAssured: licData.sumAssured,
          remarks: licData.remarks,
          dynamicFields: licData.dynamicFields,
          policyHolderName: licData.policyHolderName,
          // lic_code: latestCustomerCode,
        },
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
      });

      const licjointpolicyId = docRef.id;
      // console.log("licjointpolicyId :>> ", licjointpolicyId);

      let croppedPhotoURL = "";
      if (profileFinal.length !== 0) {
        const croppedpolicyDocumentRef = ref(
          storage,
          `LicDocument/${clientId}/${licjointpolicyId}/${licData.policyDocument.name}`
        );
        const croppedBlob = await fetch(profileFinal[0]).then((r) => r.blob());
        await uploadBytes(croppedpolicyDocumentRef, croppedBlob);
        croppedPhotoURL = await getDownloadURL(croppedpolicyDocumentRef);
      }

      await updateDoc(doc(db, "licjointpolicy", licjointpolicyId), {
        id: licjointpolicyId,
        "Personal.policyDocument": croppedPhotoURL,
      });

      resetForm();

      toast.success("Form Submit successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      // console.log("licData:", licData);
    } catch (error) {
      // Handle any errors that occur during the API request
      console.error("Error:", error);
    } finally {
      setSubmitting(false);
      //   window.location.reload();
    }
  };

  const resetForm = () => {
    setLicData({
      //   name: "",
      policyName: "",
      policyNumber: "",
      planNumber: "",
      policyTerm: "",
      premiumPayingTerm: "",
      startDate: "",
      endDate: "",
      lastPremiumDate: "",
      policyMaturityDate: "",
      sumAssured: "",
      // moneyBackAmmount: "",
      // MoneyBackYear: "",
      remarks: "",
      policyDocument: null,
      lic_code: "",
      dynamicFields: [
        {
          moneyBackAmount: "",
          moneyBackYear: null,
        },
      ],
      policyHolderName: [
        {
          name: null,
        },
      ],
    });
    setProfile([]);
    setImage(null);
    setImageURL("");
    setPview(false);
    // setSelectedYear(null);
  };

  return (
    <>
      {/* <Box height={40} /> */}
      <MetaTags>
        <title>New Joint Policy</title>
      </MetaTags>
      <Box style={{ display: "flex" }}>
        {/* new sidebar start */}
        <Sidebar />
        <Box sx={{ flex: 1, p: 2 }}>
          <h3 style={{ fontSize: "22px", fontWeight: 600 }}>
            LIC/{" "}
            <span style={{ color: "#3bd615" }}>New Family Policy</span>
          </h3>
          <Box height={30} />
          <Box className="containerl">
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={2}
                style={{ width: "95%", margin: "0 1%" }}
              >
                {/* Policy Holder Name */}
                {licData.policyHolderName?.map((policyHolder, index) => (
                  <>
                    <Grid item xs={12} sm={8}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {/* <PersonIcon sx={{ mr: 2 }} /> */}
                          <img
                            src={vector}
                            alt="vector"
                            style={{ height: "18px", marginRight: "22px" }}
                          />
                          <Typography
                            sx={{ minWidth: "100px", width: "145px" }}
                          >
                            Policy Holder Name
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                        </Box>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          sx={{ height: "45px" }}
                        >
                          <Select
                            id="fname"
                            name="name"
                            required
                            placeholder=" "
                            options={customerOptions}
                            isClearable
                            value={customerOptions.find(
                              (option) => option.label === policyHolder.name
                            )}
                            // onChange={handlePolicyHolderChange}
                            onChange={(selectedOption) =>
                              handlePolicyHolderChange(selectedOption, index)
                            }
                            styles={{
                              container: (provided) => ({
                                ...provided,
                                // width:
                                //   windowWidth >= 1920
                                //     ? "67%"
                                //     : windowWidth >= 1280
                                //     ? "69%"
                                //     : "69%",
                              }),
                              control: (provided) => ({
                                ...provided,
                                background: "#F9F9F9",
                                border: "1px solid rgba(59, 214, 21, 0.3)",
                                height:
                                  windowHeight >= 945
                                    ? "50px"
                                    : windowHeight >= 730
                                    ? "45px"
                                    : "40px",
                              }),
                            }}
                          />
                        </FormControl>
                        {index > 0 && (
                          <Button
                            variant="contained"
                            color="error"
                            sx={{ marginLeft: 2 }}
                            onClick={() => handleRemovePolicyHolder(index)}
                          >
                            -
                          </Button>
                        )}
                      </Box>
                    </Grid>
                  </>
                ))}

                <Grid item xs={12} sm={2}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mt: 0.5,
                    }}
                  >
                    <Button
                      variant="contained"
                      // color="primary"
                      sx={{
                        marginLeft: 2,
                        backgroundColor: "#57dd36",
                        color: "black",
                        "&:hover": {
                          backgroundColor: "#57dd36 !important",
                        },
                      }}
                      onClick={handleAddPolicyHolder}
                      disabled={
                        licData.policyHolderName.length >= 4 ||
                        licData.policyHolderName.some(
                          (policyHolder) => policyHolder.name === null
                        )
                      }
                    >
                      <AddIcon />
                    </Button>
                  </Box>
                </Grid>

                {/* Policy Name */}
                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {/* <PersonIcon sx={{ mr: 2 }} /> */}
                      <img
                        src={policyName}
                        alt="policyName"
                        style={{ height: "18px", marginRight: "18px" }}
                      />
                      <Typography sx={{ minWidth: "100px", width: "145px" }}>
                        Policy Name
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      variant="outlined"
                      sx={{ height: "45px" }}
                      name="policyName"
                      value={licData.policyName}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>

                {/* Policy Number */}
                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {/* <PersonIcon sx={{ mr: 2 }} /> */}
                      <img
                        src={policyNumber}
                        alt="policyNumber"
                        style={{ height: "18px", marginRight: "20px" }}
                      />
                      <Typography sx={{ minWidth: "100px", width: "145px" }}>
                        Policy Number
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      variant="outlined"
                      sx={{ height: "45px" }}
                      name="policyNumber"
                      value={licData.policyNumber}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>

                {/* Plan Number */}
                <Grid item xs={12} sm={4.5}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {/* <PersonIcon sx={{ mr: 2 }} /> */}
                      <img
                        src={email}
                        alt="email"
                        style={{ height: "18px", marginRight: "18px" }}
                      />
                      <Typography sx={{ minWidth: "100px", width: "145px" }}>
                        Plan Number
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      variant="outlined"
                      sx={{ height: "45px" }}
                      name="planNumber"
                      value={licData.planNumber}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>

                {/* Premium Paying Term */}
                <Grid item xs={12} sm={4.5}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {/* <PersonIcon sx={{ mr: 2 }} /> */}
                      <img
                        src={email}
                        alt="email"
                        style={{ height: "18px", marginRight: "20px" }}
                      />
                      <Typography sx={{ minWidth: "100px", width: "145px" }}>
                        Premium Paying Term
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      variant="outlined"
                      sx={{ height: "45px" }}
                      name="premiumPayingTerm"
                      value={licData.premiumPayingTerm}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>

                {/* Policy Term */}
                <Grid item xs={12} sm={3}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {/* <PersonIcon sx={{ mr: 2 }} /> */}
                      <img
                        src={email}
                        alt="email"
                        style={{ height: "18px", marginRight: "20px" }}
                      />
                      <Typography sx={{ minWidth: "100px", width: "100px" }}>
                        Policy Term
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      variant="outlined"
                      sx={{ height: "45px" }}
                      name="policyTerm"
                      value={licData.policyTerm}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>

                {/* Start Date */}
                <Grid item xs={12} sm={4.5}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {/* <CalendarToday sx={{ mr: 2 }} /> */}
                      <img
                        src={SDate}
                        alt="SDate"
                        style={{ height: "18px", marginRight: "20px" }}
                      />
                      <Typography sx={{ minWidth: "100px", width: "142px" }}>
                        Start Date
                      </Typography>
                    </Box>
                    <TextField
                      type="date"
                      fullWidth
                      variant="outlined"
                      sx={{ height: "45px" }}
                      name="startDate"
                      value={licData.startDate}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>

                {/* End Date */}
                <Grid item xs={12} sm={4.5}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {/* <CalendarToday sx={{ mr: 2 }} /> */}
                      <img
                        src={EDate}
                        alt="EDate"
                        style={{ height: "18px", marginRight: "16px" }}
                      />
                      <Typography sx={{ minWidth: "100px", width: "142px" }}>
                        End Date
                      </Typography>
                    </Box>
                    <TextField
                      type="date"
                      fullWidth
                      variant="outlined"
                      sx={{ height: "45px" }}
                      name="endDate"
                      value={licData.endDate}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>

                {/* Last Premium Date */}
                <Grid item xs={12} sm={4.5}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {/* <CalendarToday sx={{ mr: 2 }} /> */}
                      <img
                        src={LastPremiumDate}
                        alt="LastPremiumDate"
                        style={{ height: "18px", marginRight: "20px" }}
                      />
                      <Typography sx={{ minWidth: "100px", width: "142px" }}>
                        Last Premium Date
                      </Typography>
                    </Box>
                    <TextField
                      type="date"
                      fullWidth
                      variant="outlined"
                      sx={{ height: "45px" }}
                      name="lastPremiumDate"
                      value={licData.lastPremiumDate}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>

                {/* Policy Maturity Date */}
                <Grid item xs={12} sm={4.5}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {/* <CalendarToday sx={{ mr: 2 }} /> */}
                      <img
                        src={PolicyMaturityDate}
                        alt="PolicyMaturityDate"
                        style={{ height: "18px", marginRight: "20px" }}
                      />
                      <Typography sx={{ minWidth: "100px", width: "142px" }}>
                        Policy Maturity Date
                      </Typography>
                    </Box>
                    <TextField
                      type="date"
                      fullWidth
                      variant="outlined"
                      sx={{ height: "45px" }}
                      name="policyMaturityDate"
                      value={licData.policyMaturityDate}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>

                {/* Sum Assured */}
                <Grid item xs={12} sm={3}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {/* <PersonIcon sx={{ mr: 2 }} /> */}
                      <img
                        src={SumAssuredIcon}
                        alt="SumAssuredIcon"
                        style={{ height: "18px", marginRight: "20px" }}
                      />
                      <Typography sx={{ minWidth: "100px", width: "100px" }}>
                        Sum Assured
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      variant="outlined"
                      sx={{ height: "45px" }}
                      name="sumAssured"
                      value={licData.sumAssured}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>

                {licData.dynamicFields?.map((field, index) => (
                  <>
                    <Grid item xs={12} sm={4.5}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {/* <PersonIcon sx={{ mr: 2 }} /> */}
                          <img
                            src={MoneyBackAmount}
                            alt="MoneyBackAmount"
                            style={{ height: "18px", marginRight: "18px" }}
                          />
                          <Typography
                            sx={{ minWidth: "100px", width: "145px" }}
                          >
                            Money Back Amount
                          </Typography>
                        </Box>
                        <TextField
                          fullWidth
                          variant="outlined"
                          sx={{ height: "45px" }}
                          name="moneyBackAmount"
                          value={field.moneyBackAmount}
                          onChange={(e) => handleDynamicFieldChange(index, e)}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4.5}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {/* <PersonIcon sx={{ mr: 2 }} /> */}
                          <img
                            src={remarkIcon}
                            alt="remarkIcon"
                            style={{ height: "18px", marginRight: "20px" }}
                          />
                          <Typography
                            sx={{ minWidth: "100px", width: "145px" }}
                          >
                            Money Back Years
                          </Typography>
                        </Box>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          sx={{ height: "45px" }}
                        >
                          <Select
                            id="fname"
                            name="name"
                            placeholder=" "
                            options={generateYearOptions()}
                            value={
                              field.moneyBackYear
                                ? {
                                    value: field.moneyBackYear,
                                    label: field.moneyBackYear.toString(),
                                  }
                                : null
                            }
                            onChange={(selectedOptions) =>
                              handleYearChange(index, selectedOptions)
                            }
                            isClearable
                            styles={{
                              container: (provided) => ({
                                ...provided,
                              }),
                              control: (provided) => ({
                                ...provided,
                                background: "#F9F9F9",
                                border: "1px solid rgba(59, 214, 21, 0.3)",
                                height:
                                  windowHeight >= 945
                                    ? "50px"
                                    : windowHeight >= 730
                                    ? "45px"
                                    : "40px",
                              }),
                            }}
                          />
                        </FormControl>
                      </Box>
                    </Grid>

                    {/* addDynamicField Button */}
                    <Grid item xs={12} sm={3}>
                      <button
                        type="button"
                        onClick={addDynamicField}
                        style={{
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                          marginTop: "5px",
                        }}
                      >
                        <AddCircleOutlineIcon
                          style={{ color: "#3BD615", fontSize: "36px" }}
                        />
                      </button>
                    </Grid>
                  </>
                ))}

                {/* Remarks */}
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {/* <Home sx={{ mr: 2 }} /> */}
                      <img
                        src={remarkIcon}
                        alt="remarkIcon"
                        style={{ height: "20px", marginRight: "20px" }}
                      />
                      <Typography sx={{ minWidth: "100px", width: "140px" }}>
                        Remarks
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      variant="outlined"
                      name="remarks"
                      value={licData.remarks}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>

                {/* Policy Document */}
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "start",
                      width: "356px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", marginTop: "10px" }}>
                      <Image sx={{ mr: 2 }} />
                      <Typography sx={{ minWidth: "100px", width: "142px" }}>
                        Policy Document
                      </Typography>
                    </Box>
                    <div className="image-upload-containerl">
                      <input
                        id="policyDocument"
                        type="file"
                        name="policyDocument"
                        accept="image/*"
                        // accept="image/*"
                        onChange={handleChange}
                        style={{ display: "none" }}
                      />
                      {licData.policyDocument ? (
                        <>
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            {licData.policyDocument.name}{" "}
                            <button
                              type="button"
                              onClick={() => {
                                setLicData((prev) => ({
                                  ...prev,
                                  policyDocument: null,
                                }));
                                setImage(null);
                                setImageURL("");
                              }}
                            >
                              🗙
                            </button>
                          </p>
                          <div className="image-preview">
                            <img
                              onClick={handleClickImage}
                              src={pview ? pview : "../../images/dummy.jpeg"}
                              alt="photos"
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            sample.png
                            <button
                              type="button"
                              onClick={() => {
                                setLicData((prev) => ({
                                  ...prev,
                                  policyDocument: null,
                                }));
                                setImage(null);
                                setImageURL("");
                              }}
                            >
                              🗙
                            </button>
                          </p>
                          <div className="image-preview">
                            <label
                              htmlFor="policyDocument"
                              style={{
                                width: "206px",
                                backgroundColor: "#D9D9D9",
                                height: "108px",
                                borderRadius: "5px",
                                margin: "5px 10px",
                              }}
                            ></label>
                          </div>
                        </>
                      )}
                    </div>
                    {imageCrop && (
                      <div>
                        <Dialog
                          style={{
                            backgroundColor: "white",
                            border: "1px solid gray",
                            borderRadius: "5px",
                            padding: "10px",
                            overflowY: "hidden",
                          }}
                          open={imageCrop}
                          visible={imageCrop}
                          header={() => (
                            <p htmlFor="" className="text-2xl font-semibold">
                              Crop Image
                            </p>
                          )}
                          onHide={() => setImageCrop(false)}
                        >
                          <div className="confirmation-content flex flex-column align-items-center">
                            <Avatar
                              width={600}
                              height={400}
                              onCrop={onCrop}
                              onClose={handleClose}
                              src={imageURL}
                              position={centerCrop}
                            />
                            <div
                              className="flex align-items-center justify-content-end mt-3"
                              style={{ display: "flex", justifyContent: "end" }}
                            >
                              <Button
                                style={{
                                  padding: "10px 20px",
                                  margin: "20px",
                                  backgroundColor: "#3BD615",
                                  color: "black",
                                }}
                                onClick={saveCropImage}
                                className="btn gap-2"
                              >
                                Upload
                              </Button>
                            </div>
                          </div>
                        </Dialog>
                      </div>
                    )}
                  </Box>
                </Grid>
              </Grid>

              <div className="add-btn" style={{ marginTop: "10px" }}>
                <Button type="submit" disabled={submitting}>
                  {submitting ? <CircularProgress size={24} /> : "Add"}
                </Button>
              </div>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default NewLICJointPolicy;
