import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import "../css/ThirdParty.css";
import Select from "react-select";
import vector from "../../components/images/Vector.png";
import SDate from "../../components/images/SDate.png";
import policyNumber from "../../components/images/LicImage/PolicyNumber.png";
import remarkIcon from "../../components/images/LicImage/remarkIcon.png";
import CreatableSelect from "react-select/creatable";
import { DriveEta, People } from "@mui/icons-material";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "@firebase/firestore";
import { db } from "../../components/utils/firebase";
import { toast } from "react-toastify";
import MoneyBackAmount from "../../components/images/LicImage/MoneyBackAmount.png";
import { MetaTags } from "react-meta-tags";
import Navbar from "../../components/Navbar/Navbar";

const ThirdParty = () => {
  const [submitting, setSubmitting] = useState(false);

  const years = [
    { value: "01", label: "01" },
    { value: "02", label: "02" },
    { value: "03", label: "03" },
    { value: "04", label: "04" },
    { value: "05", label: "05" },
    { value: "06", label: "06" },
    { value: "07", label: "07" },
    { value: "08", label: "08" },
    { value: "09", label: "09" },
    { value: "10", label: "10" },
  ];

  const [thirdPartyData, setThirdPartyData] = useState({
    name: null,
    startDate: "",
    endDate: "",
    howManyYear: null,
    companyName: "",
    companyCode: "",
    premiumAmount: "",
    policyNumber: "",
    vehicleName: "",
    vehicleDetails: "",
    ref_by: null,
    customerId: null,
    reminderDate: [
      {
        date: null,
        status: false,
      },
    ],
    notificationDate: [
      {
        date: null,
        status: false,
      },
    ],
  });

  const [customers, setCustomers] = useState([]);
  const [clientId, setClientId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [customerRef, setCustomerRef] = useState([]);
  const [vehicleDetailsError, setVehicleDetailsError] = useState("");

  const fetchClientData = async (id) => {
    try {
      const docRef = doc(db, "clients", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setCompanyId(docSnap.data().companyId);
        // console.log('companyId :>> ', companyId);
      } else {
        console.log("No such document!");
        // setError("No such document!");
      }
    } catch (err) {
      console.error("Error fetching document:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Get clientId from sessionStorage
    const storedClientId = sessionStorage.getItem("clientId");
    setClientId(storedClientId);

    if (storedClientId) {
      fetchClientData(storedClientId);
    } else {
      setLoading(false);
    }
  }, []);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Get clientId from sessionStorage
    const storedClientId = sessionStorage.getItem("clientId");
    setClientId(storedClientId);

    if (storedClientId) {
      setClientId(storedClientId);
      // console.log('clientId :>> ', clientId);
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const q = query(
      collection(db, "customers"),
      where("Personal.clientId", "==", clientId)
    );

    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const customers = [];
        querySnapshot.forEach((doc) => {
          customers.push({ id: doc.id, ...doc.data() });
        });
        setCustomers(customers);
        setCustomerRef(
          customers.map((customer) => ({
            value: customer.id,
            label: customer.Personal.name,
          }))
        );
      },
      (error) => {
        console.error("Error fetching customers:", error);
      }
    );

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [clientId]);

  const customerOptions = customers.map((customers) => ({
    value: customers.id,
    label: customers.Personal.name,
  }));

  const handlePolicyHolderChange = (selectedOption) => {
    setThirdPartyData({
      ...thirdPartyData,
      name: selectedOption ? selectedOption.label : null,
      customerId: selectedOption ? selectedOption.value : null,
    });
  };

  const handleSelectChange = (selectedOption) => {
    setThirdPartyData({
      ...thirdPartyData,
      ref_by: selectedOption ? selectedOption.value : null,
    });
  };

  const handleCreate = (inputValue) => {
    const newOption = { value: inputValue, label: inputValue };
    // clientOptions.push(newOption);
    setCustomerRef((prevOptions) => [...prevOptions, newOption]);
    handleSelectChange(newOption);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "vehicleDetails") {
      const upperCaseValue = value.toUpperCase();
      const pattern = /^GJ\d{2}[A-Z]{1,2}\d{4}$/;

      if (!pattern.test(upperCaseValue)) {
        setVehicleDetailsError("Invalid vehicle details format");
      } else {
        setVehicleDetailsError("");
      }

      setThirdPartyData((prev) => ({ ...prev, [name]: upperCaseValue }));
    } else {
      setThirdPartyData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      const startDate = new Date(thirdPartyData.startDate);
      console.log("startDate :>> ", startDate);
      const endDate = new Date(startDate);

      endDate.setFullYear(
        startDate.getFullYear() + parseInt(thirdPartyData.howManyYear)
      );
      const formattedEndDate = endDate.toISOString().split("T")[0];

      const reminderDates = generateReminderDates(formattedEndDate);
      const notificationDate = generateNotificationDate(formattedEndDate);

      // console.log('notificationDate :>> ', notificationDate);
      // console.log('reminderDates :>> ', reminderDates);
      // console.log("formattedEndDate :>> ", formattedEndDate);

      const docRef = await addDoc(collection(db, "thirdPartyPolicy"), {
        name: thirdPartyData.name,
        startDate: thirdPartyData.startDate,
        howManyYear: thirdPartyData.howManyYear,
        endDate: formattedEndDate,
        companyName: thirdPartyData.companyName,
        companyCode: thirdPartyData.companyCode,
        premiumAmount: thirdPartyData.premiumAmount,
        policyNumber: thirdPartyData.policyNumber,
        vehicleName: thirdPartyData.vehicleName,
        vehicleDetails: thirdPartyData.vehicleDetails,
        ref_by: thirdPartyData.ref_by,
        clientId: clientId,
        companyId: companyId,
        customerId: thirdPartyData.customerId,
        reminderDate: reminderDates,
        notificationDate: notificationDate,

        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
      });

      const thirdPartyId = docRef.id;
      console.log("thirdPartyId :>> ", thirdPartyId);

      await updateDoc(doc(db, "thirdPartyPolicy", thirdPartyId), {
        id: thirdPartyId,
      });

      resetForm();

      toast.success("Form Submit successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      console.log("thirdPartyData >>", thirdPartyData);

      // Handle the response if needed
    } catch (error) {
      // Handle any errors that occur during the API request
      console.error("Error:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const generateReminderDates = (endDate) => {
    const date = new Date(endDate);
    const reminderDates = [];

    const addDays = (date, days) => {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    };

    const formatDate = (date) => {
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    };

    reminderDates.push({ date: formatDate(addDays(date, -30)), status: false });
    reminderDates.push({ date: formatDate(addDays(date, -15)), status: false });
    reminderDates.push({ date: formatDate(addDays(date, -7)), status: false });

    return reminderDates;
  };

  const generateNotificationDate = (endDate) => {
    const date = new Date(endDate);
    console.log("date :>> ", date);
    const notificationDates = [];

    const addDays = (date, days) => {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    };

    const formatDate = (date) => {
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    };


    notificationDates.push({ date: formatDate(addDays(date, -31)), status: false }); 
    notificationDates.push({ date: formatDate(addDays(date, -16)), status: false });
    notificationDates.push({ date: formatDate(addDays(date, -8)), status: false });

    console.log("notificationDates :>> ", notificationDates);
    return notificationDates;
  };

  const resetForm = () => {
    setThirdPartyData({
      name: null,
      startDate: "",
      endDate: "",
      howManyYear: null,
      companyName: "",
      companyCode: "",
      premiumAmount: "",
      policyNumber: "",
      vehicleName: "",
      vehicleDetails: "",
      ref_by: null,
    });
  };

  return (
    <>
    <MetaTags>
        <title>New Third Party Policy</title>
      </MetaTags>
      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box sx={{flex: 1,}}>
          <Navbar/>
        <Box sx={{  p: 2 }}>
          {/* <h3 style={{ fontSize: "22px", fontWeight: 600 }}>
            New GIC Policy /{" "}
            <span style={{ color: "#3bd615" }}>New Third Party Policy</span>
          </h3>
          <Box height={30} /> */}
          <Box className="container">
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={2}
                style={{ width: "95%", margin: "0 1%" }}
              >
                {/* Name */}
                <Grid item xs={12} md={11} sm={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {/* <PersonIcon sx={{ mr: 2 }} /> */}
                      <img
                        src={vector}
                        alt="vector"
                        style={{ height: "18px", marginRight: "22px" }}
                      />
                      <Typography sx={{ minWidth: "100px", width: "145px" }}>
                        Name
                        <span style={{ color: "red" }}>*</span>
                      </Typography>
                    </Box>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      sx={{ height: "45px" }}
                    >
                      <Select
                        required
                        id="fname"
                        name="name"
                        placeholder=""
                        options={customerOptions}
                        isClearable
                        value={customerOptions.find(
                          (option) =>
                            option.label === thirdPartyData.name &&
                            option.value === thirdPartyData.customerId
                        )}
                        onChange={handlePolicyHolderChange}
                        styles={{
                          container: (provided) => ({
                            ...provided,
                          }),
                          control: (provided) => ({
                            ...provided,
                            background: "#F9F9F9",
                            border: "1px solid rgba(59, 214, 21, 0.3)",
                            height: "45px",
                          }),
                        }}
                      />
                    </FormControl>
                  </Box>
                </Grid>

                {/* Start Date */}
                <Grid item xs={12} md={6} sm={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {/* <CalendarToday sx={{ mr: 2 }} /> */}
                      <img
                        src={SDate}
                        alt="SDate"
                        style={{ height: "18px", marginRight: "20px" }}
                      />
                      <Typography sx={{ minWidth: "100px", width: "145px" }}>
                        Start Date
                      </Typography>
                    </Box>
                    <TextField
                      type="date"
                      fullWidth
                      variant="outlined"
                      sx={{ height: "45px" }}
                      name="startDate"
                      value={thirdPartyData.startDate}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>

                {/* Year */}
                <Grid item xs={12} md={5} sm={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {/* <PersonIcon sx={{ mr: 2 }} /> */}
                      <img
                        src={vector}
                        alt="vector"
                        style={{ height: "18px", marginRight: "22px" }}
                      />
                      <Typography sx={{ minWidth: "100px", width: "145px" }}>
                        How Many Year
                      </Typography>
                    </Box>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      sx={{ height: "45px" }}
                    >
                      <Select
                        id="fname"
                        name="name"
                        placeholder=" "
                        options={years}
                        isClearable
                        value={years.find(
                          (option) =>
                            option.label === thirdPartyData.howManyYear
                        )}
                        onChange={(selectedOption) =>
                          setThirdPartyData({
                            ...thirdPartyData,
                            howManyYear: selectedOption
                              ? selectedOption.label
                              : null,
                          })
                        }
                        styles={{
                          container: (provided) => ({
                            ...provided,
                          }),
                          control: (provided) => ({
                            ...provided,
                            background: "#F9F9F9",
                            border: "1px solid rgba(59, 214, 21, 0.3)",
                            height: "45px",
                          }),
                        }}
                      />
                    </FormControl>
                  </Box>
                </Grid>

                {/* Company Name */}
                <Grid item xs={12} md={6} sm={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {/* <PersonIcon sx={{ mr: 2 }} /> */}
                      <img
                        src={remarkIcon}
                        alt="remarkIcon"
                        style={{ height: "18px", marginRight: "18px" }}
                      />
                      <Typography sx={{ minWidth: "100px", width: "145px" }}>
                        Company Name
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      variant="outlined"
                      sx={{ height: "45px" }}
                      name="companyName"
                      value={thirdPartyData.companyName}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>

                {/* Company Code */}
                <Grid item xs={12} md={5} sm={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {/* <PersonIcon sx={{ mr: 2 }} /> */}
                      <img
                        src={policyNumber}
                        alt="policyNumber"
                        style={{ height: "18px", marginRight: "20px" }}
                      />
                      <Typography sx={{ minWidth: "100px", width: "145px" }}>
                        Company Code
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      variant="outlined"
                      sx={{ height: "45px" }}
                      name="companyCode"
                      value={thirdPartyData.companyCode}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>

                {/* Ref By */}
                <Grid item xs={12} md={6} sm={12} >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <People sx={{ mr: 2 }} />
                      <Typography sx={{ minWidth: "100px", width: "142px" }}>
                        Ref By
                      </Typography>
                    </Box>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      sx={{ height: "45px" }}
                    >
                      <CreatableSelect
                        name="ref_by"
                        value={
                          customerRef.find(
                            (option) => option.value === thirdPartyData.ref_by
                          ) || {
                            value: thirdPartyData.ref_by,
                            label: thirdPartyData.ref_by,
                          }
                        }
                        onChange={handleSelectChange}
                        onCreateOption={handleCreate}
                        options={customerRef}
                        placeholder=""
                        isClearable
                        styles={{
                          container: (provided) => ({
                            ...provided,
                            //   width: "40%",
                            height: "45px",
                          }),
                          control: (provided) => ({
                            ...provided,
                            background: "#F9F9F9",
                            border: "1px solid rgba(59, 214, 21, 0.3)",
                            height: "48px",
                            // height:
                            //   windowHeight >= 945
                            //     ? "50px"
                            //     : windowHeight >= 730
                            //     ? "45px"
                            //     : "40px",
                          }),
                        }}
                      />
                    </FormControl>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6} sm={12} sx={{display: "none"}}>

                </Grid>

                {/* Premium Amount */}
                <Grid item xs={12} md={6} sm={12} sx={{ mr: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {/* <PersonIcon sx={{ mr: 2 }} /> */}
                      <img
                        src={MoneyBackAmount}
                        alt="policyNumber"
                        style={{ height: "18px", marginRight: "20px" }}
                      />
                      <Typography sx={{ minWidth: "100px", width: "145px" }}>
                        Premium Amount
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      variant="outlined"
                      sx={{ height: "45px" }}
                      name="premiumAmount"
                      value={thirdPartyData.premiumAmount}
                      onChange={handleChange}
                      type="number"
                    />
                  </Box>
                </Grid>

                {/* Policy Number */}
                <Grid item xs={12} md={6} sm={12} sx={{ mr: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {/* <PersonIcon sx={{ mr: 2 }} /> */}
                      <img
                        src={policyNumber}
                        alt="policyNumber"
                        style={{ height: "18px", marginRight: "20px" }}
                      />
                      <Typography sx={{ minWidth: "100px", width: "145px" }}>
                        Policy Number
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      variant="outlined"
                      sx={{ height: "45px" }}
                      name="policyNumber"
                      value={thirdPartyData.policyNumber}
                      onChange={handleChange}
                      // type="number"
                    />
                  </Box>
                </Grid>

                {/* Vehicle Name */}
                <Grid item xs={12} md={6} sm={12} sx={{ mr: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <DriveEta sx={{ mr: 2 }} />
                      {/* <img
                        src={policyNumber}
                        alt="policyNumber"
                        style={{ height: "18px", marginRight: "20px" }}
                      /> */}
                      <Typography sx={{ minWidth: "100px", width: "145px" }}>
                        Vehicle Name
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      variant="outlined"
                      sx={{ height: "45px" }}
                      name="vehicleName"
                      value={thirdPartyData.vehicleName}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>

                {/* Vehicle Details */}
                <Grid item xs={12} md={6} sm={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <DriveEta sx={{ mr: 2 }} />
                      <Typography sx={{ minWidth: "100px", width: "145px" }}>
                        Vehicle Details
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      variant="outlined"
                      sx={{ height: "45px" }}
                      placeholder="GJ03AA1234"
                      name="vehicleDetails"
                      value={thirdPartyData.vehicleDetails}
                      onChange={handleChange}
                      error={!!vehicleDetailsError}
                      helperText={vehicleDetailsError}
                    />
                  </Box>
                </Grid>

                <div className="add-btn" style={{ marginTop: "10px" }}>
                  <Button type="submit" disabled={submitting}>
                    {submitting ? <CircularProgress size={24} sx={{color:"white"}}/> : "Add"}
                  </Button>
                </div>
              </Grid>
            </form>
          </Box>
        </Box>
        </Box>
      </Box>
    </>
  );
};

export default ThirdParty;
