import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../components/Sidebar";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
// import Navbar from "../../Navbar/Navbar";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { Visibility as VisibilityIcon } from "@material-ui/icons";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { query } from "firebase/database";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import {
  styled,
  alpha,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import MetaTags from "react-meta-tags";
import MuiDatatables from "mui-datatables";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import { Add, Delete } from "@mui/icons-material";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "@firebase/storage";
import { storage } from "../../components/utils/firebase";
import Navbar from "../../components/Navbar/Navbar";
import CustomSearchRender from "../../components/CustomSearchRender";

const CustomMuiPaper = styled(Paper)({
  display: "flex",
  flexDirection: "column",
  height: "auto",
  maxHeight: "80vh",
  overflow: "auto",
});

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          height: "100%",
          // overflow: 'auto',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          flex: "1 1 auto", // Allow the table container to grow and shrink
        },
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          marginTop: "auto", // This ensures the footer stays at the bottom
        },
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          height: "20px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: '"Montserrat", sans-serif',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
          fontWeight: 400,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
          padding: "0px",
          textAlign: "start",
          justifyContent: "start",
          fontWeight: 600,
        },
      },
    },
  },
  MuiTableCell: {
    root: {
      borderColor: "#d3d3d3",
      fontWeight: 400,
    },
    head: {
      background: "lightgrey",
      "&:not(:last-child)": {
        borderRight: [[1, "solid", "#c0c0c0"]],
      },
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const AllGicPolicy = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [gicpolicy, setgicpolicy] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedPolicy, setselectedPolicy] = useState(null);
  const [editedPolicy, setEditedPolicy] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const db = getFirestore();

  const [companyId, setCompanyId] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const storedClientId = sessionStorage.getItem("clientId");
    setClientId(storedClientId);
    if (storedClientId) {
      fetchPolicyData(storedClientId);
    } else {
      setLoading(false);
    }
  }, []);

  const fetchPolicyData = (id) => {
    const lastFetchTimeRaw = sessionStorage.getItem("localGICDataFetchTime");
    const lastFetchTime = lastFetchTimeRaw
      ? new Date(lastFetchTimeRaw)
      : new Date(0);

    // Fetch cached policies from session storage
    const cachedPolicy =
      JSON.parse(sessionStorage.getItem("localGICPolicyData")) || [];
    console.log("cachedPolicy :>> ", cachedPolicy);
    setgicpolicy(cachedPolicy); // Update the state with cached data

    const docRef = doc(db, "clients", id);

    getDoc(docRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          const companyId = docSnap.data().companyId; // Get the company ID from the document

          const q = query(
            collection(db, "gic"),
            where("companyId", "==", companyId),
            where("updated_at", ">", lastFetchTime), // Filter for updates after last fetch time
            orderBy("name") // Order the results by name
          );

          const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const newGicData = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            const updatedPolicy = [...cachedPolicy];

            newGicData.forEach((newPolicy) => {
              const index = updatedPolicy.findIndex(
                (cached) => cached.id === newPolicy.id
              );
              if (index > -1) {
                updatedPolicy[index] = newPolicy;
              } else {
                updatedPolicy.push(newPolicy);
              }
            });

            sessionStorage.setItem(
              "localGICPolicyData",
              JSON.stringify(updatedPolicy)
            );
            sessionStorage.setItem(
              "localGICDataFetchTime",
              new Date().toISOString()
            );

            setgicpolicy(updatedPolicy);
            setLoading(false); // Set loading to false after data is loaded
          });

          return () => unsubscribe();
        } else {
          console.log("No such document!");
          setLoading(false); // Set loading to false if no document is found
        }
      })
      .catch((err) => {
        console.error("Error fetching document:", err);
        setError(err.message); // Set the error message in state
        setLoading(false); // Set loading to false if an error occurs
      });
  };

  //  const fetchPolicyData = async (id) => {
  //   try {
  //     const lastFetchTimeRaw = sessionStorage.getItem(
  //       "localGICDataFetchTime"
  //     );
  //     const lastFetchTime = lastFetchTimeRaw
  //       ? new Date(lastFetchTimeRaw)
  //       : new Date(0);

  //     // Format lastFetchTime in the desired format
  //     const formattedLastFetchTime = lastFetchTime.toLocaleString("en-US", {
  //       month: "long",
  //       day: "numeric",
  //       year: "numeric",
  //       hour: "numeric",
  //       minute: "numeric",
  //       second: "numeric",
  //       hour12: true,
  //       timeZoneName: "short",
  //     });

  //     // console.log('formattedLastFetchTime :>> ', formattedLastFetchTime);

  //     // Fetch cached customers
  //     const cachedPolicy = JSON.parse(sessionStorage.getItem("localGICPolicyData")) || [];
  //     setgicpolicy(cachedPolicy);

  //     // console.log("cachedPolicy :>> ", cachedPolicy);

  //     const docRef = doc(db, "clients", id);
  //     const docSnap = await getDoc(docRef);

  //     if (docSnap.exists()) {
  //       const querySnapshot = await getDocs(
  //         query(
  //           collection(db, "gic"),
  //           where("companyId", "==", docSnap.data().companyId),
  //           where("updated_at", ">", lastFetchTime),
  //           orderBy("name")
  //         )
  //       );

  //       const newlicData = querySnapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));

  //       console.log("newlicData :>> ", newlicData);
  //       // Merge new and cached customers
  //       const updatedPolicy = [...cachedPolicy];

  //       newlicData.forEach((newPolicy) => {
  //         const index = updatedPolicy.findIndex(
  //           (cached) => cached.id === newPolicy.id
  //         );
  //         if (index > -1) {
  //           updatedPolicy[index] = newPolicy;
  //         } else {
  //           updatedPolicy.push(newPolicy);
  //         }
  //       });

  //       // console.log("updatedPolicy :>> ", updatedPolicy);

  //       // Update local storage
  //       sessionStorage.setItem(
  //         "localGICPolicyData",
  //         JSON.stringify(updatedPolicy)
  //       );
  //       sessionStorage.setItem(
  //         "localGICDataFetchTime",
  //         new Date().toISOString()
  //       );

  //       setgicpolicy(updatedPolicy);
  //       // console.log("licData :>> ", licData);
  //     } else {
  //       console.log("No such document!");
  //     }
  //   } catch (err) {
  //     console.error("Error fetching document:", err);
  //     setError(err.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const [viewId, setViewId] = useState(null);

  const handleEditClick = (client) => {
    setselectedPolicy(client);
    setEditedPolicy(client); // Set the edited policy initially to the selected policy
    setDialogOpen(true);
  };

  const handleIconClick = (client) => {
    setselectedPolicy(client);
    setDrawerOpen(true);
    const LicPolicyId = client.id;
    setViewId(LicPolicyId);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setselectedPolicy(null);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setselectedPolicy(null);
    setEditedPolicy(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedPolicy({ ...editedPolicy, [name]: value });
  };

  const handleEditSubmit = async () => {
    try {
      setLoading(true);
      const docRef = doc(db, "gic", editedPolicy.id);
      await updateDoc(docRef, {
        ...editedPolicy,
        updated_at: serverTimestamp(),
      });
      setgicpolicy((prevPolicies) =>
        prevPolicies.map((policy) =>
          policy.id === editedPolicy.id ? editedPolicy : policy
        )
      );
      toast.success("GIC Data Update successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setDialogOpen(false);
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    if (selectedPolicy?.documents) {
      setDocuments(selectedPolicy?.documents);
    }
  }, [selectedPolicy]);

  const handleDelete = async (docUrl) => {
    try {
      const docRef = doc(db, "gic", viewId); // Adjust the path to your Firestore collection
      const policyDoc = await getDoc(docRef);

      if (policyDoc.exists()) {
        const updatedDocuments = policyDoc
          .data()
          .documents.filter((doc) => doc.url !== docUrl);

        await updateDoc(docRef, {
          documents: updatedDocuments,
        });

        // Delete the document from Firebase Storage
        const fileName = docUrl; // Extract file name from URL
        console.log("fileName :>> ", fileName);

        const documentRef = ref(storage, fileName);
        console.log("documentRef :>> ", documentRef);

        await deleteObject(documentRef).catch((error) => {
          console.error("Failed to delete document from storage:", error);
        });

        setDocuments((prevDocuments) =>
          prevDocuments.filter((doc) => doc.url !== docUrl)
        );

        // Update state to reflect the deletion
        setgicpolicy((prevGicPolicy) =>
          prevGicPolicy.map((policy) => {
            if (policy.id === viewId) {
              return {
                ...policy,
                documents: updatedDocuments,
              };
            }
            return policy;
          })
        );

        // Update the session storage to reflect the deletion
        const updatedPolicy =
          JSON.parse(sessionStorage.getItem("localGICPolicyData")) || [];
        const policyIndex = updatedPolicy.findIndex(
          (policy) => policy.id === viewId
        );
        if (policyIndex > -1) {
          updatedPolicy[policyIndex].documents = updatedDocuments;
          sessionStorage.setItem(
            "localGICPolicyData",
            JSON.stringify(updatedPolicy)
          );
        }

        console.log("Document successfully deleted!");
        toast.success("Document successfully deleted!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error removing document: ", error);
    }
  };

  const [newDocuments, setNewDocuments] = useState([]);
  const fileInputRef = useRef(null);
  const [open, setOpen] = useState(false);

  const fetchDocuments = async (gicId) => {
    try {
      const docRef = doc(db, "gic", gicId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const gicDocumentsData = docSnap.data();
        // console.log("gicDocumentsData :>> ", gicDocumentsData);
        // setOldDocuments(gicDocumentsData.Personal?.documents || []);
        setDocuments(gicDocumentsData.documents || []); // Set existing documents array
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const handleClickOpen = async (gicid) => {
    document.getElementById("fileInput").click();
    const custId = gicid.id;
    // console.log("custId :>> ", custId);
    setViewId(custId);
    await fetchDocuments(custId);
  };

  const handleFileChange = (e) => {
    const { name, value, files } = e.target;
    setOpen(true);
    if (name === "Newdocuments") {
      const filesArray = Array.from(e.target.files).map((file) => ({
        // file: file,
        name: file.name,
        url: URL.createObjectURL(file),
        editName: file.name,
      }));
      // console.log("filesArray :>> ", filesArray);
      setNewDocuments((prevDocuments) => [...prevDocuments, ...filesArray]);
      setDocuments((prevDocuments) => [...prevDocuments]);
      // console.log("documents :>> ", documents);
    }
  };

  const handleDocumentsUpload = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const uploadedDocuments = [];
      for (let i = 0; i < newDocuments.length; i++) {
        const documentRef = ref(
          storage,
          `GicDocument/${clientId}/${viewId}/${newDocuments[i].name}`
        );
        // console.log("documentRef :>> ", documentRef);
        await uploadBytes(documentRef, newDocuments[i].file);
        const documentURL = await getDownloadURL(documentRef);
        // console.log("documentURL :>> ", documentURL);
        uploadedDocuments.push({
          name: newDocuments[i].name,
          url: documentURL,
        });
      }

      const mergedDocuments = [...documents, ...uploadedDocuments];
      // console.log("mergedDocuments :>> ", mergedDocuments);

      const docRef = doc(db, "gic", viewId);
      await updateDoc(docRef, {
        documents: mergedDocuments,
        updated_at: serverTimestamp(),
      });

      toast.success("Documents uploaded successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setOpen(false);
    } catch (error) {
      console.error("Error uploading documents: ", error);
      toast.error("Failed to upload documents. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleEditNameChange = (index, newName) => {
    setNewDocuments((prevDocuments) => {
      const updatedDocuments = [...prevDocuments];
      updatedDocuments[index].editName = newName;
      return updatedDocuments;
    });
  };

  const handleSaveNameChange = (index) => {
    setNewDocuments((prevDocuments) => {
      const updatedDocuments = [...prevDocuments];
      updatedDocuments[index].name = updatedDocuments[index].editName;
      return updatedDocuments;
    });
  };

  const handleDeleteDocument = (index) => {
    setNewDocuments((prevDocuments) => {
      const updatedDocuments = [...prevDocuments];
      updatedDocuments.splice(index, 1);
      return updatedDocuments;
    });
  };

  const handleClose = () => {
    setOpen(false);
    setNewDocuments([]);
  };

  const flattenData = (data) => {
    return data.map((item) => ({
      id: item.id,
      name: item.name || "-",
      planName: item.planName || "-",
      policyNumber: item.policyNumber || "-",
      startDate: item.startDate || "-",
      companyName: item.companyName || "-",
      companyCode: item.companyCode || "-",
      remarks: item.remarks || "-",
    }));
  };

  const flattenedGICPolicy = flattenData(gicpolicy);

  const columns = [
    {
      name: "no",
      label: "No",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                width: "10px",
                maxWidth: "30px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: "center",
              }}
            >
              {tableMeta.rowIndex + 1}
            </div>
          );
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value) => (
          <div
            style={{
              // maxWidth: "150px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {capitalizeFirstLetter(value)}
          </div>
        ),
      },
    },
    {
      name: "startDate",
      label: "Start Date",
      options: {
        customBodyRender: (value) => (
          <div
            style={{
              maxWidth: "350px",
              width: "90px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              height: "20px", // Set row height
              lineHeight: "20px", // Center content vertically
            }}
          >
            {value}
          </div>
        ),
      },
    },
    {
      name: "planName",
      label: "Plan Name",
      options: {
        customBodyRender: (value) => (
          <div
            style={{
              maxWidth: "150px",
              width: "90px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              height: "20px", // Set row height
              lineHeight: "20px", // Center content vertically
            }}
          >
            {capitalizeFirstLetter(value)}
          </div>
        ),
      },
    },
    {
      name: "policyNumber",
      label: "Policy Number",
      options: {
        customBodyRender: (value) => (
          <div
            style={{
              maxWidth: "150px",
              width: "130px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              height: "20px", // Set row height
              lineHeight: "20px", // Center content vertically
            }}
          >
            {value}
          </div>
        ),
      },
    },
    // { name: "policyNumber", label: "Policy Number" },
    {
      name: "companyName",
      label: "Company Name",
      options: {
        customBodyRender: (value) => (
          <div
            style={{
              maxWidth: "250px",
              // width: "90px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              height: "20px", // Set row height
              lineHeight: "20px", // Center content vertically
            }}
          >
            {capitalizeFirstLetter(value)}
          </div>
        ),
      },
    },
    // { name: "companyCode", label: "Company Code" },
    {
      name: "remarks",
      label: "Remarks",
      options: {
        customBodyRender: (value) => (
          <div
            style={{
              maxWidth: "200px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {capitalizeFirstLetter(value)}
          </div>
        ),
      },
    },
    // {
    //   name: "edit",
    //   label: "Edit",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (
    //       <IconButton
    //         onClick={() => handleEditClick(gicpolicy[tableMeta.rowIndex])}
    //       >
    //         <EditIcon />
    //       </IconButton>
    //     ),
    //   },
    // },
    {
      name: "action",
      label: "Action",
      options: {
        // filter: false,
        // sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              // alignItems: "center",
              // justifyContent:"center"
            }}
          >
            <IconButton
              sx={{ height: "20px" }}
              onClick={() => handleIconClick(gicpolicy[tableMeta.rowIndex])}
            >
              <VisibilityIcon />
            </IconButton>

            <IconButton
              sx={{ height: "20px" }}
              onClick={() => handleEditClick(gicpolicy[tableMeta.rowIndex])}
            >
              <EditIcon />
            </IconButton>

            <IconButton
              sx={{ height: "20px" }}
              onClick={() => handleClickOpen(gicpolicy[tableMeta.rowIndex])}
            >
              <Add />
              <input
                name="Newdocuments"
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
                multiple
              />
            </IconButton>
          </div>
        ),
      },
    },
  ];

  const rows = 10; // Number of skeleton rows
  const columnsCount = 7; // Number of columns

  const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const options = {
    filterType: "checkbox",
    responsive: "standard",
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: loading ? (
          <Skeleton rows={rows} columns={columnsCount} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
  };

  return (
    <>
      <MetaTags>
        <title>All GIC Policy</title>
      </MetaTags>
      {/* <Navbar /> */}
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <Sidebar />
          <Box sx={{ flex: 1, backgroundColor: "#F4F7F9", overflow: "hidden" }}>
            <Navbar />
            <Box
              // component="main"
              sx={{
                p: 2,

                // height: "100vh",
              }}
            >
              <Box sx={{ padding: "1%" }}>
                <Box
                  borderRadius="17px"
                  sx={{ boxShadow: "0px 9px 20px rgba(46, 35, 94, 0.07);" }}
                  overflow="hidden"
                >
                  <CustomMuiPaper>
                    <MuiDatatables
                      // title="All Policy"
                      data={
                        loading
                          ? []
                          : flattenedGICPolicy && flattenedGICPolicy.length > 0
                          ? flattenedGICPolicy
                          : []
                      }
                      columns={columns}
                      options={{
                        ...options,
                        searchOpen: true,
                        searchAlwaysOpen: true,
                        searchPlaceholder: "Search keyword",
                        customSearchRender: (searchText, handleSearch) => {
                          return (
                            <CustomSearchRender
                              searchText={searchText}
                              onSearch={handleSearch}
                            />
                          );
                        },
                        // customToolbar: () => (
                        //   <div>
                        //     {loading && <CircularProgress />}{" "}
                        //     {/* Show a loading spinner when loading */}
                        //     {error && <p>Error: {error}</p>}{" "}
                        //     {/* Show an error message when there is an error */}
                        //   </div>
                        // ),
                      }}
                    />
                  </CustomMuiPaper>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle style={{ fontFamily: '"Montserrat", sans-serif' }}>
            Update GIC Policy Details
          </DialogTitle>
          <DialogContent>
            {selectedPolicy && (
              <Box sx={{ padding: 1 }}>
                <Divider />
                <Box sx={{ marginY: 2 }}>
                  <TextField
                    label="Policy Holder Name"
                    name="name"
                    value={editedPolicy?.name || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    type="date"
                    label="Start Date"
                    name="startDate"
                    value={editedPolicy?.startDate || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    type="date"
                    label="End Date"
                    name="endDate"
                    value={editedPolicy?.endDate || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    label="Policy Term"
                    name="policyTerm"
                    value={editedPolicy?.policyTerm || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Personal Code"
                    name="personalCode"
                    value={editedPolicy?.personalCode || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Company Name"
                    name="companyName"
                    value={editedPolicy?.companyName || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Company Code"
                    name="companyCode"
                    value={editedPolicy?.companyCode || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Plan Name"
                    name="planName"
                    value={editedPolicy?.planName || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Policy Number"
                    name="policyNumber"
                    value={editedPolicy?.policyNumber || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Policy Type"
                    name="policyType"
                    value={editedPolicy?.policyType || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Premium Amount"
                    name="premiumAmount"
                    value={editedPolicy?.premiumAmount || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Plan Number"
                    name="planNumber"
                    value={editedPolicy?.planNumber || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Remarks"
                    name="remarks"
                    value={editedPolicy?.remarks || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />
                </Box>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleDialogClose}
              variant="outlined"
              sx={{ border: "1px solid #57dd36", color: "#57dd36", p: 1 }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleEditSubmit}
              variant="contained"
              disabled={loading} // Assuming `loading` state indicates loading state
              // startIcon={loading ? <CircularProgress size={24} /> : null}
              sx={{ backgroundColor: "#57dd36", p: 1 }}
            >
              {loading ? <CircularProgress size={24} /> : "Save Changes"}
            </Button>
          </DialogActions>
        </Dialog>

        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={handleDrawerClose}
          sx={{ "& .MuiDrawer-paper": { width: 460, padding: 2 } }}
        >
          {selectedPolicy && (
            <Box sx={{ padding: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  onClick={handleDrawerClose}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="48"
                      d="M328 112L184 256l144 144"
                    />
                  </svg>
                </Typography>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ fontFamily: '"Montserrat", sans-serif' }}
                >
                  GIC Policy Details
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ marginY: 2 }}>
                {/* <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 2,
                }}
              >
                <img
                  src={
                    selectedPolicy.policyDocument
                      ? selectedPolicy.policyDocument
                      : "https://t4.ftcdn.net/jpg/05/42/36/11/360_F_542361185_VFRJWpR2FH5OiAEVveWO7oZnfSccZfD3.jpg"
                  }
                  alt={`${selectedPolicy.name}'s profile`}
                  style={{
                    width: 100,
                    height: 100,
                    borderRadius: "50%",
                    marginBottom: 16,
                  }}
                />
                <Divider />
              </Box> */}

                {selectedPolicy.name && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Policy Holder Name:</h5>{" "}
                    {capitalizeFirstLetter(selectedPolicy.name)}
                  </Typography>
                )}

                {selectedPolicy.planName && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Plan Name:</h5>{" "}
                    {capitalizeFirstLetter(selectedPolicy.planName)}
                  </Typography>
                )}

                {selectedPolicy.policyType && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Policy Type:</h5> {selectedPolicy.policyType}
                  </Typography>
                )}

                {selectedPolicy.policyNumber && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Policy Number:</h5> {selectedPolicy.policyNumber}
                  </Typography>
                )}

                {selectedPolicy.policyTerm && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Policy Term:</h5> {selectedPolicy.policyTerm}
                  </Typography>
                )}

                {selectedPolicy.premiumAmount && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Premium Amount:</h5> {selectedPolicy.premiumAmount}
                  </Typography>
                )}

                {selectedPolicy.startDate && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Start Date:</h5> {selectedPolicy.startDate}
                  </Typography>
                )}

                {selectedPolicy.endDate && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>End Date:</h5> {selectedPolicy.endDate}
                  </Typography>
                )}

                {selectedPolicy.personalCode && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Personal Code:</h5> {selectedPolicy.personalCode}
                  </Typography>
                )}

                {selectedPolicy.companyName && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Company Name:</h5>{" "}
                    {capitalizeFirstLetter(selectedPolicy.companyName)}
                  </Typography>
                )}

                {selectedPolicy.companyCode && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Company Code:</h5> {selectedPolicy.companyCode}
                  </Typography>
                )}

                {selectedPolicy.planNumber && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Plan Number:</h5> {selectedPolicy.planNumber}
                  </Typography>
                )}

                {selectedPolicy.remarks && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Remarks:</h5>{" "}
                    {capitalizeFirstLetter(selectedPolicy.remarks)}
                  </Typography>
                )}

                {selectedPolicy.documents &&
                  selectedPolicy.documents.length > 0 && (
                    <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                      <h5>Docments:</h5>
                      {documents.map((docName) => (
                        <div
                          key={docName.url}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <a
                            href={docName.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            {docName.name}, <br />
                          </a>
                          <p
                            onClick={() => handleDelete(docName.url)}
                            sx={{ marginLeft: "10px" }}
                          >
                            <Delete sx={{ color: "#d60000" }} />
                          </p>
                        </div>
                      ))}
                    </Typography>
                  )}
              </Box>
            </Box>
          )}
        </Drawer>

        <Dialog open={open}>
          <DialogTitle>Documents</DialogTitle>
          <DialogContent>
            <List>
              {newDocuments.map((doc, index) => (
                <ListItem
                  key={index}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <ListItemText
                    primary={
                      <TextField
                        value={doc.editName}
                        onChange={(e) =>
                          handleEditNameChange(index, e.target.value)
                        }
                        variant="outlined"
                        size="small"
                        onBlur={() => handleSaveNameChange(index)}
                      />
                    }
                  />
                  <IconButton
                    edge="end"
                    onClick={() => handleDeleteDocument(index)}
                  >
                    <Delete />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
            <Button
              onClick={handleDocumentsUpload}
              color="primary"
              disabled={loading}
            >
              {loading ? "Uploading..." : "Upload"}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default AllGicPolicy;
