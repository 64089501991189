import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
// import Navbar from "../../Navbar/Navbar";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { Visibility as VisibilityIcon } from "@material-ui/icons";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Skeleton,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { query } from "firebase/database";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import {
  styled,
  alpha,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import MetaTags from "react-meta-tags";
import MuiDatatables from "mui-datatables";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import Select from "react-select";
import Navbar from "../../components/Navbar/Navbar";
import CustomSearchRender from "../../components/CustomSearchRender";

const CustomMuiPaper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  maxHeight:"80vh",
  overflow: 'auto',
});

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          // overflow: 'auto',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          flex: '1 1 auto', // Allow the table container to grow and shrink
        },
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          marginTop: 'auto', // This ensures the footer stays at the bottom
        },
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          height: "20px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: '"Montserrat", sans-serif',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
          padding: "0px",
          textAlign: "start",
          justifyContent: "start",
          fontWeight: 600,
        },
      },
    },
  },
  MuiTableCell: {
    root: {
      borderColor: "#d3d3d3",
      fontWeight: 600,
    },
    head: {
      background: "lightgrey",
      "&:not(:last-child)": {
        borderRight: [[1, "solid", "#c0c0c0"]],
      },
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const AllThirdPartyPolicy = () => {
  const years = [
    { value: "01", label: "01" },
    { value: "02", label: "02" },
    { value: "03", label: "03" },
    { value: "04", label: "04" },
    { value: "05", label: "05" },
    { value: "06", label: "06" },
    { value: "07", label: "07" },
    { value: "08", label: "08" },
    { value: "09", label: "09" },
    { value: "10", label: "10" },
  ];

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    marginTop: 100,
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: "none",
      //   padding: "0 16px", // Ensure padding is consistent
      //   height: 45, // Ensure height matches TableRow height
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      padding: "0 16px", // Ensure padding is consistent
      height: 50, // Ensure height matches TableRow height
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    height: 45,
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [thirdPartypolicy, setThirdPartyPolicy] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedPolicy, setselectedPolicy] = useState(null);
  const [editedPolicy, setEditedPolicy] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [refName, setRefName] = useState("");

  const fetchNameByRefID = async (refID) => {
    if (!refID) return null;

    try {
      const docRef = doc(db, "customers", refID);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return docSnap.data().Personal?.name; // Adjust based on your Firestore structure
      } else {
        console.log("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error fetching name by refID: ", error);
      return null;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const db = getFirestore();

  const [companyId, setCompanyId] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const storedClientId = sessionStorage.getItem("clientId");
    setClientId(storedClientId);
    if (storedClientId) {
      fetchPolicyData(storedClientId);
    } else {
      setLoading(false);
    }
  }, []);

  const fetchPolicyData = async (id) => {
    try {
      const lastFetchTimeRaw = sessionStorage.getItem(
        "localTPPolicyDataFetchTime"
      );
      const lastFetchTime = lastFetchTimeRaw
        ? new Date(lastFetchTimeRaw)
        : new Date(0);

      // Format lastFetchTime in the desired format
      const formattedLastFetchTime = lastFetchTime.toLocaleString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
        timeZoneName: "short",
      });

      // console.log('formattedLastFetchTime :>> ', formattedLastFetchTime);

      // Fetch cached customers
      const cachedPolicy = JSON.parse(sessionStorage.getItem("localTPPolicyData")) || [];
      setThirdPartyPolicy(cachedPolicy);

      // console.log("cachedPolicy :>> ", cachedPolicy);

      const docRef = doc(db, "clients", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const querySnapshot = await getDocs(
          query(
            collection(db, "thirdPartyPolicy"),
            where("companyId", "==", docSnap.data().companyId),
            where("updated_at", ">", lastFetchTime),
            orderBy("name")
          )
        );

        const newlicData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
 
        // console.log("newlicData :>> ", newlicData);
        // Merge new and cached customers
        const updatedPolicy = [...cachedPolicy];

        newlicData.forEach((newPolicy) => {
          const index = updatedPolicy.findIndex(
            (cached) => cached.id === newPolicy.id
          );
          if (index > -1) {
            updatedPolicy[index] = newPolicy;
          } else {
            updatedPolicy.push(newPolicy);
          }
        });

        // console.log("updatedPolicy :>> ", updatedPolicy);

        // Update local storage
        sessionStorage.setItem(
          "localTPPolicyData",
          JSON.stringify(updatedPolicy)
        );
        sessionStorage.setItem(
          "localTPPolicyDataFetchTime",
          new Date().toISOString()
        );

        setThirdPartyPolicy(updatedPolicy);
        // console.log("licData :>> ", licData);
      } else {
        console.log("No such document!");
      }
    } catch (err) {
      console.error("Error fetching document:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (client) => {
    setselectedPolicy(client);
    setEditedPolicy(client); // Set the edited policy initially to the selected policy
    setDialogOpen(true);
  };

  const handleIconClick = async (client) => {
    setselectedPolicy(client);
    setDrawerOpen(true);

    const refID = client.ref_by;

    // Fetch name by refID
    const refName = await fetchNameByRefID(refID);
    setRefName(refName);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setselectedPolicy(null);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setselectedPolicy(null);
    setEditedPolicy(null);
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setEditedPolicy({ ...editedPolicy, [name]: value });
  // };
  const [vehicleDetailsError, setVehicleDetailsError] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setEditedPolicy((prevData) => {
      let newEndDate = prevData.endDate;

      if (name === "startDate" && prevData.howManyYear) {
        const [year, month, day] = value.split("-");
        const newEndDateDate = new Date(
          parseInt(year) + parseInt(prevData.howManyYear),
          month - 1,
          day
        );
        newEndDate = `${newEndDateDate.getFullYear()}-${(
          newEndDateDate.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${newEndDateDate
          .getDate()
          .toString()
          .padStart(2, "0")}`;
      }

      return {
        ...prevData,
        [name]: value,
        endDate: newEndDate,
      };
    });
  };

  const validateVehicleDetails = () => {
    if (!editedPolicy.vehicleDetails) {
      setVehicleDetailsError("Vehicle details are required.");
      return false;
    } else if (
      !/^[A-Z]{2}\d{2}[A-Z]{1,2}\d{4}$/.test(editedPolicy.vehicleDetails)
    ) {
      setVehicleDetailsError(
        "Invalid vehicle details format. Example: GJ03AA1111."
      );
      return false;
    }
    setVehicleDetailsError("");
    return true;
  };

  const handleVehiclechange = (e) => {
    const { name, value } = e.target;

    if (name === "vehicleDetails") {
      const upperCaseValue = value.toUpperCase();
      const pattern = /^GJ\d{2}[A-Z]{1,2}\d{4}$/;

      if (!pattern.test(upperCaseValue)) {
        setVehicleDetailsError("Invalid vehicle details format");
      } else {
        setVehicleDetailsError("");
      }

      setEditedPolicy((prev) => ({ ...prev, [name]: upperCaseValue }));
    }
  };

  const handleYearChange = (selectedOption) => {
    const newYearValue = selectedOption ? selectedOption.label : null;
    const startDate = editedPolicy.startDate;

    if (startDate && newYearValue) {
      const [year, month, day] = startDate.split("-");
      const newEndDate = new Date(
        parseInt(year) + parseInt(newYearValue),
        month - 1,
        day
      );
      const formattedEndDate = `${newEndDate.getFullYear()}-${(
        newEndDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${newEndDate.getDate().toString().padStart(2, "0")}`;

      setEditedPolicy((prevData) => ({
        ...prevData,
        howManyYear: newYearValue,
        endDate: formattedEndDate,
      }));
    } else {
      setEditedPolicy((prevData) => ({
        ...prevData,
        howManyYear: newYearValue,
        endDate: null, // Or handle it as required
      }));
    }
  };

  const handleEditSubmit = async () => {
    if (!validateVehicleDetails()) {
      return; // Prevent form submission if there are validation errors
    }

    try {
      setLoading(true);
      const docRef = doc(db, "thirdPartyPolicy", editedPolicy.id);
      await updateDoc(docRef, {
        ...editedPolicy,
        updated_at: serverTimestamp(),
      });
      setThirdPartyPolicy((prevPolicies) =>
        prevPolicies.map((policy) =>
          policy.id === editedPolicy.id ? editedPolicy : policy
        )
      );
      toast.success("Policy Data Update successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setDialogOpen(false);
    } catch (error) {
      console.error("Error updating document: ", error);
    } finally {
      setLoading(false);
    }
  };

  const flattenData = (data) => {
    return data.map((item) => ({
      id: item.id,
      name: item.name || "-",
      startDate: item.startDate || "-",
      endDate: item.endDate || "-",
      companyName: item.companyName || "-",
      howManyYear: item.howManyYear || "-",
    }));
  };

  const flattenedGICPolicy = flattenData(thirdPartypolicy);

  const columns = [
    {
      name: "no",
      label: "No",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                width: "38px",
                maxWidth: "50px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: "center",
              }}
            >
              {tableMeta.rowIndex + 1}
            </div>
          );
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value) => (
          <div
            style={{
              maxWidth: "150px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: "start",
            }}
          >
            {capitalizeFirstLetter(value)}
          </div>
        ),
      },
    },
    { name: "startDate", label: "Start Date" },
    { name: "endDate", label: "End Date" },
    {
      name: "companyName",
      label: "Company Name",
      options: {
        customBodyRender: (value) => (
          <div
            style={{
              // maxWidth: "150px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: "start",
            }}
          >
            {capitalizeFirstLetter(value)}
          </div>
        ),
      },
    },
    { name: "howManyYear", label: "Years" },
    // {
    //   name: "edit",
    //   label: "Edit",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (
    //       <IconButton sx={{height:"20px"}}
    //         onClick={() => handleEditClick(thirdPartypolicy[tableMeta.rowIndex])}
    //       >
    //         <EditIcon />
    //       </IconButton>
    //     ),
    //   },
    // },
    {
      name: "action",
      label: "Action",
      options: {
        // filter: false,
        // sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <IconButton
              sx={{ height: "20px" }}
              onClick={() =>
                handleIconClick(thirdPartypolicy[tableMeta.rowIndex])
              }
            >
              <VisibilityIcon />
            </IconButton>

            <IconButton
              sx={{ height: "20px" }}
              onClick={() =>
                handleEditClick(thirdPartypolicy[tableMeta.rowIndex])
              }
            >
              <EditIcon />
            </IconButton>
          </div>
        ),
      },
    },
  ];

  const rows = 10; // Number of skeleton rows
  const columnsCount = 7; // Number of columns

  const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const options = {
    filterType: "checkbox",
    responsive: "standard",
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: loading ? (
          <Skeleton rows={rows} columns={columnsCount} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
  };

  return (
    <>
      <MetaTags>
        <title>All Third Party Policy</title>
      </MetaTags>
      {/* <Navbar /> */}
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <Sidebar />
          <Box sx={{ flex: 1, backgroundColor: "#F4F7F9", overflow: "hidden" }}>
            <Navbar />
            <Box
              // component="main"
              sx={{
                p: 2,
              }}
            >
              <Box sx={{ padding: "1%" }}>
                  <Box
                    borderRadius="17px"
                    sx={{ boxShadow: "0px 9px 20px rgba(46, 35, 94, 0.07);" }}
                    overflow="hidden"
                  >
                    <CustomMuiPaper>
                    <MuiDatatables
                      // title="All Policy"
                      data={
                        loading
                          ? []
                          : flattenedGICPolicy && flattenedGICPolicy.length > 0
                          ? flattenedGICPolicy
                          : []
                      }
                      columns={columns}
                      options={{
                        ...options,
                        search: false,
                        searchOpen: true,
                        // customToolbar: () => (
                        //   <div>
                        //     {loading && <CircularProgress />}{" "}
                        //     {error && <p>Error: {error}</p>}{" "}
                        //   </div>
                        // ),searchOpen: true,
                        searchAlwaysOpen: true,
                        customSearchRender: (searchText, handleSearch) => {
                          return (
                            <CustomSearchRender
                              searchText={searchText}
                              onSearch={handleSearch}
                            />
                          );
                        },
                        headCells: {
                          style: {
                            paddingLeft: 0,
                            textAlign: "start",
                          },
                        },
                      }}
                    />
                    </CustomMuiPaper>
                  </Box>
                {/* </Box> */}
              </Box>
            </Box>
          </Box>
        </Box>
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          fullWidth
          maxWidth="md"
          sx={{ fontFamily: '"Montserrat", sans-serif' }}
        >
          <DialogTitle style={{ fontFamily: '"Montserrat", sans-serif' }}>
            Update Third Party Policy Details
          </DialogTitle>
          <DialogContent>
            {selectedPolicy && (
              <Box sx={{ padding: 2 }}>
                <Divider />
                <Box sx={{ marginY: 2 }}>
                  <TextField
                    label="Name"
                    name="name"
                    value={editedPolicy?.name || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    type="date"
                    label="Start Date"
                    name="startDate"
                    value={editedPolicy?.startDate || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{ height: "45px" }}
                  >
                    <Select
                      id="fname"
                      name="howManyYear"
                      placeholder="Select Year"
                      options={years}
                      isClearable
                      value={years.find(
                        (option) => option.label === editedPolicy?.howManyYear
                      )}
                      onChange={handleYearChange}
                      styles={{
                        container: (provided) => ({
                          ...provided,
                        }),
                        control: (provided) => ({
                          ...provided,
                          height: "50px",
                          margin: "11px 0px",
                        }),
                      }}
                    />
                  </FormControl>

                  <TextField
                    label="Company Name"
                    name="companyName"
                    value={editedPolicy?.companyName || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    sx={{ marginTop: "35px" }}
                  />

                  <TextField
                    label="Company Code"
                    name="companyCode"
                    value={editedPolicy?.companyCode || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Premium Amount"
                    name="premiumAmount"
                    value={editedPolicy?.premiumAmount || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Policy Number"
                    name="policyNumber"
                    value={editedPolicy?.policyNumber || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Vehicle Name"
                    name="vehicleName"
                    value={editedPolicy?.vehicleName || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Vehicle Details"
                    name="vehicleDetails"
                    placeholder="GJ03AA1111"
                    value={editedPolicy?.vehicleDetails || ""}
                    onChange={handleVehiclechange}
                    fullWidth
                    margin="normal"
                    error={!!vehicleDetailsError}
                    helperText={vehicleDetailsError}
                  />
                </Box>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleDialogClose}
              variant="outlined"
              sx={{ border: "1px solid #57dd36", color: "#57dd36", p: 1 }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleEditSubmit}
              variant="contained"
              disabled={loading} // Assuming `loading` state indicates loading state
              // startIcon={loading ? <CircularProgress size={24} /> : null}
              sx={{ backgroundColor: "#57dd36", p: 1  }}
            >
              {loading ? <CircularProgress size={24} /> : "Save Changes"}
            </Button>
          </DialogActions>
        </Dialog>

        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={handleDrawerClose}
          sx={{ "& .MuiDrawer-paper": { width: 460, padding: 2 } }}
        >
          {selectedPolicy && (
            <Box sx={{ padding: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  onClick={handleDrawerClose}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="48"
                      d="M328 112L184 256l144 144"
                    />
                  </svg>
                </Typography>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ fontFamily: '"Montserrat", sans-serif' }}
                >
                  Third Party Policy Details
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ marginY: 2 }}>
                {selectedPolicy.name && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Name:</h5> {capitalizeFirstLetter(selectedPolicy.name)}
                  </Typography>
                )}

                {selectedPolicy.startDate && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Start Date:</h5> {selectedPolicy.startDate}
                  </Typography>
                )}

                {selectedPolicy.endDate && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>End Date:</h5> {selectedPolicy.endDate}
                  </Typography>
                )}

                {selectedPolicy.endDate && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>How Many Year:</h5> {selectedPolicy.howManyYear}
                  </Typography>
                )}

                {selectedPolicy.endDate && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Ref By:</h5>{" "}
                    {capitalizeFirstLetter(refName || selectedPolicy.ref_by)}
                  </Typography>
                )}

                {selectedPolicy.companyName && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Company Name:</h5>{" "}
                    {capitalizeFirstLetter(selectedPolicy.companyName)}
                  </Typography>
                )}

                {selectedPolicy.companyCode && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Company Code:</h5> {selectedPolicy.companyCode}
                  </Typography>
                )}

                {selectedPolicy.premiumAmount && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Premium Amount:</h5> {selectedPolicy.premiumAmount}
                  </Typography>
                )}

                {selectedPolicy.policyNumber && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Policy Number:</h5> {selectedPolicy.policyNumber}
                  </Typography>
                )}

                {selectedPolicy.vehicleName && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Vehicle Name:</h5>{" "}
                    {capitalizeFirstLetter(selectedPolicy.vehicleName)}
                  </Typography>
                )}

                {selectedPolicy.vehicleDetails && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Vehicle Details:</h5> {selectedPolicy.vehicleDetails}
                  </Typography>
                )}
              </Box>
            </Box>
          )}
        </Drawer>
      </ThemeProvider>
    </>
  );
};

export default AllThirdPartyPolicy;
