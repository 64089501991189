import React from 'react'
import './Footer.css'

const Footer = () => {
    return (
        <div className='footer' style={{width:"100%"}}>
            <div className="sb__footer section__padding">
                <div className="sb__footer-below">
                    <div className="sb__footer-copyright">
                        <p>
                            <strong className='st'>AutoPULSE</strong> Web
                             {/* @ {new Date().getFullYear()} */}
                        </p>
                    </div>
                    <div className='terms'>
                        <a href="/terms-condition"> Terms &amp; Condition </a>
                    </div>
                    <div className='sb__footer-below-links'>
                        <p>
                            Powered by <a href="https://www.arksoftai.com/" target='_blank'>Ark Intelligence</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
