import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Button from "@mui/material/Button";
// import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Box from "@mui/material/Box";
import vector from "../../components/images/Vector.png";
import email from "../../components/images/LicImage/emailIcon.png";
import remarks from "../../components/images/star.png";
import remarkIcon from "../../components/images/LicImage/remarkIcon.png";
import SDate from "../../components/images/LicImage/start-date.png";
import EDate from "../../components/images/LicImage/EndDate.png";
import PMaturityDate from "../../components/images/MaturityDate.png";
import SumAssured from "../../components/images/SumAssured.png";
import MoneyBack from "../../components/images/moneyBack.png";
import uploadImage from "../../components/images/uploadImage.png";
import LastPremiumDate from "../../components/images/LicImage/remarkIcon.png";
import PolicyMaturityDate from "../../components/images/LicImage/PolicyMaturityDate.png";
import SumAssuredIcon from "../../components/images/LicImage/SumAssuredIcon.png";
import MoneyBackAmount from "../../components/images/LicImage/MoneyBackAmount.png";
import bday from "../../components/images/calendar.png";
import policyNumber from "../../components/images/LicImage/PolicyNumber.png";
import Checkbox from "@mui/material/Checkbox";
import {
  CircularProgress,
  Dialog,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import policyNum from "../../components/images/policy-num.png";
import Avatar from "react-avatar-edit";
import { toast } from "react-toastify";
import { db, storage } from "../../components/utils/firebase";
import { centerCrop } from "react-image-crop";
import policyName from "../../components/images/policyName.png";
import uploadIcon from "../../components/images/docUploadIcon.png";
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  serverTimestamp,
  doc,
  updateDoc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { MenuItem, FormControl, InputLabel } from "@mui/material";
import { getDownloadURL, ref, uploadBytes } from "@firebase/storage";
import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import "../css/NewLicPolicy.css";
import { Image, People } from "@mui/icons-material";
import MetaTags from "react-meta-tags";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CreatableSelect from "react-select/creatable";
import RemoveIcon from "@mui/icons-material/Remove";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import close from "../../components/images/close.png";
import Navbar from "../../components/Navbar/Navbar";

const nameOptions = [
  { value: "John", label: "John" },
  { value: "Jane", label: "Jane" },
  { value: "James", label: "James" },
  // Add more options as needed
];

const NewLicPolicy = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Debugging to check windowHeight
  // useEffect(() => {
  //   console.log("Current windowHeight:", windowHeight);
  //   console.log("Current windowWidth:", windowWidth);
  // }, [windowHeight, windowWidth]);

  const [licData, setLicData] = useState({
    name: null,
    policyName: "",
    policyNumber: "",
    planNumber: "",
    policyTerm: "",
    premiumPayingTerm: "",
    startDate: "",
    endDate: "",
    lastPremiumDate: "",
    policyMaturityDate: "",
    sumAssured: "",
    ref_by: null,
    remarks: "",
    documents: [],
    lic_code: "",
    dynamicFields: [
      {
        moneyBackAmount: "",
        moneyBackYear: null,
      },
    ],
    familyMemberName: [],
    premiumMonth: [
      {
        date: null,
      },
    ],
    premiumAmount: "",
    mode: "yearly",
    onDeath: false,
    customerId: null,
  });

  const [image, setImage] = useState(null);
  const [imageURL, setImageURL] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [imageCrop, setImageCrop] = useState(false);
  const [profile, setProfile] = useState([]);
  const [pview, setPview] = useState(false);

  const [customers, setCustomers] = useState([]);
  const [customerRef, setCustomerRef] = useState([]);
  const [clientId, setClientId] = useState(null);
  const [companyId, setCompanyId] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Get clientId from sessionStorage
    const storedClientId = sessionStorage.getItem("clientId");
    setClientId(storedClientId);
    if (storedClientId) {
      setClientId(storedClientId);
      // console.log("clientId :>> ", clientId);
    } else {
      setLoading(false);
    }
  }, []);

  const fetchClientData = async (id) => {
    try {
      const docRef = doc(db, "clients", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setCompanyId(docSnap.data().companyId);
        // console.log("companyId :>> ", companyId);
      } else {
        console.log("No such document!");
        // setError("No such document!");
      }
    } catch (err) {
      console.error("Error fetching document:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Get clientId from sessionStorage
    const storedClientId = sessionStorage.getItem("clientId");
    setClientId(storedClientId);

    if (storedClientId) {
      fetchClientData(storedClientId);
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const q = query(
      collection(db, "customers"),
      where("Personal.clientId", "==", clientId)
    );

    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const customers = [];
        querySnapshot.forEach((doc) => {
          customers.push({ id: doc.id, ...doc.data() });
        });
        setCustomers(customers);
        // console.log("customers :>> ", customers);
        setCustomerRef(
          customers.map((customer) => ({
            value: customer.id,
            label: customer.Personal.name,
          }))
        );
        // etClientOptions(
        //   clientData.map((client) => ({
        //     value: client.id,
        //     label: client.Personal.name,
        //   }))
        // );
      },
      (error) => {
        console.error("Error fetching customers:", error);
      }
    );

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [clientId]);

  // Add documents
  const [documents, setDocuments] = useState([]);

  const handleUploadClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleEditNameChange = (index, newName) => {
    setDocuments((prevDocuments) => {
      const updatedDocuments = [...prevDocuments];
      updatedDocuments[index].editName = newName;
      return updatedDocuments;
    });
  };

  const handleSaveNameChange = (index) => {
    setDocuments((prevDocuments) => {
      const updatedDocuments = [...prevDocuments];
      updatedDocuments[index].name = updatedDocuments[index].editName;
      return updatedDocuments;
    });
  };

  const handleRemoveDocument = (index) => {
    setDocuments((prevDocuments) => {
      const updatedDocuments = [...prevDocuments];
      updatedDocuments.splice(index, 1);
      return updatedDocuments;
    });
  };

  // const customerOptions = customers.map((customers) => ({
  //   value: customers.id,
  //   label: customers.Personal.name,
  // }));

  // const handlePolicyHolderChange = (selectedOption) => {
  //   setLicData({
  //     ...licData,
  //     name: selectedOption ? selectedOption.label : null,
  //   });
  //   console.log("selectedOption :>> ", selectedOption.label);
  // };

  const handleSelectChange = (selectedOption) => {
    setLicData({
      ...licData,
      ref_by: selectedOption ? selectedOption.value : null,
    });
  };

  const handleCreate = (inputValue) => {
    const newOption = { value: inputValue, label: inputValue };
    // clientOptions.push(newOption);
    setCustomerRef((prevOptions) => [...prevOptions, newOption]);
    handleSelectChange(newOption);
  };

  // useEffect(() => {
  //   sessionStorage.removeItem("policyHolderValue");
  // }, []);

  const [familyMemberField, setFamilyMemberField] = useState([]);
  const [familyMemberData, setFamilyMemberData] = useState([]);
  const [familyMemberName, setFamilyMemberName] = useState([]);

  useEffect(() => {
    if (!licData.familyMemberName) {
      setLicData({ ...licData, familyMemberName: [] });
    }
  }, [licData, setLicData]);

  const customerOptions = customers.map((customers) => ({
    value: customers.id,
    label: customers.Personal.name,
  }));

  const handlePolicyHolderChange = async (selectedOption) => {
    const policyHolderId = selectedOption ? selectedOption.value : null;
    // console.log("value :>> ", policyHolderId);

    // Store selectedOption.value in sessionStorage
    if (policyHolderId) {
      sessionStorage.setItem("policyHolderValue", policyHolderId);
    } else {
      sessionStorage.removeItem("policyHolderValue");
    }

    if (policyHolderId) {
      const docRef = doc(db, "customers", policyHolderId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setFamilyMemberData(docSnap.data());
      } else {
        console.log("No such document!");
      }
    } else {
      setFamilyMemberData({ id: "", name: "" });
    }

    setLicData({
      ...licData,
      name: selectedOption ? selectedOption.label : null,
      customerId: selectedOption ? selectedOption.value : null,
    });
  };

  useEffect(() => {
    const storedId = sessionStorage.getItem("policyHolderValue");
    // console.log("storedId :>> ", storedId);

    if (storedId) {
      const fetchData = async () => {
        const docRef = doc(db, "customers", storedId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const familyMembers = docSnap.data().Personal?.family_member;

          if (familyMembers) {
            const familyMemberPromises = familyMembers.map(async (memberId) => {
              const memberDocRef = doc(db, "customers", memberId.id);
              const memberDocSnap = await getDoc(memberDocRef);

              if (memberDocSnap.exists()) {
                return {
                  id: memberId.id,
                  ...memberDocSnap.data(),
                };
              } else {
                console.log(
                  `No document found for family member ID: ${memberId.id}`
                );
                return {
                  id: memberId.id,
                  name: "Unknown",
                };
              }
            });

            const familyMemberName = await Promise.all(familyMemberPromises);
            setFamilyMemberName(familyMemberName);
            console.log("familyMemberData :>> ", familyMemberName);
          } else {
            console.log("No family members found in the document");
            setFamilyMemberName([]);
          }
        } else {
          console.log("No such document!");
          setFamilyMemberName([]);
        }
      };

      fetchData();
    }
  }, [familyMemberData]);

  const familyMemberOptions = familyMemberName.map((member) => ({
    label: member.Personal.name,
    value: member.id,
  }));

  // console.log('familyMemberOptions :>> ', familyMemberOptions);

  const handleFamilyNameChange = (selectedOption, index) => {
    const updatedNames = [...licData.familyMemberName];
    console.log("selectedOption.value :>> ", selectedOption.value);
    updatedNames[index] = {
      name: selectedOption ? selectedOption.label : null,
      value: selectedOption ? selectedOption.value : null,
    };
    console.log("updatedNames :>> ", updatedNames);
    setLicData({ ...licData, familyMemberName: updatedNames });
  };

  const handleAddPolicyHolder = () => {
    if (familyMemberField.length < 3) {
      setFamilyMemberField([...familyMemberField, {}]);
      setLicData({
        ...licData,
        familyMemberName: [...(licData.familyMemberName || [])],
      });
    }
  };

  // Remove policy holder field
  const handleRemovePolicyHolder = (index) => {
    const updatedFields = [...familyMemberField];
    updatedFields.splice(index, 1);
    setFamilyMemberField(updatedFields);

    const updatedNames = [...licData.familyMemberName];
    updatedNames.splice(index, 1);
    setLicData({ ...licData, familyMemberName: updatedNames });
  };

  const onCrop = (view) => {
    setPview(view);
  };

  const saveCropImage = () => {
    setProfile([{ pview }]);
    console.log("pview :>> ", pview);
    setImageCrop(false);
    toast.success("profile photo added successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const profileFinal = profile.map((item) => item.pview);

  const handleClickImage = () => {
    setImageCrop(true);
  };

  //Year dropdown
  const startYear = 1940;
  const endYear = 2050;
  // const endYear = new Date().getFullYear();

  const [selectedYear, setSelectedYear] = useState(null);

  const handleYearChange = (index, selectedOptions) => {
    const selectedYearValue = selectedOptions ? selectedOptions.value : null;

    setLicData((prevData) => {
      const updatedDynamicFields = [...prevData.dynamicFields];
      console.log("updatedDynamicFields :>> ", updatedDynamicFields);
      const [year, month, day] = prevData.startDate.split("-");
      const updatedMoneyBackYear = selectedYearValue
        ? `${day}-${month}-${selectedYearValue}`
        : null;
      console.log("updatedMoneyBackYear :>> ", updatedMoneyBackYear);

      updatedDynamicFields[index].moneyBackYear = updatedMoneyBackYear;

      const updatedData = {
        ...prevData,
        dynamicFields: updatedDynamicFields,
      };

      console.log("updated licData :>> ", updatedData);
      return updatedData;
    });

    console.log(
      "selectedOptions :>> ",
      selectedOptions ? selectedOptions.value : null
    );
  };

  const addDynamicField = () => {
    setLicData({
      ...licData,
      dynamicFields: [
        ...licData.dynamicFields,
        { moneyBackAmount: "", moneyBackYear: null },
      ],
    });
  };

  const generateYearOptions = () => {
    const years = [];
    for (let year = endYear; year >= startYear; year--) {
      years.push({ value: year, label: year.toString() });
    }
    return years;
  };

  const handleDynamicFieldChange = (index, event) => {
    const { name, value } = event.target;
    if (/^\d*$/.test(value)) {
      const updatedDynamicFields = [...licData.dynamicFields];
      updatedDynamicFields[index][name] = value;
      setLicData({ ...licData, dynamicFields: updatedDynamicFields });
    }
  };

  const removeDynamicField = (index) => {
    setLicData((prevData) => {
      const updatedDynamicFields = prevData.dynamicFields.filter((_, i) => i !== index);
      return {
        ...prevData,
        dynamicFields: updatedDynamicFields,
      };
    });
  };
  // const removeDynamicField = (index) => {
  //   const updatedDynamicFields = [...licData.dynamicFields];
  //   updatedDynamicFields.splice(index, 1); // Remove current field
  //   updatedDynamicFields.splice(index, 1); // Remove next field
  //   setLicData(updatedDynamicFields);
  // };

  const handleChange = async (e) => {
    const { name, value, files } = e.target;
    if (name === "policyDocument") {
      // const file = e.target.files[0];
      const filesArray = Array.from(e.target.files).map((file) => ({
        file: e.target.files[0],
        name: file.name,
        url: URL.createObjectURL(file),
        // editName: file.name,
      }));
      setLicData((prev) => ({ ...prev, policyDocument: filesArray }));
      // if (file) {
      //   setImage(file);
      //   setImageURL(URL.createObjectURL(file));
      //   // setImageCrop(true);
      // }
    } else if (name === "documents") {
      const filesArray = Array.from(e.target.files).map((file) => ({
        file: file,
        name: file.name,
        url: URL.createObjectURL(file),
        editName: file.name,
      }));
      setDocuments((prevDocuments) => [...prevDocuments, ...filesArray]);
      setLicData((prev) => ({
        ...prev,
        documents: [...prev.documents, ...filesArray],
      }));
    } else {
      setLicData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const [showForm, setShowForm] = useState(false);
  const handleClose = () => {
    setShowForm(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      const docRef = await addDoc(collection(db, "lic"), {
        name: licData.name,
        policyName: licData.policyName,
        policyNumber: licData.policyNumber,
        planNumber: licData.planNumber,
        policyTerm: licData.policyTerm,
        premiumPayingTerm: licData.premiumPayingTerm,
        startDate: licData.startDate,
        lastPremiumDate: licData.lastPremiumDate,
        policyMaturityDate: licData.policyMaturityDate,
        sumAssured: licData.sumAssured,
        ref_by: licData.ref_by,
        remarks: licData.remarks,
        dynamicFields: licData.dynamicFields,
        familyMemberName: licData.familyMemberName,
        premiumMonth: licData.premiumMonth,
        premiumAmount: licData.premiumAmount,
        mode: licData.mode,
        onDeath: licData.onDeath,
        clientId: clientId,
        companyId: companyId,
        customerId: licData.customerId,
        // lic_code: latestCustomerCode,
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
      });

      const licId = docRef.id;
      console.log("licId :>> ", licId);

      const documents = [];
      if (licData.documents.length !== 0) {
        for (let i = 0; i < licData.documents.length; i++) {
          const documentRef = ref(
            storage,
            `LicDocument/${clientId}/${licId}/${licData.documents[i].file.name}`
          );
          await uploadBytes(documentRef, licData.documents[i].file);
          const documentURL = await getDownloadURL(documentRef);
          documents.push({
            name: licData.documents[i].name,
            url: documentURL,
          });
        }
      }

      // let croppedPhotoURL = "";
      // if (profileFinal.length !== 0) {
      //   const croppedpolicyDocumentRef = ref(
      //     storage,
      //     `LicDocument/${clientId}/${licId}/${licData.policyDocument.name}`
      //   );
      //   const croppedBlob = await fetch(profileFinal[0]).then((r) => r.blob());
      //   await uploadBytes(croppedpolicyDocumentRef, croppedBlob);
      //   croppedPhotoURL = await getDownloadURL(croppedpolicyDocumentRef);
      // }

      await updateDoc(doc(db, "lic", licId), {
        id: licId,
        documents: documents,
      });

      resetForm();

      toast.success("Form Submit successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      // console.log("licData:", licData);
    } catch (error) {
      // Handle any errors that occur during the API request
      console.error("Error:", error);
    } finally {
      setSubmitting(false);
      sessionStorage.removeItem("policyHolderValue");
    }
  };

  const resetForm = () => {
    setLicData({
      name: "",
      startDate: "",
      policyMaturityDate: "",
      policyName: "",
      policyNumber: "",
      planNumber: "",
      policyTerm: "",
      premiumPayingTerm: "",
      // endDate: "",
      lastPremiumDate: "",
      sumAssured: "",
      remarks: "",
      lic_code: "",
      dynamicFields: [
        {
          moneyBackAmount: "",
          moneyBackYear: null,
        },
      ],
      familyMemberName: [
        {
          name: null,
        },
      ],
      premiumMonth: [],
      premiumAmount: "",
      mode: "",
      documents: [],
    });
    setProfile([]);
    setDocuments([]);
    setImage(null);
    setImageURL("");
    setPview(false);
    // setSelectedYear(null);
  };

  const [mode, setMode] = useState("yearly");
  const [numFields, setNumFields] = useState(1);
  const [premiumMonths, setPremiumMonths] = useState("");

  const handleModeChange = (event) => {
    const value = event.target.value;
    console.log("value :>> ", value);
    setLicData((prevData) => ({ ...prevData, mode: value }));
    switch (value) {
      case "yearly":
        setNumFields(1);
        break;
      case "half-yearly":
        setNumFields(2);
        break;
      case "quarterly":
        setNumFields(4);
        break;
      default:
        setNumFields(1);
    }
  };

  const handleInputChange = (index) => (event) => {
    let value = event.target.value;
    if (/^(0?[1-9]|1[0-2])?$/.test(value)) {
      const newPremiumMonths = [...premiumMonths];
      newPremiumMonths[index] = value;
      setPremiumMonths(newPremiumMonths);

      setLicData((prevData) => ({
        ...prevData,
        premiumMonth: newPremiumMonths.map((month) => {
          const [year, monthStartDate, dayStartDate] =
            prevData.startDate.split("-");
          const date = new Date(
            year,
            monthStartDate - 1 + parseInt(month),
            dayStartDate
          );
          console.log("date :>> ", date);
          const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;

          console.log("formattedDate :>> ", formattedDate);
          return { date: formattedDate };
        }),
      }));
    }
  };

  const handleCheckboxChange = (event) => {
    const { checked } = event.target;
    console.log("checked :>> ", checked);
    setLicData((prevData) => ({
      ...prevData,
      onDeath: checked,
    }));
  };

  const handleInput = (event) => {
    const value = event.target.value;
    if (value < 1 || value > 12 || !/^\d+$/.test(value)) {
      event.target.value = "";
    }
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <>
      {/* <Box height={40} /> */}
      <MetaTags>
        <title>New LIC Policy</title>
      </MetaTags>
      <Box style={{ display: "flex" }}>
        <Sidebar />
        <Box sx={{ flex: 1 }}>
          <Navbar />
          <Box sx={{ p: 2 }}>
            <Box className="container">
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={2}
                  style={{ width: "95%", margin: "0 1%" }}
                >
                  <Grid item xs={12} sm={8}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <PersonIcon sx={{ mr: 2 }} /> */}
                        <img
                          src={vector}
                          alt="vector"
                          style={{ height: "18px", marginRight: "22px" }}
                        />
                        <Typography sx={{ minWidth: "100px", width: "145px" }}>
                          Policy Holder Name
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                      </Box>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        sx={{ height: "45px" }}
                      >
                        <Select
                          id="fname"
                          name="name"
                          required
                          placeholder=" "
                          options={customerOptions}
                          isClearable
                          value={customerOptions.find(
                            (option) =>
                              option.label === licData.name &&
                              option.value === licData.customerId
                          )}
                          onChange={handlePolicyHolderChange}
                          // onChange={(selectedOption) =>
                          //   handlePolicyHolderChange(selectedOption, index)
                          // }
                          styles={{
                            container: (provided) => ({
                              ...provided,
                              // width:
                              //   windowWidth >= 1920
                              //     ? "67%"
                              //     : windowWidth >= 1280
                              //     ? "69%"
                              //     : "69%",
                            }),
                            control: (provided) => ({
                              ...provided,
                              background: "#F9F9F9",
                              border: "1px solid rgba(59, 214, 21, 0.3)",
                              height:
                                windowHeight >= 945
                                  ? "50px"
                                  : windowHeight >= 730
                                  ? "45px"
                                  : "40px",
                            }),
                          }}
                        />
                      </FormControl>
                    </Box>
                  </Grid>

                  {/* family Member Name */}

                  <>
                    {familyMemberField.map((_, index) => (
                      <>
                        <Grid item xs={12} sm={8}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <img
                                src={vector}
                                alt="vector"
                                style={{ height: "18px", marginRight: "22px" }}
                              />
                              <Typography
                                sx={{ minWidth: "100px", width: "145px" }}
                              >
                                Family Member Name
                                <span style={{ color: "red" }}>*</span>
                              </Typography>
                            </Box>
                            <FormControl
                              fullWidth
                              variant="outlined"
                              sx={{ height: "45px" }}
                            >
                              <Select
                                id="fname"
                                name="name"
                                placeholder=" "
                                options={familyMemberOptions}
                                isClearable
                                value={familyMemberOptions.find(
                                  (option) =>
                                    option.label ===
                                    licData.familyMemberName?.[index]?.name
                                )}
                                // onChange={handlePolicyHolderChange}
                                onChange={(selectedOption) =>
                                  handleFamilyNameChange(selectedOption, index)
                                }
                                styles={{
                                  container: (provided) => ({
                                    ...provided,
                                  }),
                                  control: (provided) => ({
                                    ...provided,
                                    background: "#F9F9F9",
                                    border: "1px solid rgba(59, 214, 21, 0.3)",
                                    height:
                                      windowHeight >= 945
                                        ? "50px"
                                        : windowHeight >= 730
                                        ? "45px"
                                        : "40px",
                                  }),
                                }}
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={1}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mt: 0.5,
                            }}
                          >
                            <Button
                              variant="contained"
                              // color="primary"
                              sx={{
                                marginLeft: 2,
                                backgroundColor: "#d41515d6",
                                color: "black",
                                "&:hover": {
                                  backgroundColor: "#d41515d6 !important",
                                },
                              }}
                              onClick={handleRemovePolicyHolder}
                            >
                              <RemoveIcon />
                            </Button>
                          </Box>
                        </Grid>
                      </>
                    ))}
                  </>

                  <Grid item xs={12} sm={2}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mt: 0.5,
                      }}
                    >
                      <Button
                        variant="contained"
                        // color="primary"
                        sx={{
                          marginLeft: 2,
                          backgroundColor: "#57dd36",
                          color: "black",
                          "&:hover": {
                            backgroundColor: "#57dd36 !important",
                          },
                        }}
                        onClick={handleAddPolicyHolder}
                        disabled={
                          licData.name === null ||
                          licData.familyMemberName?.name === null
                        }
                      >
                        <AddIcon />
                      </Button>
                    </Box>
                  </Grid>

                  {/* Start Date */}
                  <Grid item xs={12} sm={6} sx={{ mr: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <CalendarToday sx={{ mr: 2 }} /> */}
                        <img
                          src={SDate}
                          alt="SDate"
                          style={{ height: "18px", marginRight: "20px" }}
                        />
                        <Typography sx={{ minWidth: "100px", width: "142px" }}>
                          Start Date
                        </Typography>
                      </Box>
                      <TextField
                        type="date"
                        fullWidth
                        variant="outlined"
                        sx={{ height: "45px" }}
                        name="startDate"
                        value={licData.startDate}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>

                  {/* Policy Maturity Date */}
                  <Grid item xs={12} sm={6}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <CalendarToday sx={{ mr: 2 }} /> */}
                        <img
                          src={PolicyMaturityDate}
                          alt="PolicyMaturityDate"
                          style={{ height: "18px", marginRight: "20px" }}
                        />
                        <Typography sx={{ minWidth: "100px", width: "142px" }}>
                          Policy Maturity Date
                        </Typography>
                      </Box>
                      <TextField
                        type="date"
                        fullWidth
                        variant="outlined"
                        sx={{ height: "45px" }}
                        name="policyMaturityDate"
                        value={licData.policyMaturityDate}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>

                  {/* onDeath */}
                  <Grid item xs={12} sm={3}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <CalendarToday sx={{ mr: 2 }} /> */}
                        <img
                          src={SDate}
                          alt="SDate"
                          style={{ height: "18px", marginRight: "20px" }}
                        />
                        <Typography sx={{ minWidth: "100px", width: "100px" }}>
                          On Death
                        </Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="onDeath"
                              checked={licData.onDeath}
                              onChange={handleCheckboxChange}
                            />
                          }
                          // sx={{ "& .MuiFormControlLabel-label": { fontSize: "18px" } }}
                        />
                      </Box>
                    </Box>
                  </Grid>

                  {/* Last Premium Date */}
                  <Grid item xs={12} sm={6} sx={{ mr: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <CalendarToday sx={{ mr: 2 }} /> */}
                        <img
                          src={LastPremiumDate}
                          alt="LastPremiumDate"
                          style={{ height: "18px", marginRight: "20px" }}
                        />
                        <Typography sx={{ minWidth: "100px", width: "142px" }}>
                          Last Premium Date
                        </Typography>
                      </Box>
                      <TextField
                        type="date"
                        fullWidth
                        variant="outlined"
                        sx={{ height: "45px" }}
                        name="lastPremiumDate"
                        value={licData.lastPremiumDate}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>

                  {licData.dynamicFields?.map((field, index) => (
                    <>
                      <Grid item xs={12} md={6} sm={12}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            {/* <PersonIcon sx={{ mr: 2 }} /> */}
                            <img
                              src={MoneyBackAmount}
                              alt="MoneyBackAmount"
                              style={{ height: "18px", marginRight: "18px" }}
                            />
                            <Typography
                              sx={{ minWidth: "100px", width: "145px" }}
                            >
                              Money Back Amount
                            </Typography>
                          </Box>
                          <TextField
                            fullWidth
                            variant="outlined"
                            sx={{ height: "45px" }}
                            name="moneyBackAmount"
                            value={field.moneyBackAmount}
                            onChange={(e) => handleDynamicFieldChange(index, e)}
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                            }}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={5} sm={11}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            {/* <PersonIcon sx={{ mr: 2 }} /> */}
                            <img
                              src={remarkIcon}
                              alt="remarkIcon"
                              style={{ height: "18px", marginRight: "20px" }}
                            />
                            <Typography
                              sx={{ minWidth: "100px", width: "145px" }}
                            >
                              Money Back Years
                            </Typography>
                          </Box>
                          <FormControl
                            fullWidth
                            variant="outlined"
                            sx={{ height: "45px" }}
                          >
                            <Select
                              id="fname"
                              name="name"
                              placeholder=" "
                              options={generateYearOptions()}
                              value={
                                field.moneyBackYear
                                  ? {
                                      value: field.moneyBackYear,
                                      label: field.moneyBackYear.toString(),
                                    }
                                  : null
                              }
                              onChange={(selectedOptions) =>
                                handleYearChange(index, selectedOptions)
                              }
                              isClearable
                              styles={{
                                container: (provided) => ({
                                  ...provided,
                                }),
                                control: (provided) => ({
                                  ...provided,
                                  background: "#F9F9F9",
                                  border: "1px solid rgba(59, 214, 21, 0.3)",
                                  height:
                                    windowHeight >= 945
                                      ? "50px"
                                      : windowHeight >= 730
                                      ? "45px"
                                      : "40px",
                                }),
                              }}
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      {index > 0 && (
                      <Grid item xs={12} md={0.5} sm={1}>
                        <button
                          type="button"
                          onClick={() => removeDynamicField(index)}
                          style={{
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                            marginTop: "5px",
                          }}
                        >
                          <RemoveCircleOutlineIcon
                            style={{ color: "#FF0000", fontSize: "36px" }}
                          />
                        </button>
                      </Grid>
                    )}

                      {/* addDynamicField Button */}
                      <Grid item xs={12} md={0.5} sm={1}>
                        <button
                          type="button"
                          onClick={addDynamicField}
                          disabled={
                            !field.moneyBackAmount ||
                            field.moneyBackYear === null
                          }
                          style={{
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                            marginTop: "5px",
                          }}
                        >
                          <AddCircleOutlineIcon
                            style={{ color: "#3BD615", fontSize: "36px" }}
                          />
                        </button>
                      </Grid>
                    </>
                  ))}

                  {/* Policy Name */}
                  <Grid item xs={12} md={6} sm={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <PersonIcon sx={{ mr: 2 }} /> */}
                        <img
                          src={policyName}
                          alt="policyName"
                          style={{ height: "18px", marginRight: "18px" }}
                        />
                        <Typography sx={{ minWidth: "100px", width: "145px" }}>
                          Policy Name
                        </Typography>
                      </Box>
                      <TextField
                        fullWidth
                        variant="outlined"
                        sx={{ height: "45px" }}
                        name="policyName"
                        value={licData.policyName}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid >

                  <Grid item xs={12} sm={6}>

                  </Grid>

                  {/* Plan Number */}
                  <Grid item xs={12} md={4.5} sm={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <PersonIcon sx={{ mr: 2 }} /> */}
                        <img
                          src={email}
                          alt="email"
                          style={{ height: "18px", marginRight: "18px" }}
                        />
                        <Typography sx={{ minWidth: "100px", width: "145px" }}>
                          Plan Number
                        </Typography>
                      </Box>
                      <TextField
                        fullWidth
                        variant="outlined"
                        sx={{ height: "45px" }}
                        name="planNumber"
                        value={licData.planNumber}
                        onChange={handleChange}
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        type="number"
                      />
                    </Box>
                  </Grid>

                  {/* Premium Paying Term */}
                  <Grid item xs={12} md={4.5} sm={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <PersonIcon sx={{ mr: 2 }} /> */}
                        <img
                          src={email}
                          alt="email"
                          style={{ height: "18px", marginRight: "20px" }}
                        />
                        <Typography sx={{ minWidth: "100px", width: "145px" }}>
                          Premium Paying Term
                        </Typography>
                      </Box>
                      <TextField
                        fullWidth
                        variant="outlined"
                        sx={{ height: "45px" }}
                        name="premiumPayingTerm"
                        value={licData.premiumPayingTerm}
                        onChange={handleChange}
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        type="number"
                      />
                    </Box>
                  </Grid>

                  {/* Policy Term */}
                  <Grid item xs={12} md={3} sm={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <PersonIcon sx={{ mr: 2 }} /> */}
                        <img
                          src={email}
                          alt="email"
                          style={{ height: "18px", marginRight: "20px" }}
                        />
                        <Typography sx={{ minWidth: "100px", width: "100px" }}>
                          Policy Term
                        </Typography>
                      </Box>
                      <TextField
                        fullWidth
                        variant="outlined"
                        sx={{ height: "45px" }}
                        name="policyTerm"
                        value={licData.policyTerm}
                        onChange={handleChange}
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        type="number"
                      />
                    </Box>
                  </Grid>

                  {/* End Date */}
                  {/* <Grid item xs={12} sm={4.5}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={EDate}
                        alt="EDate"
                        style={{ height: "18px", marginRight: "16px" }}
                      />
                      <Typography sx={{ minWidth: "100px", width: "142px" }}>
                        End Date
                      </Typography>
                    </Box>
                    <TextField
                      type="date"
                      fullWidth
                      variant="outlined"
                      sx={{ height: "45px" }}
                      name="endDate"
                      value={licData.endDate}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid> */}

                  {/* Ref By */}
                  <Grid item xs={12} md={6} sm={12} sx={{ mr: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <People sx={{ mr: 2 }} />
                        <Typography sx={{ minWidth: "100px", width: "142px" }}>
                          Ref By
                        </Typography>
                      </Box>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        sx={{ height: "45px" }}
                      >
                        <CreatableSelect
                          name="ref_by"
                          value={
                            customerRef.find(
                              (option) => option.value === licData.ref_by
                            ) || {
                              value: licData.ref_by,
                              label: licData.ref_by,
                            }
                          }
                          onChange={handleSelectChange}
                          onCreateOption={handleCreate}
                          options={customerRef}
                          placeholder=""
                          isClearable
                          required
                          styles={{
                            container: (provided) => ({
                              ...provided,
                              //   width: "40%",
                              height: "45px",
                            }),
                            control: (provided) => ({
                              ...provided,
                              background: "#F9F9F9",
                              border: "1px solid rgba(59, 214, 21, 0.3)",
                              height: "48px",
                              // height:
                              //   windowHeight >= 945
                              //     ? "50px"
                              //     : windowHeight >= 730
                              //     ? "45px"
                              //     : "40px",
                            }),
                          }}
                        />
                      </FormControl>
                    </Box>
                  </Grid>

                  {/* Mode */}
                  <Grid item xs={12} md={8} sm={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={LastPremiumDate}
                          alt="LastPremiumDate"
                          style={{ height: "18px", marginRight: "20px" }}
                        />
                        <Typography sx={{ minWidth: "100px", width: "145px" }}>
                          Mode
                        </Typography>
                      </Box>
                      <FormControl>
                        <RadioGroup
                          row
                          value={licData.mode}
                          onChange={handleModeChange}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yearly"
                            value="yearly"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="Half Yearly"
                            value="half-yearly"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="Quarterly"
                            value="quarterly"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Grid>

                  {/* Premium Month */}
                  <Grid item xs={12} md={6} sm={12} sx={{ mr: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={email}
                          alt="email"
                          style={{ height: "18px", marginRight: "18px" }}
                        />
                        <Typography sx={{ minWidth: "100px", width: "145px" }}>
                          Premium Month
                        </Typography>
                      </Box>
                      {Array.from({ length: numFields }).map((_, index) => (
                        <TextField
                          key={index}
                          fullWidth
                          variant="outlined"
                          sx={{ height: "45px", marginRight: "5px" }}
                          name={`premiumMonth${index + 1}`}
                          value={premiumMonths[index]}
                          onChange={handleInputChange(index)}
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          type="number"
                        />
                      ))}
                    </Box>
                  </Grid>

                  {/* value={licData.premiumMonth}
                      onChange={handleChange}
                      onInput={handleInput}
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }} */}

                  {/* Sum Assured */}
                  <Grid item xs={12} md={6} sm={12} sx={{ mr: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <PersonIcon sx={{ mr: 2 }} /> */}
                        <img
                          src={SumAssuredIcon}
                          alt="SumAssuredIcon"
                          style={{ height: "18px", marginRight: "20px" }}
                        />
                        <Typography sx={{ minWidth: "100px", width: "142px" }}>
                          Sum Assured
                        </Typography>
                      </Box>
                      <TextField
                        fullWidth
                        variant="outlined"
                        sx={{ height: "45px" }}
                        name="sumAssured"
                        value={licData.sumAssured}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>

                  {/* Premium Amount */}
                  <Grid item xs={12} md={6} sm={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <PersonIcon sx={{ mr: 2 }} /> */}
                        <img
                          src={email}
                          alt="email"
                          style={{ height: "18px", marginRight: "18px" }}
                        />
                        <Typography sx={{ minWidth: "100px", width: "145px" }}>
                          Premium Amount
                        </Typography>
                      </Box>
                      <TextField
                        fullWidth
                        variant="outlined"
                        sx={{ height: "45px" }}
                        name="premiumAmount"
                        value={licData.premiumAmount}
                        onChange={handleChange}
                        type="number"
                      />
                    </Box>
                  </Grid>

                  {/* Policy Number */}
                  <Grid item xs={12} md={6} sm={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <PersonIcon sx={{ mr: 2 }} /> */}
                        <img
                          src={policyNumber}
                          alt="policyNumber"
                          style={{ height: "18px", marginRight: "20px" }}
                        />
                        <Typography sx={{ minWidth: "100px", width: "145px" }}>
                          Policy Number
                        </Typography>
                      </Box>
                      <TextField
                        fullWidth
                        variant="outlined"
                        sx={{ height: "45px" }}
                        name="policyNumber"
                        value={licData.policyNumber}
                        onChange={handleChange}
                        // type="number"
                      />
                    </Box>
                  </Grid>

                  {/* Remarks */}
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <Home sx={{ mr: 2 }} /> */}
                        <img
                          src={remarkIcon}
                          alt="remarkIcon"
                          style={{ height: "20px", marginRight: "20px" }}
                        />
                        <Typography sx={{ minWidth: "100px", width: "140px" }}>
                          Remarks
                        </Typography>
                      </Box>
                      <TextField
                        fullWidth
                        multiline
                        rows={2}
                        variant="outlined"
                        name="remarks"
                        value={licData.remarks}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>

                  {/* Add Documents */}
                  <Grid
                    item
                    xs={12}
                    sm={7.5}
                    sx={{
                      // width: "60%",
                      // maxWidth: "60%",
                      marginTop: "20px",
                      padding: "20px",
                      display: "flex",
                      flexDirection: "column",
                      border: "1px solid #d9d9d9",
                      borderRadius: "5px",
                      ml: 2,
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <FileOpenIcon sx={{ mr: 2 }} />
                      <Typography sx={{ minWidth: "100px" }}>
                        Documents
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        // width: "100%",
                        // maxWidth: "100%",
                        marginTop: "20px",
                        padding: "10px",
                        border: "1px solid #3BD6154D",
                        borderRadius: "5px",
                        display: "flex",
                        overflowX: "auto", // Enable horizontal scrolling
                      }}
                    >
                      <Box className="box1" style={{ display: "flex" }}>
                        {documents.length === 0 ? (
                          <div>
                            No documents available. Please upload a document.
                          </div>
                        ) : (
                          documents.map((document, index) => (
                            <Box
                              key={index}
                              className="document"
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                margin: "5px",
                                height: "95px",
                              }}
                            >
                              <TextField
                                value={document.editName}
                                onChange={(e) =>
                                  handleEditNameChange(index, e.target.value)
                                }
                                onBlur={() => handleSaveNameChange(index)}
                                sx={{
                                  width: "140px",
                                  height: "140px",
                                }}
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <a
                                  href={document.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View
                                </a>
                                <button
                                  onClick={() => handleRemoveDocument(index)}
                                  style={{ background: "#d9d9d9" }}
                                >
                                  <img
                                    src={close}
                                    alt="close"
                                    style={{ width: "22px" }}
                                  />
                                </button>
                              </Box>
                            </Box>
                          ))
                        )}
                      </Box>
                    </Box>
                    <Box
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <Button
                        style={{
                          padding: "10px 0px",
                          background: "#3BD615",
                          width: "200px",
                          borderRadius: "5px",
                          fontSize: "15px",
                          color: "white",
                        }}
                        onClick={handleUploadClick}
                      >
                        <img
                          src={uploadIcon}
                          style={{ height: "20px", paddingRight: "10px" }}
                          alt="Upload"
                        />
                        Upload Document
                      </Button>
                      <input
                        type="file"
                        id="fileInput"
                        style={{ display: "none" }}
                        name="documents"
                        onChange={handleChange}
                        multiple
                        hidden
                      />
                    </Box>
                  </Grid>
                </Grid>

                <div className="add-btn" style={{ paddingBottom: "150px" }}>
                  <Button type="submit" disabled={submitting}>
                    {submitting ? <CircularProgress size={24} sx={{color:"white"}} /> : "Add"}
                  </Button>
                </div>
              </form>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default NewLicPolicy;
