import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../components/Sidebar";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
// import Navbar from "../../Navbar/Navbar";
import Select from "react-select";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  where,
  updateDoc,
  serverTimestamp,
  onSnapshot,
} from "firebase/firestore";
import { Visibility as VisibilityIcon } from "@material-ui/icons";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {
  Button,
  useMediaQuery,
  useTheme,
  TextField,
  DialogActions,
  DialogTitle,
  DialogContent,
  Dialog,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  ListItemText,
  ListItem,
  List,
  TableRow,
  TableCell,
  Skeleton,
  TableBody,
  Paper,
} from "@mui/material";
import { query } from "firebase/database";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  styled,
  alpha,
  createTheme,
  ThemeProvider,
} from "@mui/material/styles";
import MetaTags from "react-meta-tags";
import MuiDatatables from "mui-datatables";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { MenuItem } from "react-pro-sidebar";
import { Add, Delete } from "@mui/icons-material";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "@firebase/storage";
import { storage } from "../../components/utils/firebase";
import Navbar from "../../components/Navbar/Navbar";
import CustomSearchRender from "../../components/CustomSearchRender";
import SkeletonLoader from "../../components/SkeletonLoader";

const CustomMuiPaper = styled(Paper)({
  display: "flex",
  flexDirection: "column",
  height: "auto",
  maxHeight: "80vh",
  overflow: "auto",
});

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          height: "100%",
          // overflow: 'auto',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          flex: "1 1 auto", // Allow the table container to grow and shrink
        },
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          marginTop: "auto", // This ensures the footer stays at the bottom
        },
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          height: "20px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: '"Montserrat", sans-serif',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
          padding: "0px",
          textAlign: "start",
          justifyContent: "start",
          fontWeight: 600,
        },
      },
    },
  },
  MuiTableCell: {
    root: {
      borderColor: "#d3d3d3",
      fontWeight: 600,
    },
    head: {
      background: "lightgrey",
      "&:not(:last-child)": {
        borderRight: [[1, "solid", "#c0c0c0"]],
      },
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const months = [
  { value: "01", label: "01" },
  { value: "02", label: "02" },
  { value: "03", label: "03" },
  { value: "04", label: "04" },
  { value: "05", label: "05" },
  { value: "06", label: "06" },
  { value: "07", label: "07" },
  { value: "08", label: "08" },
  { value: "09", label: "09" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
];

const PremiumMonthSelect = ({ index, value, onChange }) => (
  <Select
    options={months}
    value={months.find((option) => option.value === value) || ""}
    onChange={(selectedOption) =>
      onChange({
        target: {
          name: `premiumMonth[${index}].date`,
          value: selectedOption.value,
        },
      })
    }
    placeholder="Select Month"
    // styles={{
    //   control: (baseStyles, state) => ({
    //     ...baseStyles,
    //     backgroundColor: "#ffffff",
    //   }),
    // }}
  />
);

const AllLicPolicy = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [licpolicy, setLicpolicy] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [editedPolicy, setEditedPolicy] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const rows = 10; // Number of skeleton rows
  const columnsCount = 7; // Number of columns

  const db = getFirestore();

  const [clientId, setClientId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const storedClientId = sessionStorage.getItem("clientId");
    setClientId(storedClientId);
    if (storedClientId) {
      fetchPolicyData(storedClientId);
    } else {
      setLoading(false);
    }
  }, []);

  const fetchPolicyData = (id) => {
    // Retrieve the last fetch time from session storage
    const lastFetchTimeRaw = sessionStorage.getItem("localPolicyDataFetchTime");
    const lastFetchTime = lastFetchTimeRaw
      ? new Date(lastFetchTimeRaw)
      : new Date(0);

    const cachedPolicy =
      JSON.parse(sessionStorage.getItem("localPolicyData")) || [];

      console.log('cachedPolicy :>> ', cachedPolicy);
    setLicpolicy(cachedPolicy); // Update the state with cached data

    const docRef = doc(db, "clients", id);

    getDoc(docRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          const companyId = docSnap.data().companyId; // Get the company ID from the document

          const q = query(
            collection(db, "lic"),
            where("companyId", "==", companyId),
            where("updated_at", ">", lastFetchTime), // Filter for updates after last fetch time
            orderBy("name") // Order the results by name
          );

          const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const newLicData = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            const updatedPolicy = [...cachedPolicy];

            newLicData.forEach((newPolicy) => {
              const index = updatedPolicy.findIndex(
                (cached) => cached.id === newPolicy.id
              );
              if (index > -1) {
                updatedPolicy[index] = newPolicy;
              } else {
                updatedPolicy.push(newPolicy);
              }
            });

            sessionStorage.setItem(
              "localPolicyData",
              JSON.stringify(updatedPolicy)
            );
            sessionStorage.setItem(
              "localPolicyDataFetchTime",
              new Date().toISOString()
            );

            setLicpolicy(updatedPolicy);
            setLoading(false); // Set loading to false after data is loaded
          });

          return () => unsubscribe();
        } else {
          console.log("No such document!");
          setLoading(false); // Set loading to false if no document is found
        }
      })
      .catch((err) => {
        console.error("Error fetching document:", err);
        setError(err.message); // Set the error message in state
        setLoading(false); // Set loading to false if an error occurs
      });
  };
  // const fetchPolicyData = async (id) => {
  //   try {
  //     const lastFetchTimeRaw = sessionStorage.getItem(
  //       "localPolicyDataFetchTime"
  //     );
  //     const lastFetchTime = lastFetchTimeRaw
  //       ? new Date(lastFetchTimeRaw)
  //       : new Date(0);

  //     // Format lastFetchTime in the desired format
  //     const formattedLastFetchTime = lastFetchTime.toLocaleString("en-US", {
  //       month: "long",
  //       day: "numeric",
  //       year: "numeric",
  //       hour: "numeric",
  //       minute: "numeric",
  //       second: "numeric",
  //       hour12: true,
  //       timeZoneName: "short",
  //     });

  //     // Fetch cached customers
  //     const cachedPolicy = JSON.parse(sessionStorage.getItem("localPolicyData")) || [];
  //     setLicpolicy(cachedPolicy);

  //     const docRef = doc(db, "clients", id);
  //     const docSnap = await getDoc(docRef);

  //     if (docSnap.exists()) {
  //       const querySnapshot = await getDocs(
  //         query(
  //           collection(db, "lic"),
  //           where("companyId", "==", docSnap.data().companyId),
  //           where("updated_at", ">", lastFetchTime),
  //           orderBy("name")
  //         )
  //       );

  //       const newlicData = querySnapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));
  //        // Merge new and cached customers
  //       const updatedPolicy = [...cachedPolicy];

  //       newlicData.forEach((newPolicy) => {
  //         const index = updatedPolicy.findIndex(
  //           (cached) => cached.id === newPolicy.id
  //         );
  //         if (index > -1) {
  //           updatedPolicy[index] = newPolicy;
  //         } else {
  //           updatedPolicy.push(newPolicy);
  //         }
  //       });

  //       // Update local storage
  //       sessionStorage.setItem(
  //         "localPolicyData",
  //         JSON.stringify(updatedPolicy)
  //       );
  //       sessionStorage.setItem(
  //         "localPolicyDataFetchTime",
  //         new Date().toISOString()
  //       );

  //       setLicpolicy(updatedPolicy);
  //       // console.log("licData :>> ", licData);
  //     } else {
  //       console.log("No such document!");
  //     }
  //   } catch (err) {
  //     console.error("Error fetching document:", err);
  //     setError(err.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const [refName, setRefName] = useState("");

  const fetchNameByRefID = async (refID) => {
    if (!refID) return null;

    try {
      const docRef = doc(db, "customers", refID);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return docSnap.data().Personal?.name; // Adjust based on your Firestore structure
      } else {
        console.log("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error fetching name by refID: ", error);
      return null;
    }
  };

  const handleEditClick = (client) => {
    setSelectedPolicy(client);
    setEditedPolicy(client);
    setDialogOpen(true);
    // const LicPolicyCustomerId = client.customerId;
    // console.log('LicPolicyCustomerId :>> ', LicPolicyCustomerId);
    // setViewId(LicPolicyCustomerId);
  };

  const [hasNonNullNames, setHasNonNullNames] = useState(null);
  const [hasNonNullMonth, setHasNonNullMonth] = useState(null);
  const [viewId, setViewId] = useState(null);

  const handleIconClick = async (client, docUrl) => {
    setSelectedPolicy(client);
    setEditedPolicy(client);
    setDrawerOpen(true);

    const LicPolicyId = client.id;
    setViewId(LicPolicyId);

    setDocuments((prevDocuments) =>
      prevDocuments.filter((doc) => doc.url !== docUrl)
    );

    const refID = client.ref_by;

    // Fetch name by refID
    const refName = await fetchNameByRefID(refID);
    setRefName(refName);

    setHasNonNullMonth(
      client.premiumMonth &&
        client.premiumMonth.some((date) => date.date !== null)
    );

    setHasNonNullNames(
      client.familyMemberName &&
        client.familyMemberName.some((name) => name.name !== null)
    );
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedPolicy(null);
    setEditedPolicy(null);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedPolicy(null);
    setEditedPolicy(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedPolicy({ ...editedPolicy, [name]: value });
  };

  const addDynamicField = () => {
    setEditedPolicy({
      ...editedPolicy,
      dynamicFields: [
        ...editedPolicy.dynamicFields,
        { moneyBackAmount: "", moneyBackYear: null },
      ],
    });
  };

  // const handleInputChange = (e, index) => {
  //   const { name, value } = e.target;

  //   if (name.startsWith("premiumMonth")) {
  //     const updatedPremiumMonth = editedPolicy.premiumMonth.map((dateObj, i) =>
  //       i === index ? { ...dateObj, date: `${dateObj.date.split('-')[0]}-${value}-${dateObj.date.split('-')[2]}` } : dateObj
  //     );
  //     setEditedPolicy({ ...editedPolicy, premiumMonth: updatedPremiumMonth });
  //   } else {
  //     setEditedPolicy({ ...editedPolicy, [name]: value });
  //   }
  // };

  const removeDynamicField = (index) => {
    setEditedPolicy((prevData) => {
      const updatedDynamicFields = prevData.dynamicFields.filter(
        (_, i) => i !== index
      );
      return {
        ...prevData,
        dynamicFields: updatedDynamicFields,
      };
    });
  };

  const handleYearChange = (index, selectedOptions) => {
    const selectedYearValue = selectedOptions ? selectedOptions.value : null;

    setEditedPolicy((prevData) => {
      const updatedDynamicFields = [...prevData.dynamicFields];
      // console.log("updatedDynamicFields :>> ", updatedDynamicFields);
      const [year, month, day] = prevData.startDate.split("-");
      const updatedMoneyBackYear = selectedYearValue
        ? `${day}-${month}-${selectedYearValue}`
        : null;
      // console.log("updatedMoneyBackYear :>> ", updatedMoneyBackYear);

      updatedDynamicFields[index].moneyBackYear = updatedMoneyBackYear;

      const updatedData = {
        ...prevData,
        dynamicFields: updatedDynamicFields,
      };

      // console.log("updated licData :>> ", updatedData);
      return updatedData;
    });

    // console.log(
    //   "selectedOptions :>> ",
    //   selectedOptions ? selectedOptions.value : null
    // );
  };

  const startYear = 1940;
  const endYear = 2050;

  const generateYearOptions = () => {
    const years = [];
    for (let year = endYear; year >= startYear; year--) {
      years.push({ value: year, label: year.toString() });
    }
    return years;
  };

  // const handleDynamicFieldChange = (index, event) => {
  //   const { name, value } = event.target;
  //   const updatedDynamicFields = [...editedPolicy.dynamicFields];
  //   updatedDynamicFields[index][name] = value;
  //   setEditedPolicy({ ...editedPolicy, dynamicFields: updatedDynamicFields });
  // };

  const handleDynamicFieldChange = (index, event) => {
    const { name, value } = event.target;
    setEditedPolicy((prevData) => {
      const updatedDynamicFields = [...editedPolicy.dynamicFields];
      updatedDynamicFields[index][name] = value;
      return {
        ...editedPolicy,
        dynamicFields: updatedDynamicFields,
      };
    });
  };

  const [numFields, setNumFields] = useState(1);

  const handleModeChange = (event) => {
    // const value = event.target.value;
    // // console.log("value :>> ", value);
    // setEditedPolicy((prevData) => ({ ...prevData, mode: value }));
    // switch (value) {
    //   case "yearly":
    //     setNumFields(1);
    //     break;
    //   case "half-yearly":
    //     setNumFields(2);
    //     break;
    //   case "quarterly":
    //     setNumFields(4);
    //     break;
    //   default:
    //     setNumFields(1);
    // }
    const value = event.target.value;
    setEditedPolicy((prevData) => ({ ...prevData, mode: value }));

    let newNumFields;
    switch (value) {
      case "yearly":
        newNumFields = 1;
        break;
      case "half-yearly":
        newNumFields = 2;
        break;
      case "quarterly":
        newNumFields = 4;
        break;
      default:
        newNumFields = 1;
    }

    setNumFields(newNumFields);

    setEditedPolicy((prevData) => {
      const newPremiumMonth = prevData.premiumMonth
        .slice(0, newNumFields)
        .concat(
          Array.from(
            { length: newNumFields - prevData.premiumMonth.length },
            () => ({ date: "" })
          )
        );

      return {
        ...prevData,
        premiumMonth: newPremiumMonth,
      };
    });

    // setEditedPolicy((prevData) => ({
    //   ...prevData,
    //   premiumMonth: Array.from({ length: numFields }, (_, i) => ({
    //     date: prevData.premiumMonth[i]?.date || "",
    //   })),
    // }));
  };

  const handleInputMonthChange = (e, index) => {
    const { name, value } = e.target;
    // console.log("value :>> ", value);

    if (name.startsWith("premiumMonth")) {
      // Update the premiumMonth array directly
      setEditedPolicy((prevPolicy) => {
        const [year, monthStartDate, dayStartDate] =
          editedPolicy.startDate.split("-");

        const date = new Date(
          year,
          monthStartDate - 1 + parseInt(value),
          dayStartDate
        );
        // console.log("date :>> ", date);

        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;

        // console.log("formattedDate :>> ", formattedDate);

        const updatedPremiumMonth = prevPolicy.premiumMonth.map((dateObj, i) =>
          i === index
            ? {
                ...dateObj,
                date: formattedDate,
              }
            : dateObj
        );
        return { ...prevPolicy, premiumMonth: updatedPremiumMonth };
      });
    } else {
      // Handle other input changes directly
      setEditedPolicy({ ...editedPolicy, [name]: value });
    }
  };

  // useEffect(() => {
  // }, []);

  const handleEditSubmit = async () => {
    try {
      setLoading(true);
      const docRef = doc(db, "lic", editedPolicy.id);
      await updateDoc(docRef, {
        ...editedPolicy,
        updated_at: serverTimestamp(),
      });
      setLicpolicy((prevPolicies) =>
        prevPolicies.map((policy) =>
          policy.id === editedPolicy.id ? editedPolicy : policy
        )
      );
      toast.success("LIC Data Update successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setDialogOpen(false);
    } catch (error) {
      console.error("Error updating document: ", error);
    } finally {
      setLoading(false);
    }
  };

  const [open, setOpen] = useState(false);

  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    if (selectedPolicy?.documents) {
      setDocuments(selectedPolicy?.documents);
    }
  }, [selectedPolicy]);

  // const handleDelete = async (docUrl) => {
  //   try {
  //     const docRef = doc(db, "lic", viewId); // Adjust the path to your Firestore collection
  //     const policyDoc = await getDoc(docRef);

  //     if (policyDoc.exists()) {
  //       const updatedDocuments = policyDoc
  //         .data()
  //         .documents.filter((doc) => doc.url !== docUrl);

  //       await updateDoc(docRef, {
  //         documents: updatedDocuments,
  //       });

  //       setDocuments((prevDocuments) =>
  //         prevDocuments.filter((doc) => doc.url !== docUrl)
  //       );

  //       console.log("Document successfully deleted!");
  //     } else {
  //       console.log("No such document!");
  //     }
  //   } catch (error) {
  //     console.error("Error removing document: ", error);
  //   }
  // };

  const handleDelete = async (docUrl) => {
    try {
      setLoading(true);

      // Reference to the Firestore document
      const docRef = doc(db, "lic", selectedPolicy.id);
      const licDoc = await getDoc(docRef);

      if (licDoc.exists()) {
        // Filter out the document that needs to be deleted
        const updatedDocuments = licDoc
          .data()
          .documents.filter((doc) => doc.url !== docUrl);

        // Update Firestore with the remaining documents
        await updateDoc(docRef, {
          documents: updatedDocuments,
        });

        // Delete the document from Firebase Storage
        const fileName = docUrl; // Extract file name from URL
        console.log("fileName :>> ", fileName);

        const documentRef = ref(storage, fileName);
        console.log("documentRef :>> ", documentRef);

        await deleteObject(documentRef).catch((error) => {
          console.error("Failed to delete document from storage:", error);
        });

        // Update state to reflect the deletion
        setDocuments((prevDocuments) =>
          prevDocuments.filter((doc) => doc.url !== docUrl)
        );

         // Update state to reflect the deletion
         setLicpolicy((prevLicpolicy) =>
          prevLicpolicy.map((policy) => {
            if (policy.id === selectedPolicy.id) {
              return {
                ...policy,
                documents: updatedDocuments,
              };
            }
            return policy;
          })
        );

        // Update the session storage to reflect the deletion
        const updatedPolicy = JSON.parse(sessionStorage.getItem("localPolicyData")) || [];
        const policyIndex = updatedPolicy.findIndex((policy) => policy.id === selectedPolicy.id);
        if (policyIndex > -1) {
          updatedPolicy[policyIndex].documents = updatedDocuments;
          sessionStorage.setItem("localPolicyData", JSON.stringify(updatedPolicy));
        }

        console.log("Document successfully deleted!");
        toast.success("Document successfully deleted!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        console.log("No such document!");
        toast.error("No such document!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error("Error removing document: ", error);
      toast.error("Error removing document. Please try again.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setLoading(false);
    }
  };

  // upload new documents
  const [newDocuments, setNewDocuments] = useState([]);
  const fileInputRef = useRef(null);

  const fetchDocuments = async (licId) => {
    try {
      const docRef = doc(db, "lic", licId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const licDocumentsData = docSnap.data();
        console.log("licDocumentsData :>> ", licDocumentsData.documents);
        // setOldDocuments(licDocumentsData.Personal?.documents || []);
        setDocuments(licDocumentsData.documents || []); // Set existing documents array
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const handleClickOpen = async (customer) => {
    document.getElementById("fileInput").click();
    const custId = customer.id;
    setViewId(custId);
    await fetchDocuments(custId);
  };

  const handleFileChange = (e) => {
    const { name, value, files } = e.target;
    setOpen(true);
    if (name === "Newdocuments") {
      const filesArray = Array.from(e.target.files).map((file) => ({
        // file: file,
        name: file.name,
        url: URL.createObjectURL(file),
        editName: file.name,
      }));
      // console.log("filesArray :>> ", filesArray);
      setNewDocuments((prevDocuments) => [...prevDocuments, ...filesArray]);
      setDocuments((prevDocuments) => [...prevDocuments]);
      // console.log("documents :>> ", documents);
    }
  };

  const handleDocumentsUpload = async () => {
    try {
      setLoading(true);

      const uploadedDocuments = [];
      for (let i = 0; i < newDocuments.length; i++) {
        const documentRef = ref(
          storage,
          `LicDocument/${clientId}/${viewId}/${newDocuments[i].name}`
        );
        // console.log("documentRef :>> ", documentRef);
        await uploadBytes(documentRef, newDocuments[i].file);
        const documentURL = await getDownloadURL(documentRef);
        // console.log("documentURL :>> ", documentURL);
        uploadedDocuments.push({
          name: newDocuments[i].name,
          url: documentURL,
        });
      }

      const mergedDocuments = [...documents, ...uploadedDocuments];
      // console.log("mergedDocuments :>> ", mergedDocuments);

      const docRef = doc(db, "lic", viewId);
      await updateDoc(docRef, {
        // editCustomer,
        documents: mergedDocuments,
        updated_at: serverTimestamp(),
      });

      toast.success("Documents uploaded successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setOpen(false);
    } catch (error) {
      console.error("Error uploading documents: ", error);
      toast.error("Failed to upload documents. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleEditNameChange = (index, newName) => {
    setNewDocuments((prevDocuments) => {
      const updatedDocuments = [...prevDocuments];
      updatedDocuments[index].editName = newName;
      return updatedDocuments;
    });
  };

  const handleSaveNameChange = (index) => {
    setNewDocuments((prevDocuments) => {
      const updatedDocuments = [...prevDocuments];
      updatedDocuments[index].name = updatedDocuments[index].editName;
      return updatedDocuments;
    });
  };

  const handleDeleteDocument = (index) => {
    setNewDocuments((prevDocuments) => {
      const updatedDocuments = [...prevDocuments];
      updatedDocuments.splice(index, 1);
      return updatedDocuments;
    });
  };

  const handleClose = () => {
    setOpen(false);
    setNewDocuments([]);
  };

  const flattenData = () => {
    return licpolicy.map((item) => ({
      id: item.id,
      name: item.name || "-",
      policyName: item.policyName || "-",
      policyNumber: item.policyNumber || "-",
      startDate: item.startDate || "-",
      remarks: item.remarks || "-",
    }));
  };

  const flattenedLICPolicy = flattenData(licpolicy);

  const columns = [
    {
      name: "no",
      label: "No",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return loading ? (
            <Skeleton variant="rectangular" width="100%" height={40} />
          ) : (
            <div
              style={{
                width: "10px",
                maxWidth: "30px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                height: "20px", // Set row height
                lineHeight: "20px", // Center content vertically
                textAlign: "center",
              }}
            >
              {tableMeta.rowIndex + 1}
            </div>
          );
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value) =>
          loading ? (
            <Skeleton variant="rectangular" width="100%" height={40} />
          ) : (
            <div
              style={{
                maxWidth: "150px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                height: "20px", // Set row height
                lineHeight: "20px", // Center content vertically
              }}
            >
              {capitalizeFirstLetter(value)}
            </div>
          ),
      },
    },
    {
      name: "startDate",
      label: "Start Date",
      options: {
        customBodyRender: (value) =>
          loading ? (
            <Skeleton variant="rectangular" width="100%" height={40} />
          ) : (
            <div
              style={{
                maxWidth: "350px",
                width: "90px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                height: "20px", // Set row height
                lineHeight: "20px", // Center content vertically
              }}
            >
              {value}
            </div>
          ),
      },
    },
    {
      name: "policyName",
      label: "Policy Name",
      options: {
        customBodyRender: (value) =>
          loading ? (
            <Skeleton variant="rectangular" width="100%" height={40} />
          ) : (
            <div
              style={{
                maxWidth: "150px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                height: "20px", // Set row height
                lineHeight: "20px", // Center content vertically
              }}
            >
              {capitalizeFirstLetter(value)}
            </div>
          ),
      },
    },

    {
      name: "policyNumber",
      label: "Policy Number",
      options: {
        customBodyRender: (value) =>
          loading ? (
            <Skeleton variant="rectangular" width="100%" height={40} />
          ) : (
            <div
              style={{
                maxWidth: "150px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                height: "20px", // Set row height
                lineHeight: "20px", // Center content vertically
              }}
            >
              {capitalizeFirstLetter(value)}
            </div>
          ),
      },
    },
    {
      name: "remarks",
      label: "Remarks",
      options: {
        customBodyRender: (value) =>
          loading ? (
            <Skeleton variant="rectangular" width="100%" height={40} />
          ) : (
            <div
              style={{
                maxWidth: "200px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                height: "20px", // Set row height
                lineHeight: "20px", // Center content vertically
              }}
            >
              {capitalizeFirstLetter(value)}
            </div>
          ),
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        // filter: false,
        // sort: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          loading ? (
            <Skeleton variant="rectangular" width="100%" height={40} />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                // alignItems: "center",
                // justifyContent:"center"
              }}
            >
              <IconButton
                sx={{ height: "20px" }}
                onClick={() => handleIconClick(licpolicy[tableMeta.rowIndex])}
              >
                <VisibilityIcon />
              </IconButton>
              <IconButton
                sx={{ height: "20px" }}
                onClick={() => handleEditClick(licpolicy[tableMeta.rowIndex])}
              >
                <EditIcon />
              </IconButton>

              <IconButton
                sx={{ height: "20px" }}
                onClick={() => handleClickOpen(licpolicy[tableMeta.rowIndex])}
              >
                <Add />
                <input
                  name="Newdocuments"
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  multiple
                />
              </IconButton>
            </div>
          ),
      },
    },
  ];

  const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const TableRowsLoader = ({ rows = 1, columns = 6 }) => {
    return (
      <TableBody>
        {[...Array(rows)].map((_, rowIndex) => (
          <TableRow key={rowIndex}>
            {[...Array(columns)].map((_, colIndex) => (
              <TableCell key={colIndex}>
                <Skeleton variant="rectangular" height={30} />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    );
  };

  const options = {
    filterType: "checkbox",
    responsive: "standard",
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: loading ? (
          <Skeleton rows={rows} columns={columnsCount} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    setCellProps: (row) => {
      return {
        style: { height: "20px" }, // Set a custom class for each row
      };
    },
  };

  // const [familyMemberField, setFamilyMemberField] = useState([]);
  // const [familyMemberData, setFamilyMemberData] = useState([]);
  // const [familyMemberName, setFamilyMemberName] = useState([]);

  // useEffect(() => {
  //   const storedId = viewId;
  //   // console.log("storedId :>> ", storedId);

  //   if (storedId) {
  //     const fetchData = async () => {
  //       const docRef = doc(db, "customers", storedId);
  //       const docSnap = await getDoc(docRef);

  //       if (docSnap.exists()) {
  //         const familyMembers = docSnap.data().Personal?.family_member;

  //         if (familyMembers) {
  //           const familyMemberPromises = familyMembers.map(async (memberId) => {
  //             const memberDocRef = doc(db, "customers", memberId.id);
  //             const memberDocSnap = await getDoc(memberDocRef);

  //             if (memberDocSnap.exists()) {
  //               return {
  //                 id: memberId.id,
  //                 ...memberDocSnap.data(),
  //               };
  //             } else {
  //               console.log(
  //                 `No document found for family member ID: ${memberId.id}`
  //               );
  //               return {
  //                 id: memberId.id,
  //                 name: "Unknown",
  //               };
  //             }
  //           });

  //           const familyMemberName = await Promise.all(familyMemberPromises);
  //           setFamilyMemberName(familyMemberName);
  //           console.log("familyMemberData :>> ", familyMemberName);
  //         } else {
  //           console.log("No family members found in the document");
  //           setFamilyMemberName([]);
  //         }
  //       } else {
  //         console.log("No such document!");
  //         setFamilyMemberName([]);
  //       }
  //     };

  //     fetchData();
  //   }
  // }, [familyMemberData]);

  // const familyMemberOptions = familyMemberName.map((member) => ({
  //   label: member.Personal.name,
  //   value: member.id,
  // }));

  // console.log('familyMemberOptions :>> ', familyMemberOptions);

  return (
    <>
      {/* <Navbar /> */}
      <MetaTags>
        <title>All LIC Policy</title>
      </MetaTags>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <Sidebar />
          <Box sx={{ flex: 1, backgroundColor: "#F4F7F9", overflow: "hidden" }}>
            <Navbar />
            <Box
              // component="main"
              sx={{
                p: 2,
                // height: "100vh",
              }}
            >
              <Box sx={{ padding: "1%" }}>
                <Box>
                  <Box
                    borderRadius="17px"
                    sx={{ boxShadow: "0px 9px 20px rgba(46, 35, 94, 0.07);" }}
                    overflow="hidden"
                  >
                    <CustomMuiPaper>
                      <MuiDatatables
                        data={loading ? [] : flattenedLICPolicy}
                        columns={columns}
                        options={{
                          ...options,
                          searchOpen: true,
                          searchAlwaysOpen: true,
                          searchPlaceholder: "Search keyword",
                          customSearchRender: (searchText, handleSearch) => {
                            return (
                              <CustomSearchRender
                                searchText={searchText}
                                onSearch={handleSearch}
                              />
                            );
                          },
                        }}
                      />
                    </CustomMuiPaper>
                    {/* {loading && <SkeletonLoader rows={rows} columns={columnsCount} />} */}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle style={{ fontFamily: '"Montserrat", sans-serif' }}>
            Update LIC Policy Details
          </DialogTitle>
          <DialogContent>
            {selectedPolicy && (
              <Box sx={{ padding: 1 }}>
                <Divider />
                <Box sx={{ marginY: 2 }}>
                  <TextField
                    label="Policy Holder Name"
                    name="name"
                    value={editedPolicy?.name || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  {/* <TextField
                  label="Policy Holder Name"
                  name="name"
                  value={editedPolicy?.familyMemberName.map( (item) => item.name) || ""}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                /> */}

                  <TextField
                    type="date"
                    label="Start Date"
                    name="startDate"
                    value={editedPolicy?.startDate || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    type="date"
                    label="Policy Maturity Date"
                    name="policyMaturityDate"
                    value={editedPolicy?.policyMaturityDate || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    type="date"
                    label="Last Premium Date"
                    name="lastPremiumDate"
                    value={editedPolicy?.lastPremiumDate || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  {editedPolicy?.dynamicFields &&
                    editedPolicy?.dynamicFields.length > 0 &&
                    editedPolicy.dynamicFields?.map((field, index) => (
                      <Grid
                        container
                        spacing={2}
                        key={index}
                        sx={{ marginTop: "-20px" }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={5}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <TextField
                            label="Money Back Amount"
                            name={`moneyBackAmount`}
                            value={field.moneyBackAmount || ""}
                            onChange={(e) => handleDynamicFieldChange(index, e)}
                            fullWidth
                            margin="normal"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={5}
                          sx={{ display: "flex", alignItems: "center", mt: 1 }}
                        >
                          <FormControl sx={{ width: "100%" }}>
                            <Select
                              id="fname"
                              name="name"
                              placeholder=" "
                              options={generateYearOptions()}
                              value={
                                field.moneyBackYear
                                  ? {
                                      value: field.moneyBackYear,
                                      label: field.moneyBackYear.toString(),
                                    }
                                  : null
                              }
                              onChange={(selectedOptions) =>
                                handleYearChange(index, selectedOptions)
                              }
                              isClearable
                              styles={{
                                container: (provided) => ({
                                  ...provided,
                                }),
                                control: (provided) => ({
                                  ...provided,
                                  height: "52px",
                                }),
                              }}
                            />
                          </FormControl>
                        </Grid>

                        {index > 0 && (
                          <Grid item xs={12} md={0.5} sm={1}>
                            <button
                              type="button"
                              onClick={() => removeDynamicField(index)}
                              style={{
                                background: "none",
                                border: "none",
                                cursor: "pointer",
                                marginTop: "5px",
                              }}
                            >
                              <RemoveCircleOutlineIcon
                                style={{ color: "#FF0000", fontSize: "36px" }}
                              />
                            </button>
                          </Grid>
                        )}

                        <Grid
                          item
                          xs={12}
                          sm={1}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <button
                            type="button"
                            onClick={addDynamicField}
                            disabled={
                              !field.moneyBackAmount ||
                              field.moneyBackYear === null
                            }
                            style={{
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                              marginTop: "5px",
                            }}
                          >
                            <AddCircleOutlineIcon
                              style={{ color: "#3BD615", fontSize: "36px" }}
                            />
                          </button>
                        </Grid>
                      </Grid>
                    ))}

                  <TextField
                    label="Policy Name"
                    name="policyName"
                    value={editedPolicy?.policyName || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Plan Number"
                    name="planNumber"
                    value={editedPolicy?.planNumber || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Policy Term"
                    name="policyTerm"
                    value={editedPolicy?.policyTerm || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Premium Paying Term"
                    name="premiumPayingTerm"
                    value={editedPolicy?.premiumPayingTerm || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Policy Number"
                    name="policyNumber"
                    value={editedPolicy?.policyNumber || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  {/* <TextField
                  label="Mode"
                  name="mode"
                  value={editedPolicy?.mode || ""}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                /> */}

                  <Grid item xs={12} md={4} sm={4}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography sx={{ minWidth: "100px" }}>
                          Mode -
                        </Typography>
                      </Box>
                      <FormControl>
                        <RadioGroup
                          row
                          value={editedPolicy?.mode}
                          onChange={handleModeChange}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yearly"
                            value="yearly"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="Half Yearly"
                            value="half-yearly"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="Quarterly"
                            value="quarterly"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    {Array.from({ length: numFields }).map &&
                      editedPolicy.premiumMonth.map(
                        (premiumMonthObj, index, value) => (
                          <>
                            <PremiumMonthSelect
                              key={index}
                              index={index}
                              label={`Premium Month ${index + 1}`}
                              name={`editedPolicy.premiumMonth[${index}].date`}
                              value={
                                editedPolicy?.premiumMonth[index]?.date?.split(
                                  "-"
                                )[1] || " "
                              }
                              onChange={(e) => handleInputMonthChange(e, index)}
                              // fullWidth
                              margin="normal"
                              styles={{
                                container: (provided) => ({
                                  ...provided,
                                  marginTop: "20px",
                                }),
                                control: (provided) => ({
                                  ...provided,
                                  height: "50x",
                                }),
                              }}
                            />
                            {/* <p>{premiumMonthObj.date}</p> */}
                          </>
                        )
                      )}
                  </Grid>

                  {/* <Grid item xs={12} md={4} sm={4}>
                </Grid> */}

                  {/* {Array.from({ length: numFields }).map((_, index) => (
                  <PremiumMonthSelect
                    key={index}
                    index={index}
                    value={editedPolicy?.premiumMonth[index]?.date?.split("-")[1] || ""}
                    onChange={(e) => handleInputMonthChange(e, index)}
                  />
                ))} */}

                  {/* {Array.from({ length: numFields }).map((_, index) => (
                  <TextField
                    key={index}
                    label={`Premium Month ${index + 1}`}
                    name={`premiumMonth[${index}].date`}
                    value={
                      editedPolicy.premiumMonth[index]?.date.split("-")[1] || ""
                    }
                    onChange={(e) => handleInputChange(e, index)}
                    fullWidth
                    margin="normal"
                  />
                ))} */}

                  <TextField
                    label="Sum Assured"
                    name="sumAssured"
                    value={editedPolicy?.sumAssured || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Premium Amount"
                    name="premiumAmount"
                    value={editedPolicy?.premiumAmount || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Remarks"
                    name="remarks"
                    value={editedPolicy?.remarks || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />
                </Box>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleDialogClose}
              variant="outlined"
              sx={{ border: "1px solid #57dd36", color: "#57dd36", p: 1 }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleEditSubmit}
              variant="contained"
              disabled={loading} // Assuming `loading` state indicates loading state
              // startIcon={loading ? <CircularProgress size={24} /> : null}
              sx={{ backgroundColor: "#57dd36", p: 1 }}
            >
              {loading ? <CircularProgress size={24} /> : "Save Changes"}
            </Button>
          </DialogActions>
        </Dialog>

        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={handleDrawerClose}
          sx={{ "& .MuiDrawer-paper": { width: 460, padding: 2 } }}
        >
          {selectedPolicy && (
            <Box sx={{ padding: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  onClick={handleDrawerClose}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="48"
                      d="M328 112L184 256l144 144"
                    />
                  </svg>
                </Typography>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ fontFamily: '"Montserrat", sans-serif' }}
                >
                  LIC Policy Details
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ marginY: 2 }}>
                {selectedPolicy.name && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Policy Holder Name:</h5>{" "}
                    {capitalizeFirstLetter(selectedPolicy.name)}
                  </Typography>
                )}

                {selectedPolicy.startDate && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Start Date:</h5> {selectedPolicy.startDate}
                  </Typography>
                )}

                {selectedPolicy.policyMaturityDate && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Policy Maturity Date:</h5>{" "}
                    {selectedPolicy.policyMaturityDate}
                  </Typography>
                )}

                {selectedPolicy.lastPremiumDate && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Last Premium Date:</h5> {selectedPolicy.lastPremiumDate}
                  </Typography>
                )}

                {selectedPolicy.dynamicFields &&
                  selectedPolicy.dynamicFields.length > 0 &&
                  selectedPolicy.dynamicFields.map((field, index) => (
                    <>
                      <Typography
                        key={index}
                        variant="body1"
                        sx={{ margin: "10px 0px" }}
                      >
                        {field.moneyBackAmount ? (
                          <>
                            <h5>Money Back Amount:</h5>
                            {field.moneyBackAmount}
                          </>
                        ) : (
                          <></>
                        )}
                      </Typography>
                      <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                        {field.moneyBackYear ? (
                          <>
                            <h5>Money Back Year:</h5>
                            {field.moneyBackYear}
                          </>
                        ) : (
                          <></>
                        )}
                      </Typography>
                    </>
                  ))}

                {selectedPolicy.policyName && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Policy Name:</h5>{" "}
                    {capitalizeFirstLetter(selectedPolicy.policyName)}
                  </Typography>
                )}

                {selectedPolicy.planNumber && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Plan Number:</h5> {selectedPolicy.planNumber}
                  </Typography>
                )}

                {selectedPolicy.policyTerm && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Policy Term:</h5> {selectedPolicy.policyTerm}
                  </Typography>
                )}

                {selectedPolicy.premiumPayingTerm && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Premium Paying Term:</h5>{" "}
                    {selectedPolicy.premiumPayingTerm}
                  </Typography>
                )}

                {selectedPolicy.policyNumber && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Policy Number:</h5> {selectedPolicy.policyNumber}
                  </Typography>
                )}

                {/* <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                <h5>Ref By:</h5> {selectedPolicy.ref_by}
              </Typography> */}

                {selectedPolicy.mode && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Mode:</h5> {capitalizeFirstLetter(selectedPolicy.mode)}
                  </Typography>
                )}

                {/* <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                <h5>Premium Month:</h5>
                {selectedPolicy.premiumMonth &&
                  selectedPolicy.premiumMonth.map((date, index) => (
                    <span key={index}>
                      {date.date}, <br />
                    </span>
                  ))}
              </Typography> */}

                {hasNonNullMonth && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Premium Month:</h5>
                    {selectedPolicy.premiumMonth &&
                      selectedPolicy.premiumMonth.map((date, index) => {
                        if (date.date !== "NaN-NaN-NaN") {
                          return (
                            <span key={index}>
                              {date.date}, <br />
                            </span>
                          );
                        }
                        return null; // Skip rendering if date.date is "NaN-NaN-NaN"
                      })}
                  </Typography>
                )}

                {selectedPolicy.sumAssured && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Sum Assured:</h5> {selectedPolicy.sumAssured}
                  </Typography>
                )}

                {selectedPolicy.premiumAmount && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Premium Amount:</h5> {selectedPolicy.premiumAmount}
                  </Typography>
                )}

                {selectedPolicy.policyNumber && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Policy Number:</h5> {selectedPolicy.policyNumber}
                  </Typography>
                )}

                {selectedPolicy.ref_by && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Ref By:</h5>{" "}
                    {capitalizeFirstLetter(refName || selectedPolicy.ref_by)}
                  </Typography>
                )}

                {selectedPolicy.remarks && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Remarks:</h5>{" "}
                    {capitalizeFirstLetter(selectedPolicy.remarks)}
                  </Typography>
                )}

                {hasNonNullNames && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Family Member Name:</h5>
                    {selectedPolicy.familyMemberName &&
                      selectedPolicy.familyMemberName.map((name, index) => {
                        if (name.name !== null) {
                          return (
                            <span key={index}>
                              {name.name} <br />
                            </span>
                          );
                        }
                        return null; // Skip rendering if date.date is "NaN-NaN-NaN"
                      })}
                  </Typography>
                )}

                {selectedPolicy.documents &&
                  selectedPolicy.documents.length > 0 && (
                    <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                      <h5>Docments:</h5>
                      {documents.map((docName) => (
                        <div
                          key={docName.url}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <a
                            href={docName.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            {docName.name}, <br />
                          </a>
                          <p
                            onClick={() => handleDelete(docName.url)}
                            sx={{ marginLeft: "10px" }}
                          >
                            <Delete sx={{ color: "#d60000" }} />
                          </p>
                        </div>
                      ))}
                    </Typography>
                  )}
              </Box>
            </Box>
          )}
        </Drawer>

        <Dialog open={open}>
          <DialogTitle>Documents</DialogTitle>
          <DialogContent>
            <List>
              {newDocuments.map((doc, index) => (
                <ListItem
                  key={index}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <ListItemText
                    primary={
                      <TextField
                        value={doc.editName}
                        onChange={(e) =>
                          handleEditNameChange(index, e.target.value)
                        }
                        variant="outlined"
                        size="small"
                        onBlur={() => handleSaveNameChange(index)}
                      />
                    }
                  />
                  <IconButton
                    edge="end"
                    onClick={() => handleDeleteDocument(index)}
                  >
                    <Delete />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
            <Button
              onClick={handleDocumentsUpload}
              color="primary"
              disabled={loading}
            >
              {loading ? "Uploading..." : "Upload"}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default AllLicPolicy;
