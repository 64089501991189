import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import { FiEdit } from "react-icons/fi";
import { Input } from "@mui/material";
import Navbar from "../../components/Navbar/Navbar";
import {
  Box,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

// import Navbar from '../../Navbar/Navbar'

const GstRates = () => {
  const [open, setOpen] = React.useState(false);
  const [size, setSize] = React.useState();
  const [isEditing, setIsEditing] = useState(false);
  const [percentage, setPercentage] = useState(50);
  //for first premium
  const [editedFirstPercentage, setEditedFirstPercentage] = useState(50);

  const handleEditClick = () => {
    setIsEditing(true);
    setEditedFirstPercentage(percentage);
  };

  // const handleDialogClose = () => {
  //     setIsEditing(false);
  // };

  const handleInputChange = (event) => {
    setEditedFirstPercentage(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setPercentage(editedFirstPercentage);
    setIsEditing(false);
    handleEditClick();
    handleClose();
  };

  //first premium ends

  const handleOpen = (value) => {
    setSize(value);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const fp = "First Premium";
  // const sp = "Second Premium"
  // const tp = "Term Plan"

  return (
    <>
      <Box sx={{ display: "flex" }}>
        {/* new sidebar start */}
        <Sidebar />
        <Box sx={{ flex: 1 }}>
          <Navbar />
          <Box sx={{ p: 2 }}>
            {/* <h2>Manage GST Rates</h2> */}
            <Box
              sx={{
                width: "40%",
                // height: "500px",
                // marginTop: "10vh",
                // mx: "auto",
              }}
            >
              <Typography variant="h5" gutterBottom>
                    LIC Rates
                  </Typography>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>{fp}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <TextField
                        type="number"
                        value={percentage}
                        disabled
                        variant="outlined"
                        inputProps={{ min: 0, max: 100 }}
                        placeholder="in %"
                        sx={{ height: "40px", width: "80px" }}
                      />
                    </TableCell>
                    <TableCell>
                      <Button size="small" onClick={handleOpen}>
                        <FiEdit style={{ height: "30px", width: "30px" }} />
                      </Button>
                      <Dialog
                        open={open}
                        onClose={handleClose}
                        maxWidth="sm"
                        fullWidth
                      >
                        <DialogTitle>
                          Would you like to change the GST rates of First
                          Premium!
                        </DialogTitle>
                        <DialogContent>
                          <Box sx={{ marginBottom: "16px" }}>
                            <Typography>Original Rate:</Typography>
                            <TextField
                              type="number"
                              value={percentage}
                              disabled
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                inputProps: { min: 0, max: 100 },
                              }}
                            />
                          </Box>
                          <Box>
                            <Typography>Edited Rate:</Typography>
                            <TextField
                              type="number"
                              value={editedFirstPercentage}
                              onChange={handleInputChange}
                              variant="outlined"
                              fullWidth
                              inputProps={{ step: 0.01, min: 0, max: 100 }}
                            />
                          </Box>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose} variant="outlined" sx={{border:"1px solid #57dd36", color:"#57dd36"}}>
                            Cancel
                          </Button>
                          <Button
                            onClick={handleSubmit}
                            variant="contained"
                            sx={{backgroundColor:"#57dd36"}}
                          >
                            Yes
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default GstRates;
