import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import "../../Client/css/Navbar.css";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { auth } from "../../components/utils/firebase";
import { useAppStore } from "../../components/Navbar/Appstore";
import { useState } from "react";

// const AppBar = styled(
//   MuiAppBar,
//   {}
// )(({ theme }) => ({
//   zIndex: theme.zIndex.drawer + 1,
// }));

export default function Navbar() {
  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        sessionStorage.removeItem("masterAdminId");
        window.location.href = "/"; 

        sessionStorage.removeItem("localClientData");
        sessionStorage.removeItem("localClientDataFetchTime");
      })
      .catch((error) => {
        console.error("Logout error:", error);
        // Handle logout error
      });
  };

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateOpen = useAppStore((state) => state.updateOpen);
  const dopen = useAppStore((state) => state.dopen);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const location = useLocation();

  const getBreadcrumbs = () => {
    switch (location.pathname) {
      case "/profile":
        return ["Home", "Profile"];
      case "/dashboard":
        return ["Home", " Dashboard"];
      case "/premiumreminders":
        return ["Home", " Premium Reminders"];
      case "/newclient":
        return ["Client", " New Client"];
      case "/allclients":
        return ["Client", " All Clients"];
      case "/newcompany":
        return ["Companys", " New Company"];
      case "/allcompany":
        return ["Companys", " All Companys"];
      default:
        return ["Dashboard", " Profile"];
    }
  };

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const breadcrumbs = getBreadcrumbs();
  const currentBreadcrumb = breadcrumbs[breadcrumbs.length - 1];

  return (
    <Box className="client-navbar" sx={{ zIndex: 1 }}>
      <div className="app-bar">
        <div>
          <div className="top-breadcrumb">
            <span className="current">{currentBreadcrumb}</span>
          </div>
          <div className="bottom-breadcrumb">
            <span className="icon-home">🏠 /</span>
            {breadcrumbs.map((crumb, index) => (
              <React.Fragment key={index}>
                <span
                  className={index === breadcrumbs.length - 1 ? "current" : ""}
                >
                  {crumb}
                </span>
                {index < breadcrumbs.length - 1 && (
                  <span className="separator"> / </span>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="actions">
          <div className="icons">
            <span
              className="icon notification"
              style={{ paddingRight: "10px", margin: "0" }}
            >
              🔔
            </span>
            <span
              className="icon calendar"
              style={{ paddingRight: "10px", margin: "0" }}
            >
              📅
            </span>
            <div className="profile" onClick={toggleDropdown}>
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTnui_ke5T_W3w_LQqpK5KZBdFMb-yE4OdaQ&s"
                alt="User"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  textAlign: "start",
                }}
              >
                <span>Admin User</span>
                <span className="role">Agent</span>
              </div>
              {/* {dropdownVisible && ( */}
              <div className="dropdown-menu">
                <div
                // onClick={handleShowProfile}
                >
                  Profile Details
                </div>
                <div>My Account</div>
                <div onClick={handleLogout}>Logout</div>
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}
