import {
  Box,
  Divider,
  Paper,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Grid,
  TextField,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { TableBody, TableHead } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { BsArrowUpRight } from "react-icons/bs";
import Sidebar from "../components/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import { MetaTags } from "react-meta-tags";
import "../css/ClientProfile.css";
import { doc, getDoc, serverTimestamp, updateDoc } from "@firebase/firestore";
import { auth, db } from "../../components/utils/firebase";
import { toast } from "react-toastify";
import { ResizeObserver } from "@juggle/resize-observer";
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "@firebase/auth";
import styled from "styled-components";

const ClientProfile = () => {
  const [elementSize, setElementSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      setElementSize({ width, height });
    });

    const handleResize = () => {
      // Debounce the setElementSize call to limit updates
      debounce(() => {
        resizeObserver.observe(document.getElementById("element-to-observe"));
      }, 300);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function
    return () => {
      resizeObserver.disconnect();
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const genders = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
  ];

  const [clientData, setClientData] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [companyData, setCompanyData] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const [enterPassword, setEnterPassword] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handlePasswordChange = (event) => {
    setEnterPassword(event.target.value);
    // console.log('enterPassword :>> ', enterPassword);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  // Function to handle closing the dialog
  const handleClose = () => {
    setOpen(false);
  };

  const handlePasswordReset = () => {
    // Add logic to handle password reset
    // console.log('Entered Password:', enterPassword);
    handleClose();
  };

  const handleForgotPassword = async () => {
    if (email && password === enterPassword) {
      try {
        await sendPasswordResetEmail(auth, email);
        toast.success("Check your email for password reset instructions");
        handleClose();
      } catch (error) {
        alert(error.message);
        console.log("error :>> ", error.message);
      }
    } else {
      toast.error("Please Enter currect password!");
    }
  };

  useEffect(() => {
    const clientId = sessionStorage.getItem("clientId");
    if (clientId) {
      const fetchClientData = async () => {
        console.log("clientId :>> ", clientId);
        try {
          const clientDoc = await getDoc(doc(db, "clients", clientId));
          if (clientDoc.exists()) {
            setClientData(clientDoc.data().Personal);
            setCompanyId(clientDoc.data().companyId);
            setEmail(clientDoc.data().Personal?.email);
            setPassword(clientDoc.data().Personal?.password);
            // console.log('password :>> ', password);
            // console.log('email :>> ', email);
            // console.log("companyId :>> ", companyId);
            // console.log("clientData :>> ", clientData);
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching client data: ", error);
        }
      };

      fetchClientData();
    }
  }, [companyId]);

  useEffect(() => {
    const fetchCompanyData = async () => {
      if (companyId) {
        try {
          const companyDoc = await getDoc(doc(db, "company", companyId));
          if (companyDoc.exists()) {
            setCompanyData(companyDoc.data());
            // console.log("compnyData :>> ", companyData);
          } else {
            console.log("No such company document!");
          }
        } catch (error) {
          console.error("Error fetching company data: ", error);
        }
      }
    };

    fetchCompanyData();
  }, [companyData, companyId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const isEmailRegistered = async (email) => {
    try {
      const signInMethods = await auth.fetchSignInMethodsForEmail(
        clientData.email
      );
      return signInMethods.length > 0;
    } catch (error) {
      console.error("Error checking email:", error);
      return false;
    }
  };

  // Register user with email and password
  const registerWithEmailPassword = async (email, password) => {
    try {
      const isRegistered = await isEmailRegistered(email);
      if (!isRegistered) {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        // console.log("userCredential.user :>> ", userCredential.user);
        return userCredential.user;
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const clientId = sessionStorage.getItem("clientId");
    try {
      await registerWithEmailPassword(clientData.email, clientData.password);

      await updateDoc(doc(db, "clients", clientId), {
        Personal: clientData,
        updated_at: serverTimestamp(),
      });
      console.log("Profile successfully updated!");
      toast.success("Profile Update successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // Optionally, you can show a success message or redirect the user
    } catch (error) {
      console.error("Error updating document: ", error);
    } finally {
      setSubmitting(false);
    }
  };

  // if (!clientData || !companyData) {
  //   return <CircularProgress />;
  // }

  const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
  `;

  return (
    <>
      <MetaTags>
        <title>Profile</title>
      </MetaTags>

      <Box id="element-to-observe" sx={{ display: "flex" }}>
        <Sidebar />
        <Box sx={{ flex: 1, backgroundColor: "#F4F7F9" }}>
          <Navbar />
          <Box
            // component="main"
            sx={{
              p: 3,
              // mt: 9,
              // minHeight: "100vh",
            }}
          >
            {!clientData || !companyData ? (
              <Container>
                <CircularProgress />
              </Container>
            ) : (
              <Grid container spacing={3} sx={{ zIndex: 1 }}>
                {/* <Grid item xs={12}>
                  <h3
                    style={{
                      fontSize: "22px",
                      fontWeight: 600,
                      marginTop: "5px",
                    }}
                  >
                    Dashboard /{" "}
                    <span style={{ color: "#3bd615" }}>Profile</span>
                  </h3>
                </Grid> */}

                <>
                  <Grid item xs={12} md={4} lg={4}>
                    <div
                      elevation={3}
                      className="card1"
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          textAlign: "start",
                          font: "inherit",
                          fontSize: "14px",
                          fontFamily: '"Montserrat", sans-serif',
                        }}
                      >
                        My Profile
                      </Typography>
                      <Divider sx={{ width: "100%" }} />
                      <Box className="content">
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                            width: "100%",
                            mt:2,
                          }}
                        >
                          <img
                            src={
                              clientData.profilePhotoURL ||
                              "https://img.freepik.com/premium-vector/anonymous-user-circle-icon-vector-illustration-flat-style-with-long-shadow_520826-1931.jpg?w=360"
                            }
                            alt="user-profile"
                            height={50}
                            style={{
                              marginRight: "20px",
                              borderRadius: "50%",
                              height: "80px",
                            }}
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "start",
                              justifyContent: "center",
                              font: "inherit",
                              fontSize: "16px",
                              fontFamily: '"Montserrat", sans-serif',
                            }}
                          >
                            <h4>{clientData.name}</h4>
                            <p>Agent</p>
                          </Box>
                        </Box>

                        <form>
                          <Grid container spacing={2}>
                            {/* Compny Name */}
                            <Grid item xs={12} md={12} lg={12}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "start",
                                  justifyContent: "space-between",
                                  flexDirection: "column",
                                  width: "100%",
                                  mt: 2,
                                }}
                              >
                                <Box
                                  sx={{ display: "flex", alignItems: "start" }}
                                >
                                  {/* <PersonIcon sx={{ mr: 2 }} /> */}
                                  <label
                                    sx={{
                                      minWidth: "100px",
                                      width: "145px",
                                      textAlign: "start",
                                    }}
                                  >
                                    Compny Name
                                  </label>
                                </Box>
                                <TextField
                                  fullWidth
                                  className="custom-textfield"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  variant="outlined"
                                  sx={{
                                    "& .MuiInputBase-input": {
                                      font: "inherit",
                                      fontSize: "14px",
                                      fontFamily: '"Montserrat", sans-serif',
                                      fontWeight: 400,
                                      letterSpacing: "inherit",
                                      color: "currentColor",
                                      padding: "9px 10px",
                                      // border: "1px dashed red",
                                      boxSizing: "content-box",
                                      background: "none",
                                      height: "1.4375em",
                                      margin: 0,
                                      WebkitTapHighlightColor: "transparent",
                                      display: "block",
                                      minWidth: 0,
                                      width: "100%",
                                      WebkitAnimationName:
                                        "mui-auto-fill-cancel",
                                      animationName: "mui-auto-fill-cancel",
                                      WebkitAnimationDuration: "10ms",
                                      animationDuration: "10ms",
                                    },
                                  }}
                                  name="name"
                                  value={companyData.name || ""}
                                  // onChange={handleChange}
                                />
                              </Box>
                            </Grid>

                            {/* Compny Email */}
                            <Grid item xs={12} md={12} lg={12}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "start",
                                  justifyContent: "space-between",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  sx={{ display: "flex", alignItems: "start" }}
                                >
                                  {/* <PersonIcon sx={{ mr: 2 }} /> */}
                                  <label
                                    sx={{
                                      minWidth: "100px",
                                      width: "145px",
                                      textAlign: "start",
                                    }}
                                  >
                                    Compny Email
                                  </label>
                                </Box>
                                <TextField
                                  fullWidth
                                  className="custom-textfield"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  variant="outlined"
                                  sx={{
                                    "& .MuiInputBase-input": {
                                      font: "inherit",
                                      fontSize: "14px",
                                      fontFamily: '"Montserrat", sans-serif',
                                      fontWeight: 400,
                                      letterSpacing: "inherit",
                                      color: "currentColor",
                                      padding: "9px 10px",
                                      // border: "1px dashed red",
                                      boxSizing: "content-box",
                                      background: "none",
                                      height: "1.4375em",
                                      margin: 0,
                                      WebkitTapHighlightColor: "transparent",
                                      display: "block",
                                      minWidth: 0,
                                      width: "100%",
                                      WebkitAnimationName:
                                        "mui-auto-fill-cancel",
                                      animationName: "mui-auto-fill-cancel",
                                      WebkitAnimationDuration: "10ms",
                                      animationDuration: "10ms",
                                    },
                                  }}
                                  name="email"
                                  value={companyData.email || ""}
                                  // onChange={handleChange}
                                />
                              </Box>
                            </Grid>

                            {/* Password */}
                            <Grid item xs={12} md={12} lg={12}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "start",
                                  justifyContent: "space-between",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "start",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  {/* <PersonIcon sx={{ mr: 2 }} /> */}
                                  <label
                                    sx={{
                                      minWidth: "100px",
                                      width: "145px",
                                      textAlign: "start",
                                    }}
                                  >
                                    Password
                                  </label>
                                  <Button onClick={handleClickOpen}>
                                    Reset
                                  </Button>
                                </Box>
                                <TextField
                                  fullWidth
                                  className="custom-textfield"
                                  type="password"
                                  variant="outlined"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  sx={{
                                    "& .MuiInputBase-input": {
                                      font: "inherit",
                                      fontSize: "14px",
                                      fontFamily: '"Montserrat", sans-serif',
                                      fontWeight: 400,
                                      letterSpacing: "inherit",
                                      color: "currentColor",
                                      padding: "9px 10px",
                                      border: 0,
                                      boxSizing: "content-box",
                                      background: "none",
                                      height: "1.4375em",
                                      margin: 0,
                                      WebkitTapHighlightColor: "transparent",
                                      display: "block",
                                      minWidth: 0,
                                      width: "100%",
                                      WebkitAnimationName:
                                        "mui-auto-fill-cancel",
                                      animationName: "mui-auto-fill-cancel",
                                      WebkitAnimationDuration: "10ms",
                                      animationDuration: "10ms",
                                    },
                                  }}
                                  name="name"
                                  value={clientData.password || ""}
                                  // onChange={handleChange}
                                />
                              </Box>

                              <Dialog open={open} onClose={handleClose}>
                                <DialogTitle>Reset Password</DialogTitle>
                                <DialogContent>
                                  <TextField
                                    autoFocus
                                    margin="dense"
                                    id="newPassword"
                                    label="Old Password"
                                    type="password"
                                    fullWidth
                                    variant="outlined"
                                    value={enterPassword}
                                    onChange={handlePasswordChange}
                                  />
                                </DialogContent>
                                <DialogActions>
                                  <Button onClick={handleClose}>Cancel</Button>
                                  <Button onClick={handleForgotPassword}>
                                    Reset
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </Grid>

                            {/* Company Address */}
                            <Grid item xs={12} md={12} lg={12}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "start",
                                  justifyContent: "space-between",
                                  flexDirection: "column",
                                  width: "100%",
                                  mb: 4,
                                }}
                              >
                                <Box
                                  sx={{ display: "flex", alignItems: "start" }}
                                >
                                  {/* <PersonIcon sx={{ mr: 2 }} /> */}
                                  <label
                                    sx={{
                                      minWidth: "100px",
                                      width: "145px",
                                      textAlign: "start",
                                    }}
                                  >
                                    Company Address
                                  </label>
                                </Box>
                                <TextField
                                  fullWidth
                                  multiline
                                  className="custom-textfield"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  variant="outlined"
                                  sx={{
                                    height: "45px",
                                    "& .MuiInputBase-input": {
                                      font: "inherit",
                                      fontSize: "14px",
                                      fontFamily: '"Montserrat", sans-serif',
                                      fontWeight: 400,
                                    },
                                  }}
                                  name="address"
                                  value={clientData.address || ""}
                                  onChange={handleChange}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </form>
                      </Box>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={8} lg={8}>
                    <div
                      elevation={3}
                      className="card1"
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          textAlign: "start",
                          font: "inherit",
                          fontSize: "14px",
                          fontFamily: '"Montserrat", sans-serif',
                        }}
                      >
                        Edit Profile
                      </Typography>
                      <Divider sx={{ width: "100%", mb: 2 }} />
                      <Box className="content">
                        <form onSubmit={handleSubmit}>
                          <Grid container spacing={2}>
                            {/* Full name */}
                            <Grid item xs={12} md={6} lg={6}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "start",
                                  justifyContent: "space-between",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  sx={{ display: "flex", alignItems: "start" }}
                                >
                                  {/* <PersonIcon sx={{ mr: 2 }} /> */}
                                  <label
                                    sx={{
                                      minWidth: "100px",
                                      width: "145px",
                                      textAlign: "start",
                                    }}
                                  >
                                    Full name
                                  </label>
                                </Box>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  className="custom-textfield"
                                  sx={{
                                    "& .MuiInputBase-input": {
                                      font: "inherit",
                                      fontSize: "14px",
                                      fontFamily: '"Montserrat", sans-serif',
                                      fontWeight: 400,
                                      letterSpacing: "inherit",
                                      color: "currentColor",
                                      padding: "9px 10px 9px",
                                      // border: "none",
                                      boxSizing: "content-box",
                                      background: "none",
                                      height: "1.4375em",
                                      margin: 0,
                                      WebkitTapHighlightColor: "transparent",
                                      display: "block",
                                      minWidth: 0,
                                      width: "100%",
                                      WebkitAnimationName:
                                        "mui-auto-fill-cancel",
                                      animationName: "mui-auto-fill-cancel",
                                      WebkitAnimationDuration: "10ms",
                                      animationDuration: "10ms",
                                      borderRadius: "0.375rem",
                                    },
                                  }}
                                  name="name"
                                  value={clientData.name || ""}
                                  onChange={handleChange}
                                />
                              </Box>
                            </Grid>

                            {/* Date of Birth */}
                            <Grid item xs={12} md={3} lg={3}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "start",
                                  justifyContent: "space-between",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  sx={{ display: "flex", alignItems: "start" }}
                                >
                                  {/* <PersonIcon sx={{ mr: 2 }} /> */}
                                  <label
                                    sx={{
                                      minWidth: "100px",
                                      width: "145px",
                                      textAlign: "start",
                                    }}
                                  >
                                    Date of Birth
                                  </label>
                                </Box>
                                <TextField
                                  type="date"
                                  fullWidth
                                  className="custom-textfield"
                                  variant="outlined"
                                  sx={{
                                    "& .MuiInputBase-input": {
                                      font: "inherit",
                                      fontSize: "14px",
                                      fontFamily: '"Montserrat", sans-serif',
                                      fontWeight: 400,
                                      letterSpacing: "inherit",
                                      color: "currentColor",
                                      padding: "9px 10px",
                                      // border: "1px dashed red",
                                      boxSizing: "content-box",
                                      background: "none",
                                      height: "1.4375em",
                                      margin: 0,
                                      WebkitTapHighlightColor: "transparent",
                                      display: "block",
                                      minWidth: 0,
                                      width: "100%",
                                      WebkitAnimationName:
                                        "mui-auto-fill-cancel",
                                      animationName: "mui-auto-fill-cancel",
                                      WebkitAnimationDuration: "10ms",
                                      animationDuration: "10ms",
                                    },
                                  }}
                                  name="dob"
                                  value={clientData.dob || ""}
                                  onChange={handleChange}
                                />
                              </Box>
                            </Grid>

                            {/* Gender */}
                            <Grid item xs={12} md={3} lg={3}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "start",
                                  justifyContent: "space-between",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  sx={{ display: "flex", alignItems: "start" }}
                                >
                                  <label
                                    sx={{
                                      minWidth: "100px",
                                      width: "145px",
                                      textAlign: "start",
                                    }}
                                  >
                                    Gender
                                  </label>
                                </Box>
                                <Select
                                  fullWidth
                                  variant="outlined"
                                  value={clientData.gender || ""}
                                  onChange={handleChange}
                                  name="gender"
                                  className="custom-textfield"
                                  sx={{
                                    "& .MuiSelect-select": {
                                      font: "inherit",
                                      fontSize: "14px",
                                      fontFamily: '"Montserrat", sans-serif',
                                      fontWeight: 400,
                                      letterSpacing: "inherit",
                                      color: "currentColor",
                                      padding: "9px 10px",
                                      // border: "none",
                                      boxSizing: "content-box",
                                      background: "none",
                                      height: "1.4375em",
                                      margin: 0,
                                      WebkitTapHighlightColor: "transparent",
                                      display: "block",
                                      minWidth: 0,
                                      width: "100%",
                                      WebkitAnimationName:
                                        "mui-auto-fill-cancel",
                                      animationName: "mui-auto-fill-cancel",
                                      WebkitAnimationDuration: "10ms",
                                      animationDuration: "10ms",
                                    },
                                  }}
                                >
                                  {genders.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Box>
                            </Grid>

                            {/* Mobile number */}
                            <Grid item xs={12} md={6} lg={6}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "start",
                                  justifyContent: "space-between",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  sx={{ display: "flex", alignItems: "start" }}
                                >
                                  {/* <PersonIcon sx={{ mr: 2 }} /> */}
                                  <label
                                    sx={{
                                      minWidth: "100px",
                                      width: "145px",
                                      textAlign: "start",
                                    }}
                                  >
                                    Mobile number
                                  </label>
                                </Box>
                                <TextField
                                  fullWidth
                                  className="custom-textfield"
                                  variant="outlined"
                                  sx={{
                                    "& .MuiInputBase-input": {
                                      font: "inherit",
                                      fontSize: "14px",
                                      fontFamily: '"Montserrat", sans-serif',
                                      fontWeight: 400,
                                      letterSpacing: "inherit",
                                      color: "currentColor",
                                      padding: "9px 10px",
                                      // border: "1px dashed red",
                                      boxSizing: "content-box",
                                      background: "none",
                                      height: "1.4375em",
                                      margin: 0,
                                      WebkitTapHighlightColor: "transparent",
                                      display: "block",
                                      minWidth: 0,
                                      width: "100%",
                                      WebkitAnimationName:
                                        "mui-auto-fill-cancel",
                                      animationName: "mui-auto-fill-cancel",
                                      WebkitAnimationDuration: "10ms",
                                      animationDuration: "10ms",
                                    },
                                  }}
                                  name="phone_number"
                                  value={clientData.phone_number || ""}
                                  onChange={handleChange}
                                />
                              </Box>
                            </Grid>

                            {/* Email address */}
                            <Grid item xs={12} md={6} lg={6}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "start",
                                  justifyContent: "space-between",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  sx={{ display: "flex", alignItems: "start" }}
                                >
                                  {/* <PersonIcon sx={{ mr: 2 }} /> */}
                                  <label
                                    sx={{
                                      minWidth: "100px",
                                      width: "145px",
                                      textAlign: "start",
                                    }}
                                  >
                                    Email address
                                  </label>
                                </Box>
                                <TextField
                                  // InputProps={{
                                  //   readOnly: true,
                                  // }}
                                  fullWidth
                                  className="custom-textfield"
                                  variant="outlined"
                                  sx={{
                                    "& .MuiInputBase-input": {
                                      font: "inherit",
                                      fontSize: "14px",
                                      fontFamily: '"Montserrat", sans-serif',
                                      fontWeight: 400,
                                      letterSpacing: "inherit",
                                      color: "currentColor",
                                      padding: "9px 10px",
                                      // border: "1px dashed red",
                                      boxSizing: "content-box",
                                      background: "none",
                                      height: "1.4375em",
                                      margin: 0,
                                      WebkitTapHighlightColor: "transparent",
                                      display: "block",
                                      minWidth: 0,
                                      width: "100%",
                                      WebkitAnimationName:
                                        "mui-auto-fill-cancel",
                                      animationName: "mui-auto-fill-cancel",
                                      WebkitAnimationDuration: "10ms",
                                      animationDuration: "10ms",
                                    },
                                  }}
                                  name="email"
                                  value={clientData.email || ""}
                                  onChange={handleChange}
                                />
                              </Box>
                            </Grid>

                            {/* Address */}
                            <Grid item xs={12} md={12} lg={12}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "start",
                                  justifyContent: "space-between",
                                  flexDirection: "column",
                                }}
                              >
                                <Box
                                  sx={{ display: "flex", alignItems: "start" }}
                                >
                                  {/* <PersonIcon sx={{ mr: 2 }} /> */}
                                  <label
                                    sx={{
                                      minWidth: "100px",
                                      width: "145px",
                                      textAlign: "start",
                                    }}
                                  >
                                    Address
                                  </label>
                                </Box>
                                <TextField
                                  fullWidth
                                  className="custom-textfield"
                                  variant="outlined"
                                  sx={{
                                    "& .MuiInputBase-input": {
                                      font: "inherit",
                                      fontSize: "14px",
                                      fontFamily: '"Montserrat", sans-serif',
                                      fontWeight: 400,
                                      letterSpacing: "inherit",
                                      color: "currentColor",
                                      padding: "9px 10px",
                                      border: 0,
                                      boxSizing: "content-box",
                                      background: "none",
                                      height: "1.4375em",
                                      margin: 0,
                                      WebkitTapHighlightColor: "transparent",
                                      display: "block",
                                      minWidth: 0,
                                      width: "100%",
                                      WebkitAnimationName:
                                        "mui-auto-fill-cancel",
                                      animationName: "mui-auto-fill-cancel",
                                      WebkitAnimationDuration: "10ms",
                                      animationDuration: "10ms",
                                    },
                                  }}
                                  name="address"
                                  value={clientData.address || ""}
                                  onChange={handleChange}
                                />
                              </Box>
                            </Grid>

                            {/* Additional info */}
                            <Grid item xs={12} md={12} lg={12}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "start",
                                  justifyContent: "space-between",
                                  flexDirection: "column",
                                }}
                              >
                                <Box
                                  sx={{ display: "flex", alignItems: "start" }}
                                >
                                  {/* <PersonIcon sx={{ mr: 2 }} /> */}
                                  <label
                                    sx={{
                                      minWidth: "100px",
                                      width: "145px",
                                      textAlign: "start",
                                    }}
                                  >
                                    Additional info
                                  </label>
                                </Box>
                                <TextField
                                  fullWidth
                                  className="custom-textfield"
                                  variant="outlined"
                                  sx={{
                                    "& .MuiInputBase-input": {
                                      font: "inherit",
                                      fontSize: "14px",
                                      fontFamily: '"Montserrat", sans-serif',
                                      fontWeight: 400,
                                      letterSpacing: "inherit",
                                      color: "currentColor",
                                      padding: "9px 10px",
                                      border: 0,
                                      boxSizing: "content-box",
                                      background: "none",
                                      height: "1.4375em",
                                      margin: 0,
                                      WebkitTapHighlightColor: "transparent",
                                      display: "block",
                                      minWidth: 0,
                                      width: "100%",
                                      WebkitAnimationName:
                                        "mui-auto-fill-cancel",
                                      animationName: "mui-auto-fill-cancel",
                                      WebkitAnimationDuration: "10ms",
                                      animationDuration: "10ms",
                                    },
                                  }}
                                  name="additional_info"
                                  value={clientData.additional_info || ""}
                                  onChange={handleChange}
                                />
                              </Box>
                            </Grid>
                            <Divider sx={{ width: "100%", mt: 2 }} />
                            <div
                              className="add-btn1"
                              style={{ marginTop: "10px" }}
                            >
                              <Button disabled={submitting} type="submit">
                                {submitting ? (
                                  <CircularProgress size={24} sx={{color:"black"}} />
                                ) : (
                                  "Update Profile"
                                )}
                              </Button>
                            </div>
                          </Grid>
                        </form>
                      </Box>
                    </div>
                  </Grid>
                </>
              </Grid>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ClientProfile;

// Utility function for debouncing
function debounce(func, wait) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}
