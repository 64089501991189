import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import { Link, useLocation } from "react-router-dom";
import muiFooter from "../../components/Footer/Footer";
import Brandlogo from "../../components/images/logo.png";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import PersonIcon from "@mui/icons-material/Person";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import SubMenuIcon from "../../components/images/submenu-icon.png";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { useState } from "react";
import { motion } from "framer-motion";
import { Home, Notifications } from "@material-ui/icons";

export default function Sidebars() {
  const [open, setOpen] = useState(true);

  // for collpsing sidebar
  const handleToggle = () => {
    setOpen(!open);
  };

  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [manualToggle, setManualToggle] = useState(false);
  // console.log("sidebarCollapsed :>> ", sidebarCollapsed);
  const location = useLocation();

  const handleMenuClick = (menuItem) => {
    setSidebarCollapsed(false);
    setManualToggle(!manualToggle);
  };

  const handleMouseEnter = () => {
    if (manualToggle) {
      setSidebarCollapsed(false);
    }
  };

  const handleMouseLeave = () => {
    if (manualToggle) {
      setSidebarCollapsed(true);
    }
  };

  const [hoveredMenuItem, setHoveredMenuItem] = useState(null);
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  const handleSubMenuClick = (menuItem) => {
    setSubMenuOpen(!subMenuOpen);
  };

  const handleMenuItemMouseEnter = (menuItem) => {
    setHoveredMenuItem(menuItem);
    setSubMenuOpen(menuItem);
  };

  const handleMenuItemMouseLeave = () => {
    setHoveredMenuItem(null);
  };

  const isClientActive =
    location.pathname === "/newclient" || location.pathname === "/allclients";

  const isCompanyActive =
    location.pathname === "/newcompany" || location.pathname === "/allcompany";

  return (
    <Box
      sx={{ display: "flex", height: "100vh", zIndex: 100, overflow: "hidden" }}
    >
      <Sidebar
        collapsed={sidebarCollapsed}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ height: "100vh", overflow: "hidden" }}
      >
        <div
          className="css-dip3t8"
          style={{
            backgroundColor: "#2A2B2B",
            transition: "background-color 0.3s ease",
          }}
        >
          <Menu>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px 20px",
                cursor: "pointer",
              }}
              onClick={handleMenuClick}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  style={{ width: 48, marginRight: 10 }}
                  src={Brandlogo}
                  alt="Logo"
                />
                {!sidebarCollapsed && (
                  <>
                    <strong
                      style={{
                        color: "white",
                        fontSize: "18px",
                        padding: "10px 0px",
                      }}
                    >
                      Auto
                    </strong>
                    <strong
                      style={{
                        fontSize: "18px",
                        color: "#3BD615",
                        padding: "10px 0px",
                      }}
                    >
                      PULSE
                    </strong>
                  </>
                )}
              </div>
              <motion.div
                whileHover={{
                  scale: 1.2,
                  rotate: 180,
                  transition: {
                    delay: 0.2,
                    duration: 0.4,
                  },
                }}
                onClick={handleToggle}
              >
                <Box sx={{ height: "25px" }}>
                  <GridViewRoundedIcon style={{ color: "white" }} />
                </Box>
              </motion.div>
            </div>
            <Box height={20} />
            {/* Dashboard */}
            <MenuItem
              component={<Link to="/dashboard" />}
              icon={<Home />}
              style={{
                color: "white",
                backgroundColor:
                  hoveredMenuItem === "Dashboard" ||
                  location.pathname === "/dashboard"
                    ? "#8c8c8c40"
                    : "#2A2B2B",
                height: "45px",
                marginTop: "5px",
                p: 0,
                fontSize: "14px",
                fontWeight: 500,
                letterSpacing: ".06em",
                whiteSpace: "nowrap",
              }}
              // onMouseEnter={() => handleMenuItemMouseEnter("Dashboard")}
              // onMouseLeave={handleMenuItemMouseLeave}
            >
              {" "}
              Dashboard{" "}
            </MenuItem>

            {/* Premium Reminders */}
            <MenuItem
              component={<Link to="#" />}
              icon={<Notifications />}
              style={{
                color: "white",
                backgroundColor:
                  hoveredMenuItem === "Premium Reminders"
                    ? "#8c8c8c40"
                    : "#2A2B2B",
                height: "45px",
                p: 0,
                fontSize: "14px",
                fontWeight: 500,
                letterSpacing: ".06em",
                whiteSpace: "nowrap",
              }}
              // onMouseEnter={() => handleMenuItemMouseEnter("Premium Reminders")}
              // onMouseLeave={handleMenuItemMouseLeave}
            >
              {" "}
              Premium Reminders{" "}
            </MenuItem>

            {/* Client */}
            <SubMenu
              defaultOpen={
                location.pathname === "/newclient" ||
                location.pathname === "/allclients"
                  ? true
                  : false
              }
              label="Client"
              icon={<PersonIcon />}
              style={{
                color: "white",
                backgroundColor: isClientActive ? "#8c8c8c40" : "#2A2B2B",
                // || isCustomerActive
                margin: "0px",
                borderRadius: "5px",
                height: "45px",
                fontSize: "14px",
                fontWeight: 500,
                letterSpacing: ".06em",
                whiteSpace: "nowrap",
              }}
              onClick={handleSubMenuClick}
              // onClick={() => handleSubMenuClick("Customer")}

              // onMouseEnter={() => handleMenuItemMouseEnter("Customer")}
              // onMouseLeave={handleMenuItemMouseLeave}
            >
              <MenuItem
                component={<Link to="/newclient" />}
                icon={
                  <img
                    src={SubMenuIcon}
                    alt=""
                    style={{
                      width: "20px",
                      height: "auto",
                      filter: "invert(1)",
                    }}
                  />
                }
                style={{
                  // color: "white",
                  backgroundColor: "#2A2B2B",
                  color:
                    location.pathname === "/newclient" ||
                    hoveredMenuItem === "New Customer"
                      ? "#3BD615"
                      : "white",
                  height: "45px",
                  // p: 0,
                  paddingLeft: "50px",
                  fontSize: "14px",
                  fontWeight: 500,
                  letterSpacing: ".06em",
                  whiteSpace: "nowrap",
                }}
                onMouseEnter={() => handleMenuItemMouseEnter("New Customer")}
                onMouseLeave={handleMenuItemMouseLeave}
              >
                {" "}
                New Client{" "}
              </MenuItem>
              <MenuItem
                component={<Link to="/allclients" />}
                icon={
                  <img
                    src={SubMenuIcon}
                    alt=""
                    style={{
                      width: "20px",
                      height: "auto",
                      filter: "invert(1)",
                    }}
                  />
                }
                style={{
                  color:
                    location.pathname === "/allclients" ||
                    hoveredMenuItem === "All Customer"
                      ? "#3BD615"
                      : "white",
                  backgroundColor: "#2A2B2B",
                  height: "45px",
                  paddingLeft: "50px",
                  fontSize: "14px",
                  fontWeight: 500,
                  letterSpacing: ".06em",
                  whiteSpace: "nowrap",
                }}
                onMouseEnter={() => handleMenuItemMouseEnter("All Customer")}
                onMouseLeave={handleMenuItemMouseLeave}
              >
                All Clients
              </MenuItem>
            </SubMenu>

            {/* Company */}
            <SubMenu
              defaultOpen={
                location.pathname === "/newcompany" ||
                location.pathname === "/allcompany"
                  ? true
                  : false
              }
              label="Company"
              icon={<AddBusinessIcon />}
              style={{
                color: "white",
                backgroundColor: isCompanyActive ? "#8c8c8c40" : "#2A2B2B",

                margin: "0px",
                borderRadius: "5px",
                height: "45px",
                fontSize: "14px",
                fontWeight: 500,
                letterSpacing: ".06em",
                whiteSpace: "nowrap",
              }}
              onClick={handleSubMenuClick}
              // onMouseEnter={() => handleMenuItemMouseEnter("Customer")}
              // onMouseLeave={handleMenuItemMouseLeave}
            >
              <MenuItem
                component={<Link to="/newcompany" />}
                icon={
                  <img
                    src={SubMenuIcon}
                    alt=""
                    style={{
                      width: "20px",
                      height: "auto",
                      filter: "invert(1)",
                    }}
                  />
                }
                style={{
                  // color: "white",
                  backgroundColor: "#2A2B2B",
                  color:
                    location.pathname === "/newcompany" ||
                    hoveredMenuItem === "New Company"
                      ? "#3BD615"
                      : "white",
                  height: "45px",
                  // p: 0,
                  paddingLeft: "50px",
                  fontSize: "14px",
                  fontWeight: 500,
                  letterSpacing: ".06em",
                  whiteSpace: "nowrap",
                }}
                onMouseEnter={() => handleMenuItemMouseEnter("New Company")}
                onMouseLeave={handleMenuItemMouseLeave}
              >
                {" "}
                New Company{" "}
              </MenuItem>
              <MenuItem
                component={<Link to="/allcompany" />}
                icon={
                  <img
                    src={SubMenuIcon}
                    alt=""
                    style={{
                      width: "20px",
                      height: "auto",
                      filter: "invert(1)",
                    }}
                  />
                }
                style={{
                  color:
                    location.pathname === "/allcompany" ||
                    hoveredMenuItem === "AllCompany"
                      ? "#3BD615"
                      : "white",
                  backgroundColor: "#2A2B2B",
                  height: "45px",
                  paddingLeft: "50px",
                  fontSize: "14px",
                  fontWeight: 500,
                  letterSpacing: ".06em",
                  whiteSpace: "nowrap",
                }}
                onMouseEnter={() => handleMenuItemMouseEnter("AllCompany")}
                onMouseLeave={handleMenuItemMouseLeave}
              >
                All Companys
              </MenuItem>
            </SubMenu>
          </Menu>
        </div>
      </Sidebar>

      {/* <Navbar/> */}
    </Box>
  );
}
