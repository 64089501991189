import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Button from "@mui/material/Button";
import Select from "react-select";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import Avatar from "react-avatar-edit";
import { db, storage } from "../../components/utils/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { centerCrop } from "react-image-crop";
import vector from "../../components/images/Vector.png";
import remarks from "../../components/images/star.png";
import {
  Dialog,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import SDate from "../../components/images/SDate.png";
import EDate from "../../components/images/EDate.png";
import SumAssured from "../../components/images/SumAssured.png";
import uploadImage from "../../components/images/uploadImage.png";
import email from "../../components/images/email.png";
import policyNum from "../../components/images/policy-num.png";
import policyName from "../../components/images/policyName.png";
import {
  collection,
  query,
  doc,
  where,
  onSnapshot,
  addDoc,
  serverTimestamp,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import policyNumber from "../../components/images/LicImage/PolicyNumber.png";
import remarkIcon from "../../components/images/LicImage/remarkIcon.png";
import { Image } from "@mui/icons-material";
import "../css/NewGicPolicy.css";
import MetaTags from "react-meta-tags";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import close from "../../components/images/close.png";
import uploadIcon from "../../components/images/docUploadIcon.png";
import Navbar from "../../components/Navbar/Navbar";
import {
  styled,
  alpha,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";

const theme = createTheme({
  components: {
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          height: "20px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: '"Montserrat", sans-serif',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
        },
      },
    },
  },
  MuiTableCell: {
    root: {
      borderColor: "#d3d3d3",
    },
    head: {
      background: "lightgrey",
      "&:not(:last-child)": {
        borderRight: [[1, "solid", "#c0c0c0"]],
      },
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const NewGicPolicy = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [companyId, setCompanyId] = useState(null);

  const fetchClientData = async (id) => {
    try {
      const docRef = doc(db, "clients", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setCompanyId(docSnap.data().companyId);
        // console.log('companyId :>> ', companyId);
      } else {
        console.log("No such document!");
        // setError("No such document!");
      }
    } catch (err) {
      console.error("Error fetching document:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Get clientId from sessionStorage
    const storedClientId = sessionStorage.getItem("clientId");
    setClientId(storedClientId);

    if (storedClientId) {
      fetchClientData(storedClientId);
    } else {
      setLoading(false);
    }
  }, []);

  // Debugging to check windowHeight
  // useEffect(() => {
  //   console.log("Current windowHeight:", windowHeight);
  //   console.log("Current windowWidth:", windowWidth);
  // }, [windowHeight, windowWidth]);

  const [gicData, setGicData] = useState({
    name: "",
    planName: "",
    policyNumber: "",
    policyType: "",
    policyTerm: "",
    premiumAmount: "",
    startDate: "",
    endDate: "",
    personalCode: "",
    companyName: "",
    companyCode: "",
    planNumber: "",
    remarks: "",
    documents: [],
    gic_code: "",
    // sumAssured: "",
    customerId: null,
    reminderDate: [
      {
        date: null,
        status: false,
      },
    ],
    notificationDate: [
      {
        date: null,
        status: false,
      },
    ],
  });

  const [customers, setCustomers] = useState([]);
  const [clientId, setClientId] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [documents, setDocuments] = useState([]);

  const handleUploadClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleEditNameChange = (index, newName) => {
    setDocuments((prevDocuments) => {
      const updatedDocuments = [...prevDocuments];
      updatedDocuments[index].editName = newName;
      return updatedDocuments;
    });
  };

  const handleSaveNameChange = (index) => {
    setDocuments((prevDocuments) => {
      const updatedDocuments = [...prevDocuments];
      updatedDocuments[index].name = updatedDocuments[index].editName;
      return updatedDocuments;
    });
  };

  const handleRemoveDocument = (index) => {
    setDocuments((prevDocuments) => {
      const updatedDocuments = [...prevDocuments];
      updatedDocuments.splice(index, 1);
      return updatedDocuments;
    });
  };

  useEffect(() => {
    // Get clientId from sessionStorage
    const storedClientId = sessionStorage.getItem("clientId");
    setClientId(storedClientId);

    if (storedClientId) {
      setClientId(storedClientId);
      // console.log('clientId :>> ', clientId);
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const q = query(
      collection(db, "customers"),
      where("Personal.clientId", "==", clientId)
    );

    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const customers = [];
        querySnapshot.forEach((doc) => {
          customers.push({ id: doc.id, ...doc.data() });
        });
        setCustomers(customers);
      },
      (error) => {
        console.error("Error fetching customers:", error);
      }
    );

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [clientId]);

  const customerOptions = customers.map((customers) => ({
    value: customers.id,
    label: customers.Personal.name,
  }));

  const handlePolicyHolderChange = (selectedOption) => {
    setGicData({
      ...gicData,
      name: selectedOption ? selectedOption.label : null,
      customerId: selectedOption ? selectedOption.value : null,
    });
  };

  const [image, setImage] = useState(null);
  const [imageURL, setImageURL] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [imageCrop, setImageCrop] = useState(false);
  const [profile, setProfile] = useState([]);
  const [pview, setPview] = useState(false);

  const handleChange = async (e) => {
    const { name, value, files } = e.target;
    if (name === "policyDocument") {
      const filesArray = Array.from(e.target.files).map((file) => ({
        file: e.target.files[0],
        name: file.name,
        url: URL.createObjectURL(file),
        editName: file.name,
      }));
      setGicData((prev) => ({ ...prev, policyDocument: filesArray }));
    } else if (name === "documents") {
      const filesArray = Array.from(e.target.files).map((file) => ({
        file: file,
        name: file.name,
        url: URL.createObjectURL(file),
        editName: file.name,
      }));
      setDocuments((prevDocuments) => [...prevDocuments, ...filesArray]);
      setGicData((prev) => ({
        ...prev,
        documents: [...prev.documents, ...filesArray],
      }));
    } else if (name === "endDate") {
      const reminderDates = generateReminderDates(value);
      const notificationDates = generateNotificationDate(value);
      setGicData((prev) => ({
        ...prev,
        [name]: value,
        reminderDate: reminderDates,
        notificationDate: notificationDates,
      }));
    } else {
      setGicData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const generateReminderDates = (endDate) => {
    const date = new Date(endDate);
    console.log("date :>> ", date);
    const reminderDates = [];

    const addDays = (date, days) => {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    };

    const formatDate = (date) => {
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    };

    reminderDates.push({ date: formatDate(addDays(date, -30)), status: false });
    reminderDates.push({ date: formatDate(addDays(date, -15)), status: false });
    reminderDates.push({ date: formatDate(addDays(date, -7)), status: false });

    console.log("reminderDates :>> ", reminderDates);
    return reminderDates;
  };

  const generateNotificationDate = (endDate) => {
    const date = new Date(endDate);
    console.log("date :>> ", date);
    const notificationDates = [];

    const addDays = (date, days) => {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    };

    const formatDate = (date) => {
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    };

    notificationDates.push({
      date: formatDate(addDays(date, -31)),
      status: false,
    });
    notificationDates.push({
      date: formatDate(addDays(date, -16)),
      status: false,
    });
    notificationDates.push({
      date: formatDate(addDays(date, -8)),
      status: false,
    });

    // notificationDates.push(formatDate(addDays(date, -31)));
    // notificationDates.push(formatDate(addDays(date, -16)));
    // notificationDates.push(formatDate(addDays(date, -8)));

    console.log("notificationDates :>> ", notificationDates);
    return notificationDates;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      const docRef = await addDoc(collection(db, "gic"), {
        name: gicData.name,
        planName: gicData.planName,
        policyNumber: gicData.policyNumber,
        policyType: gicData.policyType,
        policyTerm: gicData.policyTerm,
        premiumAmount: gicData.premiumAmount,
        startDate: gicData.startDate,
        endDate: gicData.endDate,
        personalCode: gicData.personalCode,
        companyName: gicData.companyName,
        companyCode: gicData.companyCode,
        remarks: gicData.remarks,
        // sumAssured: gicData.sumAssured,
        planNumber: gicData.planNumber,
        clientId: clientId,
        companyId: companyId,
        customerId: gicData.customerId,
        reminderDate: gicData.reminderDate,
        notificationDate: gicData.notificationDate,
        // gic_code: "",

        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
      });

      const gicId = docRef.id;
      // console.log("gicId :>> ", gicId);

      // const documents = [];
      // if (gicData.policyDocument.length !== 0) {
      //   for (let i = 0; i < gicData.policyDocument.length; i++) {
      //     const documentRef = ref(
      //       storage,
      //       `GicDocument/${clientId}/${gicId}/${gicData.policyDocument[i].file.name}`
      //     );
      //     await uploadBytes(documentRef, gicData.policyDocument[i].file);
      //     const documentURL = await getDownloadURL(documentRef);
      //     documents.push({
      //       name: gicData.policyDocument[i].name,
      //       url: documentURL,
      //     });
      //   }
      // }

      const documents = [];
      if (gicData.documents.length !== 0) {
        for (let i = 0; i < gicData.documents.length; i++) {
          const documentRef = ref(
            storage,
            `GicDocument/${clientId}/${gicId}/${gicData.documents[i].file.name}`
          );
          await uploadBytes(documentRef, gicData.documents[i].file);
          const documentURL = await getDownloadURL(documentRef);
          documents.push({
            name: gicData.documents[i].name,
            url: documentURL,
          });
        }
      }

      // let croppedPhotoURL = "";
      // if (profileFinal.length !== 0) {
      //   const croppedProfilePhotoRef = ref(
      //     storage,
      //     `GicDocument/${clientId}/${gicId}/${gicData.policyDocument.name}`
      //   );
      //   const croppedBlob = await fetch(profileFinal[0]).then((r) => r.blob());
      //   await uploadBytes(croppedProfilePhotoRef, croppedBlob);
      //   croppedPhotoURL = await getDownloadURL(croppedProfilePhotoRef);
      // }

      await updateDoc(doc(db, "gic", gicId), {
        id: gicId,
        documents: documents,
      });

      resetForm();

      toast.success("Form Submit successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      console.log("gicData >>", gicData);

      // Handle the response if needed
    } catch (error) {
      // Handle any errors that occur during the API request
      console.error("Error:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const resetForm = () => {
    setGicData({
      name: null,
      planName: "",
      policyNumber: "",
      policyType: "",
      policyTerm: "",
      premiumAmount: "",
      startDate: "",
      endDate: "",
      personalCode: "",
      companyName: "",
      companyCode: "",
      remarks: "",
      // policyDocument: null,
      gic_code: "",
      sumAssured: "",
      planNumber: "",
      documents: [],
    });
    setProfile([]);
    setImage(null);
    setImageURL("");
    setPview(false);
    setDocuments([]);
  };

  return (
    <>
      <MetaTags>
        <title>New GIC Policy</title>
      </MetaTags>
      {/* <Box height={40} /> */}
      <Box style={{ display: "flex" }}>
        {/* new sidebar start */}
        <Sidebar />
        <Box sx={{ flex: 1 }}>
          <Navbar />
          <Box sx={{ p: 2 }}>
            {/* <h3 style={{ fontSize: "22px", fontWeight: 600 }}>
            New GIC Policy /{" "}
            <span style={{ color: "#3bd615" }}>New Policy</span>
          </h3>
          <Box height={30} /> */}
            <Box className="container">
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={2}
                  style={{ width: "95%", margin: "0 1%" }}
                >
                  {/* Policy Holder Name */}
                  <Grid item xs={12} md={10} sm={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <PersonIcon sx={{ mr: 2 }} /> */}
                        <img
                          src={vector}
                          alt="vector"
                          style={{ height: "18px", marginRight: "22px" }}
                        />
                        <Typography sx={{ minWidth: "100px", width: "145px" }}>
                          Policy Holder Name
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                      </Box>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        // sx={{ height: "45px" }}
                      >
                        <Select
                          required
                          id="fname"
                          name="name"
                          placeholder=" "
                          options={customerOptions}
                          isClearable
                          value={customerOptions.find(
                            (option) =>
                              option.label === gicData.name &&
                              option.value === gicData.customerId
                          )}
                          onChange={handlePolicyHolderChange}
                          styles={{
                            container: (provided) => ({
                              ...provided,
                            }),
                            control: (provided) => ({
                              ...provided,
                              background: "#F9F9F9",
                              border: "1px solid rgba(59, 214, 21, 0.3)",
                              height: "45px",
                            }),
                          }}
                        />
                      </FormControl>
                    </Box>
                  </Grid>

                  {/* Start Date */}
                  <Grid item xs={12} md={5} sm={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <CalendarToday sx={{ mr: 2 }} /> */}
                        <img
                          src={SDate}
                          alt="SDate"
                          style={{ height: "18px", marginRight: "20px" }}
                        />
                        <Typography sx={{ minWidth: "100px", width: "145px" }}>
                          Start Date
                        </Typography>
                      </Box>
                      <TextField
                        type="date"
                        fullWidth
                        variant="outlined"
                        sx={{ height: "45px" }}
                        name="startDate"
                        value={gicData.startDate}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>

                  {/* End Date */}
                  <Grid item xs={12} md={5} sm={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <CalendarToday sx={{ mr: 2 }} /> */}
                        <img
                          src={EDate}
                          alt="EDate"
                          style={{ height: "18px", marginRight: "16px" }}
                        />
                        <Typography sx={{ minWidth: "100px", width: "145px" }}>
                          End Date
                        </Typography>
                      </Box>
                      <TextField
                        type="date"
                        fullWidth
                        variant="outlined"
                        sx={{ height: "45px" }}
                        name="endDate"
                        value={gicData.endDate}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>

                  {/* Policy Term */}
                  <Grid item xs={12} md={5} sm={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <PersonIcon sx={{ mr: 2 }} /> */}
                        <img
                          src={email}
                          alt="email"
                          style={{ height: "18px", marginRight: "20px" }}
                        />
                        <Typography sx={{ minWidth: "100px", width: "145px" }}>
                          Policy Term
                        </Typography>
                      </Box>
                      <TextField
                        fullWidth
                        variant="outlined"
                        sx={{ height: "45px" }}
                        name="policyTerm"
                        value={gicData.policyTerm}
                        onChange={handleChange}
                        type="number"
                      />
                    </Box>
                  </Grid>

                  {/* Personal Code */}
                  <Grid item xs={12} md={5} sm={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <PersonIcon sx={{ mr: 2 }} /> */}
                        <img
                          src={remarkIcon}
                          alt="remarkIcon"
                          style={{ height: "18px", marginRight: "20px" }}
                        />
                        <Typography sx={{ minWidth: "100px", width: "145px" }}>
                          Personal Code
                        </Typography>
                      </Box>
                      <TextField
                        fullWidth
                        variant="outlined"
                        sx={{ height: "45px" }}
                        name="personalCode"
                        value={gicData.personalCode}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>

                  {/* Company Name */}
                  <Grid item xs={12} md={5} sm={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <PersonIcon sx={{ mr: 2 }} /> */}
                        <img
                          src={remarkIcon}
                          alt="remarkIcon"
                          style={{ height: "18px", marginRight: "18px" }}
                        />
                        <Typography sx={{ minWidth: "100px", width: "145px" }}>
                          Company Name
                        </Typography>
                      </Box>
                      <TextField
                        fullWidth
                        variant="outlined"
                        sx={{ height: "45px" }}
                        name="companyName"
                        value={gicData.companyName}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>

                  {/* Company Code */}
                  <Grid item xs={12} md={5} sm={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <PersonIcon sx={{ mr: 2 }} /> */}
                        <img
                          src={policyNumber}
                          alt="policyNumber"
                          style={{ height: "18px", marginRight: "20px" }}
                        />
                        <Typography sx={{ minWidth: "100px", width: "145px" }}>
                          Company Code
                        </Typography>
                      </Box>
                      <TextField
                        fullWidth
                        variant="outlined"
                        sx={{ height: "45px" }}
                        name="companyCode"
                        value={gicData.companyCode}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>

                  {/* Policy Type */}
                  <Grid item xs={12} md={5} sm={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <PersonIcon sx={{ mr: 2 }} /> */}
                        <img
                          src={email}
                          alt="email"
                          style={{ height: "18px", marginRight: "18px" }}
                        />
                        <Typography sx={{ minWidth: "100px", width: "145px" }}>
                          Policy Type
                        </Typography>
                      </Box>
                      <TextField
                        fullWidth
                        variant="outlined"
                        sx={{ height: "45px" }}
                        name="policyType"
                        value={gicData.policyType}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>

                  {/* Plan Name */}
                  <Grid item xs={12} md={5} sm={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <PersonIcon sx={{ mr: 2 }} /> */}
                        <img
                          src={policyName}
                          alt="policyName"
                          style={{ height: "18px", marginRight: "18px" }}
                        />
                        <Typography sx={{ minWidth: "100px", width: "145px" }}>
                          Plan Name
                        </Typography>
                      </Box>
                      <TextField
                        fullWidth
                        variant="outlined"
                        sx={{ height: "45px" }}
                        name="planName"
                        value={gicData.planName}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>

                  {/* Plan Number */}
                  <Grid item xs={12} md={4} sm={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <PersonIcon sx={{ mr: 2 }} /> */}
                        <img
                          src={email}
                          alt="email"
                          style={{ height: "18px", marginRight: "20px" }}
                        />
                        <Typography sx={{ minWidth: "100px", width: "145px" }}>
                          Plan Number
                        </Typography>
                      </Box>
                      <TextField
                        fullWidth
                        variant="outlined"
                        sx={{ height: "45px" }}
                        name="planNumber"
                        value={gicData.planNumber}
                        onChange={handleChange}
                        type="number"
                      />
                    </Box>
                  </Grid>

                  {/* Premium Amount */}
                  <Grid item xs={12} md={4} sm={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <PersonIcon sx={{ mr: 2 }} /> */}
                        <img
                          src={email}
                          alt="email"
                          style={{ height: "18px", marginRight: "20px" }}
                        />
                        <Typography sx={{ minWidth: "100px", width: "135px" }}>
                          Premium Amount
                        </Typography>
                      </Box>
                      <TextField
                        fullWidth
                        variant="outlined"
                        sx={{ height: "45px" }}
                        name="premiumAmount"
                        value={gicData.premiumAmount}
                        onChange={handleChange}
                        type="number"
                      />
                    </Box>
                  </Grid>

                  {/* Policy Number */}
                  <Grid item xs={12} md={4} sm={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <PersonIcon sx={{ mr: 2 }} /> */}
                        <img
                          src={policyNumber}
                          alt="policyNumber"
                          style={{ height: "18px", marginRight: "20px" }}
                        />
                        <Typography sx={{ minWidth: "100px", width: "135px" }}>
                          Policy Number
                        </Typography>
                      </Box>
                      <TextField
                        fullWidth
                        variant="outlined"
                        sx={{ height: "45px" }}
                        name="policyNumber"
                        value={gicData.policyNumber}
                        onChange={handleChange}
                        // type="number"
                      />
                    </Box>
                  </Grid>

                  {/* Sum Assured */}
                  {/* <Grid item xs={12} sm={3.5}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={SumAssuredIcon}
                        alt="SumAssuredIcon"
                        style={{ height: "18px", marginRight: "20px" }}
                      />
                      <Typography sx={{ minWidth: "100px", width: "135px" }}>
                      Sum Assured
                      </Typography>
                    </Box>
                    <TextField
                      
                      fullWidth
                      variant="outlined"
                      sx={{ height: "45px" }}
                      name="sumAssured"
                      value={gicData.sumAssured}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid> */}

                  {/* Remarks */}
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <Home sx={{ mr: 2 }} /> */}
                        <img
                          src={remarkIcon}
                          alt="remarkIcon"
                          style={{ height: "20px", marginRight: "20px" }}
                        />
                        <Typography sx={{ minWidth: "100px", width: "140px" }}>
                          Remarks
                        </Typography>
                      </Box>
                      <TextField
                        fullWidth
                        multiline
                        rows={2}
                        variant="outlined"
                        name="remarks"
                        value={gicData.remarks}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>

                  {/* Add Documents */}
                  <Grid
                    item
                    xs={12}
                    sm={7.5}
                    sx={{
                      // width: "60%",
                      // maxWidth: "60%",
                      marginTop: "20px",
                      padding: "20px",
                      display: "flex",
                      flexDirection: "column",
                      border: "1px solid #d9d9d9",
                      borderRadius: "5px",
                      ml: 2,
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <FileOpenIcon sx={{ mr: 2 }} />
                      <Typography sx={{ minWidth: "100px" }}>
                        Documents
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        // width: "100%",
                        // maxWidth: "100%",
                        marginTop: "20px",
                        padding: "10px",
                        border: "1px solid #3BD6154D",
                        borderRadius: "5px",
                        display: "flex",
                        overflowX: "auto", // Enable horizontal scrolling
                      }}
                    >
                      <Box className="box1" style={{ display: "flex" }}>
                        {documents.length === 0 ? (
                          <div>
                            No documents available. Please upload a document.
                          </div>
                        ) : (
                          documents.map((document, index) => (
                            <Box
                              key={index}
                              className="document"
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                margin: "5px",
                                height: "95px",
                              }}
                            >
                              <TextField
                                value={document.editName}
                                onChange={(e) =>
                                  handleEditNameChange(index, e.target.value)
                                }
                                onBlur={() => handleSaveNameChange(index)}
                                sx={{
                                  width: "140px",
                                  height: "140px",
                                }}
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <a
                                  href={document.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View
                                </a>
                                <button
                                  onClick={() => handleRemoveDocument(index)}
                                  style={{ background: "#d9d9d9" }}
                                >
                                  <img
                                    src={close}
                                    alt="close"
                                    style={{ width: "22px" }}
                                  />
                                </button>
                              </Box>
                            </Box>
                          ))
                        )}
                      </Box>
                    </Box>
                    <Box
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <Button
                        style={{
                          padding: "10px 0px",
                          background: "#3BD615",
                          width: "200px",
                          borderRadius: "5px",
                          fontSize: "15px",
                          color: "white",
                        }}
                        onClick={handleUploadClick}
                      >
                        <img
                          src={uploadIcon}
                          style={{ height: "20px", paddingRight: "10px" }}
                          alt="Upload"
                        />
                        Upload Document
                      </Button>
                      <input
                        type="file"
                        id="fileInput"
                        style={{ display: "none" }}
                        name="documents"
                        onChange={handleChange}
                        multiple
                        hidden
                      />
                    </Box>
                  </Grid>
                </Grid>

                <div className="add-btn" style={{ paddingBottom: "150px" }}>
                  <Button type="submit" disabled={submitting}>
                    {submitting ? (
                      <CircularProgress size={24} sx={{ color: "white" }} />
                    ) : (
                      "Add"
                    )}
                  </Button>
                </div>
              </form>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default NewGicPolicy;
