import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  runTransaction,
  serverTimestamp,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { Visibility as VisibilityIcon } from "@material-ui/icons";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {
  styled,
  alpha,
  createTheme,
  ThemeProvider,
} from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import MetaTags from "react-meta-tags";
import Navbar from "../components/Navbar";
import Skeleton from "react-loading-skeleton";
import CustomSearchRender from "../../components/CustomSearchRender";
import MuiDatatables from "mui-datatables";
import { Add, Delete } from "@mui/icons-material";
import CachedIcon from "@mui/icons-material/Cached";
import { toast } from "react-toastify";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import Registration from "../../components/images/registration.png";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { storage } from "../../components/utils/firebase";
import {
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "@firebase/storage";

const CustomMuiPaper = styled(Paper)({
  display: "flex",
  flexDirection: "column",
  height: "auto",
  maxHeight: "80vh",
  overflow: "auto",
});

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          height: "auto",
          // height: "100%",
          // overflow: 'auto',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          flex: "1 1 auto", // Allow the table container to grow and shrink
        },
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          marginTop: "auto", // This ensures the footer stays at the bottom
        },
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          height: "20px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: '"Montserrat", sans-serif',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
          fontWeight: 600,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
          padding: "0px",
          textAlign: "start",
          justifyContent: "start",
          fontWeight: 600,
        },
      },
    },
  },
  MuiTableCell: {
    root: {
      borderColor: "#d3d3d3",
      fontWeight: 600,
    },
    head: {
      background: "lightgrey",
      "&:not(:last-child)": {
        borderRight: [[1, "solid", "#c0c0c0"]],
      },
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const AllCompany = () => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: "none",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      padding: "0 16px",
      height: 50,
    },
  }));

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [company, setCompany] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updating, setupdating] = useState(false);

  const [editCompany, setEditCompany] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [companyDocumentDialog, setCompanyDocumentDialog] = useState(false);

  const [documents, setDocuments] = useState([]);
  const [documentName, setDocumentName] = useState("");

  useEffect(() => {
    if (selectedCompany?.documents) {
      setDocuments(selectedCompany.documents);
    }
  }, [selectedCompany]);

  const db = getFirestore();

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "company"),
      (querySnapshot) => {
        const companyData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setCompany(companyData);
        // console.log("companyData :>> ", companyData);
      }
    );
    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [db]);

  const [updatingRow, setUpdatingRow] = useState(null);

  const getNextClientCode = (currentCode) => {
    if (!currentCode) {
      return "A001";
    }
    const prefix = currentCode.slice(0, 1);
    const number = parseInt(currentCode.slice(1), 10);
    const nextNumber = number + 1;
    return `${prefix}${nextNumber.toString().padStart(3, "0")}`;
  };

  const getNextCompanyCode = (currentCode) => {
    if (!currentCode) {
      return "A001";
    }
    const prefix = currentCode.slice(0, 1);
    const number = parseInt(currentCode.slice(1), 10);
    const nextNumber = number + 1;
    return `${prefix}${nextNumber.toString().padStart(3, "0")}`;
  };

  const handleUpdateClick = async (company) => {
    console.log("company.id :>> ", company.id);
    setUpdatingRow(company.id);
    setupdating(true);

    try {
      const currentCompanyCode = company.company_code;
      if (currentCompanyCode && !currentCompanyCode.includes("*")) {
        toast.success("Company already updated!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }

      await runTransaction(db, async (transaction) => {
        // Read company and client code documents first
        const companyCodeDocRef = doc(db, "companyCode", "latest");
        const clientCodeDocRef = doc(db, "clientCode", "latest");

        const [companyCodeDocSnap, clientCodeDocSnap] = await Promise.all([
          transaction.get(companyCodeDocRef),
          transaction.get(clientCodeDocRef),
        ]);

        if (companyCodeDocSnap.exists() && clientCodeDocSnap.exists()) {
          let latestClientCode = clientCodeDocSnap.data().code;
          const latestCompanyCode = companyCodeDocSnap.data().code;

          // Read the company document
          const companyDocRef = doc(db, "company", company.id);
          const companyDocSnap = await transaction.get(companyDocRef);

          if (!companyDocSnap.exists()) {
            throw new Error("Company document does not exist.");
          }

          const currentCompanyData = companyDocSnap.data();

          // Calculate the expiry date (1 year from today)
          const today = new Date();
          today.setFullYear(today.getFullYear() + 1);
          const expiryDate = today.toISOString().split("T")[0];
          console.log("expiryDate :>> ", expiryDate);

          // Prepare updated company data
          const updatedCompanyData = {
            ...currentCompanyData,
            company_code: latestCompanyCode,
            customer_code: currentCompanyData.customer_code.replace("*", ""),
            expiryDate: expiryDate,
            updated_at: serverTimestamp(),
          };

          // Perform the update on the company document
          transaction.update(companyDocRef, updatedCompanyData);

          // Determine the next company code
          const nextCompanyCode = getNextCompanyCode(latestCompanyCode);

          // Perform the update on the company code document
          transaction.update(companyCodeDocRef, {
            code: nextCompanyCode,
            updatedAt: serverTimestamp(),
          });

          // Fetch and update clients' codes sequentially
          const clientsQuery = query(
            collection(db, "clients"),
            where("companyId", "==", company.id)
          );

          const clientsSnap = await getDocs(clientsQuery);

          if (clientsSnap.empty) {
            console.log("No clients found for this company.");
            return;
          }

          for (const clientDoc of clientsSnap.docs) {
            const clientDocRef = clientDoc.ref;

            // Prepare updated client data
            const updatedClientData = {
              ...clientDoc.data(),
              Personal: {
                ...clientDoc.data().Personal,
                client_code: latestClientCode, // Update client code
                client_type: "permanent",
              },
              updated_at: serverTimestamp(),
            };

            // Perform the update on the client document
            transaction.update(clientDocRef, updatedClientData);

            // Determine the next client code
            const nextClientCode = getNextClientCode(latestClientCode);
            console.log("nextClientCode :>> ", nextClientCode);

            // Update the client code document with the new latest code
            transaction.update(clientCodeDocRef, {
              code: nextClientCode,
              updatedAt: serverTimestamp(),
            });

            // Update the latest client code for the next iteration
            latestClientCode = nextClientCode;
          }
        } else {
          throw new Error("Company or client code document does not exist.");
        }
      });

      const customerQuery = query(
        collection(db, "customers"),
        where("Personal.companyId", "==", company.id)
      );

      const customerDocs = await getDocs(customerQuery);
      console.log("customerDocs :>> ", customerDocs);

      if (customerDocs.empty) {
        console.log("No matching customer documents found.");
        return;
      }

      const batch = writeBatch(db); // Create a new batch instance

      customerDocs.forEach((doc) => {
        const customerData = doc.data();

        const updatedCustomerData = {
          Personal: {
            ...customerData.Personal,
            customer_code: customerData.Personal.customer_code.replace("*", ""), // Remove asterisk from customer code
          },
          updated_at: serverTimestamp(),
        };

        batch.update(doc.ref, updatedCustomerData);
      });

      // Commit the batch
      await batch.commit();

      toast.success("Company permanent!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      console.log("Batch update committed successfully.");
    } catch (error) {
      console.log("error.message :>> ", error.message);
    } finally {
      setUpdatingRow(null);
      setupdating(false);
    }
  };

  const handleEditClick = (company) => {
    console.log("company :>> ", company);
    setSelectedCompany(company);
    setEditCompany(company);
    // console.log('customer :>> ', customer.id);
    setDialogOpen(true);
  };


  // Add new Documents
  
  const [open, setOpen] = useState(false);
  const [newDocuments, setNewDocuments] = useState([]);

  const fetchDocuments = async (companyId) => {
    try {
      const docRef = doc(db, "company", companyId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const companyData = docSnap.data();
        console.log("companyData :>> ", companyData.documents);
        // setOldDocuments(customerData.Personal?.documents || []);
        setDocuments(companyData.documents || []); // Set existing documents array
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const handleClickOpen = async (company) => {
    console.log('company.id :>> ', company.id);
    document.getElementById("fileInput").click();
    const selectCompanyID = company.id;
    setViewId(selectCompanyID);
    await fetchDocuments(selectCompanyID);
  };

  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const { name, value, files } = e.target;
    setOpen(true);
    if (name === "Newdocuments") {
      const filesArray = Array.from(e.target.files).map((file) => ({
        // file: file,
        name: file.name,
        url: URL.createObjectURL(file),
        editName: file.name,
      }));
      console.log("filesArray :>> ", filesArray);
      setNewDocuments((prevDocuments) => [...prevDocuments, ...filesArray]);
      setDocuments((prevDocuments) => [...prevDocuments]);
      console.log("documents :>> ", documents);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setNewDocuments([]);
    setDrawerOpen(false);
    setSelectedCompany(null);
  };

  const handleEditNameChange = (index, newName) => {
    setNewDocuments((prevDocuments) => {
      const updatedDocuments = [...prevDocuments];
      updatedDocuments[index].editName = newName;
      return updatedDocuments;
    });
  };

  const handleSaveNameChange = (index) => {
    setNewDocuments((prevDocuments) => {
      const updatedDocuments = [...prevDocuments];
      updatedDocuments[index].name = updatedDocuments[index].editName;
      return updatedDocuments;
    });
  };

  const handleDeleteDocument = (index) => {
    setNewDocuments((prevDocuments) => {
      const updatedDocuments = [...prevDocuments];
      updatedDocuments.splice(index, 1);
      return updatedDocuments;
    });
  };

  const handleNewDocumentsUpload = async () => {
    try {
      setLoading(true);

      const uploadedDocuments = [];
      for (let i = 0; i < newDocuments.length; i++) {
        const documentRef = ref(
          storage,
          `CompanyDocuments/${viewId}/${newDocuments[i].name}`
        );
        // console.log("documentRef :>> ", documentRef);
        await uploadBytes(documentRef, newDocuments[i].file);
        const documentURL = await getDownloadURL(documentRef);
        // console.log("documentURL :>> ", documentURL);
        uploadedDocuments.push({
          name: newDocuments[i].name,
          url: documentURL,
        });
      }

      console.log("uploadedDocuments :>> ", uploadedDocuments);

      const mergedDocuments = [...documents, ...uploadedDocuments];
      console.log("mergedDocuments :>> ", mergedDocuments);

      const docRef = doc(db, "company", viewId);
      await updateDoc(docRef, {
        // editCustomer,
        "documents": mergedDocuments,
        updated_at: serverTimestamp(),
      });

      toast.success("Documents uploaded successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setOpen(false);
    } catch (error) {
      console.error("Error uploading documents: ", error);
      console.log("Error: ", error);
      toast.error("Failed to upload documents. Please try again later.");
    } finally {
      setLoading(false);
      setNewDocuments([]);
      setDrawerOpen(false);
    }
  };

  //Delete Documents
  const handleDelete = async (docUrl) => {
    try {
      setLoading(true);

      // Reference to the Firestore document
      const docRef = doc(db, "company", selectedCompany.id);
      const companyDoc = await getDoc(docRef);

      if (companyDoc.exists()) {
        // Filter out the document that needs to be deleted
        const updatedDocuments = companyDoc
          .data()
          .documents.filter((doc) => doc.url !== docUrl);

        // Update Firestore with the remaining documents
        await updateDoc(docRef, {
          "documents": updatedDocuments,
        });

        // Delete the document from Firebase Storage
        const fileName = docUrl; // Extract file name from URL
        console.log("fileName :>> ", fileName);

        const documentRef = ref(storage, fileName);
        console.log("documentRef :>> ", documentRef);

        await deleteObject(documentRef).catch((error) => {
          console.error("Failed to delete document from storage:", error);
        });

        // Update state to reflect the deletion
        setDocuments((prevDocuments) =>
          prevDocuments.filter((doc) => doc.url !== docUrl)
        );

        console.log("Document successfully deleted!");
        toast.success("Document successfully deleted!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        console.log("No such document!");
        toast.error("No such document!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error("Error removing document: ", error);
      toast.error("Error removing document. Please try again.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setLoading(false);
    }
  };

  // Add new Company Documents
  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedCompany(null);
    setEditCompany(null);
    setCompanyDocumentDialog(false);
  };

  const handleCompanyDocDialogClose = (company) => {
    setSelectedCompany(null);
    setEditCompany(null);
    setCompanyDocumentDialog(false);
    setDrawerOpen(false);
  };

  const handleAddCompanyDocClick = (company) => {
    console.log("company.id :>> ", company.id);
    const cumpanyId = company.id;
    setViewId(cumpanyId);
    setSelectedCompany(company);
    setEditCompany(company);
    // console.log('customer :>> ', customer.id);
    setCompanyDocumentDialog(true);
  };

  const [companyDocuments, setCompanyDocuments] = useState([]);
  const [viewId, setViewId] = useState(null);

  const handleCompanyDocChange = (e) => {
    const { name, value } = e.target;

    if (name === "companyDocument") {
      const filesArray = Array.from(e.target.files).map((file) => ({
        file: file,
        name: file.name,
        url: URL.createObjectURL(file),
        documentName: editCompany.documentName,
        documentNumber: editCompany.documentNumber,
      }));
      setCompanyDocuments(filesArray);
      setEditCompany((prev) => ({ ...prev, companyDocument: filesArray }));
    } else {
      setEditCompany((prevCompany) => ({
        ...prevCompany,
        [name]: value,
      }));
    }
  };

  // const handleDocumentsUpload = async () => {
  //   try {
  //     setLoading(true);

  //     // Initialize an array to store new document URLs
  //     const newCompanyDocuments = [];

  //     // Reference to the Firestore document
  //     const docRef = doc(db, "company", viewId);

  //     // Get the existing documents from Firestore
  //     const docSnap = await getDoc(docRef);
  //     const existingDocuments = docSnap.data().companyDocument || [];

  //     // Loop through the new documents and upload them
  //     if (editCompany.companyDocument.length !== 0) {
  //       for (let i = 0; i < editCompany.companyDocument.length; i++) {
  //         const file = editCompany.companyDocument[i].file;
  //         const documentRef = ref(
  //           storage,
  //           `CompanyDocuments/${viewId}/${file.name}`
  //         );

  //         // Upload new document
  //         await uploadBytes(documentRef, file);

  //         // Get the URL for the uploaded document
  //         const companyDocumentURL = await getDownloadURL(documentRef);

  //         // Add the new document info to the array
  //         newCompanyDocuments.push({
  //           name: editCompany.companyDocument[i].name,
  //           url: companyDocumentURL,
  //           documentName: editCompany.documentName,
  //           documentNumber: editCompany.documentNumber,
  //         });
  //       }
  //     }

  //     console.log('newCompanyDocuments :>> ', newCompanyDocuments);

  //     // Update Firestore with new documents
  //     await updateDoc(docRef, {
  //       companyDocument: newCompanyDocuments,
  //       updated_at: serverTimestamp(),
  //     });

  //     // Delete old documents from storage
  //     for (let i = 0; i < existingDocuments.length; i++) {
  //       const oldDocumentRef = ref(storage, existingDocuments[i].url);

  //       // Delete the document
  //       await deleteObject(oldDocumentRef).catch(error => {
  //         console.error("Failed to delete old document:", error);
  //       });
  //     }

  //     toast.success("Documents uploaded successfully!", {
  //       position: "top-right",
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });

  //   } catch (error) {
  //     console.error("Error uploading documents: ", error);
  //     console.log("Error: ", error);
  //     toast.error("Failed to upload documents. Please try again later.");
  //   } finally {
  //     setCompanyDocumentDialog(false);
  //     setLoading(false);
  //   }
  // };

  const handleCompanyDocumentsUpload = async () => {
    try {
      setLoading(true);

      const companyDocument = [];
      if (editCompany.companyDocument.length !== 0) {
        for (let i = 0; i < editCompany.companyDocument.length; i++) {
          const documentRef = ref(
            storage,
            `CompanyDocuments/${viewId}/${editCompany.companyDocument[i].file.name}`
          );
          await uploadBytes(documentRef, editCompany.companyDocument[i].file);
          const companyDocumentURL = await getDownloadURL(documentRef);
          companyDocument.push({
            name: editCompany.companyDocument[i].name,
            url: companyDocumentURL,
            documentName: editCompany.documentName,
            documentNumber: editCompany.documentNumber,
          });
        }
      }
      console.log("companyDocument :>> ", companyDocument);

      const docRef = doc(db, "company", viewId);
      console.log("docRef :>> ", docRef);
      await updateDoc(docRef, {
        companyDocument: companyDocument,
        updated_at: serverTimestamp(),
      });

      toast.success("Documents uploaded successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error("Error uploading documents: ", error);
      console.log("Error: ", error);
      toast.error("Failed to upload documents. Please try again later.");
    } finally {
      setDrawerOpen(false);
      setCompanyDocumentDialog(false);
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditCompany({ ...editCompany, [name]: value });
  };

  const handleEditSubmit = async () => {
    try {
      setLoading(true);
      const docRef = doc(db, "company", editCompany.id);
      await updateDoc(docRef, {
        ...editCompany,
        reciveAmount: editCompany.reciveAmount,
        updated_at: serverTimestamp(),
      });
      setCompany((prevCustomer) =>
        prevCustomer.map((client) =>
          client.id === editCompany.id ? editCompany : client
        )
      );
      toast.success("Company Data Update successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setDialogOpen(false);
    } catch (error) {
      console.error("Error updating document: ", error);
    } finally {
      setLoading(false);
    }
  };

  const flattenData = () => {
    return company.map((item) => ({
      id: item.id,
      customer_code: item.Personal?.client_code || "-",
      name: item.name || "-",
      phone_number: item.phone_number || "-",
      email: item.email || "-",
      workwith: item.workwith || "-",
      address: item.address || "-",
    }));
  };

  const flattenedCompany = flattenData(company);

  const columns = [
    {
      name: "no",
      label: "No",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                width: "60px",
                maxWidth: "30px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: "center",
              }}
            >
              {tableMeta.rowIndex + 1}
            </div>
          );
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value) => {
          const capitalizeFirstLetter = (str) => {
            if (!str) return str;
            return str.charAt(0).toUpperCase() + str.slice(1);
          };
          return (
            <div
              style={{
                // maxWidth: "150px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {capitalizeFirstLetter(value)}
            </div>
          );
        },
      },
    },
    {
      name: "phone_number",
      label: "Mobile Number",
      options: {
        customBodyRender: (value) => (
          <div
            style={{
              maxWidth: "250px",
              width: "150px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {value}
          </div>
        ),
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        customBodyRender: (value) => (
          <div
            style={{
              // maxWidth: "150px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {value}
          </div>
        ),
      },
    },
    {
      name: "workwith",
      label: "Work with",
      options: {
        customBodyRender: (value) => {
          const capitalizeFirstLetter = (str) => {
            if (!str) return str;
            return str.charAt(0).toUpperCase() + str.slice(1);
          };

          return (
            <div
              style={{
                maxWidth: "200px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {capitalizeFirstLetter(value)}
            </div>
          );
        },
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        customBodyRender: (value) => {
          const capitalizeFirstLetter = (str) => {
            if (!str) return str;
            return str.charAt(0).toUpperCase() + str.slice(1);
          };
          return (
            <div
              style={{
                // maxWidth: "200px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {capitalizeFirstLetter(value)}
            </div>
          );
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        // filter: false,
        // sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const companyId = company[tableMeta.rowIndex].id;

          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {/* Eye */}
              <div>
                <IconButton
                  sx={{ pt: 0 }}
                  onClick={() => handleIconClick(company[tableMeta.rowIndex])}
                >
                  <VisibilityIcon />
                </IconButton>
              </div>

              {/* Edit company */}
              <div>
                <IconButton
                  sx={{ pt: 0 }}
                  onClick={() => handleEditClick(company[tableMeta.rowIndex])}
                >
                  <ModeEditOutlineIcon />
                </IconButton>
              </div>

              {/* Update */}
              <div>
                <IconButton
                  sx={{ pt: 0 }}
                  onClick={() => handleUpdateClick(company[tableMeta.rowIndex])}
                  disabled={updatingRow === companyId} // Disable if updatingRow matches the current company id
                >
                  {updatingRow === companyId ? (
                    <CircularProgress size={24} />
                  ) : (
                    <CachedIcon />
                  )}
                </IconButton>
              </div>
            </div>
          );
        },
      },
    },
  ];

  const rows = 10; // Number of skeleton rows
  const columnsCount = 7; // Number of columns

  const options = {
    filterType: "multiselect",
    responsive: "standard",
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: loading ? (
          <Skeleton rows={rows} columns={columnsCount} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleIconClick = (data) => {
    setSelectedCompany(data);
    // console.log("selectedCompany :>> ", data);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedCompany(null);
  };

  const handleCompanyDocument = () => {
    document.getElementById("singleFileInput").click();
  };

  return (
    <>
      <MetaTags>
        <title>All Companys</title>
      </MetaTags>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <Sidebar />
          <Box sx={{ flex: 1, backgroundColor: "#F4F7F9", overflow: "hidden" }}>
            <Navbar />
            <Box
              sx={{
                p: 2,
              }}
            >
              <Box sx={{ padding: "1%" }}>
                <Box
                  borderRadius="17px"
                  sx={{ boxShadow: "0px 9px 20px rgba(46, 35, 94, 0.07)" }}
                  overflow="hidden"
                >
                  <CustomMuiPaper>
                    <MuiDatatables
                      data={
                        loading
                          ? []
                          : flattenedCompany && flattenedCompany.length > 0
                          ? flattenedCompany
                          : []
                      }
                      columns={columns}
                      options={{
                        ...options,
                        searchOpen: true,
                        searchAlwaysOpen: true,
                        searchPlaceholder: "Search keyword",
                        customSearchRender: (searchText, handleSearch) => {
                          return (
                            <CustomSearchRender
                              searchText={searchText}
                              onSearch={handleSearch}
                            />
                          );
                        },
                      }}
                    />
                  </CustomMuiPaper>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle style={{ fontFamily: '"Montserrat", sans-serif' }}>
            Update Company Details
          </DialogTitle>
          <DialogContent>
            {selectedCompany && (
              <Box sx={{ padding: 1 }}>
                <Divider />
                <Box sx={{ marginY: 2 }}>
                  <TextField
                    label="Company name"
                    name="name"
                    value={editCompany?.name || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    type="number"
                    label="Phone Number"
                    name="phone_number"
                    value={editCompany?.phone_number || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Office Email"
                    name="email"
                    value={editCompany?.email || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    type="number"
                    label="AMC Amount"
                    name="amcAmount"
                    value={editCompany?.amcAmount || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    type="number"
                    label="Package Ammount"
                    name="packageAmount"
                    value={editCompany?.packageAmount || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    type="number"
                    label="Per User Amount"
                    name="perUserAmount"
                    value={editCompany?.perUserAmount || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Work with"
                    name="workwith"
                    value={editCompany?.workwith || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Address"
                    name="address"
                    value={editCompany?.address || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    label="Recive Amount"
                    name="reciveAmount"
                    type="number"
                    value={editCompany?.reciveAmount || ""}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />
                </Box>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleDialogClose}
              variant="outlined"
              sx={{ border: "1px solid #57dd36", color: "#57dd36", p: 1 }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleEditSubmit}
              variant="contained"
              disabled={loading} // Assuming `loading` state indicates loading state
              // startIcon={loading ? <CircularProgress size={24} /> : null}
              sx={{ backgroundColor: "#57dd36", p: 1 }}
            >
              {loading ? <CircularProgress size={24} /> : "Save Changes"}
            </Button>
          </DialogActions>
        </Dialog>

        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={handleDrawerClose}
          sx={{ "& .MuiDrawer-paper": { width: 460, padding: 2 } }}
        >
          {selectedCompany && (
            <Box sx={{ padding: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  onClick={handleDrawerClose}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="48"
                      d="M328 112L184 256l144 144"
                    />
                  </svg>
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Company Details
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ marginY: 2 }}>
                {selectedCompany.name && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Name:</h5> {capitalizeFirstLetter(selectedCompany.name)}
                  </Typography>
                )}

                {selectedCompany.phone_number && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Mobile:</h5> {selectedCompany.phone_number}
                    {/* .replace(/^\+91(\d)/, '+91 $1') */}
                  </Typography>
                )}

                {selectedCompany.email && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Email:</h5> {selectedCompany.email}
                  </Typography>
                )}

                {selectedCompany.amcAmount && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>AMC Amount:</h5> {selectedCompany.amcAmount}
                  </Typography>
                )}
                {selectedCompany.packageAmount && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Package Amount:</h5> {selectedCompany.packageAmount}
                  </Typography>
                )}
                {selectedCompany.perUserAmount && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Per User Amount:</h5> {selectedCompany.perUserAmount}
                  </Typography>
                )}

                {selectedCompany?.reciveAmount && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Recive Amount:</h5> {selectedCompany.reciveAmount}
                  </Typography>
                )}

                {selectedCompany.workwith && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Work With:</h5>{" "}
                    {capitalizeFirstLetter(selectedCompany.workwith)}
                  </Typography>
                )}

                {selectedCompany.address && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Address:</h5>{" "}
                    {capitalizeFirstLetter(selectedCompany.address)}
                  </Typography>
                )}

                {selectedCompany.companyEmployee !== 0 && (
                  <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                    <h5>Company Employee:</h5>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      {selectedCompany.companyEmployee.map((user, index) => (
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          <Box>
                            <p key={index}>{user.name}</p>
                          </Box>
                          <Box>
                            <div key={index}>
                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp; &nbsp; {user.role}
                            </div>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Typography>
                )}

                <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <h5>Company Document:</h5>
                    <Button
                      sx={{ display: "flex", justifyContent: "center", width:"6%", minWidth:"6%" }}
                      onClick={() => handleAddCompanyDocClick(selectedCompany)}
                    >
                      <Add size={28} sx={{ color: "#3bd615" }} />
                    </Button>
                  </Box>
                  {selectedCompany.companyDocument &&
                    selectedCompany.companyDocument.length > 0 && (
                      <>
                        {selectedCompany.companyDocument.map((docName) => (
                          <div
                            key={docName.url}
                            style={{
                              display: "flex",
                              alignItems: "start",
                              justifyContent: "start",
                              flexDirection: "column",
                            }}
                          >
                            <p>Name: {docName.documentName}</p>
                            <p>Number: {docName.documentNumber}</p>
                            <a
                              href={docName.url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                              {docName.name},
                            </a>
                            {/* <p
                            // onClick={() => handleDelete(docName.url)}
                            sx={{ marginLeft: "10px" }}
                          >
                            <Delete sx={{ color: "#d60000" }} />
                          </p> */}
                          </div>
                        ))}
                      </>
                    )}
                </Typography>

                <Typography variant="body1" sx={{ margin: "10px 0px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <h5>Document:</h5>
                    <Button
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "6%",
                        minWidth: "6%",
                      }}
                      onClick={() => handleClickOpen(selectedCompany)}
                    >
                      <Add size={28} sx={{ color: "#3bd615" }} />
                      <input
                        name="Newdocuments"
                        type="file"
                        id="fileInput"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        multiple
                      />
                    </Button>
                  </Box>
                  {selectedCompany.documents &&
                    selectedCompany.documents.length > 0 && (
                      <>
                        {documents.map((docName) => (
                          <div
                            key={docName.url}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <a
                              href={docName.url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                              {docName.name}, <br />
                            </a>
                            <p
                            onClick={() => handleDelete(docName.url)}
                            sx={{ marginLeft: "10px" }}
                          >
                            <Delete sx={{ color: "#d60000" }} />
                          </p>
                          </div>
                        ))}
                      </>
                    )}
                </Typography>

              </Box>
            </Box>
          )}
        </Drawer>

        {/* Add Company Document Dialog */}
        <Dialog
          open={companyDocumentDialog}
          onClose={handleCompanyDocDialogClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle style={{ fontFamily: '"Montserrat", sans-serif' }}>
            Add Company Document
          </DialogTitle>
          <DialogContent sx={{ overflowX: "hidden", px: 0 }}>
            <Grid container spacing={2} style={{ margin: "0 1%" }}>
              {/* Document Name */}
              <Grid item xs={10}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ minWidth: "100px", width: "145px" }}>
                      Document Name
                    </Typography>
                  </Box>
                  <TextField
                    fullWidth
                    variant="outlined"
                    sx={{ height: "45px" }}
                    name="documentName"
                    value={editCompany?.documentName}
                    onChange={handleCompanyDocChange}
                  />
                </Box>
              </Grid>

              {/* Document no. */}
              <Grid item xs={10}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {/* <Email sx={{ mr: 2 }} /> */}
                    <Typography sx={{ minWidth: "100px", width: "145px" }}>
                      Document no.
                    </Typography>
                  </Box>
                  <TextField
                    fullWidth
                    variant="outlined"
                    sx={{ height: "45px" }}
                    name="documentNumber"
                    value={editCompany?.documentNumber}
                    onChange={handleCompanyDocChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={10}>
                <Box
                  sx={{ display: "flex", alignItems: "center", height: "45px" }}
                >
                  <input
                    type="file"
                    id="singleFileInput"
                    name="companyDocument"
                    onChange={handleCompanyDocChange}
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCompanyDocDialogClose}
              variant="outlined"
              sx={{ border: "1px solid #57dd36", color: "#57dd36", p: 1 }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleCompanyDocumentsUpload}
              variant="contained"
              disabled={loading} // Assuming `loading` state indicates loading state
              // startIcon={loading ? <CircularProgress size={24} /> : null}
              sx={{
                backgroundColor: "#57dd36",
                p: 1,
                display: "flex",
                justifyContent: "center",
              }}
            >
              {loading ? <CircularProgress size={24} /> : "Add"}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Add New Documents */}
        <Dialog open={open}>
          <DialogTitle>Documents</DialogTitle>
          <DialogContent>
            <List>
              {newDocuments.map((doc, index) => (
                <ListItem
                  key={index}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <ListItemText
                    primary={
                      <TextField
                        value={doc.editName}
                        onChange={(e) =>
                          handleEditNameChange(index, e.target.value)
                        }
                        variant="outlined"
                        size="small"
                        onBlur={() => handleSaveNameChange(index)}
                      />
                    }
                  />
                  <IconButton
                    edge="end"
                    onClick={() => handleDeleteDocument(index)}
                  >
                    <Delete />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
            <Button
              onClick={handleNewDocumentsUpload}
              color="primary"
              disabled={loading}
            >
              {loading ? "Uploading..." : "Upload"}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default AllCompany;
