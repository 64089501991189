import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import MuiDatatables from "mui-datatables";
import IconButton from "@mui/material/IconButton";
import MetaTags from "react-meta-tags";
import { Visibility as VisibilityIcon } from "@material-ui/icons";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import {
  Skeleton,
  Radio,
  Paper,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Divider,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  CircularProgress,
} from "@mui/material";
import { db, storage } from "../../components/utils/firebase";
import Navbar from "../../components/Navbar/Navbar";
import { CustomerProvider } from "../../components/Navbar/CustomerContext";
import CustomSearchRender from "../../components/CustomSearchRender";
import PRIcon from "../../components/images/PR-icon.png";
import { withStyles } from "@mui/styles";
import { Check } from "@mui/icons-material";
import { toast } from "react-toastify";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const theme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          fontFamily: '"Montserrat", sans-serif',
          display: "flex",
          flexDirection: "column",
          height: "auto",
          width: "auto",
          // padding: "10px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          height: "100%",
          // overflow: 'auto',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          flex: "1 1 auto", // Allow the table container to grow and shrink
        },
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          marginTop: "auto", // This ensures the footer stays at the bottom
        },
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          height: "20px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: '"Montserrat", sans-serif',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
          fontWeight: 600,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: "0px",
          //   backgroundColor: "#0b0b0b",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: "0px",
          height: "45px",
          width: "100%",
          marginLeft: "5px",
          //   backgroundColor: "#0b0b0b",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
          padding: "0px",
          textAlign: "start",
          justifyContent: "start",
          fontWeight: 600,
        },
      },
    },
  },
  MuiTableCell: {
    root: {
      borderColor: "#d3d3d3",
      fontWeight: 600,
    },
    head: {
      background: "lightgrey",
      "&:not(:last-child)": {
        borderRight: [[1, "solid", "#c0c0c0"]],
      },
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

const CustomMuiPaper = styled(Paper)({
  display: "flex",
  flexDirection: "column",
  height: "auto",
  maxHeight: "80vh",
  overflow: "auto",
});

// const fetchClientData = (id, setCustomers, setFilteredCustomers, setLoading, setError) => {
//   const docRef = doc(db, "clients", id);

//   const unsubscribeClient = onSnapshot(docRef, (docSnap) => {
//     if (docSnap.exists()) {
//       const companyId = docSnap.data().companyId;

//       const gicQuery = query(
//         collection(db, "gic"),
//         where("companyId", "==", companyId),
//         orderBy("name")
//       );

//       const thirdPartyQuery = query(
//         collection(db, "thirdPartyPolicy"),
//         where("companyId", "==", companyId),
//         orderBy("name")
//       );

//       const handleSnapshot = (snapshot, source) => {
//         return snapshot.docs.map((doc) => ({
//           id: doc.id,
//           source,
//           ...doc.data(),
//         }));
//       };

//       const unsubscribeGic = onSnapshot(gicQuery, (gicSnapshot) => {
//         const gicData = handleSnapshot(gicSnapshot, "GIC");
//         setCustomers((prevCustomers) => {
//           const otherData = prevCustomers.filter(
//             (item) => item.source !== "GIC"
//           );
//           return [...otherData, ...gicData].sort((a, b) => a.name.localeCompare(b.name));;
//         });
//         setFilteredCustomers((prevFilteredCustomers) => {
//           const otherData = prevFilteredCustomers.filter(
//             (item) => item.source !== "GIC"
//           );
//           return [...otherData, ...gicData].sort((a, b) => a.name.localeCompare(b.name));;
//         });
//       }, (err) => {
//         console.error("Error fetching GIC data:", err);
//         setError(err.message);
//         setLoading(false);
//       });

//       const unsubscribeThirdParty = onSnapshot(thirdPartyQuery, (thirdPartySnapshot) => {
//         const thirdPartyData = handleSnapshot(thirdPartySnapshot, "TPP");
//         setCustomers((prevCustomers) => {
//           const otherData = prevCustomers.filter(
//             (item) => item.source !== "TPP"
//           );
//           return [...otherData, ...thirdPartyData].sort((a, b) => a.name.localeCompare(b.name));;
//         });
//         setFilteredCustomers((prevFilteredCustomers) => {
//           const otherData = prevFilteredCustomers.filter(
//             (item) => item.source !== "TPP"
//           );
//           return [...otherData, ...thirdPartyData].sort((a, b) => a.name.localeCompare(b.name));;
//         });
//         setLoading(false);
//       }, (err) => {
//         console.error("Error fetching third-party policy data:", err);
//         setError(err.message);
//         setLoading(false);
//       });

//       // Return a function that unsubscribes from both queries
//       return () => {
//         unsubscribeGic();
//         unsubscribeThirdParty();
//       };
//     } else {
//       console.log("No such document!");
//       setError("No such document!");
//       setLoading(false);
//     }
//   }, (err) => {
//     console.error("Error fetching document:", err);
//     setError(err.message);
//     setLoading(false);
//   });

//   // Return the unsubscribe function to clean up the listener
//   return unsubscribeClient;
// };

const PremiumReminders = () => {
  const [customers, setCustomers] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [selectedPolicy, setSelectedPolicy] = useState("");
  const [selectedGicPolicy, setSelectedGicPolicy] = useState("");
  const [customerNumber, setCustomerNumber] = useState("");
  const [message, setMessage] = useState("");
  const [statusCount, setStatusCount] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [renewSubmitting, setRenewSubmitting] = useState(false);

  const [reminder, setReminder] = useState({
    premiumAmount: null,
    policyId: null,
    reminderMsg: "Reminder sent",
  });

  const [Flow, setFlow] = useState([
    { index: 0, reminderSent: false },
    { index: 1, premiumReceived: false, type: "Cash" },
    { index: 2, premiumPaid: false },
    { index: 3, policyRenewed: false },
    { index: 4, dataUpdated: false },
  ]);

  const [renewData, setRenewData] = useState({
    name: "",
    planName: "",
    policyNumber: "",
    policyType: "",
    policyTerm: "",
    premiumAmount: "",
    startDate: "",
    endDate: "",
    personalCode: "",
    companyName: "",
    companyCode: "",
    planNumber: "",
    remarks: "",
    documents: [],
    gic_code: "",
    customerId: null,
    reminderDate: [
      {
        date: null,
        status: false,
      },
    ],
    notificationDate: [
      {
        date: null,
        status: false,
      },
    ],
  });

  const [thirdPartyData, setThirdPartyData] = useState({
    name: null,
    startDate: "",
    endDate: "",
    howManyYear: null,
    companyName: "",
    companyCode: "",
    premiumAmount: "",
    policyNumber: "",
    vehicleName: "",
    vehicleDetails: "",
    ref_by: null,
    customerId: null,
    reminderDate: [{ date: null, status: false }],
    notificationDate: [{ date: null, status: false }],
  });

  const [openRenewDialog, setRenewOpenDialog] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "endDate") {
      const reminderDates = generateReminderDates(value);
      const notificationDates = generateNotificationDate(value);

      if (selectedPolicy.source === "GIC") {
        setRenewData((prev) => ({
          ...prev,
          [name]: value,
          reminderDate: reminderDates,
          notificationDate: notificationDates,
        }));
      } else {
        setThirdPartyData((prev) => ({
          ...prev,
          [name]: value,
          reminderDate: reminderDates,
          notificationDate: notificationDates,
        }));
      }
    } else {
      if (selectedPolicy.source === "GIC") {
        setRenewData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else {
        setThirdPartyData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }
  };

  const generateReminderDates = (endDate) => {
    const date = new Date(endDate);
    console.log("date :>> ", date);
    const reminderDates = [];

    const addDays = (date, days) => {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    };

    const formatDate = (date) => {
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    };

    reminderDates.push({ date: formatDate(addDays(date, -30)), status: false });
    reminderDates.push({ date: formatDate(addDays(date, -15)), status: false });
    reminderDates.push({ date: formatDate(addDays(date, -7)), status: false });

    console.log("reminderDates :>> ", reminderDates);
    return reminderDates;
  };

  const generateNotificationDate = (endDate) => {
    const date = new Date(endDate);
    console.log("date :>> ", date);
    const notificationDates = [];

    const addDays = (date, days) => {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    };

    const formatDate = (date) => {
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    };

    notificationDates.push({
      date: formatDate(addDays(date, -31)),
      status: false,
    });
    notificationDates.push({
      date: formatDate(addDays(date, -16)),
      status: false,
    });
    notificationDates.push({
      date: formatDate(addDays(date, -8)),
      status: false,
    });

    console.log("notificationDates :>> ", notificationDates);
    return notificationDates;
  };

  const handleCloseRenewDialog = () => {
    setRenewOpenDialog(false);

    const newFlow = [...Flow];
    newFlow[3].policyRenewed = false;
    setFlow(newFlow);
  };

  const handleCheckboxChange = (index, key) => (event) => {
    const newFlow = [...Flow];
    newFlow[index][key] = event.target.checked;

    // Handle automatic selections based on dependencies
    if (key === "dataUpdated" && event.target.checked) {
      newFlow[0].reminderSent = true;
      newFlow[1].premiumReceived = true;
      newFlow[2].premiumPaid = true;
    } else if (key === "premiumPaid" && event.target.checked) {
      newFlow[1].premiumReceived = true;
      newFlow[0].reminderSent = true;
    } else if (key === "premiumReceived" && event.target.checked) {
      newFlow[0].reminderSent = true;
    }

    setFlow(newFlow);

    if (key === "policyRenewed" && event.target.checked) {
      setRenewOpenDialog(true);
    }
  };

  const handleRadioChange = (index, value) => {
    setFlow((prevFlow) =>
      prevFlow.map((item) => (item.index === index ? { ...item, value } : item))
    );
  };

  const updateReminder = async () => {
    setLoading(true);

    try {
      const reminderSnap = await getDocs(
        query(
          collection(db, "reminder"),
          where("policyId", "==", selectedPolicy.id)
        )
      );

      if (!reminderSnap.empty) {
        const docRef = reminderSnap.docs[0].ref;

        const currentDate = new Date();

        // Check if all items in Flow are selected
        const allSelected =
          Flow[0].reminderSent &&
          Flow[1].premiumReceived &&
          Flow[2].premiumPaid &&
          Flow[3].policyRenewed &&
          Flow[4].dataUpdated;

        // Define the Flow array with the required format
        const updatedFlow = [
          {
            index: 0,
            reminderSent: Flow[0].reminderSent,
            created_at: currentDate,
            updated_at: currentDate,
          },
          {
            index: 1,
            premiumReceived: Flow[1].premiumReceived,
            type: Flow[1].value,
            created_at: currentDate,
            updated_at: currentDate,
          },
          {
            index: 2,
            premiumPaid: Flow[2].premiumPaid,
            created_at: currentDate,
            updated_at: currentDate,
          },
          {
            index: 3,
            policyRenewed: Flow[3].policyRenewed,
            created_at: currentDate,
            updated_at: currentDate,
          },
          {
            index: 4,
            dataUpdated: Flow[4].dataUpdated,
            created_at: currentDate,
            updated_at: currentDate,
          },
        ];

        await updateDoc(docRef, {
          Flow: updatedFlow,
          updated_at: serverTimestamp(),
        });

        // Check if selectedPolicy.id exists in the "gic" collection
        const gicDocRef = doc(db, "gic", selectedPolicy.id);
        const gicDocSnap = await getDoc(gicDocRef);

        if (gicDocSnap.exists()) {
          // Update the "gic" collection
          if (allSelected) {
            await updateDoc(doc(db, "gic", selectedPolicy.id), {
              reminderDate: selectedPolicy.reminderDate.map((item) => ({
                ...item,
                status: true,
              })),
              notificationDate: selectedPolicy.notificationDate.map((item) => ({
                ...item,
                status: true,
              })),
            });
          }
        } else {
          // If it doesn't exist in "gic", add/update in "thirdPartyPolicy" collection
          if (allSelected) {
            await updateDoc(doc(db, "thirdPartyPolicy", selectedPolicy.id), {
              reminderDate: selectedPolicy.reminderDate.map((item) => ({
                ...item,
                status: true,
              })),
              notificationDate: selectedPolicy.notificationDate.map((item) => ({
                ...item,
                status: true,
              })),
            });
          }
        }

        toast.success("Reminder updated successfully!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log("flow :>> ", Flow);

        setFlow([
          { index: 0, reminderSent: false },
          { index: 1, premiumReceived: false, type: "Cash" },
          { index: 2, premiumPaid: false },
          { index: 3, policyRenewed: false },
          { index: 4, dataUpdated: false },
        ]);
      } else {
        toast.error("No document found!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
      setOpenDialog(false);
    }
  };

  const fetchClientData = (id) => {
    // Fetch cached customers

    const lastFetchTimeRaw = sessionStorage.getItem("localGICDataFetchTime");

    // console.log('lastFetchTimeRaw :>> ', lastFetchTimeRaw);

    const lastFetchTime = lastFetchTimeRaw
      ? new Date(lastFetchTimeRaw)
      : new Date(0);

    const cachedPolicy =
      JSON.parse(sessionStorage.getItem("localGICPolicyData")) || [];
    setFilteredCustomers(cachedPolicy);

    // console.log('cachedPolicy :>> ', cachedPolicy);

    const lastFetchTPPTimeRaw = sessionStorage.getItem(
      "localTPPolicyDataFetchTime"
    );
    // console.log('lastFetchTPPTimeRaw :>> ', lastFetchTPPTimeRaw);

    // Fetch cached customers
    const cachedTPPPolicy =
      JSON.parse(sessionStorage.getItem("localTPPolicyData")) || [];
    setFilteredCustomers(cachedTPPPolicy);

    const docRef = doc(db, "clients", id);

    const unsubscribeClient = onSnapshot(
      docRef,
      (docSnap) => {
        if (docSnap.exists()) {
          const companyId = docSnap.data().companyId;

          const gicQuery = query(
            collection(db, "gic"),
            where("companyId", "==", companyId),
            orderBy("name")
          );

          const thirdPartyQuery = query(
            collection(db, "thirdPartyPolicy"),
            where("companyId", "==", companyId),
            orderBy("name")
          );

          const handleSnapshot = (snapshot, source) => {
            return snapshot.docs.map((doc) => ({
              id: doc.id,
              source,
              ...doc.data(),
            }));
          };

          const unsubscribeGic = onSnapshot(
            gicQuery,
            (gicSnapshot) => {
              const gicData = handleSnapshot(gicSnapshot, "GIC");
              console.log('gicData :>> ', gicData);
              setCustomers((prevCustomers) => {
                const otherData = prevCustomers.filter(
                  (item) => item.source !== "GIC"
                );
                return [...otherData, ...gicData].sort((a, b) =>
                  a.name.localeCompare(b.name)
                );
              });
              setFilteredCustomers((prevFilteredCustomers) => {
                const otherData = prevFilteredCustomers.filter(
                  (item) => item.source !== "GIC"
                );
                return [...otherData, ...gicData].sort((a, b) =>
                  a.name.localeCompare(b.name)
                );
              });
              setLoading(false);
            },
            (err) => {
              console.error("Error fetching GIC data:", err);
              setError(err.message);
              setLoading(false);
            }
          );

          const unsubscribeThirdParty = onSnapshot(
            thirdPartyQuery,
            (thirdPartySnapshot) => {
              const thirdPartyData = handleSnapshot(thirdPartySnapshot, "TPP");
              setCustomers((prevCustomers) => {
                const otherData = prevCustomers.filter(
                  (item) => item.source !== "TPP"
                );
                return [...otherData, ...thirdPartyData].sort((a, b) =>
                  a.name.localeCompare(b.name)
                );
              });
              setFilteredCustomers((prevFilteredCustomers) => {
                const otherData = prevFilteredCustomers.filter(
                  (item) => item.source !== "TPP"
                );
                return [...otherData, ...thirdPartyData].sort((a, b) =>
                  a.name.localeCompare(b.name)
                );
              });
              setLoading(false);
            },
            (err) => {
              console.error("Error fetching third-party policy data:", err);
              setError(err.message);
              setLoading(false);
            }
          );

          // Return a function that unsubscribes from both queries
          return () => {
            unsubscribeGic();
            unsubscribeThirdParty();
          };
        } else {
          console.log("No such document!");
          setError("No such document!");
          setLoading(false);
        }
      },
      (err) => {
        console.error("Error fetching document:", err);
        setError(err.message);
        setLoading(false);
      }
    );

    // Return the unsubscribe function to clean up the listener
    return unsubscribeClient;
  };

  useEffect(() => {
    const storedClientId = sessionStorage.getItem("clientId");
    setClientId(storedClientId);
    if (storedClientId) {
      setLoading(true);
      const unsubscribe = fetchClientData(storedClientId);

      return () => unsubscribe && unsubscribe();
    } else {
      setLoading(false);
    }
  }, []);

  const handleChange = async (e) => {
    const { name, value, files } = e.target;
    setReminder((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const reminderSnap = await getDocs(
        query(
          collection(db, "reminder"),
          where("policyId", "==", selectedPolicy.id)
        )
      );

      console.log("reminderSnap :>> ", reminderSnap);

      if (!reminderSnap.empty) {
        const docRef = reminderSnap.docs[0].ref;
        console.log("docRef :>> ", docRef);
        await updateDoc(docRef, {
          premiumAmount: reminder.premiumAmount,
          updated_at: serverTimestamp(),
        });
      } else {
        const docRef = await addDoc(collection(db, "reminder"), {
          premiumAmount: reminder.premiumAmount,
          policyId: selectedPolicy.id,
          reminderMsg: "Reminder sent",
          created_at: serverTimestamp(),
          updated_at: serverTimestamp(),
        });

        const reminderId = docRef.id;
        await updateDoc(doc(db, "reminder", reminderId), {
          id: reminderId,
        });
      }

      // Check if selectedPolicy.id exists in the "gic" collection
      // const gicDocRef = doc(db, "gic", selectedPolicy.id);
      // const gicDocSnap = await getDoc(gicDocRef);

      // if (gicDocSnap.exists()) {
      //   // Update the "gic" collection
      //   await updateDoc(gicDocRef, {
      //     premiumAmount: reminder.premiumAmount,
      //     updated_at: serverTimestamp(),
      //   });
      // } else {
      //   // If it doesn't exist in "gic", add/update in "thirdPartyPolicy" collection
      //   const thirdPartyDocRef = doc(db, "thirdPartyPolicy", selectedPolicy.id);
      //   const thirdPartyDocSnap = await getDoc(thirdPartyDocRef);

      //   if (thirdPartyDocSnap.exists()) {
      //     await updateDoc(thirdPartyDocRef, {
      //       premiumAmount: reminder.premiumAmount,
      //       updated_at: serverTimestamp(),
      //     });
      //   }
      // }

      toast.success("Reminder Submit successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      console.log("reminder :>> ", reminder);
    } catch (error) {
      console.log(error.message);
    } finally {
      setSubmitting(false);
      setOpenDialog(false);
    }
  };

  const handleIconClick = async (value) => {
    console.log("value :>> ", value.id);
    console.log("value :>> ", value);
    const oldGicId = value.id;
    setSelectedGicPolicy(oldGicId);
    setSelectedPolicy(value); // Assuming value has a property 'policyName'
    // setReminder(value);
    setOpenDialog(true);

    const countTrueStatuses = value?.reminderDate
      .map((item, index) => (item.status ? index + 1 : "_"))
      .join(" ");
    setStatusCount(countTrueStatuses);

    try {
      const reminderSnap = await getDocs(
        query(collection(db, "reminder"), where("policyId", "==", value.id))
      );

      if (!reminderSnap.empty) {
        const docRef = reminderSnap.docs[0].ref;
        const docSnap = await getDoc(docRef);
        console.log("docSnap.data() :>> ", docSnap.data());
        const flowData = docSnap.data().Flow;

        // Check which checkboxes should be initially selected based on Flow data
        const initialFlowState = flowData.map((item) => {
          return {
            ...item,
            // Check conditions to set initial checkbox state
            checked:
              (item.reminderSent && item.reminderSent === true) ||
              (item.premiumReceived && item.premiumReceived === true) ||
              (item.premiumPaid && item.premiumPaid === true) ||
              (item.policyRenewed && item.policyRenewed === true) ||
              (item.dataUpdated && item.dataUpdated === true),
            value: item.type || item.value,
          };
        });

        setFlow(initialFlowState);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleWhatsAppClick = async (value) => {
    try {
      console.log("value :>> ", value.customerId);
      console.log("value :>> ", value);
  
      const oldGicId = value.id;
      setSelectedGicPolicy(oldGicId);
      setSelectedPolicy(value);
      setReminder(value);
  
      // Fetch customer and reminder data in parallel
      const [customerDoc, reminderSnap] = await Promise.all([
        getDoc(doc(db, "customers", value.customerId)),
        getDocs(
          query(collection(db, "reminder"), where("policyId", "==", value.id))
        ),
      ]);
  
      if (customerDoc.exists()) {
        const customerData = customerDoc.data();
        const customerPhoneNumber = customerData.Personal.phone_number;
        console.log("Customer Data: ", customerPhoneNumber);
        setCustomerNumber(customerPhoneNumber);
  
        if (!reminderSnap.empty) {
          const reminderDoc = reminderSnap.docs[0];
          if (reminderDoc.exists()) {
            const reminderMessage = reminderDoc.data().reminderMsg;
  
            // Ensure reminderMessage is not empty
            if (!reminderMessage || reminderMessage.trim() === '') {
              console.error("Reminder message is empty or undefined.");
              return;
            }
  
            console.log('reminderMessage :>> ', reminderMessage);
            setMessage(reminderMessage);
  
            const gicDocRef = doc(db, "gic", value.id);
            const gicDocSnap = await getDoc(gicDocRef);
  
            const updateSingleReminderDate = (reminderDate) => {
              // Find the first index with status `false` and update it to `true`
              for (let i = 0; i < reminderDate.length; i++) {
                if (reminderDate[i].status === false) {
                  reminderDate[i].status = true;
                  break;
                }
              }
              return reminderDate;
            };
  
            const updatedReminderDate = updateSingleReminderDate(value.reminderDate);
  
            if (gicDocSnap.exists()) {
              await updateDoc(gicDocRef, {
                reminderDate: updatedReminderDate,
              });
            } else {
              await updateDoc(doc(db, "thirdPartyPolicy", value.id), {
                reminderDate: updatedReminderDate,
              });
            }
  
            // Construct WhatsApp URL
            const whatsappUrl = `https://wa.me/${customerPhoneNumber}?text=${encodeURIComponent(reminderMessage)}`;
            console.log('whatsappUrl :>> ', whatsappUrl);
  
            // Open WhatsApp URL
            window.open(whatsappUrl, "_blank");
          } else {
            console.error("No reminder message found.");
          }
        } else {
          console.log("No reminders available.");
        }
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  
  // const handleWhatsAppClick = async (value) => {
  //   try {
  //     console.log("value :>> ", value.customerId);
  //     console.log("value :>> ", value);
  
  //     const oldGicId = value.id;
  //     setSelectedGicPolicy(oldGicId);
  //     setSelectedPolicy(value);
  //     setReminder(value);
  
  //     // Fetch customer and reminder data in parallel
  //     const [customerDoc, reminderSnap] = await Promise.all([
  //       getDoc(doc(db, "customers", value.customerId)),
  //       getDocs(
  //         query(collection(db, "reminder"), where("policyId", "==", value.id))
  //       ),
  //     ]);
  
  //     if (customerDoc.exists()) {
  //       const customerData = customerDoc.data();
  //       const customerPhoneNumber = customerData.Personal.phone_number;
  //       console.log("Customer Data: ", customerPhoneNumber);
  //       setCustomerNumber(customerPhoneNumber);
  
  //       if (!reminderSnap.empty) {
  //         const reminderDoc = reminderSnap.docs[0];
  //         if (reminderDoc.exists()) {
  //           const reminderMessage = reminderDoc.data().reminderMsg;
  //           console.log('reminderMessage :>> ', reminderMessage);
  //           setMessage(reminderMessage);

  //           // Construct WhatsApp URL
  //           const whatsappUrl = `https://wa.me/${customerPhoneNumber}?text=${encodeURIComponent(reminderMessage)}`;
  //           console.log('whatsappUrl :>> ', whatsappUrl);
  
  //           // Open WhatsApp URL
  //           window.open(whatsappUrl, "_blank");
  
  //           const gicDocRef = doc(db, "gic", value.id);
  //           const gicDocSnap = await getDoc(gicDocRef);
  
  //           const updateSingleReminderDate = (reminderDate) => {
  //             // Find the first index with status `false` and update it to `true`
  //             for (let i = 0; i < reminderDate.length; i++) {
  //               if (reminderDate[i].status === false) {
  //                 reminderDate[i].status = true;
  //                 break;
  //               }
  //             }
  //             return reminderDate;
  //           };
  
  //           const updatedReminderDate = updateSingleReminderDate(value.reminderDate);
  
  //           if (gicDocSnap.exists()) {
  //             await updateDoc(gicDocRef, {
  //               reminderDate: updatedReminderDate,
  //             });
  //           } else {
  //             await updateDoc(doc(db, "thirdPartyPolicy", value.id), {
  //               reminderDate: updatedReminderDate,
  //             });
  //           }
  
  //         }
  //       }
  //     } else {
  //       console.log("No such document!");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data: ", error);
  //   }
  // };
  

  // const handleWhatsAppClick = async (value) => {
  //   try {
  //     console.log("value :>> ", value.customerId);
  //     console.log("value :>> ", value);

  //     const oldGicId = value.id;
  //     setSelectedGicPolicy(oldGicId);
  //     setSelectedPolicy(value);
  //     setReminder(value);

  //     // Fetch customer and reminder data in parallel
  //     const [customerDoc, reminderSnap] = await Promise.all([
  //       getDoc(doc(db, "customers", value.customerId)),
  //       getDocs(
  //         query(collection(db, "reminder"), where("policyId", "==", value.id))
  //       ),
  //     ]);

  //     if (customerDoc.exists()) {
  //       const customerData = customerDoc.data();
  //       const customerPhoneNumber = customerData.Personal.phone_number;
  //       console.log("Customer Data: ", customerPhoneNumber);
  //       setCustomerNumber(customerPhoneNumber);

  //       if (!reminderSnap.empty) {
  //         const reminderDoc = reminderSnap.docs[0];
  //         if (reminderDoc.exists()) {
  //           const reminderMessage = reminderDoc.data().reminderMsg;
  //           setMessage(reminderMessage);

  //           const gicDocRef = doc(db, "gic", value.id);
  //           const gicDocSnap = await getDoc(gicDocRef);

  //           if (gicDocSnap.exists()) {
  //             await updateDoc(doc(db, "gic", value.id), {
  //               reminderDate: value.reminderDate.map((item) => ({
  //                 status: true,
  //               })),
  //             });
  //           } else {
  //             await updateDoc(doc(db, "thirdPartyPolicy", value.id), {
  //               reminderDate: value.reminderDate.map((item) => ({
  //                 ...item,
  //                 status: true,
  //               })),
  //             });
  //           }

  //           // const whatsappUrl = `https://wa.me/${customerPhoneNumber}?text=${encodeURIComponent(reminderMessage)}`;
  //           // console.log('whatsappUrl :>> ', whatsappUrl);
  //           // window.open(whatsappUrl, "_blank");
  //         }
  //       }
  //     } else {
  //       console.log("No such document!");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data: ", error);
  //   }
  // };

  const handleRenewSubmit = async (e) => {
    e.preventDefault();
    setRenewSubmitting(true);

    try {
      const dataToSave =
        selectedPolicy.source === "GIC"
          ? {
              name: selectedPolicy.name,
              planName: selectedPolicy.planName,
              policyType: selectedPolicy.policyType,
              policyTerm: selectedPolicy.policyTerm,
              personalCode: selectedPolicy.personalCode,
              companyCode: selectedPolicy.companyCode,
              remarks: selectedPolicy.remarks,
              planNumber: selectedPolicy.planNumber,
              clientId: clientId,
              customerId: selectedPolicy.customerId,
              companyId: selectedPolicy.companyId,
              documents: selectedPolicy.documents,
              policyNumber: renewData.policyNumber,
              companyName: renewData.companyName,
              premiumAmount: renewData.premiumAmount,
              startDate: renewData.startDate,
              endDate: renewData.endDate,
              reminderDate: renewData.reminderDate,
              notificationDate: renewData.notificationDate,
              created_at: serverTimestamp(),
              updated_at: serverTimestamp(),
            }
          : {
              name: selectedPolicy.name,
              howManyYear: selectedPolicy.howManyYear,
              companyCode: selectedPolicy.companyCode,
              vehicleName: selectedPolicy.vehicleName,
              vehicleDetails: selectedPolicy.vehicleDetails,
              ref_by: selectedPolicy.ref_by,
              customerId: selectedPolicy.customerId,

              policyNumber: thirdPartyData.policyNumber,
              companyName: thirdPartyData.companyName,
              premiumAmount: thirdPartyData.premiumAmount,
              startDate: thirdPartyData.startDate,
              endDate: thirdPartyData.endDate,
              reminderDate: thirdPartyData.reminderDate,
              notificationDate: thirdPartyData.notificationDate,
              created_at: serverTimestamp(),
              updated_at: serverTimestamp(),
            };

      const collectionName =
        selectedPolicy.source === "GIC" ? "gic" : "thirdPartyPolicy";
      const docRef = await addDoc(collection(db, collectionName), dataToSave);

      const newId = docRef.id;
      console.log(`${collectionName} ID :>> `, newId);

      await updateDoc(doc(db, collectionName, newId), {
        id: newId,
      });

      toast.success("Policy renewe successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      resetForm();
      console.log("renewData :>> ", renewData);
      console.log("thirdPartyData :>> ", thirdPartyData);

      const newFlow = [...Flow];
      newFlow[3].policyRenewed = true;
      setFlow(newFlow);

      // Handle the response if needed
    } catch (error) {
      // Handle any errors that occur during the API request
      console.error("Error:", error);
    } finally {
      setRenewSubmitting(false);
      setRenewOpenDialog(false);
    }
  };

  const resetForm = () => {
    setRenewData({
      policyNumber: "",
      premiumAmount: "",
      companyName: "",
      startDate: "",
      endDate: "",
    });
  };

  useEffect(() => {
    // Function to filter customers based on start and end date
    const filterCustomersByDate = () => {
      if (startDate && endDate) {
        const formattedStartDate = new Date(startDate).toLocaleDateString(
          "en-CA"
        );
        const formattedEndDate = new Date(endDate).toLocaleDateString("en-CA");
        const filteredData = customers.filter((customer) => {
          if (customer.reminderDate) {
            return customer.reminderDate.some((dateObj) => {
              const date = new Date(dateObj.date);
              const formattedDate = date.toLocaleDateString("en-CA");
              return (
                formattedDate >= formattedStartDate &&
                formattedDate <= formattedEndDate
              );
            });
          }
          return false;
        });
        setFilteredCustomers(filteredData);
      } else {
        setFilteredCustomers([]);
      }
    };

    filterCustomersByDate();
  }, [startDate, endDate, customers]);

  const flattenData = (data) => {
    return data.map((item) => ({
      id: item.id,
      name: item.name || "-",
      policyName: item.policyName || item.planName || "-",
      premiumAmount: item.premiumAmount || "-",
      policyNumber: item.policyNumber || "-",
      sumAssured: item.sumAssured || "-",
      source: item.source || "-",
      firstField:
        item.reminderDate && item.reminderDate[0]
          ? item.reminderDate[0].status === true
          : true,
      secondField:
        item.reminderDate && item.reminderDate[1]
          ? item.reminderDate[1].status === true
          : true,
      thirdField:
        item.reminderDate && item.reminderDate[2]
          ? item.reminderDate[2].status === true
          : true,
    }));
  };

  useEffect(() => {
    setFilteredCustomers(customers); // Initialize filtered data with all data
  }, [customers]);

  const flattenedCustomers = flattenData(
    filteredCustomers.length > 0 ? filteredCustomers : []
  );

  // Define custom styles for the Radio button
  const CustomRadio = withStyles({
    root: {
      color: "#000", // Default color when unchecked
      "&$checked": {
        color: "#000", // Color when checked
        backgroundColor: "black", // Background color when checked
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  const getNameStyle = (firstField, secondField, thirdField) => {
    const allChecked = firstField && secondField && thirdField;
    return {
      textDecoration: allChecked ? "line-through" : "none",
      color: allChecked ? "#bababa" : "inherit",
    };
  };

  const columns = [
    {
      name: "no",
      label: "No",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                width: "60px",
                maxWidth: "30px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: "center",
              }}
            >
              {tableMeta.rowIndex + 1}
            </div>
          );
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          // const { firstField, secondField, thirdField } = flattenedCustomers[tableMeta.rowIndex];
          // const allChecked = firstField && secondField && thirdField;
          // const textLine = allChecked ? 'line-through' : 'inherit';
          // const textColor = allChecked ? '#bababa' : 'inherit';

          const { firstField, secondField, thirdField } =
            flattenedCustomers[tableMeta.rowIndex];
          const style = getNameStyle(firstField, secondField, thirdField);

          return (
            <div
              style={{
                // maxWidth: "150px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                // ...style,
              }}
            >
              {capitalizeFirstLetter(value)}
            </div>
          );
        },
      },
    },
    {
      name: "firstField",
      label: "1st",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div
              style={{
                maxWidth: "250px",
                width: "30px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <CustomRadio checked={value} readOnly />
            </div>
          );
        },
      },
    },
    {
      name: "secondField",
      label: "2nd",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div
              style={{
                maxWidth: "150px",
                width: "30px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <CustomRadio checked={value} readOnly />
            </div>
          );
        },
      },
    },
    {
      name: "thirdField",
      label: "3rd",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div
              style={{
                maxWidth: "200px",
                width: "30px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <CustomRadio checked={value} readOnly />
            </div>
          );
        },
      },
    },
    {
      name: "policyName",
      label: "Policy Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          const capitalizeFirstLetter = (str) => {
            if (!str) return str;
            return str.charAt(0).toUpperCase() + str.slice(1);
          };

          return (
            <div
              style={{
                maxWidth: "200px",
                // width: "130px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {capitalizeFirstLetter(value)}
            </div>
          );
        },
      },
    },
    {
      name: "premiumAmount",
      label: "Premium amount",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div
              style={{
                maxWidth: "200px",
                width: "145px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {value}
            </div>
          );
        },
      },
    },
    {
      name: "policyNumber",
      label: "Policy Number ",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div
              style={{
                maxWidth: "200px",
                width: "145px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {value}
            </div>
          );
        },
      },
    },
    {
      name: "sumAssured",
      label: "Sum Assured",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div
              style={{
                maxWidth: "200px",
                width: "130px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {value}
            </div>
          );
        },
      },
    },
    {
      name: "source",
      label: "Policy",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div
              style={{
                maxWidth: "200px",
                width: "50px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {value}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {/* Eye */}
            <div>
              <IconButton
                sx={{ pt: 0 }}
                onClick={() =>
                  handleIconClick(filteredCustomers[tableMeta.rowIndex])
                }
              >
                <img src={PRIcon} alt="view" height={20} />
              </IconButton>
            </div>

            <div>
              <IconButton
                sx={{ pt: 0 }}
                onClick={() =>
                  handleWhatsAppClick(filteredCustomers[tableMeta.rowIndex])
                }
              >
                <WhatsAppIcon sx={{ color: "black" }} />
              </IconButton>
            </div>
          </div>
        ),
      },
    },
  ];

  const handleSort = (columnName, direction) => {
    const sortedData = [...flattenedCustomers].sort((a, b) => {
      if (direction === "asc") {
        return a[columnName] > b[columnName] ? 1 : -1;
      }
      return a[columnName] < b[columnName] ? 1 : -1;
    });
    setFilteredCustomers(sortedData);
  };

  const rows = 10; // Number of skeleton rows
  const columnsCount = 7; // Number of columns

  const options = {
    filterType: "multiselect",
    responsive: "standard",
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: loading ? (
          <Skeleton rows={rows} columns={columnsCount} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Search keyword",
    customSearchRender: (searchText, handleSearch) => {
      return (
        <CustomSearchRender searchText={searchText} onSearch={handleSearch} />
      );
    },
    // onColumnSortChange: handleSort,
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setFlow([
      { index: 0, reminderSent: false },
      { index: 1, premiumReceived: false, type: "Cash" },
      { index: 2, premiumPaid: false },
      { index: 3, policyRenewed: false },
      { index: 4, dataUpdated: false },
    ]);
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <>
      <MetaTags>
        <title>Premium Reminders</title>
      </MetaTags>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <Sidebar />
          <Box
            sx={{ flex: 1, backgroundColor: "#F4F7F9", overflowX: "hidden" }}
          >
            <CustomerProvider />
            <Navbar />
            <Box
              // component="main"
              sx={{
                p: 2,
              }}
            >
              <Grid
                container
                spacing={3}
                alignItems="center"
                justifyContent="end"
              >
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="start-date-picker"></InputLabel>
                    <TextField
                      id="start-date-picker"
                      type="date"
                      value={startDate}
                      onChange={(e) => handleStartDateChange(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="end-date-picker"></InputLabel>
                    <TextField
                      id="end-date-picker"
                      type="date"
                      value={endDate}
                      onChange={(e) => handleEndDateChange(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Box sx={{ padding: "1%" }}>
                <Box
                  borderRadius="17px"
                  sx={{ boxShadow: "0px 9px 20px rgba(46, 35, 94, 0.07)" }}
                  overflow="hidden"
                >
                  <CustomMuiPaper>
                    <MuiDatatables
                      data={loading ? [] : flattenedCustomers}
                      columns={columns}
                      options={options}
                    />
                  </CustomMuiPaper>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Dialog open={openDialog}>
          {/* <DialogTitle>Reminders</DialogTitle>
          <Divider /> */}
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                {/* Policy Name */}
                <Grid item xs={12} sm={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      // justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          minWidth: "140px",
                        }}
                        InputProps={{
                          readOnly: true,
                        }}
                      >
                        Policy Name :
                      </Typography>
                    </Box>

                    <p style={{ textAlign: "start" }}>
                      {selectedPolicy.planName ||
                        selectedPolicy.policyName ||
                        " "}
                    </p>
                  </Box>
                </Grid>

                {/* Old Premium */}
                <Grid item xs={12} sm={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      // justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          minWidth: "140px",
                        }}
                        InputProps={{
                          readOnly: true,
                        }}
                      >
                        Old Premium :
                      </Typography>
                    </Box>

                    <p style={{ textAlign: "start" }}>
                      {selectedPolicy.premiumAmount || " "}
                    </p>
                  </Box>
                </Grid>
                {/* Premium amount */}
                <Grid item xs={12} sm={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          minWidth: "135px",
                        }}
                      >
                        New Premium :
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      type="number"
                      variant="outlined"
                      sx={{ height: "45px" }}
                      name="premiumAmount"
                      value={reminder?.premiumAmount || " "}
                      onChange={handleChange}
                    />
                    <DialogActions>
                      <Button
                        type="submit"
                        disabled={submitting}
                        variant="contained"
                        sx={{
                          backgroundColor: "#57dd36",
                          p: 1,
                          ml: 1,
                          display: "flex",
                          justifyContent: "center",
                          "&:hover": {
                            backgroundColor: "#57dd36", // Ensures the background color stays the same on hover
                          },
                        }}
                      >
                        {submitting ? (
                          <CircularProgress size={24} sx={{ color: "white" }} />
                        ) : (
                          "Set"
                        )}
                      </Button>
                    </DialogActions>
                  </Box>
                </Grid>
              </Grid>
            </form>

            <Divider />

            <Grid container spacing={2}>
              {/* Reminder Sent */}
              <Grid item xs={12} sm={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "35px",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        minWidth: "170px",
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                    >
                      Reminder Sent : &nbsp; {statusCount}
                    </Typography>
                  </Box>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Flow[0].reminderSent}
                        onChange={handleCheckboxChange(0, "reminderSent")}
                        sx={{
                          "&.Mui-checked": {
                            color: "#3bd615",
                          },
                        }}
                      />
                    }
                  />
                </Box>
              </Grid>

              {/* Premium Recived */}
              <Grid item xs={12} sm={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "35px",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        minWidth: "170px",
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                    >
                      Premium Recived :
                    </Typography>
                  </Box>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Flow[1].premiumReceived}
                        onChange={handleCheckboxChange(1, "premiumReceived")}
                        sx={{
                          "&.Mui-checked": {
                            color: "#3bd615",
                          },
                        }}
                      />
                    }
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "35px",
                  }}
                >
                  <RadioGroup
                    row
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      width: "100%",
                    }}
                    value={Flow[1].value}
                    onChange={(e) => handleRadioChange(1, e.target.value)}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: "#3bd615",
                            },
                          }}
                        />
                      }
                      label="Cash"
                      name="Cash"
                      value="Cash"
                      checked={Flow[1].value === "Cash"}
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: "#3bd615",
                            },
                          }}
                        />
                      }
                      label="Cheque"
                      name="Cheque"
                      value="Cheque"
                      checked={Flow[1].value === "Cheque"}
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: "#3bd615",
                            },
                          }}
                        />
                      }
                      label="UPI"
                      name="UPI"
                      value="UPI"
                      checked={Flow[1].value === "UPI"}
                    />
                  </RadioGroup>
                </Box>
              </Grid>

              {/* Premium Paid */}
              <Grid item xs={12} sm={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "35px",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        minWidth: "170px",
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                    >
                      Premium Paid :
                    </Typography>
                  </Box>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Flow[2].premiumPaid}
                        onChange={handleCheckboxChange(2, "premiumPaid")}
                        sx={{
                          "&.Mui-checked": {
                            color: "#3bd615",
                          },
                        }}
                      />
                    }
                  />
                </Box>
              </Grid>

              {/* Policy Renewed */}
              <Grid item xs={12} sm={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "35px",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        minWidth: "170px",
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                    >
                      Policy Renewed :
                    </Typography>
                  </Box>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Flow[3].policyRenewed}
                        onChange={handleCheckboxChange(3, "policyRenewed")}
                        sx={{
                          "&.Mui-checked": {
                            color: "#3bd615",
                          },
                        }}
                      />
                    }
                  />
                </Box>
              </Grid>

              <Dialog open={openRenewDialog}>
                <DialogTitle>Policy Renewed</DialogTitle>
                <Divider />
                <form onSubmit={handleRenewSubmit}>
                  <DialogContent>
                    <Grid container spacing={2}>
                      {/* Policy Number */}
                      <Grid item xs={12} sm={12}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            height: "35px",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                fontSize: "16px",
                                minWidth: "155px",
                              }}
                            >
                              Policy Number :
                            </Typography>
                          </Box>

                          <TextField
                            fullWidth
                            variant="outlined"
                            sx={{ height: "45px" }}
                            name="policyNumber"
                            value={
                              renewData.policyNumber ||
                              thirdPartyData.policyNumber
                            }
                            onChange={handleInputChange}
                            type="text"
                            required
                          />
                        </Box>
                      </Grid>

                      {/* Company Name */}
                      <Grid item xs={12} sm={12}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            height: "35px",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                fontSize: "16px",
                                minWidth: "155px",
                              }}
                            >
                              Company Name :
                            </Typography>
                          </Box>

                          <TextField
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            sx={{ height: "45px" }}
                            name="companyName"
                            value={
                              renewData.companyName ||
                              thirdPartyData.companyName
                            }
                            onChange={handleInputChange}
                            type="text"
                            required
                          />
                        </Box>
                      </Grid>

                      {/* Policy Amount */}
                      <Grid item xs={12} sm={12}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            height: "35px",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                fontSize: "16px",
                                minWidth: "155px",
                              }}
                            >
                              Policy Amount :
                            </Typography>
                          </Box>

                          <TextField
                            fullWidth
                            variant="outlined"
                            sx={{ height: "45px" }}
                            name="premiumAmount"
                            value={
                              renewData.premiumAmount ||
                              thirdPartyData.premiumAmount
                            }
                            onChange={handleInputChange}
                            type="number"
                            required
                          />
                        </Box>
                      </Grid>

                      {/* Start Date */}
                      <Grid item xs={12} sm={12}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            height: "35px",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                fontSize: "16px",
                                minWidth: "155px",
                              }}
                            >
                              Start Date :
                            </Typography>
                          </Box>

                          <TextField
                            fullWidth
                            variant="outlined"
                            sx={{ height: "45px" }}
                            name="startDate"
                            value={
                              renewData.startDate || thirdPartyData.startDate
                            }
                            onChange={handleInputChange}
                            type="date"
                            required
                          />
                        </Box>
                      </Grid>

                      {/* End Date */}
                      <Grid item xs={12} sm={12}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            height: "35px",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                fontSize: "16px",
                                minWidth: "155px",
                              }}
                            >
                              End Date :
                            </Typography>
                          </Box>

                          <TextField
                            fullWidth
                            variant="outlined"
                            sx={{ height: "45px" }}
                            name="endDate"
                            value={renewData.endDate || thirdPartyData.endDate}
                            onChange={handleInputChange}
                            type="date"
                            required
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleCloseRenewDialog}
                      variant="outlined"
                      sx={{
                        border: "1px solid #57dd36",
                        color: "#57dd36",
                        p: 1,
                        "&:hover": {
                          border: "1px solid #57dd36", // Ensures the background color stays the same on hover
                        },
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disabled={renewSubmitting}
                      variant="contained"
                      sx={{
                        backgroundColor: "#57dd36",
                        p: 1,
                        "&:hover": {
                          backgroundColor: "#57dd36", // Ensures the background color stays the same on hover
                        },
                      }}
                    >
                      {renewSubmitting ? (
                        <CircularProgress size={24} sx={{ color: "white" }} />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>

              {/* Data Updated */}
              <Grid item xs={12} sm={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "35px",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        minWidth: "170px",
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                    >
                      Data Updated :
                    </Typography>
                  </Box>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Flow[4].dataUpdated}
                        onChange={handleCheckboxChange(4, "dataUpdated")}
                        sx={{
                          "&.Mui-checked": {
                            color: "#3bd615",
                          },
                        }}
                      />
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={handleCloseDialog}
              variant="outlined"
              sx={{
                border: "1px solid #57dd36",
                color: "#57dd36",
                p: 1,
                "&:hover": {
                  border: "1px solid #57dd36", // Ensures the background color stays the same on hover
                },
              }}
            >
              Close
            </Button>
            <Button
              onClick={updateReminder}
              variant="contained"
              disabled={loading}
              sx={{
                backgroundColor: "#57dd36",
                p: 1,
                "&:hover": {
                  backgroundColor: "#57dd36", // Ensures the background color stays the same on hover
                },
              }}
            >
              {loading ? (
                <CircularProgress size={24} sx={{ color: "white" }} />
              ) : (
                "Done"
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default PremiumReminders;
