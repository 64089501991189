// import "./App.css";
import { motion } from "framer-motion";
import { Add, Home, Info, Notifications, Settings } from "@material-ui/icons";
import { useEffect, useState } from "react";
// import "./Sidebar.css";
import { Box, CssBaseline, ListItemIcon, Typography } from "@mui/material";
import Brandlogo from "../../components/images/logo-nav.png";
import Life from "../../components/images/LifeInsurence.png";
import General from "../../components/images/general.png";
import SubMenuIcon from "../../components/images/submenu-icon.png";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import ReceiptRoundedIcon from "@mui/icons-material/ReceiptRounded";
import PersonIcon from "@mui/icons-material/Person";
import Navbar from "../../components/Navbar/Navbar";
// import LIC from "../../components/images/insurance.png"
// import GIC from "../../components/images/health-insurance.png"

function Sidebars() {
  const [open, setOpen] = useState(true);

  // for collpsing sidebar
  const handleToggle = () => {
    setOpen(!open);
  };

  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [manualToggle, setManualToggle] = useState(false);
  // console.log("sidebarCollapsed :>> ", sidebarCollapsed);
  const location = useLocation();

  const handleMenuClick = (menuItem) => {
    setSidebarCollapsed(false);
    setManualToggle(!manualToggle);
  };

  const handleMouseEnter = () => {
    if (manualToggle) {
      setSidebarCollapsed(false);
    }
  };

  const handleMouseLeave = () => {
    if (manualToggle) {
      setSidebarCollapsed(true);
    }
  };

  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [hoveredMenuItem, setHoveredMenuItem] = useState(null);
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  const handleSubMenuClick = (menuItem) => {
    setSubMenuOpen(!subMenuOpen);
    // setActiveSubMenu(activeSubMenu === menuItem ? null : menuItem);

    // setActiveSubMenu(activeSubMenu === "Customer" ? null : "Customer");
  };

  const handleMenuItemMouseEnter = (menuItem) => {
    setHoveredMenuItem(menuItem);
    setSubMenuOpen(menuItem);
  };

  const handleMenuItemMouseLeave = () => {
    setHoveredMenuItem(null);
  };

  const isCustomerActive =
    location.pathname === "/newcustomer" ||
    location.pathname === "/allcustomers";

  const isLICActive =
    location.pathname === "/newlicpolicy" ||
    location.pathname === "/alllicpolicy";

  const isSettingActive =
    // location.pathname === "/newlicpolicy" ||
    location.pathname === "/managegstrates";

  const isGICActive =
    location.pathname === "/newgicpolicy" ||
    location.pathname === "/thirdparty" ||
    location.pathname === "/allthirdpartypolicy" ||
    location.pathname === "/allgicpolicy";

  return (
    <Box sx={{ display: "flex", height: "100vh", zIndex: 100, overflow:"hidden"}}>
      {/* <CssBaseline /> */}
      
      {/* <Box height={40} /> */}
      <Sidebar
        collapsed={sidebarCollapsed}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{height:"100vh", overflow:"hidden"}}
      >
        <div
          className="css-dip3t8"
          style={{
            backgroundColor: "#2A2B2B",
            transition: "background-color 0.3s ease",
          }}
          // onMouseEnter={handleMouseEnter}
          // onMouseLeave={handleMouseLeave}
        >
          <Menu>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px 20px",
                cursor: "pointer",
              }}
              onClick={handleMenuClick}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  style={{ width: 48, marginRight: 10 }}
                  src={Brandlogo}
                  alt="Logo"
                />
                {!sidebarCollapsed && (
                  <>
                    <strong style={{ color: "white", fontSize: "18px", padding:"10px 0px" }}>
                      Auto
                    </strong>
                    <strong style={{ fontSize: "18px", color: "#3BD615", padding:"10px 0px" }}>
                      PULSE
                    </strong>
                  </>
                )}
              </div>
              {/* <MenuRoundedIcon style={{ color: "white" }} /> */}
              <motion.div
                whileHover={{
                  scale: 1.2,
                  rotate: 180,
                  // backgroundColor: "rgba(255, 255, 255, 0.3)",
                  // backdropFilter: "blur(3.5px)",
                  // WebkitBackdropFilter: "blur(3.5px)",
                  // border: "1px solid rgba( 255, 255, 255, 0.18 )",
                  transition: {
                    delay: 0.2,
                    duration: 0.4,
                  },
                }}
                onClick={handleToggle}
                // className="lines_icon"
              >
                {/* <TocRounded /> */}
                <Box sx={{height:"25px"}}>
                <GridViewRoundedIcon style={{ color: "white" }} />
                </Box>
              </motion.div>
            </div>
            <Box height={20} />
            {/* Dashboard */}
            <MenuItem
              component={<Link to="/cDashboard" />}
              icon={<Home />}
              style={{
                color: "white",
                backgroundColor:
                  hoveredMenuItem === "Dashboard" ||
                  location.pathname === "/cDashboard"
                    ? "#8c8c8c40"
                    : "#2A2B2B",
                height: "45px",
                marginTop: "5px",
                p: 0,
                fontSize: "14px",
                fontWeight: 500,
                letterSpacing: ".06em",
                whiteSpace: "nowrap",
              }}
              // onMouseEnter={() => handleMenuItemMouseEnter("Dashboard")}
              // onMouseLeave={handleMenuItemMouseLeave}
            >
              {" "}
              Dashboard{" "}
            </MenuItem>

            {/* Premium Reminders */}
            <MenuItem
            component={<Link to="/premiumreminders" />}
              icon={<Notifications />}
              style={{
                color: "white",
                backgroundColor:
                  hoveredMenuItem === "Premium Reminders"
                    ? "#8c8c8c40"
                    : "#2A2B2B",
                height: "45px",
                p: 0,
                fontSize: "14px",
                fontWeight: 500,
                letterSpacing: ".06em",
                whiteSpace: "nowrap",
              }}
              // onMouseEnter={() => handleMenuItemMouseEnter("Premium Reminders")}
              // onMouseLeave={handleMenuItemMouseLeave}
            >
              {" "}
              Premium Reminders{" "}
            </MenuItem>

            {/* Customer */}
            <SubMenu
              defaultOpen={location.pathname === "/newcustomer" || location.pathname === "/allcustomers" ? true : false}
              label="Customer"
              icon={<PersonIcon />}
              style={{
                color: "white",
                backgroundColor:  isCustomerActive ? "#8c8c8c40" : "#2A2B2B",
                // || isCustomerActive
                margin: "0px",
                borderRadius: "5px",
                height: "45px",
                fontSize: "14px",
                fontWeight: 500,
                letterSpacing: ".06em",
                whiteSpace: "nowrap",
              }}
              onClick={handleSubMenuClick}
              // onClick={() => handleSubMenuClick("Customer")}

              // onMouseEnter={() => handleMenuItemMouseEnter("Customer")}
              // onMouseLeave={handleMenuItemMouseLeave}
            >
              <MenuItem
                component={<Link to="/newcustomer" />}
                icon={<img src={SubMenuIcon} alt="" style={{ width: '20px', height: 'auto', filter:"invert(1)" }}  />}
                style={{
                  // color: "white",
                  backgroundColor: "#2A2B2B",
                  color:
                    location.pathname === "/newcustomer" || hoveredMenuItem === "New Customer" ? "#3BD615" : "white",
                  height: "45px",
                  // p: 0,
                  paddingLeft: "50px",
                  fontSize: "14px",
                  fontWeight: 500,
                  letterSpacing: ".06em",
                  whiteSpace: "nowrap",
                }}
                onMouseEnter={() => handleMenuItemMouseEnter("New Customer")}
                onMouseLeave={handleMenuItemMouseLeave}
              >
                {" "}
                New Customer{" "}
              </MenuItem>
              <MenuItem
                component={<Link to="/allcustomers" />}
                icon={<img src={SubMenuIcon} alt="" style={{ width: '20px', height: 'auto', filter:"invert(1)" }}  />}
                style={{
                  color:
                  location.pathname === "/allcustomers" || hoveredMenuItem === "All Customer" ? "#3BD615" : "white",
                  backgroundColor: "#2A2B2B",
                  height: "45px",
                  paddingLeft: "50px",
                  fontSize: "14px",
                  fontWeight: 500,
                  letterSpacing: ".06em",
                  whiteSpace: "nowrap",
                }}
                onMouseEnter={() => handleMenuItemMouseEnter("All Customer")}
                onMouseLeave={handleMenuItemMouseLeave}
              >
                All Customer
              </MenuItem>
            </SubMenu>

            {/* Life Insurance */}
            <SubMenu
              defaultOpen={location.pathname === "/newlicpolicy" || location.pathname === "/alllicpolicy" ? true : false}
              label="Life Insurance"
              icon={<img src={Life} alt="" style={{ width: '20px', height: 'auto', filter:"invert(1)" }}  />}
              style={{
                color: "white",
                backgroundColor:  isLICActive ? "#8c8c8c40" : "#2A2B2B",

                margin: "0px",
                borderRadius: "5px",
                height: "45px",
                fontSize: "14px",
                fontWeight: 500,
                letterSpacing: ".06em",
                whiteSpace: "nowrap",
              }}
              onClick={handleSubMenuClick}
              // onMouseEnter={() => handleMenuItemMouseEnter("Customer")}
              // onMouseLeave={handleMenuItemMouseLeave}
            >
              
              <MenuItem
                component={<Link to="/newlicpolicy" />}
                icon={<img src={SubMenuIcon} alt="" style={{ width: '20px', height: 'auto', filter:"invert(1)" }}  />}
                style={{
                  // color: "white",
                  backgroundColor: "#2A2B2B",
                  color:
                    location.pathname === "/newlicpolicy" || hoveredMenuItem === "New Policy" ? "#3BD615" : "white",
                  height: "45px",
                  // p: 0,
                  paddingLeft: "50px",
                  fontSize: "14px",
                  fontWeight: 500,
                  letterSpacing: ".06em",
                  whiteSpace: "nowrap",
                }}
                onMouseEnter={() => handleMenuItemMouseEnter("New Policy")}
                onMouseLeave={handleMenuItemMouseLeave}
              >
                {" "}
                New Policy{" "}
              </MenuItem>
              <MenuItem
                component={<Link to="/alllicpolicy" />}
                icon={<img src={SubMenuIcon} alt="" style={{ width: '20px', height: 'auto', filter:"invert(1)" }}  />}
                style={{
                  color:
                    location.pathname === "/alllicpolicy" || hoveredMenuItem === "AllPolicy" ? "#3BD615" : "white",
                  backgroundColor: "#2A2B2B",
                  height: "45px",
                  paddingLeft: "50px",
                  fontSize: "14px",
                  fontWeight: 500,
                  letterSpacing: ".06em",
                  whiteSpace: "nowrap",
                }}
                onMouseEnter={() => handleMenuItemMouseEnter("AllPolicy")}
                onMouseLeave={handleMenuItemMouseLeave}
              >
                All Policy
              </MenuItem>
            </SubMenu>

            {/* General Insurance */}
            <SubMenu
              defaultOpen={location.pathname === "/newgicpolicy" || location.pathname === "/thirdparty" || location.pathname === "/allthirdpartypolicy" || location.pathname === "/allgicpolicy" ? true : false}
              label="General Insurance"
              icon={<img src={General} alt="" style={{ width: '18px', height: 'auto', filter:"invert(1)" }}  />}
              style={{
                color: "white",
                backgroundColor:  isGICActive ? "#8c8c8c40" : "#2A2B2B",

                margin: "0px",
                borderRadius: "5px",
                height: "45px",
                fontSize: "14px",
                fontWeight: 500,
                letterSpacing: ".06em",
                whiteSpace: "nowrap",
              }}
              onClick={handleSubMenuClick}
              // onClick={() => handleSubMenuClick("General Insurance")}

              // onMouseEnter={() => handleMenuItemMouseEnter("Customer")}
              // onMouseLeave={handleMenuItemMouseLeave}
            >
              <MenuItem
                component={<Link to="/newgicpolicy" />}
                icon={<img src={SubMenuIcon} alt="" style={{ width: '20px', height: 'auto', filter:"invert(1)" }}  />}
                style={{
                  // color: "white",
                  backgroundColor: "#2A2B2B",
                  color:
                    location.pathname === "/newgicpolicy" || hoveredMenuItem === "NewGICPolicy" ? "#3BD615" : "white",
                  height: "45px",
                  // p: 0,
                  paddingLeft: "50px",
                  fontSize: "14px",
                  fontWeight: 500,
                  letterSpacing: ".06em",
                  whiteSpace: "nowrap",
                }}
                onMouseEnter={() => handleMenuItemMouseEnter("NewGICPolicy")}
                onMouseLeave={handleMenuItemMouseLeave}
              >
                {" "}
                New Policy{" "}
              </MenuItem>
              <MenuItem
                component={<Link to="/thirdparty" />}
                icon={<img src={SubMenuIcon} alt="" style={{ width: '20px', height: 'auto', filter:"invert(1)" }}  />}
                style={{
                  color:
                    location.pathname === "/thirdparty" || hoveredMenuItem === "ThirdParty" ? "#3BD615" : "white",
                  backgroundColor: "#2A2B2B",
                  height: "45px",
                  paddingLeft: "50px",
                  fontSize: "14px",
                  fontWeight: 500,
                  letterSpacing: ".06em",
                  whiteSpace: "nowrap",
                }}
                onMouseEnter={() => handleMenuItemMouseEnter("ThirdParty")}
                onMouseLeave={handleMenuItemMouseLeave}
              >
                Third Party Policy
              </MenuItem>
              <MenuItem
                component={<Link to="/allthirdpartypolicy" />}
                icon={<img src={SubMenuIcon} alt="" style={{ width: '20px', height: 'auto', filter:"invert(1)" }}  />}
                style={{
                  color:
                    location.pathname === "/allthirdpartypolicy" || hoveredMenuItem === "AllThirdParty"
                      ? "#3BD615"
                      : "white",
                  backgroundColor: "#2A2B2B",
                  height: "45px",
                  paddingLeft: "50px",
                  fontSize: "14px",
                  fontWeight: 500,
                  letterSpacing: ".06em",
                  whiteSpace: "nowrap",
                }}
                onMouseEnter={() => handleMenuItemMouseEnter("AllThirdParty")}
                onMouseLeave={handleMenuItemMouseLeave}
              >
                All Third Party Policy
              </MenuItem>
              <MenuItem
                component={<Link to="/allgicpolicy" />}
                icon={<img src={SubMenuIcon} alt="" style={{ width: '20px', height: 'auto', filter:"invert(1)" }}  />}
                style={{
                  color:
                    location.pathname === "/allgicpolicy" || hoveredMenuItem === "AllGICParty" ? "#3BD615" : "white",
                  backgroundColor: "#2A2B2B",
                  height: "45px",
                  paddingLeft: "50px",
                  fontSize: "14px",
                  fontWeight: 500,
                  letterSpacing: ".06em",
                  whiteSpace: "nowrap",
                }}
                onMouseEnter={() => handleMenuItemMouseEnter("AllGICParty")}
                onMouseLeave={handleMenuItemMouseLeave}
              >
                All Policy
              </MenuItem>
            </SubMenu>

            {/* Intimation */}
            <MenuItem
              icon={<Info />}
              style={{
                color: "white",
                backgroundColor:
                  hoveredMenuItem === "Intimation" ? "#8c8c8c40" : "#2A2B2B",
                height: "45px",
                p: 0,
                fontSize: "14px",
                fontWeight: 500,
                letterSpacing: ".06em",
                whiteSpace: "nowrap",
              }}
              // onMouseEnter={() => handleMenuItemMouseEnter("Intimation")}
              // onMouseLeave={handleMenuItemMouseLeave}
            >
              {" "}
              Intimation{" "}
            </MenuItem>

             {/* Reports */}
             {/* <SubMenu
              defaultOpen={false}
              label="Reports"
              icon={<PersonIcon />}
              style={{
                color: "white",
                backgroundColor:  isGICActive ? "#8c8c8c40" : "#2A2B2B",

                margin: "0px",
                borderRadius: "5px",
                height: "45px",
                fontSize: "14px",
                fontWeight: 500,
                letterSpacing: ".06em",
                whiteSpace: "nowrap",
              }}
              onClick={handleSubMenuClick}
              // onMouseEnter={() => handleMenuItemMouseEnter("Customer")}
              // onMouseLeave={handleMenuItemMouseLeave}
            >
              <MenuItem
                component={<Link to="#" />}
                // icon={<PersonAddIcon />}
                style={{
                  // color: "white",
                  backgroundColor: "#2A2B2B",
                  color:
                    location.pathname === "#" ? "#3BD615" : "white",
                  height: "45px",
                  // p: 0,
                  paddingLeft: "90px",
                  fontSize: "14px",
                  fontWeight: 500,
                  letterSpacing: ".06em",
                  whiteSpace: "nowrap",
                }}
              >
                {" "}
                Client Detail Report{" "}
              </MenuItem>

              <MenuItem
                component={<Link to="#" />}
                // icon={<PersonAddIcon />}
                style={{
                  // color: "white",
                  backgroundColor: "#2A2B2B",
                  color:
                    location.pathname === "#" ? "#3BD615" : "white",
                  height: "45px",
                  // p: 0,
                  paddingLeft: "90px",
                  fontSize: "14px",
                  fontWeight: 500,
                  letterSpacing: ".06em",
                  whiteSpace: "nowrap",
                }}
              >
                {" "}
                LIC Policy Report{" "}
              </MenuItem>

              <MenuItem
                component={<Link to="#" />}
                // icon={<PersonAddIcon />}
                style={{
                  // color: "white",
                  backgroundColor: "#2A2B2B",
                  color:
                    location.pathname === "#" ? "#3BD615" : "white",
                  height: "45px",
                  // p: 0,
                  paddingLeft: "90px",
                  fontSize: "14px",
                  fontWeight: 500,
                  letterSpacing: ".06em",
                  whiteSpace: "nowrap",
                }}
              >
                {" "}
                GIC Policy Report{" "}
              </MenuItem>

              <MenuItem
                component={<Link to="#" />}
                // icon={<PersonAddIcon />}
                style={{
                  // color: "white",
                  backgroundColor: "#2A2B2B",
                  color:
                    location.pathname === "#" ? "#3BD615" : "white",
                  height: "45px",
                  // p: 0,
                  paddingLeft: "90px",
                  fontSize: "14px",
                  fontWeight: 500,
                  letterSpacing: ".06em",
                  whiteSpace: "nowrap",
                }}
              >
                {" "}
                Premium Reminder Report{" "}
              </MenuItem>

              <MenuItem
                component={<Link to="#" />}
                // icon={<PersonAddIcon />}
                style={{
                  // color: "white",
                  backgroundColor: "#2A2B2B",
                  color:
                    location.pathname === "#" ? "#3BD615" : "white",
                  height: "45px",
                  // p: 0,
                  paddingLeft: "90px",
                  fontSize: "14px",
                  fontWeight: 500,
                  letterSpacing: ".06em",
                  whiteSpace: "nowrap",
                }}
              >
                {" "}
                Self Account Report{" "}
              </MenuItem>
             
            </SubMenu> */}

            {/* Settings */}
            <SubMenu
              defaultOpen={location.pathname === "#" || location.pathname === "/managegstrates" ? true : false}
              label="Settings"
              icon={<Settings />}
              style={{
                color: "white",
                backgroundColor:  isSettingActive ? "#8c8c8c40" : "#2A2B2B",

                margin: "0px",
                borderRadius: "5px",
                height: "45px",
                fontSize: "14px",
                fontWeight: 500,
                letterSpacing: ".06em",
                whiteSpace: "nowrap",
              }}
              onClick={handleSubMenuClick}
              // onMouseEnter={() => handleMenuItemMouseEnter("Customer")}
              // onMouseLeave={handleMenuItemMouseLeave}
            >
              <MenuItem
                component={<Link to="#" />}
                icon={<img src={SubMenuIcon} alt="" style={{ width: '20px', height: 'auto', filter:"invert(1)" }}  />}
                style={{
                  // color: "white",
                  backgroundColor: "#2A2B2B",
                  color:
                    location.pathname === "#" || hoveredMenuItem === "Change Password" ? "#3BD615" : "white",
                  height: "45px",
                  // p: 0,
                  paddingLeft: "50px",
                  fontSize: "14px",
                  fontWeight: 500,
                  letterSpacing: ".06em",
                  whiteSpace: "nowrap",
                }}
                onMouseEnter={() => handleMenuItemMouseEnter("Change Password")}
                onMouseLeave={handleMenuItemMouseLeave}
              >
                {" "}
                Change Password{" "}
              </MenuItem>
              <MenuItem
                component={<Link to="/managegstrates" />}
                icon={<img src={SubMenuIcon} alt="" style={{ width: '20px', height: 'auto', filter:"invert(1)" }}  />}
                style={{
                  color:
                    location.pathname === "/managegstrates" || hoveredMenuItem === "Manage GST Rates" ? "#3BD615" : "white",
                  backgroundColor: "#2A2B2B",
                  height: "45px",
                  paddingLeft: "50px",
                  fontSize: "14px",
                  fontWeight: 500,
                  letterSpacing: ".06em",
                  whiteSpace: "nowrap",
                }}
                onMouseEnter={() => handleMenuItemMouseEnter("Manage GST Rates")}
                onMouseLeave={handleMenuItemMouseLeave}
              >
                Manage GST Rates
              </MenuItem>
            </SubMenu>

          </Menu>
        </div>
      </Sidebar>

      {/* <Navbar/> */}
    </Box>
  );
}

export default Sidebars;
