import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "./firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  collection,
  query,
  where,
  getFirestore,
  getDocs,
} from "firebase/firestore";

const checkEmailExistsInMasterAdmin = async (email) => {
  try {
    const db = getFirestore();
    const usersRef = collection(db, "master_admin");
    const q = query(usersRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);
    // console.log("querySnapshot :>> ", querySnapshot);
    if (!querySnapshot.empty) {
      const masterAdminId = querySnapshot.docs[0].id;
      sessionStorage.setItem('masterAdminId', masterAdminId);
      // console.log('masterAdminId stored:', masterAdminId);
    }else{
      // console.log('masterAdminId not found');
      return null;
    }
    return !querySnapshot.empty; 
  } catch (error) {
    console.error("Error checking email in Master Admin table:", error);
    throw error;
  }
};

export const checkEmailExistsInClient = async (email) => {
  try {
    const db = getFirestore();
    const usersRef = collection(db, "clients");
    const q = query(usersRef, where("Personal.email", "==", email));
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      // If there's at least one matching client
      const clientData = [];
      querySnapshot.forEach((doc) => {
        clientData.push({ id: doc.id, ...doc.data() });
      });

      sessionStorage.setItem('clientId', clientData[0].id);
      // console.log('clientData[0].id :>> ',   sessionStorage.getItem('clientId'),);

      return clientData; // Return an array of client data
    } else {
      return null; // Return null if no matching client found
    }
  } catch (error) {
    console.error("Error checking email in clients table:", error);
    throw error;
  }
};

const FirebaseAuthService = {
  loginService: (email, password, navigate) => {
    return async function (dispatch) {
      console.log("Attempting login");
      // If email exists, proceed with Firebase authentication
      signInWithEmailAndPassword(auth, email, password)
        .then(async (credentials) => {
          const emailExists = await checkEmailExistsInMasterAdmin(email);
          // console.log("Admin-emailExists :>> ", emailExists);
          if (emailExists) {
            toast.success("Admin Login Successful", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }); 
            // console.log("Admin >>", credentials);
            // const token = await credentials.user.getIdToken();
            // sessionStorage.setItem(token);
            navigate("/dashboard");
          } else {
            const emailExists = await checkEmailExistsInClient(email);
            // console.log("Client-emailExists :>> ", emailExists);
            if (emailExists) {
              toast.success("Client Login Successful", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              // console.log("Clients >>", credentials);
              // const token = await credentials.user.getIdToken();
              // sessionStorage.setItem(token);
              // console.log('token :>> ', token);
              navigate("/cDashboard");
            } else {
              toast.error("Email not found in Master Admin table", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        })
        .catch((err, emailExists) => {
          console.error(err);
          if (err.code === "auth/user-not-found")
            toast.error("Incorrect email, please register first.");
          else if (err.code === "auth/wrong-password")
            toast.error("Incorrect password, please try again.");
          // else if (!emailExists)
          //   toast.error("Email not Exist in master table.");
          else toast.error("Login failed, please try again.");
        });
      // If email does not exist, show error message
      // setErrorMessage('Email not found in Master Admin table');
    };
  },
};

export default FirebaseAuthService;
