import React from "react";

const TermsCondition = () => {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <p>
        ARK Intelligence operates the AutoPulse Pro app and AutoPulse App. This
        privacy policy explains how we collect, use, and share your personal and
        sensitive user data.
      </p>
      <h4>Data Collection and Use</h4>
      <p>We collect the following types of personal and sensitive user data:</p>
      <ul>
        <li>
          <strong>Personal Information:</strong> Information you provide to us,
          such as your name, email address, and phone number.
        </li>
        <li>
          <strong>Usage Data:</strong> Information about how you use the
          Application, including access times, pages viewed, and your IP
          address.
        </li>
        <li>
          <strong>Device Data:</strong> Information about the device you use to
          access the Application, including device type, operating system, and
          unique device identifiers.
        </li>
      </ul>
      <p>We use this data to:</p>
      <ul>
        <li>Provide, operate, and maintain the Application</li>
        <li>Improve, personalize, and expand our services</li>
        <li>
          Communicate with you, either directly or through one of our partners
        </li>
        <li>Process transactions and send related information</li>
        <li>
          Monitor and analyze usage and trends to improve the user experience
        </li>
      </ul>
      <h4>Data Sharing and Disclosure</h4>
      <p>
        We may share your data with third parties in the following situations:
      </p>
      <ul>
        <li>
          With service providers that perform services on our behalf, such as
          hosting and data analytics
        </li>
        <li>
          To comply with legal obligations or to protect and defend our rights
          and property
        </li>
        <li>
          In connection with a business transfer, such as a merger or
          acquisition
        </li>
      </ul>
      <h4>Third-Party Services</h4>
      <p>
        The Application uses third-party services that may collect information
        used to identify you. Links to the privacy policies of these third-party
        service providers are:
      </p>
      <h4>Data Security</h4>
      <p>
        We are committed to protecting your data. We use industry-standard
        security measures to safeguard your information. However, no method of
        transmission over the internet or electronic storage is 100% secure, and
        we cannot guarantee absolute security.
      </p>
      <h4>Data Retention and Deletion</h4>
      <p>
        We retain your personal data only as long as necessary to fulfill the
        purposes for which it was collected, including to comply with legal,
        regulatory, or reporting requirements. You can request the deletion of
        your data by contacting us.
      </p>
      <h4>User Rights</h4>
      <p>
        You have the right to access, update, or delete your personal
        information. You can do so by contacting us at{" "}
        <a href="mailto:contact@arkintelligence.in">
          contact@arkintelligence.in
        </a>
      </p>
      <h4>Changes to This Privacy Policy</h4>
      <p>
        We may update our privacy policy from time to time. We will notify you
        of any changes by posting the new privacy policy on this page. You are
        advised to review this policy periodically for any changes.
      </p>
      <h4>Contact Us</h4>
      <p>
        If you have any questions or suggestions about our privacy policy, do
        not hesitate to contact us at{" "}
        <a href="mailto:info@arkintelligence.in">info@arkintelligence.in</a>
      </p>
    </div>
  );
};

export default TermsCondition;
